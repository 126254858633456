<template>
    <div class="container-box">
        <div class="container-box-top">
            <div class="_head">
                <h1>现货账户</h1>
                <div class="button-group">
                    <a-button class="button" @click="$emit('topup')">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                             xmlns="http://www.w3.org/2000/svg" class="">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M6 3C6 2.44772 6.44772 2 7 2H9C9.55229 2 10 2.44772 10 3V6H11.0073C11.864 6 12.3241 7.00701 11.7632 7.65465L8.75593 11.1271C8.35716 11.5876 7.64284 11.5876 7.24407 11.1271L4.23682 7.65465C3.67594 7.00701 4.136 6 4.99275 6H6V3ZM4 12C3.44772 12 3 12.4477 3 13C3 13.5523 3.44772 14 4 14H12C12.5523 14 13 13.5523 13 13C13 12.4477 12.5523 12 12 12H4Z"
                                  fill="var(--content-white)">
                            </path>
                        </svg>
                        充值
                    </a-button>
                    <a-button class="button" @click="withdrawShow = true">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                             xmlns="http://www.w3.org/2000/svg" class="">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M10 13C10 13.5523 9.55228 14 9 14L7 14C6.44771 14 6 13.5523 6 13L6 10L4.99275 10C4.136 10 3.67594 8.99299 4.23682 8.34535L7.24407 4.87287C7.64284 4.41241 8.35716 4.41241 8.75593 4.87287L11.7632 8.34535C12.3241 8.99299 11.864 10 11.0072 10L10 10L10 13ZM12 4C12.5523 4 13 3.55229 13 3C13 2.44771 12.5523 2 12 2L4 2C3.44772 2 3 2.44771 3 3C3 3.55228 3.44772 4 4 4L12 4Z"
                                  fill="var(--content-primary)">
                            </path>
                        </svg>
                        提现
                    </a-button>
                    <a-button class="button" @click="transfeShow = true">
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                             xmlns="http://www.w3.org/2000/svg" class="">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M2.26949 5.29583C1.64358 5.92699 2.09065 6.99998 2.97954 6.99998L5.39787 6.99998H13.0008C13.5531 6.99998 14.0008 6.55226 14.0008 5.99998C14.0008 5.44769 13.5531 4.99998 13.0008 4.99998H7.52129V2.42852C7.52129 1.5356 6.43998 1.09035 5.81124 1.72437L2.26949 5.29583Z"
                                  fill="var(--content-primary)"></path>
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M13.7081 10.7042C14.334 10.073 13.8869 9.00002 12.998 9.00002L10.5797 9.00002L8.45625 9.00002L2.97672 9.00002C2.42444 9.00002 1.97672 9.44774 1.97672 10C1.97672 10.5523 2.42444 11 2.97672 11L8.45625 11L8.45625 13.5715C8.45625 14.4644 9.53756 14.9096 10.1663 14.2756L13.7081 10.7042Z"
                                  fill="var(--content-primary)">
                            </path>
                        </svg>
                        划转
                    </a-button>
                </div>
            </div>
            <div class="asset-all">
                <p class="_t">
                    <span>总额</span>
                    <div @click="visual = !visual">
                        <icon-eye-invisible v-if="visual" class="color-gray" size="16"/>
                        <icon-eye v-else class="color-gray" size="16"/>
                    </div>
                </p>
                <p class="price">
                    {{ visual ? '********' : (total ? $util.thousandSeparator(total, 2) : '0.00000000') }} USDT
                </p>
                <p class="convert">
                    ≈ {{symbol}} {{ visual ? '********' : $util.thousandSeparator(total * symbol_rate,2) }}
                </p>
            </div>
        </div>
        <!--总览-->
        <div class="content-main-bottom">
            <div class="btm-left flex flex-column align-start">
                <div class="flex align-center jus-bet btm-left-head">
                    <div class="flex align-center">
                        <span class="font-20 font-bold color-black mr-10">资金列表</span>
                        <a-checkbox value="1" @change="checkboxChange">
                            <span class="font-12 color-gray">隐藏0资产</span>
                        </a-checkbox>
                    </div>
                    <div>
                        <a-input class="search" placeholder="搜索币种">
                            <template #suffix>
                                <icon-search/>
                            </template>
                        </a-input>
                    </div>
                </div>
                <!--        -->
                <div class="list">
                    <div v-if="checked == false">
                        <div class="tab">
                            <span>币种</span>
                            <span>总额</span>
                            <span>可用</span>
                            <span>冻结</span>
                            <span>资产折合(USDT)</span>
                            <span>操作</span>
                        </div>
                        <div class="cell flex align-center jus-bet" v-for="(item,idx) in list.filter((it)=>{if(checked){return it.trade_balance > 0}else{return true}})" :key="idx">
                            <div class="cell-item">
                                <div class="flex align-center">
                                    <img class="coin-icon" :src="item.icon">
                                    <span class="coin-text">{{ item.symbol }}</span>
                                </div>
                            </div>
                            <div class="cell-item">
                                <span>{{ item.trade_balance }}</span>
                            </div>
                            <div class="cell-item">
                                <span>{{
                                        (parseFloat(item.trade_balance) - parseFloat(item.lock_trade)).toFixed(8)
                                    }}</span>
                            </div>
                            <div class="cell-item">
                                <span>{{ item.lock_trade }}</span>
                            </div>
                            <div class="cell-item">
                                <span>
                                    {{ $util.thousandSeparator(item.trade_balance * item.new_price,2) }}
                                </span>
                            </div>
                            <div class="cell-item">
                                <a-button class="button-gray" @click="transfeShow = true">划转</a-button>
                                <a-button class="button-gray" @click="$router.push('/spot')">交易</a-button>
                            </div>
                        </div>
                        <div class="flex align-center jus-end mt-20 pagination">
                            <a-pagination :total="pagination.total" show-total show-jumper @change="getList"/>
                        </div>
                    </div>
                    <not-date v-else :height="500"></not-date>
                </div>
            </div>
            <div class="btm-right">
                <div class="btm-right-box">
                    <div class="_title">
                        <span>资产分布</span>
                    </div>
                    <div class="flex align-center">
                        <div>
                            <v-chart :option="chartOption" :autoresize="true" width="250px"
                                     height="250px" style="width: 250px; height: 250px;"></v-chart>
                        </div>
                    </div>
                </div>
                <div class="btm-right-box">
                    <div class="_title">
                        <span>资金流水</span>
                        <span class="seeall">
                            查看全部
                            <icon-right />
                        </span>
                    </div>
                    <a-scrollbar class="_list">
                        <div class="_cell" v-for="(item,index) in logList" :key="'l'+item.id">
                            <div class="_item">
                                <span>{{types[item.type]}}</span>
                                <span>{{item.amount}} {{item.currency.symbol}}</span>
                            </div>
                            <div class="_item">
                                <span>{{item.created_at}}</span>
                                <span class="color-primary">已完成</span>
                            </div>
                        </div>
                    </a-scrollbar>
                    <not-date :height="300"></not-date>
                </div>
            </div>
        </div>
    </div>
    <!--    资金划转-->
    <funds-transfe :visible="transfeShow" @close="transfeShow = false"></funds-transfe>
    <!--    充值-->
    <up-trigget :visible="upShow" @close="upShow = false"></up-trigget>
    <!--     提现-->
    <withdraw-trgget :visible="withdrawShow" @click="withdrawShow = false"></withdraw-trgget>
</template>
<script>
import PageNav from "@/components/PageNav/PageNav.vue";
import Footer from "@/components/Footer/footer.vue";
import fundAccount from "@/views/Home/assets/fundAccount/fundAccount.vue"
import notlog from "@/components/notlog/notlog.vue";
import notDate from "@/components/notdate/notdate.vue";

import {mapGetters} from "vuex";

import VChart from 'vue-echarts';
import * as echarts from 'echarts/core';
import {
    PieChart
} from 'echarts/charts';
import {
    TooltipComponent,
    LegendComponent,
    TitleComponent
} from 'echarts/components';
import {
    CanvasRenderer
} from 'echarts/renderers';
import fundsTransfe from "@/components/fundsTransfe/fundsTransfe.vue";
import upTrigget from "@/components/topUp/topUp.vue";
import withdrawTrgget from "@/components/withdraw/withdraw.vue";
import userApi from "@/common/api/user";
echarts.use([PieChart, TooltipComponent, LegendComponent, CanvasRenderer,TitleComponent]);

export default {
    components: {withdrawTrgget, upTrigget, fundsTransfe, notlog, Footer, PageNav, fundAccount, notDate,VChart},
    props: {
        balance: {
            type: Array,
            default: [],
        },
        total: {
            type: Object,
            default: {},
        },
    },
    data() {
        return {
            transfeShow: false,
            upShow: false,
            withdrawShow: false,

            visual: false,
            checked: false,
            list: [],
            pagination: {
                total: 0,
                pageSize: 10
            },
            chartOption:{
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    orient: 'horizontal',
                    left: 'center',
                    top:"top"
                },
                series: [
                    {
                        name: '',
                        type: 'pie',
                        label:{
                            show:false
                        },
                        center:['50%','50%'],
                        radius: '50%',
                        data: [
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                // shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            },
            types:[],
            logList:[]
        }
    },
    computed: {
        ...mapGetters(['symbol','symbol_rate'])
    },
    mounted() {
        this.pagination.total = this.balance.length
        this.getList(1)
        var data = [];
        this.balance.map((item)=>{
            if(item.trade_balance > 0){
                data.push({
                    value:parseFloat(item.trade_balance),
                    name:item.symbol
                });
            }
        });
        this.chartOption['series'][0]['data'] = data;
        this.getFundLogList();
    },
    methods: {
        getFundLogList(){
            userApi.getCpitalFlow({
                account:2,
                start_at:"",
                end_at:"",
                currency_id:0,
                type:0,
                page:1
            }).then(res=>{
                console.log(res);
                let types = [];
                res.data.types.map((item)=>{
                    types[item.value] = item.label;
                });
                this.types = types;
                this.logList = res.data.list;
            })
        },
        checkboxChange(e) {
            this.checked = e;
        },
        getList(c) {
            this.list = this.balance.slice(this.pagination.pageSize * (c - 1), c * 10)
        },
    }
}
</script>
<style lang="scss" scoped>
@import "./spotAccount.scss";
</style>