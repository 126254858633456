<template>
  <a-layout class="fundDetails dark">
    <a-layout-header>
      <PageNav></PageNav>
    </a-layout-header>
    <a-layout-content>
      <div class="fund-detail-content flex flex-column align-center">
        <div class="content-main">
          <div class="main-card">
            <!--     -->
            <div class="flex align-center mb-24">
              <img @click="goBack" class="back-image" src="@/assets/pic-back.png">
              <span class="font-20">产品详情</span>
            </div>
            <!--     -->
            <div class="flex align-center jus-bet">
              <div class="flex align-center">
                <img class="coin-image" src="@/assets/images/coin/usdt.png">
                <span class="font-20">180天乐享版</span>
              </div>
              <a-button @click="visible = true">立即申购</a-button>
            </div>
            <!--     -->
            <div class="info-box" type="two">
              <div class="info-con flex align-start jus-bet">
                <div class="flex flex-column align-start _column">
                  <div class="flex align-center jus-bet">
                    <span>产品总额度</span>
                    <span>10,000 USDT</span>
                  </div>
                  <div class="flex align-center jus-bet">
                    <span>开始申购时间</span>
                    <span>2023-08-01 00:00:00</span>
                  </div>
                  <div class="flex align-center jus-bet">
                    <span>预计年化收益率</span>
                    <span class="color-green">7%</span>
                  </div>
                  <div class="flex align-center jus-bet">
                    <span>个人限额</span>
                    <span>10,000,000 USDT</span>
                  </div>
                </div>
                <div class="flex flex-column align-start _column">
                  <div class="flex align-center jus-bet">
                    <span>起购金额</span>
                    <span>1 USDT</span>
                  </div>
                  <div class="flex align-center jus-bet">
                    <span>结束申购时间</span>
                    <span>2023-08-01 00:00:00</span>
                  </div>
                  <div class="flex align-center jus-bet">
                    <span>产品期限</span>
                    <span>180天</span>
                  </div>
                </div>
              </div>
            </div>
            <!--     -->
            <div class="info-box" type="first">
              <p class="_title">产品周期</p>
              <div class="info-con flex align-center jus-bet">
                <div class="flex flex-row align-center">
                  <span>申购日</span>
                  <span>T 日</span>
                </div>
                <div class="flex flex-row align-center">
                  <span>起息日</span>
                  <span>T+1 日 12:00:00</span>
                </div>
                <div class="flex flex-row align-center">
                  <span>赎回日</span>
                  <span>T+产品期限+3 日 12:00:00</span>
                </div>
              </div>
            </div>
            <!--     交易规则     -->
            <div class="rule flex flex-column align-start">
              <p class="font-16">交易规则</p>
              <p class="font-14 color-gray">1、 本理财产品的申购时间为2023年7月1日
                00:00:00-2023年9月30日23:59:59（UTC+8）；</p>
              <p class="font-14 color-gray">2、 本次共推出三款产品，收益与投资周期如下：</p>
              <p class="font-14 color-gray">（1）14天产品享2.5%年化收益，产品申购总限额1000万USDT；</p>
              <p class="font-14 color-gray">（2）90天产品享5%年化收益，产品申购总限额1000万USDT；</p>
              <p class="font-14 color-gray">（3）180天产品享7%年化收益，产品申购总限额1000万USDT；</p>
              <p class="font-14 color-gray">请注意：</p>
              <p class="font-14 color-gray">1、本次理财产品是封闭型静态理财，期间不支持赎回；</p>
              <p class="font-14 color-gray">
                2、产品申购确认时间为申购发起日的次日12:00，利率以申购确认时的产品利率为准，用户可在次日10:00前撤销该笔申购；</p>
              <p class="font-14 color-gray">
                3、系统将于申购日的次日12:00开始计算产品期限，并将于产品到期日+3日发放本金与利息；</p>
              <p class="font-14 color-gray">4、“奔富理财”存量产品仍按其历史产品规则计息。</p>
              <p class="font-14 color-gray">*本理财产品的最终解释权归ComeBit平台所有。</p>
            </div>
          </div>
          <!--     我的申购     -->
          <div class="main-card flex flex-column align-start">
            <p class="font-16">我的申购</p>
            <div class="column-group flex align-start jus-bet">
              <div class="card flex flex-column align-start jus-center">
                <span class="font-16 color-gray">产品申购总额</span>
                <span class="font-14 color-white mt-10"><b class="font-30">--</b> USDT</span>
              </div>
              <div class="card flex flex-column align-start jus-center">
                <span class="font-16 color-gray">已发放利息</span>
                <span class="font-14 color-white mt-10"><b class="font-30">--</b> USDT</span>
              </div>
              <div class="card flex flex-column align-start jus-center">
                <span class="font-16 color-gray">剩余可购</span>
                <span class="font-14 color-white mt-10"><b class="font-30">--</b> USDT</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--      立即申购弹窗-->
      <a-modal :visible="visible" align-center :render-to-body="false" mask-style="background:rgba(0, 0, 0, .5)"
               modal-class="mode-box" :hide-title="true" :footer="false">
        <div class="modal-con">
          <div class="title-box flex align-center jus-bet">
            <span class="font-20 color-white">申购确认</span>
            <icon-close @click="visible = false" class="color-white font-bold pointer" size="24"/>
          </div>
          <div class="flex align-start jus-bet con-box">
            <div class="flex flex-column align-start con-left">
              <div class="cell flex align-center jus-bet">
                <span class="font-14 color-gray">申购产品</span>
                <span class="font-14 color-white">180天乐享版</span>
              </div>
              <div class="cell flex align-center jus-bet">
                <span class="font-14 color-gray">期限(天)</span>
                <span class="font-14 color-white">180天</span>
              </div>
              <div class="cell cell-input flex flex-column align-start">
                <span class="font-14 color-gray mb-5">申购数量</span>
                <div class="input_box mb-5">
                  <a-input placeholder="请输入">
                    <template #suffix>
                      <div class="flex align-center jus-end">
                        <span class="font-14 color-gray">USDT</span>
                        <span>|</span>
                        <span class="color-blue pointer">最大</span>
                      </div>
                    </template>
                  </a-input>
                </div>
                <span class="font-13 color-red" v-if="false">账户可用余额不足</span>
              </div>
              <a-radio-group :default-value="radioVal" class="radio-list" @change="radioChange">
                <div class="cell flex align-center jus-bet">
                  <div class="flex align-center">
                    <a-radio value="1" default-checked></a-radio>
                    <span class="font-14 color-gray">可用余额：</span>
                    <span class="font-14 color-white">0.0000 USDT</span>
                  </div>
                  <span class="color-blue pointer">划转</span>
                </div>
                <div class="cell flex align-center jus-bet">
                  <div class="flex align-center">
                    <a-radio value="2"></a-radio>
                    <span class="font-14 color-gray">活币宝余额：</span>
                    <span class="font-14 color-white">0.0000 USDT</span>
                  </div>
                </div>
              </a-radio-group>
              <div class="cell flex align-center jus-bet">
                <div class="flex align-center">
                  <span class="font-14 color-gray">本次可投：</span>
                  <span class="font-14 color-white mr-5">0.0000 USDT</span>
                  <a-tooltip background-color="#636870">
                    <icon-question-circle-fill class="color-white pointer"/>
                    <template #content>
                      <span
                          class="font-13">本次可投是根据您的已申购数量、个人申购上限、产品限额等多种条件，计算出来的该产品您本次可申购的数量</span>
                    </template>
                  </a-tooltip>
                </div>
              </div>
              <div class="cell flex align-center jus-bet">
                <div class="flex align-center">
                  <span class="font-14 color-gray">产品可申购额度：</span>
                  <span class="font-14 color-white">9,797,389.9136 USDT</span>
                </div>
              </div>
              <div class="cell flex align-center jus-bet" v-if="radioVal == 1">
                <div class="flex align-center">
                  <a-switch>
                    <template #checked-icon>
                      <icon-check/>
                    </template>
                    <template #unchecked-icon>
                      <icon-close/>
                    </template>
                  </a-switch>
                  <span class="font-14 color-gray">自动续购</span>
                </div>
              </div>
            </div>
            <div class="flex flex-column align-start con-right">
              <p class="font-16 color-white">概览</p>
              <div class="timeline">
                <a-timeline>
                  <a-timeline-item label="2017-03-10">申购日</a-timeline-item>
                  <a-timeline-item label="2018-05-12">起息日</a-timeline-item>
                  <a-timeline-item label="2020-09-30">赎回日</a-timeline-item>
                </a-timeline>
              </div>
              <div class="cell flex align-center jus-bet">
                <span class="font-14 color-gray">预期年化收益率</span>
                <span class="font-14 color-green">7%</span>
              </div>
              <div class="cell flex align-center jus-bet">
                <span class="font-14 color-gray">预计收益</span>
                <span class="font-14 color-green">--</span>
              </div>
            </div>
          </div>
          <div class="flex align-center mt-10">
            <div class="pointer flex align-center">
              <a-checkbox class="mr-5"></a-checkbox>
              <span class="font-14 color-white mr-5">我已阅读并同意</span>
              <span class="font-14 color-blue">ComeBit奔富理财产品用户协议</span>
            </div>
          </div>
          <div class="flex align-center jus-bet btn-group">
            <a-button>取消</a-button>
            <a-button disabled>确认</a-button>
          </div>
        </div>
      </a-modal>
    </a-layout-content>
    <a-layout-footer>
      <Footer></Footer>
    </a-layout-footer>
  </a-layout>
</template>
<script>
import Footer from "@/components/Footer/footer.vue";
import PageNav from "@/components/PageNav/PageNav.vue";

export default {
  components: {PageNav, Footer},
  data() {
    return {
      visible: false,
      radioVal: "1",
    }
  },
  methods: {
    goBack() {
      this.$router.back()
    },
    radioChange(e) {
      console.log(e);
      this.radioVal = e;
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./detail.scss";
</style>