<!-- API -->
<template>
    <div class="api-warp">
        <a-breadcrumb>
            <a-breadcrumb-item>ComeBit</a-breadcrumb-item>
            <a-breadcrumb-item>个人中心</a-breadcrumb-item>
            <a-breadcrumb-item>API keys</a-breadcrumb-item>
        </a-breadcrumb>
        <h1>API keys</h1>
        <div class="tips">
            <p>1.单个用户最多创建5个密钥。</p>
            <p>
                2.建议为密钥绑定IP，每个密钥最多绑定5个IP，用英文逗号隔开。
            </p>
            <p>
                3.请您不要泄露密钥，以免造成资产损失。
            </p>
        </div>
        <a-button class="button"
                  @click="visible = true">
            <icon-plus/>
            创建API
        </a-button>
        <a-modal :visible="visible"
                 :hide-title="true"
                 :footer="false">
            <div class="model">
                <div class="title">
                    <span>创建API</span>
                    <icon-close @click="visible = false"/>
                </div>
                <div class="input-label">
                    <p>备注名</p>
                    <a-input class="input" placeholder="IP地址"></a-input>
                </div>
                <div class="input-label">
                    <p>Passphrase（API 口令）</p>
                    <a-input class="input" placeholder="备注"></a-input>
                </div>
                <a-button class="next">下一步</a-button>
            </div>
        </a-modal>
    </div>
</template>

<script>
import notdate from "@/components/notdate/notdate.vue";

export default {
    components: {
        notdate,
    },
    data() {
        return {
            visible: false,
            setting: [
                {
                    name: "Lv.1 基础认证",
                    des: "极速审核，快速完成基础认证，获得更高提现额度。",
                    icon: "idAuth",
                    status: {
                        name: "未绑定",
                        color: "#ff9e2d",
                    },
                    btn: "去认证",
                    type: "primary",
                    ifBind: true,
                },
                {
                    name: "Lv.2 高级认证",
                    des: "24小时内完成审核，完成高级认证，获得最高提现额度，并获取参与ComeBit各类福利活动的资质。",
                    icon: "idAuth",
                    status: {
                        name: "未绑定",
                        color: "#ff9e2d",
                    },
                    btn: "去认证",
                    type: "primary",
                    ifBind: true,
                },
            ],
        };
    },
    methods: {},
};
</script>
<style lang="scss" scoped>
@import "./apiMangement.scss";
</style>
