<template>
    <a-layout class="topup">
        <a-layout-header>
            <page-nav></page-nav>
        </a-layout-header>
        <a-layout-content>
            <div class="content">
                <div class="header">
                    <p class="title">充值</p>
                    <div class="label">
                        <p>充值币种</p>
                        <a-trigger position="bottom" :popup-translate="[0, -40]" :render-to-body="false">
                            <div class="inputBox">
                                <span class="span">{{ coinVal }}</span>
                                <icon-caret-down/>
                            </div>
                            <template #content>
                                <div class="list">
                                    <coin-list :display="true"></coin-list>
                                </div>
                            </template>
                        </a-trigger>
                    </div>
                    <div class="label">
                        <p>充值网络</p>
                        <a-trigger position="bottom" :render-to-body="false">
                            <div class="inputBox">
                                <span class="span">{{ coinVal }}</span>
                                <icon-caret-down/>
                            </div>
                            <template #content>
                                <div class="list">
                                    <div class="cell">
                                        <span class="color-black">Ethereum(ERC20)</span>
                                    </div>
                                </div>
                            </template>
                        </a-trigger>
                    </div>
                    <div class="label">
                        <p>充值地址</p>
                        <p class="rg_add">
                            0x737a7c795978ade93d3ac5863a88ef0f3a25d6de
                            <icon-copy/>
                        </p>
                        <div class="re_code">
                            <canvas></canvas>
                        </div>
                        <p>复制充值地址或使用交易所/钱包APP扫码</p>
                        <div class="tips">
                            <p>
                                <span>最小充值数</span>
                                <span>0.0001 USDT</span>
                            </p>
                            <p>
                                <span>充值区块确认数</span>
                                <span>6 次网络确认</span>
                            </p>
                            <p>
                                <span>提现解锁确认数</span>
                                <span>6 次网络确认</span>
                            </p>
                        </div>
                        <div class="tips" type="tip">
                            <p>
                                <span>充值提醒</span>
                                1.请勿向地址充值任何非1INCH资产，否则资产将不可找回。<br>
                                2.请在每次充值前认真确认地址，以免因地址变更导致不必要的损失；当地址发生变更时，我们将通过短信或邮件通知您。<br>
                                3.请务必确认操作设备的安全，防止信息被篡改或泄露。<br>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="btm">
                    <p class="title">最近的充值记录</p>
                    <div class="warp">
                        <a-scrollbar class="topup-list">
                            <div class="cell">
                                <span>时间</span>
                                <span>充值币种</span>
                                <span>充值网络</span>
                                <span>充值数量</span>
                                <span>充值地址</span>
                                <span>交易哈希</span>
                                <span>确认数</span>
                                <span>状态</span>
                            </div>
                            <div class="cell" v-for="i in 30">
                                <span>2023-09-14 14:50:01</span>
                                <span>
                                <img src="@/assets/images/coin/usdt.png" alt="">
                                USDT
                            </span>
                                <span>Tron(TRC20)</span>
                                <span>21.999925</span>
                                <span class="up_line">
                                <b>TJTE6HJZ...pH54r5Pj</b>
                                <icon-copy/>
                            </span>
                                <span class="up_line">
                                <b>d6ca082c...1e82c04a</b>
                                 <icon-copy/>
                            </span>
                                <span>6/6</span>
                                <span class="color-green">充值成功</span>
                            </div>
                        </a-scrollbar>
                    </div>
                </div>
            </div>
        </a-layout-content>
        <a-layout-footer>
            <Footer></Footer>
        </a-layout-footer>
    </a-layout>
</template>

<style lang="scss" scoped>
@import "topUp";
</style>
<script>
import PageNav from "@/components/PageNav/PageNav.vue";
import Footer from "@/components/Footer/footer.vue";
import CoinList from "@/components/coinList/coinList.vue";

export default {
    components: {
        PageNav,
        Footer,
        CoinList
    },
    data() {
        return {
            coinVal: '请选择充值币种'
        }
    }
}
</script>