<template>
    <a-layout class="assets">
        <a-layout-header>
            <PageNav></PageNav>
        </a-layout-header>
        <a-layout-content>
            <div class="assets-body">
                <div class="menu-list">
                    <div class="cell flex align-center pointer"
                         :class="menuActive === index ? 'cell-active' : ''"
                         v-for="(item,index) in menuList" :key="index"
                         @click="menuActive = index">
                        <img v-if="menuActive === index"
                             :src="require('@/assets/images/assets_img/menu-icon' + item.id + '-s.png')"/>
                        <img v-else :src="require('@/assets/images/assets_img/menu-icon' + item.id + '-d.png')"/>
                        <span>{{ item.text }}</span>
                    </div>
                </div>
                <div class="content-main">
                    <div class="overview" v-if="menuActive === 0">
                        <div class="title">
                            <h1>资产</h1>
                            <div class="button-group">
                                <a-button class="button" @click="upShow = true">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                         xmlns="http://www.w3.org/2000/svg" class="">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                              d="M6 3C6 2.44772 6.44772 2 7 2H9C9.55229 2 10 2.44772 10 3V6H11.0073C11.864 6 12.3241 7.00701 11.7632 7.65465L8.75593 11.1271C8.35716 11.5876 7.64284 11.5876 7.24407 11.1271L4.23682 7.65465C3.67594 7.00701 4.136 6 4.99275 6H6V3ZM4 12C3.44772 12 3 12.4477 3 13C3 13.5523 3.44772 14 4 14H12C12.5523 14 13 13.5523 13 13C13 12.4477 12.5523 12 12 12H4Z"
                                              fill="var(--content-white)">
                                        </path>
                                    </svg>
                                    充值
                                </a-button>
                                <a-button class="button" @click="withdrawShow = true">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                         xmlns="http://www.w3.org/2000/svg" class="">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                              d="M10 13C10 13.5523 9.55228 14 9 14L7 14C6.44771 14 6 13.5523 6 13L6 10L4.99275 10C4.136 10 3.67594 8.99299 4.23682 8.34535L7.24407 4.87287C7.64284 4.41241 8.35716 4.41241 8.75593 4.87287L11.7632 8.34535C12.3241 8.99299 11.864 10 11.0072 10L10 10L10 13ZM12 4C12.5523 4 13 3.55229 13 3C13 2.44771 12.5523 2 12 2L4 2C3.44772 2 3 2.44771 3 3C3 3.55228 3.44772 4 4 4L12 4Z"
                                              fill="var(--content-primary)">
                                        </path>
                                    </svg>
                                    提现
                                </a-button>
                                <a-button class="button" @click="transfeShow = true">
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                         xmlns="http://www.w3.org/2000/svg" class="">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                              d="M2.26949 5.29583C1.64358 5.92699 2.09065 6.99998 2.97954 6.99998L5.39787 6.99998H13.0008C13.5531 6.99998 14.0008 6.55226 14.0008 5.99998C14.0008 5.44769 13.5531 4.99998 13.0008 4.99998H7.52129V2.42852C7.52129 1.5356 6.43998 1.09035 5.81124 1.72437L2.26949 5.29583Z"
                                              fill="var(--content-primary)"></path>
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                              d="M13.7081 10.7042C14.334 10.073 13.8869 9.00002 12.998 9.00002L10.5797 9.00002L8.45625 9.00002L2.97672 9.00002C2.42444 9.00002 1.97672 9.44774 1.97672 10C1.97672 10.5523 2.42444 11 2.97672 11L8.45625 11L8.45625 13.5715C8.45625 14.4644 9.53756 14.9096 10.1663 14.2756L13.7081 10.7042Z"
                                              fill="var(--content-primary)">
                                        </path>
                                    </svg>
                                    划转
                                </a-button>
                            </div>
                        </div>
                        <div class="asset-all">
                            <p class="_t">
                                <a-tooltip background-color="var(--tooltip-bg)">
                                    <span>总额</span>
                                    <template #content>
                                        <span
                                            class="color-always-white">总额是将您所有账户的余额相加计算得出的估计值。</span>
                                    </template>
                                </a-tooltip>
                                <div @click="visual = !visual">
                                    <icon-eye-invisible v-if="visual" class="color-gray" size="16"/>
                                    <icon-eye v-else class="color-gray" size="16"/>
                                </div>
                            </p>
                            <p class="price">
                                <a-statistic class="price" :value-style="{'color':'var(--content-primary)','font-weight':'600','font-size':'40px'}" separator="," animation :show-group-separator="true" title="" :value="parseFloat(total)" :precision="2"></a-statistic>
                                USDT
                            </p>
                            <p class="convert">
                                ≈ {{ symbol }} {{
                                    visual ? '********' : $util.thousandSeparator(total * symbol_rate, 2)
                                }}
                            </p>
                        </div>
                        <!--账户-->
                        <div class="account-box">
                            <div class="_top">
                                <p>账户</p>
                                <div class="swiper-button">
                                    <a-button class="prev btn">
                                        <icon-left/>
                                    </a-button>
                                    <a-button class="next btn">
                                        <icon-right/>
                                    </a-button>
                                </div>
                            </div>
                            <div class="account-swiper">
                                <swiper class="swiper"
                                        :hideOnClick="true"
                                        :navigation="{
                                          nextEl: '.next',
                                          prevEl: '.prev'
                                        }"
                                        :loop="false"
                                        :modules="modules"
                                        :slidesPerView="4">
                                    <!--    资金账户-->
                                    <swiper-slide class="slide">
                                        <div class="slide-box" @click="menuActive = 1">
                                            <img :src="require(`@/assets/images/assets_img/funding_${userTheme}.svg`)"
                                                 alt="">
                                            <p>资金账户</p>
                                            <p>
                                                {{ balance ? $util.thousandSeparator(balance, 2) : '0.00' }} USDT
                                            </p>
                                            <p>
                                                ≈ {{ symbol }} {{ $util.thousandSeparator(balance * symbol_rate, 2) }}
                                            </p>
                                        </div>
                                    </swiper-slide>
                                    <!--    现货账户-->
                                    <swiper-slide class="slide" @click="menuActive = 2">
                                        <div class="slide-box">
                                            <img :src="require(`@/assets/images/assets_img/spot_${userTheme}.svg`)"
                                                 alt="">
                                            <p>现货账户</p>
                                            <p>
                                                {{ trade ? $util.thousandSeparator(trade, 2) : '0.00' }} USDT
                                            </p>
                                            <p>
                                                ≈ {{ symbol }} {{ $util.thousandSeparator(trade * symbol_rate, 2) }}
                                            </p>
                                        </div>
                                    </swiper-slide>
                                    <!--    合约账户-->
                                    <swiper-slide class="slide" @click="menuActive = 3">
                                        <div class="slide-box">
                                            <img :src="require(`@/assets/images/assets_img/futures_${userTheme}.svg`)"
                                                 alt="">
                                            <p>合约账户</p>
                                            <p>
                                                {{ contract ? $util.thousandSeparator(contract, 2) : '0.00' }} USDT
                                            </p>
                                            <p>
                                                ≈ {{ symbol }} {{ $util.thousandSeparator(contract * symbol_rate, 2) }}
                                            </p>
                                        </div>
                                    </swiper-slide>
                                    <!--    期权账户-->
                                    <swiper-slide class="slide" @click="menuActive = 4">
                                        <div class="slide-box">
                                            <img :src="require(`@/assets/images/assets_img/bot_${userTheme}.svg`)"
                                                 alt="">
                                            <p>期权账户</p>
                                            <p>
                                                {{ future ? $util.thousandSeparator(future, 2) : '0.00' }} USDT
                                            </p>
                                            <p>
                                                ≈ {{ symbol }} {{ $util.thousandSeparator(future * symbol_rate, 2) }}
                                            </p>
                                        </div>
                                    </swiper-slide>
                                    <!--    理财账户-->
                                    <swiper-slide class="slide" @click="menuActive = 5">
                                        <div class="slide-box">
                                            <img :src="require(`@/assets/images/assets_img/earn_${userTheme}.svg`)"
                                                 alt="">
                                            <p>理财账户</p>
                                            <p>
                                                {{ finance ? $util.thousandSeparator(finance, 2) : '0.00' }} USDT
                                            </p>
                                            <p>
                                                ≈ {{ symbol }} {{ $util.thousandSeparator(finance * symbol_rate, 2) }}
                                            </p>
                                        </div>
                                    </swiper-slide>
                                </swiper>
                            </div>
                        </div>
                        <!--   资产分布/资产动态 -->
                        <div class="card-box">
                            <div class="card">
                                <div class="top">
                                    <span>资产分布</span>
                                </div>
                                <div class="chartBox">
                                    <v-chart :option="chartOption"
                                             :autoresize="true"
                                             width="712px"
                                             height="230px">
                                    </v-chart>
                                </div>
                            </div>
                            <div class="card">
                                <div class="top">
                                    <span>资产动态</span>
                                </div>
                                <div class="bottom">
                                    <a-scrollbar class="bottom-scroll">
                                        <template v-if="false">
                                            <div class="cell" v-for="i in 30">
                                                <div class="_item">
                                                    <span>现货划转</span>
                                                    <span>-0.1USDT</span>
                                                </div>
                                                <div class="_item">
                                                    <span>2023-12-05 15:20:32</span>
                                                    <span class="color-primary">已完成</span>
                                                </div>
                                            </div>
                                        </template>
                                        <template v-if="true">
                                            <div class="empty">
                                                <not-date :height="100"></not-date>
                                            </div>
                                        </template>
                                    </a-scrollbar>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="menuActive === 1">
                        <fundAccount :balance="list" :total="balance" @topup="upShow=true"></fundAccount>
                    </div>
                    <div v-if="menuActive === 2">
                        <spotAccount :balance="list" :total="trade" @topup="upShow=true"></spotAccount>
                    </div>
                    <div v-if="menuActive === 3">
                        <futuresAccount :balance="list" :total="contract" @topup="upShow=true"></futuresAccount>
                    </div>
                    <div v-if="menuActive === 4">
                        <options-account :balance="list" :total="future" @topup="upShow=true"></options-account>
                    </div>
                    <div v-if="menuActive === 5">
                        <investment-account :balance="list" :total="finance" @topup="upShow=true"></investment-account>
                    </div>
                    <div v-if="menuActive === 6">
                        <capital-flow></capital-flow>
                    </div>
                </div>
            </div>
            <!--    资金划转-->
            <funds-transfe :visible="transfeShow" @close="transfeShow = false"></funds-transfe>
            <!--    充值-->
            <up-trigget :visible="upShow" @close="upShow = false"></up-trigget>
            <!--     提现-->
            <withdraw-trgget :visible="withdrawShow" @close="withdrawShow = false"></withdraw-trgget>
        </a-layout-content>
        <a-layout-footer>
            <Footer></Footer>
        </a-layout-footer>
    </a-layout>
</template>

<script>
import service from './service';

export default service;
</script>
<style lang="scss" scoped>
@import "./assets.scss";
</style>