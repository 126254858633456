<template>
  <div class="assets dark arco-theme">
    <a-layout class="assets-layout">
      <a-layout-header>
        <PageNav></PageNav>
      </a-layout-header>
      <a-layout-content>
        <div class="assets-content-wrap flex align-start jus-bet ">
          <div class="menu-list">
            <div class="cell flex align-center pointer" :class="menuActive == index ? 'cell-active' : ''"
              v-for="(item, index) in menuList" :key="index" @click="menuActive = index">
              <img v-if="menuActive == index" :src="require('@/assets/images/order_img/menu-icon' +
                item.id +
                '-d.png')
                " />
              <img v-else :src="require('@/assets/images/order_img/menu-icon' +
                item.id +
                '-s.png')
                " />
              <span>{{ item.text }}</span>
            </div>
          </div>
          <div class="content-main">
            <div class="main-box" v-if="menuActive == 0">
              <div class="content-main-bottom">
                <div class="btm-left">
                  <div class="btm-left-head">
                    <a-tabs :default-active-key="activeKey" type="line" :animation="true" size="large"
                      style="font-size: 20px">
                      <a-tab-pane class="order-tab" :key="index" v-for="(item, index) in tabs">
                        <template #title>
                          <span :style="{ fontSize: '16px' }">{{ item.label }}({{ item.num }})</span>
                        </template>
                        <div>
                          <!-- kline -->
                          <div class="kline" v-if="item.id == 1">
                            <div>
                              <div class="flex">
                                <text class="title">交易方向</text>
                                <text class="title">交易市场</text>
                                <text class="title">币兑</text>
                              </div>
                              <div class="flex">
                              <div class="select-box">
                                <a-select class="flex" direction="vertical" :style="{
                                  width: '120px',
                                  backgroundColor: '#1d2126',
                                  
                                }" default-value="全部" size="large" >
                                  <a-option :style="{
                                    backgroundColor: '#1d2126',
                                    color: '#fff',
                                  }" v-for="props in props.options" :key="props.value">{{ props.label }}</a-option>
                                </a-select>
                              </div>
                              <div class="select-box">
                                <a-select class="flex" direction="vertical" :style="{
                                  width: '120px',
                                  backgroundColor: '#1d2126',
                                  
                                }" default-value="全部" size="large" >
                                  <a-option :style="{
                                    backgroundColor: '#1d2126',
                                    color: '#fff',
                                  }" v-for="props in props.options" :key="props.value">{{ props.label }}</a-option>
                                </a-select>
                              </div>
                              <div class="select-box">
                                <a-select class="flex" direction="vertical" :style="{
                                  width: '120px',
                                  backgroundColor: '#1d2126',
                                  
                                }" default-value="全部" size="large" >
                                  <a-option :style="{
                                    backgroundColor: '#1d2126',
                                    color: '#fff',
                                  }" v-for="props in props.options" :key="props.value">{{ props.label }}</a-option>
                                </a-select>
                              </div>
                              </div>
                            </div>
                            <notdate> </notdate>
                          </div>
                          <div class="kline" v-if="item.id == 2">
                            <div >
                              <div class="flex">
                                <text class="title">交易方向</text>
                                <text class="title">交易市场</text>
                                <text class="title">币兑</text>
                              </div>
                              <div class="flex">
                              <div class="select-box">
                                <a-select class="flex" direction="vertical" :style="{
                                  width: '120px',
                                  backgroundColor: '#1d2126',
                                  
                                }" default-value="全部" size="large" >
                                  <a-option :style="{
                                    backgroundColor: '#1d2126',
                                    color: '#fff',
                                  }" v-for="props in props.options" :key="props.value">{{ props.label }}</a-option>
                                </a-select>
                              </div>
                              <div class="select-box">
                                <a-select class="flex" direction="vertical" :style="{
                                  width: '120px',
                                  backgroundColor: '#1d2126',
                                  
                                }" default-value="全部" size="large" >
                                  <a-option :style="{
                                    backgroundColor: '#1d2126',
                                    color: '#fff',
                                  }" v-for="props in props.options" :key="props.value">{{ props.label }}</a-option>
                                </a-select>
                              </div>
                              <div class="select-box">
                                <a-select class="flex" direction="vertical" :style="{
                                  width: '120px',
                                  backgroundColor: '#1d2126',
                                  
                                }" default-value="全部" size="large" >
                                  <a-option :style="{
                                    backgroundColor: '#1d2126',
                                    color: '#fff',
                                  }" v-for="props in props.options" :key="props.value">{{ props.label }}</a-option>
                                </a-select>
                              </div>
                              </div>
                            </div>
                            <notdate> </notdate>
                          </div>
                          <div class="kline" v-if="item.id == 3">
                            <div >
                              <div class="flex">
                                <text class="title">交易方向</text>
                                <text class="title">交易市场</text>
                                <text class="title">币兑</text>
                              </div>
                              <div class="flex">
                              <div class="select-box">
                                <a-select class="flex" direction="vertical" :style="{
                                  width: '120px',
                                  backgroundColor: '#1d2126',
                                  
                                }" default-value="全部" size="large" >
                                  <a-option :style="{
                                    backgroundColor: '#1d2126',
                                    color: '#fff',
                                  }" v-for="props in props.options" :key="props.value">{{ props.label }}</a-option>
                                </a-select>
                              </div>
                              <div class="select-box">
                                <a-select class="flex" direction="vertical" :style="{
                                  width: '120px',
                                  backgroundColor: '#1d2126',
                                  
                                }" default-value="全部" size="large" >
                                  <a-option :style="{
                                    backgroundColor: '#1d2126',
                                    color: '#fff',
                                  }" v-for="props in props.options" :key="props.value">{{ props.label }}</a-option>
                                </a-select>
                              </div>
                              <div class="select-box">
                                <a-select class="flex" direction="vertical" :style="{
                                  width: '120px',
                                  backgroundColor: '#1d2126',
                                  
                                }" default-value="全部" size="large" >
                                  <a-option :style="{
                                    backgroundColor: '#1d2126',
                                    color: '#fff',
                                  }" v-for="props in props.options" :key="props.value">{{ props.label }}</a-option>
                                </a-select>
                              </div>
                              </div>
                            </div>
                            <notdate> </notdate>
                          </div>
                        </div>
                      </a-tab-pane>
                    </a-tabs>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="menuActive == 1">
              <exchangeOrder></exchangeOrder>
            </div>
            <div v-if="menuActive == 2">
              <subscriptionHistory></subscriptionHistory>
            </div>
          </div>
        </div>
      </a-layout-content>
      <a-layout-footer>
        <Footer></Footer>
      </a-layout-footer>
    </a-layout>
  </div>
</template>
<script>
import PageNav from "@/components/PageNav/PageNav.vue";
import Footer from "@/components/Footer/footer.vue";
import exchangeOrder from "@/views/Home/order/exchangeOrder/exchangeOrder.vue";
import subscriptionHistory from "@/views/Home/order/subscriptionHistory/subscriptionHistory.vue";
import notdate from "@/components/notdate/notdate.vue";

export default {
  components: { Footer, PageNav, exchangeOrder, subscriptionHistory, notdate },
  data() {
    return {
      menuList: [
        {
          id: "1",
          text: "现货订单",
        },
        {
          id: "2",
          text: "合约订单",
        },
        {
          id: "3",
          text: "理财订单",
        },
      ],
      tabs: [
        {
          id: 1,
          num: "100",
          label: "当前订单",
        },
        {
          id: 2,
          num: "100",
          label: "历史订单",
        },
        {
          id: 3,
          num: "100",
          label: "历史成交",
        },
      ],
      activeKey: 0,
      menuActive: 0,
      visual: false,
      decimal: "全部",
      props: {
        type: "numselect",
        options: [
          {
            label: "全部",
            value: "全部",
          },
          {
            label: "测试",
            value: "测试",
          },
          {
            label: "测试",
            value: "测试",
          },
        ],
      },
    };
  },
  methods: {
    numSelect(e) {
      this.decimal = e;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./orderView.scss";
</style>
