import {createStore} from 'vuex'
import util from '../common/util'

const store = createStore({
    state: {
        userinfo: false, //用户身份信息
        accounts: false, //用户余额相关信息
        config: false, //站点全局配置
        // 主题
        userTheme: util.getCache('userTheme') ? util.getCache('userTheme') : 'light',
        userThemeIndex: util.getCache('userThemeIndex') ? util.getCache('userThemeIndex') : '0',
        symbol: false,
        symbol_rate: false
    },
    getters: {
        symbol: state => {
            if(!state.symbol){
                const local = JSON.parse(util.getCache('local-currency'));
                state.symbol = local.symbol;
            }
            return state.symbol;
        },
        symbol_rate: state => {
            if(!state.symbol_rate){
                state.symbol_rate = parseFloat(util.getCache('local-fee'));
            }
            return state.symbol_rate;
        },
        isLogin: state => {
            return state.userinfo !== false;
        },
        userAccount: state => {
            return state.userinfo.phone != null ? state.userinfo.phone : state.userinfo.email;
        },
        userNickname: state => {
            if (state.userinfo.nickname != null && state.userinfo.nickname !== '') {
                return state.userinfo.nickname;
            }
            return state.userinfo.phone != null && state.userinfo.phone !== '' ? state.userinfo.phone : state
                .userinfo.email;
        },
        userId: state => {
            if (state.userinfo !== false && state.userinfo.id) {
                return state.userinfo.id;
            }
            return false;
        },
        userPhone: state => {
            return state.userinfo.phone != null ? state.userinfo.phone : false;
        },
        userEmail: state => {
            return state.userinfo.email != null ? state.userinfo.email : false;
        },
        googleCode: state => {
            if (typeof state.userinfo.google2fa == 'undefined') {
                return false;
            }
            return state.userinfo.google2fa != null ? state.userinfo.google2fa : false;
        },
        balanceNull: state => {
            return {
                legal_balance: "0.00",
                balance: "0.00",
                lock_balance: "0.00",
                trade_balance: "0.00",
                lock_trade: "0.00",
                contract_balance: "0.00",
                lock_contract: "0.00",
                future_balance: "0.00",
                lock_future: "0.00",
                finance_balance: "0.00",
                lock_finance: "0.00"
            }
        },
        // 主题
        userTheme: state => {
            return state.userTheme
        },
        userThemeIndex: state => {
            return state.userThemeIndex
        }
    },
    mutations: {
        userLogin(state, _userinfo) {
            if (_userinfo) {
                state.userinfo = _userinfo;
                if (_userinfo['token']) {
                    util.setCache('token', _userinfo['token']);
                }
            } else {
                state.userinfo = false;
                util.delCache('token');
            }
        },
        updateInfo(state, field, value) {
            state['userinfo'][field] = value;
        },
        setConfig(state, data) {
            state.config = data;
        },
        updateTheme(state, data) {
            state.userTheme = data;
            state.userThemeIndex = data.index;
            state.theme = data.color;
            util.setCache('userTheme', data)
            util.setCache('userThemeIndex', data.index)
            util.setCache('theme', data)
            console.log(`当前的主题是:${this.matches ? 'light' : 'dark'}`)
            if (state.userTheme) {
                document.documentElement.setAttribute('data-theme', data)
            }
        },
        /**
         * 更新本地计价货币符号
         * @param state
         * @param value
         */
        updateSymbol(state,value){
            state.symbol = value;
        },
        /**
         * 更新本地计价货币汇率
         * @param state
         * @param value
         */
        updateSRate(state,value){
            state.symbol_rate = value;
        }
    }
});

export default store