<template>
    <div class="coinListWarp" :class="display ? 'show' : ''" v-show="display">
        <div class="search">
            <icon-search class="color-gray" size="16"/>
            <a-input class="input" @input="search" placeholder="搜索" allow-clear/>
        </div>
        <a-scrollbar :class="outerClass">
            <div class="coinCell"
                 v-for="(item,index) in coinList.filter(item=>item.symbol !== null)" :key="index"
                 @click="itemSelect(item)" >
                <span :class="selectedIndex === index ? 'color-blue' : 'color-white'">{{ item.symbol }}</span>
            </div>
        </a-scrollbar>
    </div>
</template>
<script>
let list = [];
export default {
    name: "coinList",
    props: {
        display: {
            default: false,
            type: Boolean
        },
        outerClass: {
            default: 'coinList',
            type: String
        },
        coinGroup: {
            default: [],
            type: Object
        }
    },
    data() {
        return {
            selectedIndex: 0,
            coinList:[]
        }
    },
    watch:{
        coinGroup(v){
            list = v;
            this.coinList = v;
        },
        display(v){
            console.log(v);
        }
    },
    methods: {
        search(e){
            this.coinList = list.filter((item)=>{
                return item.symbol != null && item.symbol.toLowerCase().includes(e.toLowerCase());
            })
        },
        itemSelect(item){
            this.$emit('select',item);
        }
    }
}
</script>

<style lang="scss" scoped>
.show {
    animation: show 0.25s linear forwards;
    @keyframes show {
        0% {
            transform: scale(0);
        }
        100% {
            transform: scale(1) !important;
        }
    }
}

.coinListWarp {
    width: 100%;
    height: auto;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: var(--background-primary);
    box-shadow: 0 0 32px var(--shadow-color);
    position: absolute;
    left: 0;
    top: 50px;
    z-index: 1;

    .search {
        width: 100%;
        height: 40px;
        background-color: var(--background-secondary);
        border-radius: 44px;
        padding: 0 10px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        border: 1px solid transparent;
        transition: all 0.25s linear;

        &:focus-within {
            border-color: var(--color-primary);
        }

        .input {
            width: calc(100% - 60px);
            height: 40px;
            font-size: 14px;
            color: var(--content-primary);
            background: transparent;

            &:focus-within {
                border-color: transparent;
                background: transparent;
            }
        }
    }

    :deep(.coinList) {
        width: 100%;
        height: 200px;
        overflow: auto;

        .coinCell {
            width: 100%;
            height: 36px;
            padding: 0 10px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            border-radius: 4px;
            cursor: pointer;
            color: var(--content-primary);

            &:hover {
                background-color: var(--hover-bg);
                transition: all 0.15s linear;
            }

            &:first-of-type {
                margin-top: 10px;
            }
        }
    }
}
</style>