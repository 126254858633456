import PageNav from "@/components/PageNav/PageNav.vue";
import Footer from "@/components/Footer/footer.vue";
import notlog from "@/components/notlog/notlog.vue"
import nodate from '@/components/notdate/notdate.vue';
import fundsTransfe from "@/components/fundsTransfe/fundsTransfe.vue";
import topUp from "@/components/topUp/topUp.vue";

import config from '@/common/api/config';

import currencyApi from '@/common/api/currency';
import userApi from '@/common/api/user';
import tradeApi from '@/common/api/trade';

import {mapGetters, mapState} from "vuex";

import { KLineChartPro, DefaultDatafeed } from '@klinecharts/pro'
import '@klinecharts/pro/dist/klinecharts-pro.css'
import {clear} from "core-js/internals/task";

let socket = false;
let socketInterval = false;

let amax;
let bmax;

let slider = false;
//k线图相关变量
let klineCharts;
let _ticker;
let upset = false;

let firstPrice;
let last;

const time = {
    '1s':0,
    '1m':1,
    '5m':5,
    '15':15,
    '30m':30,
    '1H':60,
    '4H':240,
    '12H':720,
    '1D':1440,
    '1W':10080
}

export default {
    components: {Footer, PageNav, notlog,nodate,fundsTransfe,topUp},
    data() {
        return {
            coinName: "BTC",
            coinType: "USDT",
            showAll: false,
            chartType: 0,
            timeActive: 0,
            timeEdit: false,
            tableActive: 0,
            aboutCoin: false,
            decimal: "0.000001",
            listType: 0,
            timer: "",
            btnActive: 0,
            tabActive:1,
            modeIndex: 0,
            subtabActive: 0,
            marks: {
                0: '0%',
                25: '25%',
                50: '50%',
                75: '75%',
                100: '100%'
            },
            currency: [], //所有现货交易对
            current: false, //当前币种数据
            currencyInfo: {}, //币种详情数据

            asks: [],
            bids: [],
            lastPrice: 0,
            tradeList: [],
            orderList:[],
            history:[],

            balance: {},
            amount: "",//委托数量
            rate: 0,//百分比
            pamount: '',//成交额
            price : '',//委托价格

            loading:false,
            transfeVisable:false,
            topVisable:false
        }
    },
    computed: {
        ...mapGetters(['isLogin', 'symbol', 'symbol_rate', 'isLogin', 'userId']),
        ...mapState(['userinfo'])
    },
    mounted() {
        this.getTradeCurrencyList();
    },
    watch: {
        isLogin(v) {
            if (v && this.current) {
                this.getCurrencyBalance();
                this.getOrderList();
            }
        },
        tabActive(v){
            if(parseInt(v) === 2){
                if(this.price === ''){
                    this.price = this.current['new_price'];
                }
            }
        },
        btnActive(v){
            this.amount = '';
            this.pamount = '';
            this.rate = 0;
        }
    },
    methods: {
        createCharts(){
            let _this = this;
            class CustomDatafeed {
                searchSymbols(search) {
                }
                async getHistoryKLineData(symbol, period, from, to) {
                    _this.$util.setCache('local-period',JSON.stringify(period));
                    let res = await currencyApi.contractLine({
                        interval: period.text,
                        currency_id: _this.current['id'],
                        limit:1000
                    });
                    let type = 'candle_solid';
                    if(period.text === '1s'){
                        type = 'area';
                    }
                    klineCharts.setStyles({
                        candle:{
                            type:type
                        }
                    });
                    let data = res.data.map((item)=>{
                        return {
                            timestamp:item['time'],
                            open: item['open'],
                            high: item['high'],
                            low: item['low'],
                            close: item['close'],
                            volume: item['vol'],
                            turnover:item['amount'],
                        }
                    });
                    last = data[data.length - 1];
                    return data;
                }
                subscribe(symbol, period, callback) {
                    // 完成ws订阅或者http轮询
                    console.log(symbol);
                    if(upset !== false){
                        clearInterval(upset);
                    }
                    upset = setInterval(()=>{
                        if(period.text === '1s'){
                            firstPrice = parseFloat(_ticker['c']);
                            callback({
                                timestamp:_ticker['E'],
                                open:firstPrice,
                                close:firstPrice,
                                high:firstPrice,
                                low:firstPrice,
                                volume:parseInt(_ticker['Q']),
                                turnover:parseInt(_ticker['Q']),
                            });
                        }else{
                            let minint = time[period.text] * 60 * 1000;
                            firstPrice = parseFloat(_ticker['c']);
                            let data;
                            if(parseInt(_ticker['E']) - last['timestamp'] > minint){
                                data = {
                                    timestamp:parseInt(_ticker['E']),
                                    open:firstPrice,
                                    close:firstPrice,
                                    high:firstPrice,
                                    low:firstPrice,
                                    volume:parseInt(_ticker['Q']),
                                    turnover:parseInt(_ticker['Q']),
                                };

                            }else{
                                data = {
                                    timestamp:last['timestamp'],
                                    open:last['open'],
                                    close:firstPrice,
                                    high:last['high'] < firstPrice ? firstPrice : last['high'],
                                    low:last['low'] > firstPrice ? firstPrice : last['low'],
                                    volume:last['volume'] + parseInt(_ticker['Q']),
                                    turnover:last['volume'] + parseInt(_ticker['Q']),
                                };
                            }
                            last = data;
                            callback(data);
                        }
                    },500);
                }
                unsubscribe(symbol, period) {
                    clearInterval(upset);
                }
            }
            let lang = this.$i18n.locale;
            let localPeriod = this.$util.getCache('local-period','');
            if(localPeriod === ''){
                localPeriod = {
                    multiplier : 1,timespan:'minute',text:'1m'
                }
            }else{
                localPeriod = JSON.parse(localPeriod);
            }
            klineCharts = new KLineChartPro({
                container: document.getElementById('chartBox'),
                watermark:"ComeBit Exchange",
                theme:this.$util.getCache('userTheme'),
                locale:lang === 'zh' ? "zh-CN":'en-US',
                timezone:this.$util.getCache('time_zone'),
                // 初始化标的信息
                symbol: {
                    ticker: 'BTCUSDT',
                    pricePrecision:_this.current['decimals'],
                    logo:_this.current['icon'],
                    type:"ADRC"
                },
                // 初始化周期
                period: localPeriod,
                periods:[
                    {multiplier:0,timespan:'second',text:'1s'},
                    {multiplier:1,timespan:'minute',text:'1m'},
                    {multiplier:5,timespan:'minute',text:'5m'},
                    {multiplier:15,timespan:'minute',text:'15m'},
                    {multiplier:30,timespan:'minute',text:'30m'},
                    {multiplier:60,timespan:'minute',text:'1H'},
                    {multiplier:240,timespan:'minute',text:'4H'},
                    {multiplier:720,timespan:'minute',text:'12H'},
                    {multiplier:1440,timespan:'minute',text:'1D'},
                    {multiplier:10080,timespan:'minute',text:'1W'}
                ],
                subIndicators: [],
                datafeed: new CustomDatafeed()
            });
        },
        reloadCredit(){
            this.getCurrencyBalance();
        },
        orderChange(e){
            if(parseInt(e) === 2){
                this.getHistoryList();
            }
        },
        historyOrderScroll(e){
            console.log(e);
        },
        getOrderList(){
            if(this.isLogin && this.current){
                tradeApi.tradeOrderList(this.current['id']).then(res=>{
                    this.orderList = res.data;
                });
            }
        },
        getHistoryList(){
            tradeApi.orderHistory(this.current['id'],1).then(res=>{
                this.history = res.data.list;
            });
        },
        cancelOrder(orderId){
            tradeApi.orderCancel(orderId).then(res=>{
                if(res.code === 200){
                    this.$notification.success("已取消委托订单");
                    this.getOrderList();
                }
            })
        },
        submitOrder(){
            if(this.amount === '' || this.pamount === '' || this.amount <= 0 || this.pamount <= 0){
                return;
            }
            if(
                (this.btnActive === 0 && parseFloat(this.pamount) > parseFloat(this.balance['legal_balance'])) ||
                (this.btnActive === 1) && parseFloat(this.amount) > parseFloat(this.balance['trade_balance'])
            ){
                this.$notification.info("可用资金余额不足");
                this.loading = false;
                return;
            }
            this.loading = true;
            const form = {
                cmd_id: 1,
                currency_id: this.current['id'],
                order_type: this.tabActive,
                direction: this.btnActive === 0 ? 1 : 2,
                trade_type: 1,
                price: this.tabActive === 1 ? parseFloat(this.current['new_price']) : parseFloat(this.price),
                amount: this.btnActive === 0 ? parseFloat(this.pamount) : parseFloat(this.amount)
            };
            tradeApi.tradeSubmit(form).then(res=>{
                this.loading = false;
                this.rate = 0;
                this.amount = '';
                this.pamount = '';
                this.price = '';
                if(res.code === 200){
                    this.$notification.info(parseInt(this.tabActive) === 1 ? '已成交':'已提交');
                    this.$refs.audioPlayer.play();
                    this.getCurrencyBalance();
                    this.getOrderList();
                }else{
                    this.$notification.info(res.message);
                }
            }).catch((e)=>{
                this.loading = false;
                this.$notification.info(e);
            })
        },
        /**
         * 限价委托单输入价格
         */
        inputPrice(e){
            this.price = e;
        },
        inputAmount(e){
            if(e > 0){
                this.amount = e;
                let price = this.tabActive === 1 ? parseFloat(this.current['new_price']) : (this.price !== '' ? this.price : 0);
                this.pamount = parseFloat(parseFloat(this.amount) * price).toFixed(2);
                if(this.btnActive === 0){
                    let avaAmount = parseFloat(this.balance['legal_balance']) / price;
                    this.rate = this.amount / avaAmount * 100 > 100 ? 100 : this.amount / avaAmount * 100;
                }else{
                    let avaAmount = parseFloat(this.balance['trade_balance']);
                    this.rate = this.amount / avaAmount * 100 > 100 ? 100 : this.amount / avaAmount * 100;
                }
                if(this.amount <= 0){
                    this.amount = '';
                    this.pamount = '';
                    this.rate = 0;
                }
            }
        },
        inputPamoun(e){
            this.pamount = e;
            let price = this.tabActive === 1 ? parseFloat(this.current['new_price']) : (this.price !== '' ? this.price : 0);
            if(this.btnActive === 0){
                this.amount = parseFloat(this.pamount / price).toFixed(this.current['trade_decimals']);
                let avaAmount = parseFloat(this.balance['legal_balance']) / price;
                this.rate = this.amount / avaAmount * 100 > 100 ? 100 :this.amount / avaAmount * 100;
            }else{
                this.amount = parseFloat(this.pamount / price).toFixed(this.current['trade_decimals']);
                let avaAmount = parseFloat(this.balance['trade_balance']);
                this.rate = this.amount / avaAmount * 100 > 100 ? 100 :this.amount / avaAmount * 100;
            }
            if(this.amount <= 0){
                this.amount = '';
                this.pamount = '';
                this.rate = 0;
            }
        },
        sliderChange(e) {
            slider = true;
            this.rate = e;
            let price = this.tabActive === 1 ? parseFloat(this.current['new_price']) : (this.price !== '' ? this.price : 0);
            if(this.btnActive === 0){
                this.amount = parseFloat(parseFloat(this.balance['legal_balance']) / price * (e / 100)).toFixed(this.current['trade_decimals']);
                this.pamount = parseFloat(parseFloat(this.amount) * price).toFixed(2);
            }else{
                this.amount = parseFloat(parseFloat(this.balance['trade_balance']) * (e / 100)).toFixed(this.current['trade_decimals']);
                this.pamount = parseFloat(parseFloat(this.amount) * price).toFixed(2);
            }
            if(this.amount <= 0 || price === 0){
                this.amount = '';
                this.pamount = '';
                this.rate = 0;
            }
            slider = false;
        },
        changeTab(e){
            this.tabActive = parseInt(e);
        },
        connectSocket() {
            if (!socket) {
                const deviceId = this.$util.getCache('deviceId');
                const url = config['BASE_WSS'] + '/?token=' + deviceId + '$trade' + '&sub=trade';
                socket = new WebSocket(url);
                socket.onopen = () => {
                    setTimeout(() => {
                        socketInterval = setInterval(() => {
                            socket.send(JSON.stringify({
                                event: 'updateUserId',
                                uid: this.userId
                            }));
                        }, 5000);
                    }, 1500);
                    this.subscribe();
                    socket.onmessage = (data) => {
                        this.onMessage(JSON.parse(data.data));
                    }
                }
            }

        },
        onMessage(data) {
            if (data.type === 102) {
                const date = new Date(data.data.data.E);
                let trades = this.tradeList;
                if (trades.length > 50) {
                    trades = trades.slice(1);
                }
                trades.push({
                    time: date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds(),
                    price: data.data.data.p,
                    vol: data.data.data.q
                });
                this.tradeList = trades;
            }
            if (data.type === 100) {
                let ticker = this.$util.getCurrentTicker(this.current['id'], data.data.data);
                _ticker = ticker;
                this.lastPrice = this.current['new_price'];
                let current = this.current;
                current['new_price'] = ticker['c'];
                current['change_rate'] = ticker['P'];
                current['high_price'] = ticker['h'];
                current['low_price'] = ticker['l'];
                current['quantity'] = ticker['v'];
                current['quota'] = ticker['q']
                this.current = current;
            }
            if (data.type === 101) {
                //深度数据
                amax = 0;
                bmax = 0;
                let asks = data.data.data.asks;
                let bids = data.data.data.bids;
                asks.sort((a, b) => {
                    return a[1] - b[1];
                });
                asks.map((v, i) => {
                    if (amax < parseFloat(v[1])) {
                        amax = v[1];
                    }
                });

                for (let i = 0; i < asks.length; i++) {
                    asks[i][3] = parseFloat(asks[i][1] / amax).toFixed(2);
                }
                asks.reverse();
                bids.map((v, i) => {
                    if (bmax < parseFloat(v[1])) {
                        bmax = v[1];
                    }
                });
                bids.reverse();
                for (let i = 0; i < bids.length; i++) {
                    bids[i][3] = parseFloat(bids[i][1] / bmax).toFixed(2);
                }
                this.asks = asks;
                this.bids = bids;
            }
        },
        subscribe() {
            socket.send(JSON.stringify({
                event: "trade_depth_subscribe",
                cm_id: 1,
                currency_id: this.current['id']
            }));
        },
        getCurrencyBalance() {
            if (this.isLogin) {
                userApi.getCurrencyBalance(this.current['id']).then(res => {
                    this.balance = res.data;
                });
            }
        },
        getTradeCurrencyList() {
            currencyApi.tradeCurrencyList().then(res => {
                this.currency = res.data;
                this.getCurrentCurrency();
                this.connectSocket();
                this.getOrderList();
            });
        },
        setCurrentCurrency(item) {
            this.current = item;
            this.getCurrencyInfo(this.current['id']);
            this.subscribe();
            this.getCurrencyBalance();
            this.getOrderList();
            this.tradeList = [];
            this.$util.setCache('trade-current', JSON.stringify(item));
            klineCharts.setSymbol({
                ticker: item['symbol']+'USDT',
                pricePrecision:item['decimals'],
                logo:item['icon'],
                type:""
            });
        },
        getCurrentCurrency() {
            const currency = this.$util.getCache('trade-current', '');
            if (currency === '') {
                this.current = this.currency[0]['currency'][0];
                this.$util.setCache('trade-current', JSON.stringify(this.current));
            } else {
                this.current = JSON.parse(currency);
            }
            this.createCharts();
            this.getCurrencyInfo(this.current['id']);
            this.getCurrencyBalance();
        },
        getCurrencyInfo(currency_id) {
            currencyApi.getCurrencyInfo(currency_id, 1).then(res => {
                this.currencyInfo = res.data;
                if (this.currencyInfo.currency_info != null) {
                    this.currencyInfo.currency_info = JSON.parse(this.currencyInfo.currency_info);
                    console.log(this.currencyInfo.currency_info);
                }
            });
        },
        tableToggle(item, index) {
            this.tableActive = index;
            this.coinName = item.name;
            this.coinType = item.type;
        },
        aboutCoinShow() {
            this.aboutCoin = true;
        },
        numSelect(e) {
            this.decimal = e;
        },
        toDetail() {
            this.$router.push("/HomeView/futures/futuresData")
        }
    },
    beforeUnmount() {
        clearInterval(this.timer);
        clearInterval(socketInterval);
        this.timer = null;
        socket.close();
        if (socketInterval) {
            clearInterval(socketInterval);
        }
        socket = false;
    }
}