<template>
    <div class="fundsTransfe">
        <a-modal :visible="visible"
                 align-center
                 :render-to-body="false"
                 mask-style="background:rgba(0, 0, 0, .5)"
                 modal-class="transfePopup"
                 width="488px"
                 :hide-title="true"
                 :mask-closable="true"
                 :footer="false">
            <div class="titleBox">
                <span class="titleText">资金划转</span>
                <icon-close @click="close" size="22"/>
            </div>
            <div class="contentBox">
                <div class="label">
                    <p class="color-black">从</p>
                    <!--                    <div class="labelInput">-->
                    <!--                        <span class="color-black">资金账户</span>-->
                    <!--                    </div>-->
                    <a-dropdown type="accSelectFirst" @select="fromSelect" :render-to-body="false"
                                popup-container="label" position="bottom">
                        <div class="labelInput cursor">
                            <span class="color-black">{{ from.text }}</span>
                            <icon-caret-down class="color-black"/>
                        </div>
                        <template #content>
                            <a-doption v-for="(item,index) in accOptions" :value="item" :key="index">
                                {{ item.text }}
                            </a-doption>
                        </template>
                    </a-dropdown>
                </div>
                <div class="tradeImage">
                    <img src="@/assets/images/trade-icon.png" alt="">
                </div>
                <div class="label">
                    <p class="color-black">划转到</p>
                    <a-dropdown type="accSelect" @select="accSelect" :render-to-body="false" popup-container="label"
                                position="bottom" v-if="cut === false">
                        <div class="labelInput cursor">
                            <span class="color-black">{{ to.text }}</span>
                            <icon-caret-down class="color-black"/>
                        </div>
                        <template #content>
                            <a-doption v-for="(item,index) in accOptions" :value="item" :key="index">
                                {{ item.text }}
                            </a-doption>
                        </template>
                    </a-dropdown>
                    <div class="labelInput" v-else>
                        <span class="color-black">资金账户</span>
                    </div>
                </div>
                <div class="label">
                    <p class="color-black">币种</p>
                    <div class="labelInput cursor" @click="listShow = true">
                        <span class="color-black" >{{currency ? currency['symbol'] :'请选择'}}</span>
                        <icon-caret-down class="color-black" />
                    </div>
                    <coin-list :display="listShow" @select="coinSelect" :coinGroup="coin"></coin-list>
                </div>
                <div class="label">
                    <p class="color-black">数量</p>
                    <div class="labelInput">
                        <a-input class="input" placeholder="划转数量" v-model="amount">
                            <template #suffix>
                                <span class="color-blue" @click="amount = currency[from['filed']] ? currency[from['filed']] : 0">全部划转</span>
                            </template>
                        </a-input>
                    </div>
                    <p class="tipText color-gray">可划转 <span class="color-primary font-14 mr-5 ml-5">{{currency[from['filed']] ? currency[from['filed']] : '0.00'}}</span> USDT</p>
                </div>
                <div class="tipBox">
                    <icon-exclamation-polygon-fill class="color-primary" size="20"/>
                    <span class="color-primary">
                        只有将资产划转到对应账户才可进行交易，账户间的划转不收取手续费。
                    </span>
                </div>
                <div class="buttonBox">
                    <a-button class="button" :loading="loading" :disabled="amount === '' || amount <= 0" @click="confirm">
                        <span>确认</span>
                    </a-button>
                </div>
            </div>
        </a-modal>
    </div>
</template>
<script>
import coinList from "@/components/coinList/coinList.vue";
import commonApi from '@/common/api/common';

export default {
    components: {
        coinList
    },
    name: "fundsTransfe",
    props: {
        visible: {
            default: false,
            type: Boolean
        }
    },
    data() {
        return {
            loading:false,
            listShow: false,
            cut: false,
            selectVal: '合约账户',
            accOptions: [
                {
                    text:'资金账户',
                    filed:"balance"
                },
                {
                    text:'现货账户',
                    filed:"trade_balance"
                },
                {
                    text:'合约账户',
                    filed:"contract_balance"
                },
                {
                    text: '期权账户',
                    filed: "future_balance"
                },
                {
                    text: '理财账户',
                    filed: "finance_balance"
                }
            ],
            coin:[],
            currency:false,
            from:{
                text:'资金账户',
                filed:"balance"
            },
            to:{
                text:'现货账户',
                filed:"trade_balance"
            },
            amount:''
        }
    },
    mounted() {

    },
    watch:{
        visible(v){
            if(v){
                this.getCurrencyAccount();
            }
        }
    },
    methods: {
        confirm(){
            if(this.amount === '' || this.amount <= 0){
                return;
            }
            if(this.from['filed'] === this.to['filed']){
                this.$notification.info("划转账户不能一致");
                return;
            }
            if(parseFloat(this.amount) > parseFloat(this.currency[this.from['filed']])){
                this.$notification.info("可划转余额不足");
                return;
            }
            this.loading = true;
            const form = {
                currency_id: this.currency['id'],
                amount: this.amount,
                to: this.to['filed'],
                from: this.from['filed']
            };
            commonApi.postExchange(form).then(res=>{
                this.loading = false;
                if(res.code === 200){
                    this.$notification.success("划转成功");
                    this.currency[this.from['filed']] = parseFloat(this.currency[this.from['filed']]) - parseFloat(this.amount);
                    this.$emit('success');
                }
            }).catch((e)=>{
                this.loading = false;
                this.$notification.info(e);
            });
        },
        fromSelect(e){
            this.from = e;
            this.amount = '';
        },
        coinSelect(item){
            console.log(item);
            this.currency = item;
            this.listShow = false;
        },
        getCurrencyAccount(){
            commonApi.getAccountCurrency().then(res=>{
                this.coin = res.data;
            });
        },
        toggle() {
            // this.cut = !this.cut;
            let temp = this.to;
            this.to = this.form;
            this.form = temp;
        },
        accSelect(e) {
            this.selectVal = e;
            this.to = e;
        },
        close() {
            this.$emit('close')
        }
    }
}
</script>
<style lang="scss" scoped>
:deep(.transfePopup) {
    border-radius: 8px;
    padding: 20px;
    box-sizing: border-box;
    background-color: var(--background-primary) !important;

    .titleBox {
        width: 100%;
        height: auto;
        display: flex;
        align-content: center;
        justify-content: space-between;

        .titleText {
            font-size: 20px;
            font-weight: 500;
            color: var(--content-primary);
        }

        .arco-icon {
            cursor: pointer;
            color: var(--content-primary);
        }
    }

    .contentBox {
        width: 100%;
        height: auto;
        padding: 30px 0 0 0;
        box-sizing: border-box;

        .tradeImage {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: var(--background-secondary);
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 10px auto;
            cursor: pointer;

            img {
                width: 20px;
                height: 20px;
                transform: rotate(90deg);
            }
        }

        .label {
            width: 100%;
            height: auto;
            margin-bottom: 20px;

            p {
                margin: 0;
            }

            .labelInput {
                margin-top: 6px;
                width: 100%;
                height: 44px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 10px;
                box-sizing: border-box;
                background: var(--background-secondary);
                border-radius: 44px;
                position: relative;
                border: 1px solid transparent;
                transition: all 0.25s linear;

                &:focus-within {
                    border-color: var(--color-primary);
                }

                .input {
                    padding: 0;
                    border-color: transparent;
                    background: transparent;

                    .arco-input-suffix {
                        background: transparent;
                        cursor: pointer;
                    }
                }
            }

            .tipText {
                margin-top: 6px;
                font-size: 13px;
            }
        }

        .tipBox {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            line-height: 24px;
            background: var(--tip-primary-bg);
            padding: 10px;
            box-sizing: border-box;
            border-radius: 6px;

            .arco-icon {
                margin-top: 2px;
            }

            span {
                padding-left: 10px;
                box-sizing: border-box;
            }
        }

        .buttonBox {
            width: 100%;
            height: auto;
            margin-top: 50px;

            .button {
                width: 100%;
                height: 44px;
                background: var(--color-primary);
                color: var(--color-always-white);
                border-radius: 44px;

                &:hover {
                    opacity: 0.8;
                }
            }

            .arco-btn-disabled {
                background: var(--background-secondary) !important;
                color: var(--content-tertiary) !important;

                &:hover {
                    opacity: 1 !important;
                }
            }
        }
    }
}

:deep(.arco-trigger-popup) {
    z-index: 2 !important;
    left: 0 !important;

    &[type='accSelect'] {
        top: 280px !important;
    }

    &[type='accSelectFirst'] {
        top: 140px !important;
    }

    &[type='coinSelect'] {
        top: 300px !important;
    }

    .arco-dropdown {
        width: 448px !important;
        border-radius: 8px;
        padding: 8px;
        background: var(--background-primary);
        border-color: var(--border-strength-100);

        .arco-dropdown-option {
            color: #ffffff;
            font-size: 13px;

            &:hover {
                background: var(--form-bg);
                border-radius: 4px;
                color: #ffffff;
            }
        }
    }
}
</style>