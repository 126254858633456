import {createRouter, createWebHashHistory} from "vue-router";
import HomeView from "../views/newHome/newHome.vue"
import BindBox from "../views/BindBox/BindBox.vue"
import login from "../views/Login/login.vue"
import register from "../views/Register/register.vue"
import resetPass from "../views/ResetPass/resetPass.vue"
import onePurchase from "../views/onePurchase/onePurchase.vue"
import flashOption from "../views/Flash/flashOption.vue"
import volatilityOption from "../views/Volatility/volatilityOption.vue"
import term from "../views/Home/investment/Term/term.vue"
import newHome from "../views/newHome/newHome.vue"
import spot from "../views/Home/spot/spot.vue"
import futures from "../views/Home/futures/futures.vue"
import mock from "../views/Home/mock/mock.vue"
import futuresData from "../views/Home/futures/futuresData/futuresData.vue"
import copyTraders from "../views/Home/copyTraders/copyTraders.vue"
import appDownload from "../views/Home/appDownload/appDownload.vue"
import investment from "../views/Home/investment/investment.vue"
import fund from "../views/Home/investment/fund/fund.vue"
import fundDetails from "../views/Home/investment/fund/fundDetails/fundDetails.vue"
import termDetail from "../views/Home/investment/Term/detail/detail.vue"
import easyEarn from "../views/Home/investment/easyEarn/easyEarn.vue"
import pledge from "../views/Home/investment/pledge/pledge.vue"
import assets from "../views/Home/assets/assets.vue"
import order from "../views/Home/order/orderView.vue"
import personal from "../views/Home/personal/userManagement.vue"
import help from "../views/Help/help.vue"
import categories from "../views/Help/categories/categories.vue"
import content from "../views/Help/content/contentView.vue"
import rank from "../views/rank/rank.vue"
import topUp from "../views/Home/assets/topUp/topUp.vue"
import withdraw from "../views/Home/assets/withdraw/withdraw.vue"
import quotes from "@/views/Home/quotes/quotes.vue";

const routes = [
    // {
    //   path: "/",
    //   name: "newHome",
    //   component: newHome,
    // },
    {
        path: "/",
        name: "HomeView",
        component: HomeView,
    },
    {
        path: "/BindBox",
        name: "BindBox",
        component: BindBox,
    },
    {
        path: "/login",
        name: "login",
        component: login,
    },
    {
        path: "/register",
        name: "register",
        component: register,
    },
    {
        path: "/resetPass",
        name: "resetPass",
        component: resetPass,
    },
    {
        path: "/onePurchase",
        name: "onePurchase",
        component: onePurchase,
    },
    {
        path: "/flashOption",
        name: "flashOption",
        component: flashOption,
    },
    {
        path: "/volatilityOption",
        name: "volatilityOption",
        component: volatilityOption,
    },
    {
        path: "/term",
        name: "term",
        component: term,
    },
    {
        path: "/spot",
        name: "spot",
        component: spot,
    },
    {
        path: "/futures",
        name: "futures",
        component: futures,
    },
    {
        path: "/mock",
        name: "mock",
        component: mock,
    },
    {
        path: "/HomeView/futures/futuresData",
        name: "futuresData",
        component: futuresData,
    },
    {
        path: "/copyTraders",
        name: "copyTraders",
        component: copyTraders,
    },
    {
        path: "/HomeView/appDownload",
        name: "appDownload",
        component: appDownload,
    },
    {
        path: "/investment",
        name: "investment",
        component: investment,
    },
    {
        path: "/fund",
        name: "fund",
        component: fund,
    },
    {
        path: "/HomeView/investment/fund/fundDetails",
        name: "fundDetails",
        component: fundDetails,
    },
    {
        path: "/HomeView/investment/Term/detail",
        name: "termDetail",
        component: termDetail,
    },
    {
        path: "/easyEarn",
        name: "easyEarn",
        component: easyEarn,
    },
    {
        path: "/pledge",
        name: "pledge",
        component: pledge,
    },
    {
        path: "/HomeView/assets",
        name: "assets",
        component: assets,
    },
    {
        path: "/HomeView/order",
        name: "order",
        component: order,
    },
    {
        path: "/HomeView/personal/:e?",
        name: "personal",
        component: personal,
    },
    {
        path: "/help",
        name: "help",
        component: help,
    }, {
        path: "/categories",
        name: "categories",
        component: categories
    },
    {
        path: "/content",
        name: "content",
        component: content
    },
    {
        path: "/rank",
        name: "rank",
        component: rank
    },
    {
        path: "/topup",
        name: "topUp",
        component: topUp
    },
    {
        path: "/withdraw",
        name: "withdraw",
        component: withdraw
    },
    {
        path: "/quotes",
        name: "quotes",
        component: quotes
    },
    {
        path: "/about",
        name: "about",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
