import _request from './request.js';
import util from "@/common/util";

export default {
    config(groupId, key = '') {
        return _request.request("api/common/config", {
            group_id: groupId,
            key: key
        }, "POST");
    },
    info(params){
        return _request.request("api/article/detail", {
            key: params
        }, "GET");
    },
    list(cate_id){
        return _request.request("api/article/list", {
            cate_id: cate_id
        }, "GET");
    },
    child_catelist(id){
        return _request.request("api/article/child_catelist", {
            id: id
        }, "GET");
    },
    get_catename(id){
        return _request.request("api/article/get_catename", {
            id: id
        }, "GET");
    }
}