import PageNav from "@/components/PageNav/PageNav.vue";
import Footer from "@/components/Footer/footer.vue";
import idAuth from "@/views/Home/personal/idAuth/idAuth.vue";
import apiManagement from "./apiManagement/apiManagement.vue";
import recommendInvite from "./recommendInvite/recommendInvite.vue";
import safetyRecord from "./safetyRecord/safetyRecord.vue";
import bindEmail from "./bindEmail/bindEmail.vue";
import changePhone from "./changePhone/changePhone.vue";
import bindGoogle from "./bindGoogle/bindGoogle.vue";
import changePassword from "./changePassword/changePassword.vue";
import userSettings from "./userSettings/userSettings.vue";
import Model from "@/components/model/modelContent.vue";
import {mapGetters, mapState} from "vuex";

export default {
    components: {
        Footer,
        PageNav,
        idAuth,
        apiManagement,
        recommendInvite,
        safetyRecord,
        userSettings,
        Model,
        bindEmail,
        changePhone,
        bindGoogle,
        changePassword,
    },
    data() {
        return {
            menuList: [
                {
                    id: 1,
                    text: "总览",
                },
                {
                    id: 2,
                    text: "身份认证",
                },
                {
                    id: 3,
                    text: "API",
                },
                {
                    id: 4,
                    text: "邀请返佣",
                },
                {
                    id: 5,
                    text: "偏好设置",
                },
            ],
            activeKey: 1,
            showImg: false,
            //控制主页面
            userManagement: true,
            // 控制子页面
            safetyRecord: false,
            bindEmail: false,
            changePhone: false,
            bindGoogle: false,
            changePassword: false,
            setting: [
                {
                    name: "邮箱验证", //名称
                    des: "用于登录、提现、修改密码、安全设置等操作时收取邮箱验证码",  //描述
                    status: {
                        name: "未绑定",  //状态
                        color: "#ff9e2d",  //状态标识
                    },
                    btn: "绑定", //按钮名称
                    type: "primary", //按钮类型
                    ifBind: false, //是否绑定
                    ifShow: false, //控制子页面是否显示
                    component: "bindEmail", //子页面名称
                },
                {
                    name: "手机验证",
                    des: "用于登录、提现、修改密码、安全设置等操作时收取手机验证码",
                    status: {
                        name: "未绑定",
                        color: "#ff9e2d",
                    },
                    btn: "编辑",
                    type: "primary",
                    ifBind: false,
                    ifShow: false,
                    component: "changePhone",
                },
                {
                    name: "谷歌验证",
                    des: "用于登录、提现、修改密码、安全设置等操作时收取谷歌验证码",
                    status: {
                        name: "未绑定",
                        color: "#ff9e2d",
                    },
                    btn: "绑定",
                    type: "primary",
                    ifBind: false,
                    ifShow: false,
                    component: "bindGoogle",
                },
                {
                    name: "登陆密码",
                    des: "通过设置登录密码，您将可以使用账号和登录密码直接登录",
                    status: {
                        name: "未绑定",
                        color: "#ff9e2d",
                    },
                    btn: "编辑",
                    type: "primary",
                    ifBind: true,
                    ifShow: false,
                    component: "changePassword",
                },
            ],
            visible: false,
            eyeShow: false
        };
    },
    computed: {
        ...mapGetters(['userId', 'userNickname', 'userAccount', 'userTheme']),
        ...mapState(['userinfo'])
    },
    mounted() {
        console.log(this.userinfo);
        console.log(this.$route.params);
    },
    methods: {
        toSafety() {
            this.userManagement = false;
            this.safetyRecord = true;
        },
        numSelect(e) {
            this.decimal = e;
        },
        tabActive(e) {
            this.safetyRecord = false;
            this.activeKey = e;
        },
        goBack() {
            this.safetyRecord = false;
            this.bindEmail = false;
            this.changePhone = false;
            this.bindGoogle = false;
            this.changePassword = false;
            this.userManagement = true;
        },
        handleClick() {
            this.visible = true;
        },
        handleOkA() {
            console.log('确认')
            this.visible = false;
        },
        handleCancel() {
            this.visible = false;
        },
        ClickToBind(e) {
            switch (e.component) {
                case 'bindEmail':
                    if (e.ifShow) {
                        console.log(e, '已绑定，请修改')
                    } else {
                        this.bindEmail = true
                        this.userManagement = false
                    }
                    break;
                case 'changePhone':
                    if (e.ifShow) {
                        console.log(e, '已绑定，请修改')
                    } else {
                        this.changePhone = true
                        this.userManagement = false
                    }
                    break;
                case 'bindGoogle':
                    if (e.ifShow) {
                        console.log(e, '已绑定，请修改')
                    } else {
                        this.bindGoogle = true
                        this.userManagement = false
                    }
                    break;
                case 'changePassword':
                    if (e.ifShow) {
                        console.log(e, '已绑定，请修改')
                    } else {
                        this.changePassword = true
                        this.userManagement = false
                    }
                    break;
                default:
                    break;
            }
        }
    },
};