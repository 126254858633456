import PageNav from "@/components/PageNav/PageNav.vue";
import Footer from "@/components/Footer/footer.vue";

export default {
    components: {
        Footer,
        PageNav
    },
    data() {
        return {
            tabOptions: ['涨幅榜', '跌幅榜', '新币榜', '成交额榜'],
            tabIndex: 0,
            priceGroup: ['全部价格', '<$0.5', '$0.5-1', '$1-10', '$10-100', '>$100'],
            market: ['全部市值', '<100M', '100-1B', '1B-5B', '5B-10B', '>50B'],
            increase: ['今日涨幅', '5分钟涨幅', '1小时涨幅', '当周涨幅', '当月涨幅'],
            priceVal: '',
            marketVal: '',
            increaseVal: '',
            pricesort: 1,
            marketsort: 1,
            increasesort: 1
        }
    },
    created() {
        this.priceVal = this.priceGroup[0]
        this.marketVal = this.market[0]
        this.increaseVal = this.increase[0]
    },
    methods: {
        priceSort() {
            switch (this.pricesort) {
                case 1:
                    this.pricesort = 2;
                    break;
                case 2:
                    this.pricesort = 3;
                    break;
                case 3:
                    this.pricesort = 1;
                    break;
            }
        }
    }
}
