import PageNav from "@/components/PageNav/PageNav.vue";
import common from '@/common/api/common.js';
import country from '../../common/area';
import util from "@/common/util";
import {mapState, mapMutations} from 'vuex';

import { v4 as uuidv4 } from 'uuid';
import UQRCode from 'uqrcodejs';

let m = true;

export default {
    name: "LoginView",
    components: {PageNav},
    data() {
        return {
            country: country.countryList,
            code: '+86',
            showCountry: false,
            phone: '',
            is_phone: false,
            email: '',
            is_email: false,
            password: '',
            is_password: false,
            type: 1,

            loading:false,
            loginText:"立即登录",
            uuid:uuidv4()
        }
    },
    mounted() {
        let code = this.$route.query['invaite-code']
        if (code) {
            this.$util.setCache('invaite-code', code);
        }
        this.createQrcode();
        m = true;
        this.autoLogin();
    },
    computed: {
        ...mapState(['config'])
    },
    unmounted() {
        m = false
    },
    destroyed() {
        m = false
    },
    methods: {
        ...mapMutations(['userLogin']),
        autoLogin(){
            if(!m){
                return;
            }
            common.checkUuid(this.uuid).then(res=>{
                if(res.code === 200){
                    this.userLogin(res.data);
                    this.$message.info({
                        content: this.$t('login.t31'),
                        duration: 2000,
                        showIcon: false
                    });
                    this.$message.info({
                        content: this.$t('login.t31'),
                        duration: 1500,
                        showIcon: false
                    });
                    setTimeout(()=>{
                        this.$router.push('/HomeView/assets');
                    },1500);
                }else{
                    setTimeout(()=>{
                        this.autoLogin();
                    },1000);
                }
            });
        },
        changeType(e){
            this.type = parseFloat(e);
        },
        createQrcode() {
            setTimeout(()=>{
                var qr = new UQRCode();
                qr.data = "login:"+this.uuid;
                qr.size = 180;
                qr.make();
                var canvasContext = document.getElementById("qrcode").getContext("2d");
                qr.canvasContext = canvasContext;
                qr.drawCanvas();
            },500);
        },
        googleLogin(){
            this.$gAuth.signIn().then(res=>{
                console.log(res);
            }).catch((e)=>{
                this.$notification.warning("google接口调用失败，请检查配置");
            })

        },
        filterCountry(e){
            this.country = country.countryList.filter((item)=>{
                return item.dialingCode.includes(e) || item.enName.includes(e) || item.cnName.includes(e);
            });
        },
        resetCountry(){
            this.country = country.countryList;
        },
        loginType(k) {
            this.type = k
        },
        checkPhone() {
            this.is_phone = !util.validatePhoneNumber(this.code + this.phone)
        },
        checkEmail() {
            this.is_email = !util.validateEmail(this.email)
        },
        checkPassword() {
            let len = this.password.length
            this.is_password = !(len >= 8 && len <= 64)
        },
        login() {
            const loginInfo = {
                password: this.password,
                type: this.type
            };
            if (this.type === 1) {
                if (!this.phone || !this.password) {
                    this.$notification.info("请输入登录账户或密码");
                    return;
                }
                loginInfo['phone'] = this.phone;
                loginInfo['code'] = this.code.replace('+', '');
                util.setCache('login_phone', this.phone);
            } else {
                if (!this.email || !this.password) {
                    this.$notification.info("请输入登录账户或密码");
                    return;
                }
                loginInfo['email'] = this.email;
                util.setCache('login_email', this.email);
            }
            this.loginText = "登录中";
            this.loading = true;
            common.login(loginInfo).then((res) => {
                if (res.success) {
                    this.userLogin(res.data);
                    this.$message.info({
                        content: this.$t('login.t31'),
                        duration: 2000,
                        showIcon: false
                    })
                    this.loginText = "登录成功";
                    this.loading = false;
                    this.$router.push('/HomeView/assets')
                } else {
                    this.loginText = "重新登录";
                    this.loading = false;
                    this.$message.info({
                        content: res.message,
                        showIcon: false
                    })
                }
            });
        },
        toRegister() {
            this.$router.push("/register");
        },
        toReset() {
            this.$router.push("/resetPass");
        },
        countryOk(e) {
            this.code = e.dialingCode
            this.showCountry = false
        },
        openUrl(){
            window.open("https://t.me/chenmaq");
        }
    }
}