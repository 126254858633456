<template>
    <div v-show="!show"><a-spin /></div>
    <div v-show="show" class="kline-container" ref="kline"></div>
</template>
<script>
import { createChart } from "lightweight-charts"
import currencyApi from '@/common/api/currency';

export default {
    name:"kline",
    props: {
        kData: {
            default: []
        },
        change: {
            default: 0
        },
        currencyId:{
            type:Number,
            default:0
        }
    },
    components: {
    },
    data() {
        return {
            chart: null,
            lineSeries: null,
            show:false
        }
    },
    watch: {
        currencyId(v){
            console.log(v);
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        syncToInterval() {
            if (this.lineSeries) {
                this.chart.removeSeries(this.lineSeries);
                this.lineSeries = null;
            }
            this.lineSeries = this.chart.addAreaSeries({
                topColor: "#fff",
                bottomColor: "#fff",
                lineColor:  Number(this.change) >= 0 ? "#13b26f" : '#dd4b4b', // #13b26f 绿色 #dd4b4b 红
                lineWidth: 2,
                priceFormat: {
                    type: "price",
                },
                priceLineVisible: !1,
            });
            let kData = JSON.parse(JSON.stringify(this.kData))
            this.lineSeries.setData(kData)
        },
        init() {
            currencyApi.contractLine({
                interval: '1H',
                currency_id: this.currencyId,
                limit:48
            }).then(res=>{
                if(res.code !== 200){
                    return false;
                }
                let them = this.$util.getCache('userTheme','light');
                let chart = createChart(this.$refs.kline, {
                    width: 60,
                    height: 36,
                    watermark: {
                        visible: !1
                    },
                    grid: {
                        vertLines: {
                            visible: !1
                        },
                        horzLines: {
                            visible: !1
                        }
                    },
                    layout: {
                        background: {
                            color: them === 'light' ? '#fff' :"#1f1f1f"
                        },
                        textColor: "rgba(255, 255, 255, 0.9)"
                    },
                    crosshair: {
                        mode: 'Magnet',
                        vertLine: {
                            visible: !1,
                            labelVisible: !1,
                            labelBackgroundColor: "#fff"
                        },
                        horzLine: {
                            visible: !1,
                            labelVisible: !1,
                            labelBackgroundColor: "#fff"
                        }
                    },
                    timeScale: {
                        visible: !1
                    },
                    rightPriceScale: {
                        visible: !1
                    },
                    handleScale: {
                        mouseWheel: !1
                    },
                    handleScroll: {
                        mouseWheel: !1,
                        pressedMouseMove: !1,
                        horzTouchDrag: !1,
                        vertTouchDrag: !1
                    }
                });
                chart.timeScale().applyOptions({
                    barSpacing:7,
                    borderColor: '#71649C',
                    timeVisible:false,
                    secondsVisible:false,
                    scrollToRealTime:false,
                    tickMarkFormatter:(time,tickMarkType,locale)=>{
                        let date = new Date(time);  // 将微秒转换为毫秒
                        let hours = String(date.getHours()).padStart(2, '0');
                        let minutes = String(date.getMinutes()).padStart(2, '0');
                        let seconds = String(date.getSeconds()).padStart(2, '0');
                        return `${hours}:${minutes}:${seconds}`;
                    }
                });

                let lineSeries = chart.addAreaSeries({
                    topColor: them === 'light' ? '#fff' :"#1f1f1f",
                    bottomColor: them === 'light' ? '#fff' :"#1f1f1f",
                    lineColor:  Number(this.change) >= 0 ? "#13b26f" : '#dd4b4b', // #13b26f 绿色 #dd4b4b 红
                    lineWidth: 2,
                    priceFormat: {
                        type: "price",
                    },
                    priceLineVisible: !1,
                });
                lineSeries.setData(res.data);
                this.show = true;
            })
        },
    }
}
</script>

<style>

</style>