<template>
    <a-modal :visible="visible"
             :hide-title="true"
             :footer="false"
             :width="width">
        <div class="warining-warp">
            <div class="title">
                <div class="close" @click="close">
                    <icon-close></icon-close>
                </div>
            </div>
            <div class="content">
                <img src="@/assets/images/wd_order_img.png" alt="">
                <p>{{ content }}</p>
            </div>
            <div class="footer">
                <a-button class="button" @click="handleCancel">取消</a-button>
                <a-button class="button" @click="handleOk">确定</a-button>
            </div>
        </div>
    </a-modal>
</template>
<script>
export default {
    name: "warningPopup",
    props: {
        width: {
            default: '464px',
            type: String, Boolean
        },
        visible: {
            default: false,
            type: String, Boolean
        },
        content: {
            default: '警示内容',
            type: String
        }
    },
    methods: {
        close(){
            this.$emit('close')
        },
        handleCancel(){
            this.$emit('handleCancel')
        },
        handleOk(){
            this.$emit('handleOk')
        }
    }
}
</script>

<style lang="scss" scoped>
.warining-warp {
    padding: 24px;
    box-sizing: border-box;

    .title {
        width: 100%;
        height: auto;
        display: flex;
        align-content: center;
        justify-content: flex-end;

        .close {
            width: 32px;
            height: 32px;

            .arco-icon {
                font-size: 22px;
                color: var(--content-primary);
                cursor: pointer;

                &:hover {
                    opacity: .8;
                }
            }
        }
    }

    .content {
        width: 100%;
        height: auto;
        padding: 30px 0;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
            width: 85px;
            height: 76px;
            margin-bottom: 30px;
        }

        p {
            font-size: 14px;
            color: var(--content-primary);
            line-height: 24px;
            text-align: center;
        }
    }

    .footer {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .button {
            width: calc(50% - 8px);
            height: 40px;
            border-radius: 8px;

            &:hover {
                opacity: .8;
            }

            &:first-child {
                color: var(--content-primary);
                background: var(--background-tertiary);
            }

            &:last-child {
                color: var(--content-white);
                background: var(--content-primary);
            }
        }
    }
}
</style>