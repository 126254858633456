<template>
    <a-layout class="topup">
        <a-layout-header>
            <page-nav></page-nav>
        </a-layout-header>
        <a-layout-content>
            <div class="content">
                <div class="header">
                    <p class="title">提现</p>
                    <div class="label">
                        <p>提现币种</p>
                        <a-trigger position="bottom" :popup-translate="[0, -40]" :render-to-body="false">
                            <div class="inputBox">
                                <span class="span">{{ coinVal }}</span>
                                <icon-caret-down/>
                            </div>
                            <template #content>
                                <div class="list">
                                    <coin-list :display="true"></coin-list>
                                </div>
                            </template>
                        </a-trigger>
                    </div>
                    <div class="label">
                        <p>提现网络</p>
                        <a-trigger position="bottom" :render-to-body="false">
                            <div class="inputBox">
                                <span class="span">{{ coinVal }}</span>
                                <icon-caret-down/>
                            </div>
                            <template #content>
                                <div class="list">
                                    <div class="cell">
                                        <span class="color-black">Ethereum(ERC20)</span>
                                    </div>
                                </div>
                            </template>
                        </a-trigger>
                    </div>
                    <div class="label">
                        <p>提现地址</p>
                        <a-input class="input-box" placeholder="提现地址">
                            <template #suffix>
                                <span class="color-primary cursor" @click="visible = true">地址管理</span>
                            </template>
                        </a-input>
                    </div>
                    <div class="label">
                        <p>提现数量</p>
                        <a-input class="input-box" placeholder="提现数量">
                            <template #suffix>
                                <span class="color-primary cursor">全部提现</span>
                            </template>
                        </a-input>
                        <p class="num_tip">可用 <span>--BCH</span></p>
                        <p class="num_tip">手续费 <span>0.001 BCH</span></p>
                    </div>
                    <a-button class="button">提现</a-button>
                    <div class="label">
                        <div class="tips">
                            <p>
                                <span>最小提现限额</span>
                                <span>0.7 AAVE</span>
                            </p>
                            <p>
                                <span>最大提现限额</span>
                                <span>10,000 AAVE</span>
                            </p>
                            <p>
                                <span>单日提现限额</span>
                                <span>10,000 AAVE</span>
                            </p>
                            <p>
                                <span>24小时提现限额</span>
                                <span>21,905.805 AAVE</span>
                            </p>
                        </div>
                        <div class="tips" type="tip">
                            <p>
                                <span>提现提醒</span>
                                1.为保障资产安全，当您的账户安全策略发生变更或密码修改后，将暂时不能提现。<br>
                                2.账号重新登录后，需等待180秒后方可操作提现。请务必确认操作安全，防止信息被篡改或泄露<br>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="btm">
                    <p class="title">最近提现</p>
                    <div class="warp">
                        <a-scrollbar class="topup-list">
                            <div class="cell">
                                <span>时间</span>
                                <span>币种</span>
                                <span>提现网络</span>
                                <span>提现数量</span>
                                <span>提现地址</span>
                                <span>交易哈希</span>
                                <span>确认数</span>
                                <span>状态</span>
                                <span>操作</span>
                            </div>
                            <div class="cell" v-for="i in 30">
                                <span>2023-09-14 14:50:01</span>
                                <span>
                                <img src="@/assets/images/coin/usdt.png" alt="">
                                USDT
                            </span>
                                <span>Tron(TRC20)</span>
                                <span>21.999925</span>
                                <span class="up_line">
                                <b>TJTE6HJZ...pH54r5Pj</b>
                                <icon-copy/>
                            </span>
                                <span class="up_line">
                                <b>d6ca082c...1e82c04a</b>
                                 <icon-copy/>
                            </span>
                                <span>6/6</span>
                                <span class="color-green">充值成功</span>
                                <span>查看详情</span>
                            </div>
                        </a-scrollbar>
                    </div>
                </div>
            </div>
            <a-modal :visible="visible" ok-text="管理" :closable="false" @cancel="visible = false" :hide-title="true">
                <div class="model">
                    <p>BTC地址管理</p>
                    <a-scrollbar class="add_scroll">
                        <div class="cell">
                            <span>adasdasadasdasdasd9879797789779</span>
                        </div>
                    </a-scrollbar>
                </div>
            </a-modal>
        </a-layout-content>
        <a-layout-footer>
            <Footer></Footer>
        </a-layout-footer>
    </a-layout>
</template>

<style lang="scss" scoped>
@import "withdraw";
</style>
<script>
import PageNav from "@/components/PageNav/PageNav.vue";
import Footer from "@/components/Footer/footer.vue";
import CoinList from "@/components/coinList/coinList.vue";

export default {
    components: {
        PageNav,
        Footer,
        CoinList
    },
    data() {
        return {
            coinVal: '请选择充值币种',
            visible: false
        }
    }
}
</script>