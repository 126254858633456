<!-- 修改登录密码 -->
<template>
    <div class="dark main-box">
        <div class="content-main">
            <div class="top-info">
                <a-button class="back-btn" @click="goBack">
                    <template #icon>
                        <icon-left/>
                    </template>
                </a-button>
                <span>修改登录密码</span>
            </div>
            <div class="recommendInvite-content">
                <div class="recommendInvite-content-box">
                    <div class="title"><span>原密码</span></div>
                    <a-input-password class="input" placeholder="请输入登陆密码" allow-clear long/>
                </div>
                <div class="recommendInvite-content-box">
                    <div class="title"><span>新密码</span></div>
                    <a-input-password class="input" placeholder="请输入登陆密码" allow-clear long/>
                </div>
                <div class="recommendInvite-content-box">
                    <div class="title"><span>确认新密码</span></div>
                    <a-input-password class="input" placeholder="请输入新登录密码" allow-clear long/>
                </div>
                <div class="recommendInvite-content-box">
                    <a-button class="confirm" @click="handleOk">确定</a-button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'changePhone',
    components: {},
    data() {
        return {};
    },
    methods: {
        goBack() {
            this.$emit("safet-event");
        },
    },
};
</script>
<style lang="scss" scoped>
@import "./changePassword.scss";

</style>