import PageNav from "@/components/PageNav/PageNav.vue";
import Footer from "@/components/Footer/footer.vue";
import fundAccount from "@/views/Home/assets/fundAccount/fundAccount.vue"
import spotAccount from "@/views/Home/assets/spotAccount/spotAccount.vue";
import futuresAccount from "@/views/Home/assets/futuresAccount/futuresAccount.vue"
import optionsAccount from "@/views/Home/assets/optionsAccount/optionsAccount.vue"
import investmentAccount from "@/views/Home/assets/investmentAccount/investmentAccount.vue"
import capitalFlow from "@/views/Home/assets/capitalFlow/capitalFlow.vue"
import fundsTransfe from "@/components/fundsTransfe/fundsTransfe.vue";

import upTrigget from "@/components/topUp/topUp.vue"
import withdrawTrgget from "@/components/withdraw/withdraw.vue"

import user from "@/common/api/user";
import {mapGetters} from "vuex";

// Import Swiper Vue.js components
import {Swiper, SwiperSlide} from 'swiper/vue';
// 引入swiper模块
import {Navigation} from 'swiper/modules';


import VChart from 'vue-echarts';
import * as echarts from 'echarts/core';
import {
    PieChart
} from 'echarts/charts';
import {
    TooltipComponent,
    LegendComponent,
    TitleComponent
} from 'echarts/components';
import {
    CanvasRenderer
} from 'echarts/renderers';
import NotDate from "@/components/notdate/notdate.vue";
import {useRoute} from "vue-router";

echarts.use([PieChart, TooltipComponent, LegendComponent, CanvasRenderer, TitleComponent]);

let currency;

export default {
    components: {
        NotDate,
        Footer,
        PageNav,
        fundAccount,
        spotAccount,
        futuresAccount,
        optionsAccount,
        investmentAccount,
        VChart,
        capitalFlow,
        fundsTransfe,
        Swiper,
        SwiperSlide,
        upTrigget,
        withdrawTrgget
    },
    data() {
        return {
            modules: [Navigation],
            upShow: false,
            withdrawShow: false,
            menuList: [
                {
                    id: "1",
                    text: "总览"
                }, {
                    id: "2",
                    text: "资金账户"
                }, {
                    id: "3",
                    text: "现货账户"
                }, {
                    id: "4",
                    text: "合约账户"
                }, {
                    id: "5",
                    text: "期权账户"
                }, {
                    id: "6",
                    text: "理财账户"
                }, {
                    id: "7",
                    text: "资金流水"
                }
            ],
            transfeShow: false,
            menuActive: 0,
            visual: false,
            balance: [],
            total: 0,
            trade: "",
            contract: "",
            future: "",
            finance: "",
            list: [],
            chartOption: {
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    orient: 'vertical',
                    right: 40,
                    top: "center",
                    itemGap: 20,
                    textStyle: {
                        color: '#565656',
                        fontSize: 14
                    }
                },
                series: [
                    {
                        name: '',
                        type: 'pie',
                        label: {
                            show: false
                        },
                        center: ['15%', '50%'],
                        radius: '75%',
                        data: [
                            // { value: 1048, name: '资金' },
                            // { value: 735, name: '现货' },
                            // { value: 580, name: '合约' },
                            // { value: 484, name: '期权' },
                            // { value: 300, name: '理财' }
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            }
        }
    },
    mounted() {
        this.getBalance();
        let route = useRoute();
        let params = route.query;
        if(params.menu){
            this.menuActive = parseInt(params.menu);
        }
    },
    computed: {
        ...mapGetters(['symbol', 'symbol_rate', 'userTheme'])
    },
    methods: {
        createCircleChart() {
            const data = [
                {value: parseFloat(this.balance), name: "资金"},
                {value: parseFloat(this.trade), name: "现货"},
                {value: parseFloat(this.contract), name: "合约"},
                {value: parseFloat(this.future), name: "期权"},
                {value: parseFloat(this.finance), name: "理财"}
            ];
            this.chartOption['series'][0]['data'] = data;
        },
        async getBalance() {
            let res = await user.getBalance();
            try {
                if (res.code === 200) {
                    let balance = 0;
                    let total = 0;
                    let trade = 0;
                    let contract = 0;
                    let future = 0;
                    let finance = 0;
                    res.data.balance.map((item) => {
                        if (item.symbol === 'USDT') {
                            total += parseFloat(item.balance) + parseFloat(item.contract_balance) +
                                parseFloat(item.finance_balance) + parseFloat(item.future_balance) +
                                parseFloat(item.trade_balance);
                            balance += parseFloat(item.balance);
                            trade += parseFloat(item.trade_balance);
                            contract += parseFloat(item.contract_balance);
                            future += parseFloat(item.future_balance);
                            finance += parseFloat(item.finance_balance);

                        } else {
                            let new_price = parseFloat(item.new_price != null ? item.new_price : 0);
                            total += (parseFloat(item.balance) + parseFloat(item.contract_balance) +
                                parseFloat(item.finance_balance) + parseFloat(item
                                    .future_balance) +
                                parseFloat(item.trade_balance)) * new_price;

                            balance += parseFloat(item.balance) * new_price;
                            trade += parseFloat(item.trade_balance) * new_price;
                            contract += parseFloat(item.contract_balance) * new_price;
                            future += parseFloat(item.future_balance) * new_price;
                            finance += parseFloat(item.finance_balance) * new_price;

                        }
                    });

                    this.total = total;
                    this.balance = balance;
                    this.trade = trade;
                    this.contract = contract;
                    this.future = future;
                    this.finance = finance;

                    this.list = res.data.balance;
                    currency = res.data.balance;
                    this.createCircleChart();
                }
            } catch (e) {
                console.log(e);
            }
        }
    }
}