import {createI18n} from 'vue-i18n'

import en from '/src/lang/en.json';
import zh_Hant from '/src/lang/zh-Hant.json';
import zh_Hans from '/src/lang/zh-Hans.json';
import ja from '/src/lang/ja.json';
import ko from '/src/lang/ko.json';

import util from "@/common/util";
import config from "@/common/api/config";

const lang = util.getCache('lang') ? util.getCache('lang'): 'zh' ;

export default createI18n({
    legacy: true, // 让 setup 函数可以通过 t 访问
    globalInjection: true, // 让 template 可以像 vue2 那样使用 $t 来访问
    locale: lang ? lang : config.default_lang,
    fallbackLocale: 'zh',
    messages: {
        'zh': zh_Hant,
        'zh_Hans': zh_Hans,
        'en': en,
        'ja': ja,
        'ko': ko,
    }
})