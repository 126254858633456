<!-- 基础认证 -->
<template>
    <div class="basicAuth">
        <div class="title-box">
            <a-button class="back-btn" @click="goBack">
                <template #icon>
                    <icon-left/>
                </template>
            </a-button>
            <span>Lv.1 基础认证</span>
        </div>
        <div class="basic-body">
            <div class="steps">
                <a-steps :current="step">
                    <a-step description="填写基本信息">第1步</a-step>
                    <a-step description="上传证件照">第2步</a-step>
                </a-steps>
            </div>
            <div class="tips">
                <icon-exclamation-polygon-fill size="22" class="color-orange"/>
                <span class="color-orange">
                    为了保障账户资金安全，需实名认证后才能进行相关操作，请您确保所提交的信息真实有效。
                </span>
            </div>
            <template v-if="step === 1">
                <!--    国家或地区-->
                <div class="label">
                    <p>国家或地区</p>
                    <a-select v-model="value"
                              class="input"
                              style="border-radius: 20px"
                              placeholder="请选择国家或地区" allow-search>
                        <a-option v-for="item of area" :value="item.cnName" :key="item" :label="item.cnName"/>
                    </a-select>
                </div>
                <!--    证件类型-->
                <div class="label">
                    <p>证件类型</p>
                    <a-select v-model="value2" size="large" :width="300"
                              class="input"
                              placeholder="证件类型"
                              allow-clear long>
                        <a-option>护照</a-option>
                        <a-option>驾照</a-option>
                        <a-option>其他合法证件</a-option>
                    </a-select>
                </div>
                <!--    姓名-->
                <div class="label">
                    <p>姓名</p>
                    <a-input class="input" placeholder="请输入真实姓名"></a-input>
                </div>
                <!--    证件号码-->
                <div class="label">
                    <p>证件号码</p>
                    <a-input class="input" placeholder="请输入真实证件号码"></a-input>
                </div>
                <a-button class="button" disabled @click="step = 2">下一步</a-button>
            </template>
            <template v-if="step === 2">
                <div class="label">
                    <p>身份证正面</p>
                    <a-upload list-type="picture-card"
                              action="/"
                              limit="1"
                              :fileList="file ? [file] : []">
                        <template #upload-button>
                            <div class="upload-button">
                                <icon-upload/>
                                <p>点击上传</p>
                            </div>
                        </template>
                    </a-upload>
                </div>
                <div class="label">
                    <p>身份证反面</p>
                    <a-upload list-type="picture-card"
                              action="/"
                              limit="1"
                              :fileList="file ? [file] : []">
                        <template #upload-button>
                            <div class="upload-button">
                                <icon-upload/>
                                <p>点击上传</p>
                            </div>
                        </template>
                    </a-upload>
                </div>
                <a-button class="button">下一步</a-button>
                <div class="tip-box">
                    <p>上传要求</p>
                    <p>1.支持小于5M的JPG、JPEG或PNG格式图片。</p>
                    <p>2.证件照片要求清晰，必须能看清人像、证件号和姓名。</p>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import country from '@/common/area';

export default {
    name: 'foundationAuth',
    components: {},
    data() {
        return {
            step: 1,
            file: '',
            area: country.countryList,
        };
    },
    methods: {
        goBack() {
            this.$emit("safet-event");
        },
        onChange(_, currentFile) {
            this.file = {
                ...currentFile,
            }
        },
        onProgress(currentFile) {
            this.file = currentFile
        }

    },
};
</script>
<style lang="scss" scoped>
@import "./foundationAuth.scss";
</style>
  