<template>
  <a-layout class="fundDetails">
    <a-layout-header>
      <PageNav></PageNav>
    </a-layout-header>
    <a-layout-content>
      <div class="_body">
        <div class="label">
          <p class="title">产品详情</p>
          <div class="card">
            <div class="row">
              <div class="flex align-center">
                <img src="@/assets/images/coin/usdt.png" alt="">
                <span class="font-18 color-black font-bold">USDT-增强收益精选</span>
              </div>
              <a-button class="button">立即申购</a-button>
            </div>
            <div class="column">
              <div class="item">
                <p class="font-16 color-gray">预计年化收益</p>
                <span class="font-24 color-black font-bold">8%-40%</span>
              </div>
              <div class="item">
                <p class="font-16 color-gray">单位净值（2023-09-25）</p>
                <span class="font-24 color-green font-bold">0.99759137 <span class="font-16 color-green">+0.0128%</span></span>
              </div>
              <div class="item">
                <p class="font-16 color-gray">累计收益率（2023-09-25）</p>
                <span class="font-24 color-red font-bold">-0.9634 %</span>
              </div>
            </div>
            <div class="row">
              <div class="flex align-center">
                <span class="font-16 color-gray">基金类型</span>
                <a-tag color="orange" class="tag">中低风险</a-tag>
              </div>
            </div>
            <div class="column">
              <div class="item">
                <p class="color-gray font-16">起购数量</p>
                <span class="color-black font-16">10,000 USDT</span>
              </div>
              <div class="item">
                <p class="color-gray font-16">产品总额度</p>
                <span class="color-black font-16">10,000 USDT</span>
              </div>
              <div class="item">
                <p class="color-gray font-16">成立日</p>
                <span class="color-black font-16">2023-05-02</span>
              </div>
              <div class="item">
                <p class="color-gray font-16">申购开放时间</p>
                <span class="color-black font-16">2023-05-02</span>
              </div>
              <div class="item">
                <p class="color-gray font-16">申购关闭时间</p>
                <span class="color-black font-16">2023-05-02</span>
              </div>
              <div class="item">
                <p class="color-gray font-16">申购生效时间</p>
                <span class="color-black font-16">2023-05-02</span>
              </div>
              <div class="item">
                <p class="color-gray font-16">赎回开放时间</p>
                <span class="color-black font-16">2023-05-02</span>
              </div>
              <div class="item">
                <p class="color-gray font-16">赎回关闭时间</p>
                <span class="color-black font-16">2023-05-02</span>
              </div>
              <div class="item">
                <p class="color-gray font-16">赎回生效时间</p>
                <span class="color-black font-16">2023-05-02</span>
              </div>
              <div class="item">
                <p class="color-gray font-16">锁定期</p>
                <span class="color-black font-16">365天</span>
              </div>
            </div>
          </div>
        </div>
        <div class="label">
          <p class="title">产品数据展示</p>
          <div class="chartList">
            <div class="chartBox">
              <div class="_hd">
                <a-tag>单位净值走势</a-tag>
                <a-dropdown>
                  <a-tag>近一个月</a-tag>
                  <template #content>
                    <a-option>近1月</a-option>
                    <a-option>近3月</a-option>
                    <a-option>近6月</a-option>
                    <a-option>今年来</a-option>
                  </template>
                </a-dropdown>
              </div>
              <div class="canvasBox">
                <canvas></canvas>
              </div>
            </div>
            <div class="chartBox">
              <div class="_hd">
                <a-tag>累计收益率走势</a-tag>
                <a-dropdown>
                  <a-tag>近一个月</a-tag>
                  <template #content>
                    <a-option>近1月</a-option>
                    <a-option>近3月</a-option>
                    <a-option>近6月</a-option>
                    <a-option>今年来</a-option>
                  </template>
                </a-dropdown>
              </div>
              <div class="canvasBox">
                <canvas></canvas>
              </div>
            </div>
            <div class="chartBox">
              <div class="_hd">
                <a-tag>历史净值</a-tag>
                <a-dropdown>
                  <a-tag>近一个月</a-tag>
                  <template #content>
                    <a-option>近1月</a-option>
                    <a-option>近3月</a-option>
                    <a-option>近6月</a-option>
                    <a-option>今年来</a-option>
                  </template>
                </a-dropdown>
              </div>
              <div class="listBox">
                <div class="_cell">
                  <span class="font-12 color-gray">日期</span>
                  <span class="font-12 color-gray">单位净值</span>
                  <span class="font-12 color-gray">累计收益率</span>
                </div>
                <a-scrollbar class="scrollBox">
                  <div class="_cell" v-for="i in 30">
                    <span class="font-12 color-black">2023-09-25</span>
                    <span class="font-12 color-black">0.99759137</span>
                    <span class="font-12 color-red">-0.9634 %</span>
                  </div>
                </a-scrollbar>
              </div>
            </div>
            <div class="chartBox">
              <div class="_hd">
                <a-tag>策略权重比</a-tag>
                <a-dropdown>
                  <a-tag>近一个月</a-tag>
                  <template #content>
                    <a-option>近1月</a-option>
                    <a-option>近3月</a-option>
                    <a-option>近6月</a-option>
                    <a-option>今年来</a-option>
                  </template>
                </a-dropdown>
              </div>
              <div class="canvasBox">
                <canvas></canvas>
              </div>
            </div>
          </div>
        </div>
        <div class="label">
          <p class="title">产品规则</p>
          <p class="font-14 color-black rules">
            1. 起息时间：您申购完成后，平台会在5天后开始计息。例如：您 D 日申购，D+5日后才会开始计息。 <br>
            2. 派息时间：Bitget 财富管理每月派息一次。例如：您的申购订单在 D 月起息，平台首次派息会在 D+1月1日的18:00（UTC+8）前发放至您的现货账户。<br>
            3. 赎回期间收益：在您发起赎回申请后，当月的收益计算不会受到影响。例如：您在 D 月发起赎回申请，则收益结算的终止时间是：D+1月1日0:00（UTC+8）。<br>
            4. 赎回申请：您可以随时发起赎回申请。赎回申请入口：Bitget 财富管理主页 > 账户 > 持仓订单 >点击“赎回”。<br>
            5. 赎回等待时间：发起赎回申请后，您的赎回资产需要等待一段时间才能到账。例如：您 D 月发起赎回申请，D+1月5日8:00（UTC+8）前赎回资产会存入您的现货账户。<br>
            6. 历史年化收益范围：当前收益率基于策略供应商的历史数据统计。实际 APR 会根据真实的资产收益率实时变化并进行展示。
          </p>
        </div>
      </div>
    </a-layout-content>
    <a-layout-footer>
      <Footer></Footer>
    </a-layout-footer>
  </a-layout>
</template>
<script>
import Footer from "@/components/Footer/footer.vue";
import PageNav from "@/components/PageNav/PageNav.vue";

export default {
  components: {PageNav, Footer},
  data() {
    return {}
  },
  methods: {
    goBack() {
      this.$router.back()
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./fundDetails.scss";
</style>