<template>
    <div class="contentView">
        <div>
            <div class="backbox">
                <img class="backimg" src="@/assets/help/banner.jpg" alt="">
                    <div class="search-box">
                    <a-input size="large"  :style="{width:'420px', backgroundColor: '#fff', color: '#1d2126', height: '44px' }"  placeholder="请输入关键词搜索"   />
                </div>
            </div>
            <div class="site-main">
                <div class="featured">
                    <div class="plate">
                        <div class="box">
                            <div ></div>
                            <div class="right"></div>
                        </div>
                        <div class="title">关于上线“奔富理财”产品（七期）的公告</div>
                            <div class="label">
                                <span>公告中心</span>
                        </div>
                    </div>
                </div>
                    
            </div>
        </div>
    </div>
</template>


<style lang="scss" scoped>
.backbox{
    /* 相对定位 */
    position: relative;
}

.backimg{
    max-width: none;
    width: 100%;
    height: 500px;
    overflow: hidden;
    pointer-events: none;
    margin-top: 23.5px;
    transform: translate3d(0px, -16.5px, 0px);
}
.search-box{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0px);
}

.featured{
    .title{
        color: #1d211d;
    }
    .label{
        color: red;
    }
}

.site-main{
    .featured{
        .plate{
            .box{
                display: flex;
                .right{
                    color: #1d211d;
                }
            }

        }

}
}

</style>