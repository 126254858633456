<template>
    <div id="nav" :data-theme="userTheme">
        <div class="left">
            <a class="logo" href="">
                <img src="@/assets/logo1.png" alt="">
            </a>
            <nav class="nav-menu">
                <!--    现货交易    -->
                <div class="nav-menu-item" @click="$router.push('/spot')">
                    <span class="menu-text">{{ $t('nav.t1') }}</span>
                </div>
                <!--    现货交易    -->
                <div class="nav-menu-item" @click="$router.push('/quotes')">
                    <span class="menu-text">市场行情</span>
                </div>
                <!--    衍生品    -->
                <div class="nav-menu-item">
                    <span class="menu-text">{{ $t('nav.t2') }}</span>
                    <div class="submenu">
                        <div class="submenu-item" @click="$router.push('/futures')">
                            <img :src="require(`@/assets/images/navbar/hy-${userTheme}.png`)" alt="">
                            <span>合约交易</span>
                        </div>
<!--                        $router.push('/flashOption')-->
                        <div class="submenu-item" @click="$notification.info('暂未开放')">
                            <img :src="require(`@/assets/images/navbar/sf-${userTheme}.png`)" alt="">
                            <span>期权交易</span>
                        </div>
<!--                        $router.push('/copyTraders')-->
                        <div class="submenu-item" @click="$notification.info('暂未开放')">
                            <img :src="require(`@/assets/images/navbar/hygd-${userTheme}.png`)" alt="">
                            <span>跟单交易</span>
                        </div>
                    </div>
                </div>
                <!--   资管理财   -->
                <div class="nav-menu-item">
                    <span class="menu-text" @click="$router.push('/investment')">暴富理财</span>
                    <div class="submenu">
                        <div class="submenu-item" @click="$router.push('/easyEarn')">
                            <img :src="require(`@/assets/images/navbar/hbb-${userTheme}.png`)" alt="">
                            <span>质押生息</span>
                        </div>
                        <div class="submenu-item" @click="$router.push('/term')">
                            <img :src="require(`@/assets/images/navbar/bflc-${userTheme}.png`)" alt="">
                            <span>暴富理财</span>
                        </div>
<!--                        <div class="submenu-item" @click="$router.push('/pledge')">-->
<!--                            <img :src="require(`@/assets/images/navbar/ptzy-${userTheme}.png`)" alt="">-->
<!--                            <span>平台币质押理财</span>-->
<!--                        </div>-->
<!--                        <div class="submenu-item" @click="$router.push('/fund')">-->
<!--                            <img :src="require(`@/assets/images/navbar/gfjj-${userTheme}.png`)" alt="">-->
<!--                            <span>共富基金</span>-->
<!--                        </div>-->
                    </div>
                </div>
                <!--    智富乐园    -->
                <div class="nav-menu-item">
                    <span class="menu-text">福利活动</span>
                    <div class="submenu">
                        <div class="submenu-item" @click="$router.push('/onePurchase')">
                            <img :src="require(`@/assets/images/navbar/1yg-${userTheme}.png`)" alt="">
                            <span>一元购</span>
                        </div>
<!--                        <div class="submenu-item" @click="$router.push('/flashOption')">-->
<!--                            <img :src="require(`@/assets/images/navbar/sf-${userTheme}.png`)" alt="">-->
<!--                            <span>闪富 (闪期权)</span>-->
<!--                        </div>-->
<!--                        <div class="submenu-item" @click="$router.push('/volatilityOption')">-->
<!--                            <img :src="require(`@/assets/images/navbar/kf-${userTheme}.png`)" alt="">-->
<!--                            <span>快富 (波动率期权)</span>-->
<!--                        </div>-->
                        <div class="submenu-item" @click="$router.push('/BindBox')">
                            <img :src="require(`@/assets/images/navbar/bfmh-${userTheme}.png`)" alt="">
                            <span>暴富盲盒</span>
                        </div>
                    </div>
                </div>
                <!--    帮助中心    -->
                <div class="nav-menu-item" @click="$router.push('/help')">
                    <span class="menu-text">{{ $t('nav.t5') }}</span>
                </div>
            </nav>
        </div>
        <div class="right">
            <template v-if="!isLogin">
                <a-button class="login button" @click="$router.push('/login')">登录</a-button>
                <a-button class="enroll button" @click="$router.push('/register')">注册</a-button>
            </template>
            <div class="imgBox" v-if="false">
                <icon-search/>
            </div>
            <template v-if="isLogin">
                <div class="imgBox" @click="$router.push('/HomeView/assets')">
                    <icon-archive/>
                </div>
                <div class="imgBox">
                    <icon-file/>
                    <div class="menuPopup">
                        <p class="font-16 _title color-black font-bold">交易记录</p>
                        <div class="popBox">
                            <div class="row" @click="$router.push({path:'/HomeView/assets',query:{menu:6,account:1}})">
                                <span class="font-14 color-black">现货交易</span>
                                <icon-right size="16"/>
                            </div>
                            <div class="row" @click="$router.push({path:'/HomeView/assets',query:{menu:6,account:2}})">
                                <span class="font-14 color-black">合约交易</span>
                                <icon-right size="16"/>
                            </div>
                            <div class="row" @click="$router.push({path:'/HomeView/assets',query:{menu:6,account:3}})">
                                <span class="font-14 color-black">期权交易</span>
                                <icon-right size="16"/>
                            </div>
                            <div class="row" @click="$router.push({path:'/HomeView/assets',query:{menu:6,account:4}})">
                                <span class="font-14 color-black">资金账户</span>
                                <icon-right size="16"/>
                            </div>
<!--                            <div class="row">-->
<!--                                <span class="font-14 color-black">闪兑交易记录</span>-->
<!--                                <icon-right size="16"/>-->
<!--                            </div>-->
<!--                            <div class="row">-->
<!--                                <span class="font-14 color-black">理财交易记录</span>-->
<!--                                <icon-right size="16"/>-->
<!--                            </div>-->
<!--                            <div class="row">-->
<!--                                <span class="font-14 color-black">质押借贷记录</span>-->
<!--                                <icon-right size="16"/>-->
<!--                            </div>-->
                        </div>
                    </div>
                </div>
            </template>
            <div class="imgBox">
                <icon-notification/>
            </div>
            <div class="imgBox">
                <icon-menu v-if="!isLogin"/>
                <img v-else class="userImg" @click="$router.push('/HomeView/personal')" src="@/assets/images/avatar.png"
                     alt="">
                <div class="menuPopup">
                    <div class="cell">
                        <a-tooltip background-color="var(--tooltip-bg)" content="语言和地区">
                            <div class="item" @click="languagePop = true">
                                <icon-language size="22" class="mr-10"/>
                                <span>简体中文</span>
                            </div>
                        </a-tooltip>
                        <a-tooltip background-color="var(--tooltip-bg)" content="汇率">
                            <div class="item" @click="languagePop = true">
                                <icon-common size="22" class="mr-10"/>
                                <span>USD</span>
                            </div>
                        </a-tooltip>
                        <a-tooltip background-color="var(--tooltip-bg)" content="主题模式">
                            <div class="item" @click="toggleUserTheme">
                                <icon-sun-fill size="22"/>
                            </div>
                        </a-tooltip>
                    </div>
                    <template v-if="!isLogin">
                        <div class="codeBox">
                            <div class="codeImage">
                                <img :src="config['dpage']" alt="">
                            </div>
                            <span>扫码下载</span>
                        </div>
                        <a-button class="button">
                            <icon-download class="mr-10" size="20"/>
                            更多下载选择
                        </a-button>
                    </template>
                    <template v-else>
                        <div class="popBox">
                            <div class="info">
                                <div class="top">
                                    <img src="@/assets/images/avatar.png" alt="">
                                    <div class="right">
                                        <p class="font-16 color-black font-bold">{{ userNickname }}</p>
                                        <p class="font-13 color-gray">UID:{{ userId }}
                                            <icon-copy/>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="row" @click="$router.push('/HomeView/assets')">
                                <span class="font-14 color-black">安全中心</span>
                                <icon-right size="16"/>
                            </div>
                            <div class="row">
                                <span class="font-14 color-black">身份认证</span>
                                <icon-right size="16"/>
                            </div>
                            <!--              <div class="row">-->
                            <!--                <span class="font-14 color-black">卡卷</span>-->
                            <!--                <icon-right size="16"/>-->
                            <!--              </div>-->
                            <!--              <div class="row">-->
                            <!--                <span class="font-14 color-black">地址簿</span>-->
                            <!--                <icon-right size="16"/>-->
                            <!--              </div>-->
                            <!--              <div class="row">-->
                            <!--                <span class="font-14 color-black">API密钥</span>-->
                            <!--                <icon-right size="16"/>-->
                            <!--              </div>-->
                            <div class="row" @click="$router.push('/HomeView/assets')">
                                <span class="font-14 color-black">我的资产</span>
                                <icon-right size="16"/>
                            </div>
                            <div class="row" @click="$router.push('/HomeView/personal')">
                                <span class="font-14 color-black">账户设置</span>
                                <icon-right size="16"/>
                            </div>
                            <div class="row" @click="$router.push('/HomeView/personal')">
                                <span class="font-14 color-black">邀请返佣</span>
                                <icon-right size="16"/>
                            </div>
                            <a-button class="quit" @click="loginOut">退出</a-button>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <!--  弹窗-->
        <a-modal :closable="false"
                 align-center
                 width="840px"
                 mask-closable
                 body-class="modelBox"
                 :mask-style="{'background':'rgba(0, 0, 0, .5)'}"
                 :hide-title="false"
                 :footer="false"
                 :visible="languagePop">
            <div class="model">
                <a-tabs>
                    <template #extra>
                        <icon-close class="cursor close" size="20" @click="languagePop = false"/>
                    </template>
                    <a-tab-pane key="1" title="语言和地区">
                        <div class="list">
                            <div class="item" :class="langIndex == index ? 'active' : '' " v-for="(item,index) in lang"
                                 :key="index">
                                <span>{{ item.label }}</span>
                            </div>
                        </div>
                    </a-tab-pane>
                    <a-tab-pane key="2" title="汇率">
                        <div class="list">
                            <div class="item" :class="langIndex == index ? 'active' : '' "
                                 v-for="(item,index) in exchange"
                                 :key="index">
                                <span>{{ item }}</span>
                            </div>
                        </div>
                    </a-tab-pane>
                </a-tabs>
            </div>
        </a-modal>
    </div>
</template>

<script>
import WOW from "wow.js";
import config from "@/common/api/config";
import {
    mapState,
    mapGetters,
    mapMutations
} from 'vuex';


export default {
    name: 'pageNav',
    props: {
        menuShow: {
            default: true,
            type: Boolean
        }
    },
    data() {
        return {
            downpop: false,
            languagePop: false,
            coinPop: false,
            langIndex: 0,
            propertyOption: [
                {
                    src: require("@/assets/navIcon1.png"),
                    text: "总览"
                }, {
                    src: require("@/assets/navIcon1.png"),
                    text: "资金账户"
                }, {
                    src: require("@/assets/navIcon1.png"),
                    text: "现货账户"
                }, {
                    src: require("@/assets/navIcon1.png"),
                    text: "合约账户"
                }, {
                    src: require("@/assets/navIcon1.png"),
                    text: "期权账户"
                }, {
                    src: require("@/assets/navIcon1.png"),
                    text: "理财账户"
                }, {
                    src: require("@/assets/navIcon1.png"),
                    text: "资金流水"
                }],
            property: false,
            order: false,
            orderList: [
                {
                    src: require("@/assets/navIcon1.png"),
                    text: "现货订单"
                }, {
                    src: require("@/assets/navIcon1.png"),
                    text: "合约订单"
                }, {
                    src: require("@/assets/navIcon1.png"),
                    text: "理财订单"
                },
            ],
            usOption: [
                {
                    src: require("@/assets/navIcon1.png"),
                    text: "账号与安全"
                }, {
                    src: require("@/assets/navIcon1.png"),
                    text: "身份认证"
                }, {
                    src: require("@/assets/navIcon1.png"),
                    text: "邀请返佣"
                }, {
                    src: require("@/assets/navIcon1.png"),
                    text: "API"
                }, {
                    src: require("@/assets/navIcon1.png"),
                    text: "偏好设置"
                }, {
                    src: require("@/assets/navIcon1.png"),
                    text: "退出登录"
                },
            ],
            uspop: false,
            logType: true,
            langText: this.$t('nav.t9'),
            lang: [],
            exchange: ['USD - $', 'CNY - ￥', 'JPY - Ұ', 'TWD - NT$']
        }
    },
    mounted() {
        var wow = new WOW({
            boxClass: "wow",
            animateClass: "animated",
            offset: 0,
        });
        wow.init();
        this.getLang()
        // this.logType = this.isLogin
    },
    computed: {
        ...mapGetters(['isLogin', 'userId', 'userTheme', 'userNickname']),
        ...mapState(['config', 'userinfo']),
    },
    methods: {
        loginOut() {
            this.$store.commit('userLogin', false);
            this.$router.push('/login');
        },
        toggleUserTheme() {
            switch (this.userTheme) {
                case 'light':
                    this.$store.commit('updateTheme', 'dark');
                    break;
                case 'dark':
                    this.$store.commit('updateTheme', 'light');
                    break;
            }
            console.log(this.userTheme);
        },
        routerLink(subItem) {
            this.$router.push(subItem.pageUrl)
        },
        routerPage(menu) {
            this.$router.push(menu.href)
        },
        toSport() {
            this.$router.push("/HomeView/spot")
        },
        toDownload() {
            this.$router.push("/HomeView/appDownload")
        },
        toAssets() {
            this.$router.push("/HomeView/assets")
        },
        toPersonal() {
            this.$router.push("/HomeView/personal")
        },
        toOrder() {
            this.$router.push("/HomeView/order")
        },
        usChange(index) {
            console.log(index);
            if (index == 5) {
                this.logType = false;
            }
        },
        getLang() {
            this.lang = [
                {label: '中文', value: 'zh_CN'},
                {label: '中文繁体', value: 'zh-Hant'},
                {label: '英文', value: 'en'},
                {label: 'Japanese', value: 'ja'},
                {label: 'Korean', value: 'ko'},
            ]
            let lang = this.$util.getCache('lang')
            if (!lang) {
                lang = config.default_lang
            }
            for (const langKey in this.lang) {
                if (this.lang[langKey].value == lang) {
                    this.langText = this.lang[langKey].label
                }
            }
            console.log(this.$util.getCache('lang'))
        },
        setLang(item) {
            this.$util.setCache('lang', item.value)
            this.langText = item.label
            window.location.reload();
        }
    }
}
</script>

<style scoped lang="scss">
@import "./pagenav.scss";
</style>
  