import _request from './request.js'

export default {
	tradeCurrencyList() {
		return _request.request("api/currency/getTradeCurrencyList");
	},
	contractCurrencyList() {
		return _request.request("api/currency/getContractCurrencyList");
	},
	matchesCurrency() {
		return _request.request("api/currency/getMatchesCurrency");
	},
	getCurrencyInfo(id, cid) {
		return _request.request("api/currency/currencyinfo", {
			id: id,
			cm_id: cid
		}, "POST");
	},
	getRechargeLog(param) {
		return _request.request("api/currency/rechargeLog", param);
	},
	getWithLog(param){
		return _request.request("api/account/withRecord",param,"POST");
	},
	contractLine(form){
		return _request.request("api/currency/contractLine",form,"POST");
	}
}