<template>
  <a-layout id="LoginView" style="height: 400px;">
    <a-layout-header>
      <PageNav></PageNav>
    </a-layout-header>
    <a-layout-content class="login_content">
      <div class="login_left">
        <img src="@/assets/bibLogin.png" alt=""/>
      </div>
      <div class="login_right">
        <div class="login_right_content">
          <div class="back_box" @click="backChange()">
            <img src="@/assets/pic-back.png" alt=""/>
          </div>
          <p class="title">{{ $t('login.t14') }}</p>
          <div class="tipBox">
            <img src="@/assets/tipBox.png" alt="">
            <span>{{ $t('login.t43') }}</span>
          </div>
          <a-tabs class="tabs" default-active-key="1" @change="loginType">
            <a-tab-pane key="1" :title="$t('login.t46')">
              <div class="input_item">
                <p>{{ $t('login.t3') }}</p>
                <!-- start -->
                <a-input :style="{ width: '400px', height: '44px' }" :placeholder="$t('login.t5')" allow-clear>
                  <template #prefix>
                    <a-trigger v-model="showCountry" trigger="hover" :popup-translate="[145, 18]"
                               :auto-fix-position="false">
                      <div style="width: 70px;">{{ phone_code }}</div>
                      <template #content>
                        <a-scrollbar style="height:400px;overflow: auto;">
                          <div class="basic">
                            <a-input-search :style="{ height: '44px', color: '#fff' }" long
                                            :placeholder="$t('login.t9')"/>
                            <div style="margin-top: 20px;">
                              <div class="country-item" v-for="(item, index) in country" :key="index"
                                   @click="countryOk(item.code)">
                                <div>{{ item.dialingCode }} {{ item.showName }}</div>
                                <div>{{ item.code }}</div>
                              </div>
                            </div>
                          </div>
                        </a-scrollbar>
                      </template>
                    </a-trigger>
                  </template>
                </a-input>
                <!-- end -->
                <p class="input_tip" v-show="is_phone">{{ $t('login.t10') }}</p>
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" :title="$t('login.t2')">
              <div class="input_item">
                <p>{{ $t('login.t4') }}</p>
                <a-input :placeholder="$t('login.t6')" v-model="email"/>
                <p class="input_tip" v-show="is_email">{{ $t('login.t11') }}</p>
              </div>
            </a-tab-pane>
          </a-tabs>
          <a-button class="button">{{ $t('login.t44') }}</a-button>
        </div>
      </div>
    </a-layout-content>
  </a-layout>
</template>
<script>
import PageNav from "@/components/PageNav/PageNav.vue";
import common from '@/common/api/common.js';
import country from '../../common/area';
import util from "@/common/util";


export default {
  name: "LoginView",
  components: {PageNav},
  data() {
    return {
      country: country.countryList,
      phone_code: '+86',
      showCountry: false,
      phone: '',
      is_phone: false,
      email: '',
      is_email: false,
      type: 1,
    }
  },
  methods: {
    toLogin() {
      this.$router.push("/login");
    },
    backChange() {
      this.$router.push("/login");
    },
    loginType(k) {
      this.type = k
    },
    checkPhone() {
      this.is_phone = !util.validatePhoneNumber(this.code + this.phone)
    },
    checkEmail() {
      this.is_email = !util.validateEmail(this.email)
    },
    countryOk(e) {
      this.code = e.dialingCode
    },
    async getVcode() {
      let res

      if (this.type == 1) {
        this.checkPhone()
        if (this.is_phone) {
          return
        }
        res = await common.sendPhoneVerifyCode(this.phone, this.code)
      } else {
        this.checkEmail()
        if (this.is_email) {
          return
        }
        res = await common.sendEmailVerifyCode(this.email)
      }
      if (res.success()) {
        this.$message.info({
          content: this.$t('login.t39'),
          showIcon: false
        })
      } else {
        this.$message.info({
          content: res.message,
          showIcon: false
        })
      }
    },
  }
}
</script>