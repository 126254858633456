<template>
  <a-layout class="pledge">
    <a-layout-header>
      <PageNav></PageNav>
    </a-layout-header>
    <a-layout-content>
      <div class="pledge-body">
        <div class="banner">
          <div class="banner-content">
            <div class="_left">
              <h1>质押赚币</h1>
              <p>本金无损挖矿，每日净赚收益</p>
            </div>
            <div class="_right">
              <div v-if="isLogin" class="account">
                <div class="head">
                  <div class="row">
                    <span class="color-gray font-12 cursor">定期持仓估值 <icon-caret-down class="cursor"/></span>
                    <icon-eye class="cursor color-gray"/>
                  </div>
                  <div class="row">
                    <span class="font-18 color-black font-bold">-- USDT</span>
                  </div>
                  <div class="row">
                    <span class="font-12 color-gray">累计收益(USDT)</span>
                  </div>
                  <div class="row">
                    <span class="font-14 color-black">0.00000000</span>
                  </div>
                </div>
                <div class="bottom">
                  <a-button class="button">前往账户</a-button>
                  <a-button class="button">前往历史</a-button>
                </div>
              </div>
              <div v-else class="not-log">
                <svg width="50" height="50" viewBox="0 0 66 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M52.962 3.83c3.23 4.245 2.435 10.285-1.776 13.49-4.212 3.204-10.245 2.36-13.475-1.886-3.23-4.245-2.435-10.285 1.776-13.49C43.7-1.26 49.732-.415 52.962 3.83Z"
                      fill="#9C69FF"></path>
                  <path
                      d="M23.228 29.47c-4.163-5.471-3.138-13.254 2.29-17.383 5.426-4.13 13.2-3.042 17.363 2.429 4.163 5.47 3.138 13.253-2.29 17.382-5.426 4.13-13.2 3.042-17.363-2.428Z"
                      fill="#05C3DD"></path>
                  <g filter="url(#assetLogin_svg__a)">
                    <rect x="9" y="22" width="47" height="33" rx="1.82" fill="url(#assetLogin_svg__b)"
                          fill-opacity="0.5"></rect>
                    <rect x="8.75" y="21.75" width="47.5" height="33.5" rx="2.07" stroke="#393939"
                          stroke-width="0.5"></rect>
                  </g>
                  <path fill="#05C3DD" d="M47.233 35.597H58v6.63H47.233z"></path>
                  <defs>
                    <linearGradient id="assetLogin_svg__b" x1="10.152" y1="32.033" x2="15.541" y2="7.514"
                                    gradientUnits="userSpaceOnUse">
                      <stop stop-color="#B8B8B8"></stop>
                      <stop offset="1" stop-color="#393939" stop-opacity="0"></stop>
                    </linearGradient>
                    <filter id="assetLogin_svg__a" x="0.191" y="13.191" width="64.618" height="50.618"
                            filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
                      <feGaussianBlur in="BackgroundImageFix" stdDeviation="4.154"></feGaussianBlur>
                      <feComposite in2="SourceAlpha" operator="in"
                                   result="effect1_backgroundBlur_2497_16220"></feComposite>
                      <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_2497_16220" result="shape"></feBlend>
                    </filter>
                  </defs>
                </svg>
                <p>登录查看收益</p>
                <a-button class="button">登录</a-button>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="label">
            <p class="title">全部产品</p>
            <div class="coinList" :class="{'openAll': openAll}">
              <div class="item" v-for="i in 20" :key="index">
                <div class="item-con">
                  <p class="font-20 color-gray font-bold">ETH 2.0</p>
                  <div class="coinImage">
                    <img src="@/assets/images/coin/eth.png" alt="">
                  </div>
                  <p class="font-14 color-gray">APR</p>
                  <p class="font-24 color-black font-bold">4.00%</p>
                  <a-button class="button" @click="visible = true">质押</a-button>
                </div>
              </div>
            </div>
            <a-button class="seeAll" :class="{'hideBtn': openAll == false}" @click="openAll = !openAll">
              {{ openAll ? '收起' : '展开全部' }}
              <icon-down/>
            </a-button>
          </div>

          <!--          质押赚币常见问题-->
          <div class="label">
            <p class="title">质押赚币常见问题</p>
            <div class="collapse">
              <a-collapse expand-icon-position="right" :default-active-key="['1']">
                <a-collapse-item header="1、什么是权益证明（PoS）？" key="1">
                  <p>不同于让矿工依靠算力进行挖矿的工作量证明（Proof of Work）共识机制，权益证明（Proof of
                    Staking）共识机制使用加密货币质押量取代工作量证明。在 PoS
                    共识机制下，质押的加密货币数量越多、质押时间越长、距离上次获得记账权越久的节点，获得下一次记账权的机率就越高。</p>
                </a-collapse-item>
                <a-collapse-item header="2、为什么选择 ComeBit 质押赚币？" key="2">
                  <p>
                    1. 每天都有利息入账。D 日质押，D+1 日开始计息，D+2 日进行派息，只要您保持质押状态，Bitget 将每日发放利息到您的现货帐户。<br/>
                    2. 随时一键申购，支持提前赎回。让您体验到最灵活、自由、丝滑的申购赎回功能。 <br/>
                    3. 链上赚多少您就拿多少。Bitget 不会对您的收益抽成，收益率也完全和链上节点保持一致。<br/>
                    4. 100%保有本金，低风险坐享收益。从申购到获得收益的过程中，您的资产只增不减！此外，您也无需支付设备运行费用、承担罚没（slash）等链上节点质押风险。Bitget
                    让您实现真正的“躺赢”！
                  </p>
                </a-collapse-item>
                <a-collapse-item header="3、年化收益率如何计算？" key="3">
                  <p>页面上的收益率和链上节点完全保持一致，未经过 ComeBit 调整。</p>
                </a-collapse-item>
                <a-collapse-item header="4、如果我质押了多个项目，总收益该如何计算？ " key="4">
                  <p>ComeBit
                    会按照项目自身的计算比例为您一一获取节点收益，所有质押成功的订单收益汇总成您的个人当日收益。</p>
                </a-collapse-item>
                <a-collapse-item header="5、什么时候可以收到我的质押奖励？" key="5">
                  <p>D 日质押，D+1 日开始计息，D+2 日进行派息，利息会在赎回时统一发放到您的现货账户。</p>
                </a-collapse-item>
                <a-collapse-item
                    header="6、快速赎回和标准赎回有什么不同？" key="6">
                  <p>
                    标准赎回：D 日质押，D+1 日起可发起标准赎回，赎回期与链上节点一致。赎回期过后，质押代币和利息将发放到您的现货账户（赎回期不产生收益）。<br>
                    快速赎回：D 日质押，您最少在 D+3
                    日即可发起快速赎回（具体根据项目确定），质押的本金和利息将在10分钟内发放到您的现货账户，平台将额外扣除收益的10%作为服务费（每日额度有限，超出限额的部分需第二天再发起快速赎回）。
                  </p>
                </a-collapse-item>
              </a-collapse>
            </div>
          </div>
        </div>
      </div>

      <!--      立即申购弹窗-->
      <a-modal :visible="visible"
               align-center
               :render-to-body="false"
               mask-style="background:rgba(0, 0, 0, .5)"
               modal-class="mode-box"
               :hide-title="true"
               :footer="false">
        <div class="modal-con">
          <div class="title-box flex align-center jus-bet">
            <div class="flex align-center">
              <span class="font-20 color-black font-bold">质押</span>
              <img src="@/assets/images/coin/eth.png" alt="">
              <span class="font-20 color-black font-bold">ETH2.0</span>
            </div>
            <icon-close @click="visible = false" class="color-black font-bold pointer" size="24"/>
          </div>
          <div class="modal-container">
            <div class="left">
              <div class="label">
                <div class="_hd">
                  <span class="font-14 color-black font-bold">质押数量</span>
                </div>
                <a-input class="inputBox" placeholder="0.00100000 ETH 起投">
                  <template #suffix>
                    <span class="color-primary cursor">最大</span>
                  </template>
                </a-input>
              </div>
              <div class="label">
                <div class="row">
                  <a-tooltip position="bottom" background-color="var(--tooltip-bg)">
                    <span class="font-12 color-gray dotted cursor">可用余额:</span>
                    <template #content>
                      <span class="color-always-white font-12">不包括现货跟单</span>
                    </template>
                  </a-tooltip>
                  <span class="ml-5 color-gray font-12">0.00000000 ETH</span>
                </div>
              </div>
              <div class="label">
                <div class="_hd">
                  <span class="font-14 color-black font-bold">数量限制</span>
                </div>
                <div class="row">
                  <a-tooltip position="bottom" background-color="var(--tooltip-bg)">
                    <span class="font-12 color-gray dotted cursor">本次质押数量上限: </span>
                    <template #content>
                      <p class="font-14 font-bold color-always-white">Bitget 为您总结了以下限制：</p>
                      <p>● 单笔最大质押金额：无限额</p>
                      <p>● 当日剩余可质押数量：无限额</p>
                      <p>● ETH 项目剩余可质押额度：无限额</p>
                    </template>
                  </a-tooltip>
                  <span class="ml-5 color-black font-bold font-14">无限额</span>
                </div>
              </div>
              <div class="label">
                <div class="tips">
                  <p class="font-12 font-bold color-black">在质押前，Bitget 希望您能知道</p>
                  <a-checkbox-group class="checkboxGroup">
                    <a-checkbox value="1">
                      <span class="font-12 color-gray">我们永远致力于为您构建最安全的投资环境，为您甄选优质的链上挖矿项目。但链上状况瞬息万变，若未来有任何链上合约漏洞造成的资产损失，Bitget 将无法预测，也无法为您承担。若您有任何疑虑，可随时 前往 <strong class="font-bold color-black dotted">理财账户</strong> 赎回您的资产</span>
                    </a-checkbox>
                    <a-checkbox value="2">
                      <span class="font-12 color-gray">最后，请仔细阅读 <strong class="font-bold color-black dotted">《ComeBit 质押赚币产品协议》</strong> ，勾选将视为同意协议内容</span>
                    </a-checkbox>
                  </a-checkbox-group>
                </div>
              </div>
            </div>
            <div class="right">
              <div class="_top">
                <div class="row">
                  <span class="font-14 color-black font-bold">收益模式</span>
                  <span class="font-14 color-black font-bold">日息日结</span>
                </div>
                <div class="row">
                  <span class="font-14 color-black font-bold">APR</span>
                  <span class="font-14 color-black font-bold">4.00%</span>
                </div>
                <div class="row">
                  <span class="font-14 color-black font-bold">预计每日收益</span>
                  <span class="font-14 color-primary font-bold">0.00000000 ETH</span>
                </div>
              </div>
              <div class="_btm">
                <div class="row">
                  <span class="font-14 color-black font-bold">订单周期</span>
                  <a-tooltip position="bottom" background-color="var(--tooltip-bg)">
                    <span class="font-14 color-black font-bold dotted cursor" @click="rdpType = !rdpType"><icon-swap /> {{ rdpType ? '快速赎回':'标准赎回' }}</span>
                    <template #content>
                      <span>在此切换两种赎回类型，预览订单全周期，帮助您更加得心应手地规划资产分配</span>
                    </template>
                  </a-tooltip>
                </div>
                <a-timeline :reverse="isReverse">
                  <a-timeline-item label="2023-12-01 17:07:11">质押时间</a-timeline-item>
                  <a-timeline-item label="2023-12-01 08:00:00">起息时间</a-timeline-item>
                  <a-timeline-item label="2023-12-01 08:00:00">派息时间</a-timeline-item>
                  <a-timeline-item label="D日">发起赎回时间</a-timeline-item>
                  <a-timeline-item :label="rdpType ? 'D+10分钟' : 'D+5日'">赎回到账时间</a-timeline-item>
                </a-timeline>
              </div>
              <a-button class="confirmBtn">确认</a-button>
            </div>
          </div>
          <div class="flex align-start jus-bet con-box" v-if="false">
            <div class="flex flex-column align-start con-right">
              <p class="font-16 color-black">概览</p>
              <div class="timeline">
                <a-timeline>
                  <a-timeline-item label="2017-03-10">申购日</a-timeline-item>
                  <a-timeline-item label="2018-05-12">起息日</a-timeline-item>
                  <a-timeline-item label="2020-09-30">赎回日</a-timeline-item>
                </a-timeline>
              </div>
              <div class="cell flex align-center jus-bet">
                <span class="font-14 color-gray">预期年化收益率</span>
                <span class="font-14 color-green">1.5% - 2.6%</span>
              </div>
            </div>
          </div>
          <div class="flex align-center jus-bet btn-group" v-if="false">
            <a-button @click="visible = false">取消</a-button>
            <a-button disabled>确认</a-button>
          </div>
        </div>
      </a-modal>
    </a-layout-content>
    <a-layout-footer>
      <Footer></Footer>
    </a-layout-footer>
  </a-layout>
</template>
<script>
import Footer from "@/components/Footer/footer.vue";
import PageNav from "@/components/PageNav/PageNav.vue";

export default {
  components: {PageNav, Footer},
  data() {
    return {
      visible: false,
      radioVal: "1",
      openAll: false,
      rdpType: false
    }
  },
  methods: {
    goBack() {
      this.$router.back()
    },
    radioChange(e) {
      console.log(e);
      this.radioVal = e;
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./pledge.scss";
</style>