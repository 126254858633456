<template>
  <div class="flashOption" id="flashOption">
    <a-layout>
      <a-layout-header>
        <PageNav></PageNav>
      </a-layout-header>
      <a-layout-content>
        <div class="top-bar f-bc">
          <div class="coin-selector">
            <div class="btcImg"></div>
            <text>BTC/USDT-1分钟</text>
            <text>21:51:00</text>
          </div>
        </div>
        <div class="options-wrap">
          <!-- 实时交易大盘 -->
          <div class="options-chart">
            实时交易大盘
          </div>
          <!-- 右侧导航 -->
          <div class="side-bar">
            <div class="btn-switch">
                <div v-if="sidebarOpen" @click="sidebarOpen=false">
                  <svg data-v-7a5db56e="" data-v-54776376="" width="20" height="20" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M19.999 1.24647C19.9738 0.550138 19.4707 0.000313338 18.8584 0H1.14164L1.04766 0.00445714C0.437467 0.0621171 -0.0242282 0.657203 0.00098516 1.35353C0.0261985 2.04986 0.52935 2.59969 1.14164 2.6H18.8584L18.9523 2.59554C19.5625 2.53788 20.0242 1.9428 19.999 1.24647ZM18.862 9C19.4723 9.00031 19.9739 9.55014 19.999 10.2465C20.0242 10.9428 19.5639 11.5379 18.9557 11.5955L18.862 11.6H1.13802C0.527672 11.5997 0.0261154 11.0499 0.000982037 10.3535C-0.0241514 9.6572 0.436081 9.06212 1.04434 9.00446L1.13802 9H18.862ZM18.8684 18C19.4753 18.0003 19.974 18.5501 19.999 19.2465C20.024 19.9428 19.5664 20.5379 18.9615 20.5955L18.8684 20.6H1.13165C0.524716 20.5997 0.0259691 20.0499 0.000976535 19.3535C-0.0240161 18.6572 0.433637 18.0621 1.03849 18.0045L1.13165 18H18.8684Z" fill="#2178FE"></path></svg>
                </div>
                <div v-if="!sidebarOpen" @click="sidebarOpen=true">
                  <svg data-v-7a5db56e="" data-v-54776376="" width="20" height="20" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21.5806 0.341624C22.0909 0.809632 22.1424 1.59637 21.6975 2.12695L21.6218 2.20913L2.20893 21.622C1.71871 22.111 0.93046 22.1275 0.420173 21.6595C-0.0901141 21.1915 -0.141647 20.4048 0.303231 19.8742L0.378941 19.792L19.7918 0.379141C20.282 -0.109852 21.0703 -0.126385 21.5806 0.341624Z" fill="#2178FE"></path> <path d="M0.378922 0.379116C0.853676 -0.0956896 1.6127 -0.128568 2.12673 0.303406L2.20891 0.379116L21.6218 19.792C22.1108 20.2822 22.1273 21.0705 21.6593 21.5808C21.1913 22.091 20.4046 22.1426 19.874 21.6977L19.7918 21.622L0.378922 2.2091C-0.126307 1.70372 -0.126307 0.884498 0.378922 0.379116Z" fill="#2178FE"></path></svg>
                </div>
              </div>
            <div class="sidebar-box">
              <ul class="sidebar-list" >
                <li class="sidebar-item" :class="item.ifActive ? 'current' : ''" v-for="item,index in sidebar" :key="index" @click="tableToggle(index)">
                  <IconQuestionCircle style="width:32px;height:32px"/>
                  <text>{{item.name}}</text>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </a-layout-content>
      <a-layout-footer>
        <Footer></Footer>
      </a-layout-footer>
    </a-layout>
  </div>
</template>
<script>
import PageNav from "@/components/PageNav/PageNav.vue";
import Footer from "@/components/Footer/footer.vue";

export default {
  components: { PageNav, Footer },
  data() {
    return {
      sidebarOpen:true,
      sidebar:[
        {
          icon: 'IconQuestionCircle',
          name: "交易设置",
          ifActive: true
        },
        {
          icon:'IconQuestionCircle',
          name:'产品说明',
          ifActive: false
        },
        {
          icon:'IconQuestionCircle',
          name:'交易记录',
          ifActive: false
        },
        {
          icon:'IconQuestionCircle',
          name:'市场分析',
          ifActive: false
        },
        {
          icon:'IconQuestionCircle',
          name:'新手引导',
          ifActive: false
        }
      ]
    };
  },
  methods: {
    tableToggle(index){
      //点击切换背景色
      for(let i=0;i<this.sidebar.length;i++){
        this.sidebar[i].ifActive = false;
      }
      this.sidebarOpen = false;
      this.sidebar[index].ifActive = true;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "./flashOption.scss";
</style>
