<!-- 邀请返佣 -->
<template>
    <div class="invite-warp">
        <div class="banner">
            <div class="banner-title">
                <h1>邀请好友注册，佣金日结</h1>
                <p>
                    无需交易，躺赚
                    <strong>20%</strong>
                    返佣
                </p>
            </div>
            <div class="banner-content">
                <div class="row-item">
                    <span>邀请返佣</span>
                    <span @click="invite = true">
                        设置邀请链接
                        <icon-right/>
                    </span>
                </div>
                <div class="row-item">
                    <div class="row-item-left">
                        <span>现货基础返佣：20%</span>
                        <i>|</i>
                        <span>合约基础返佣：20%</span>
                    </div>
                </div>
                <div class="row-box">
                    <div class="item">
                        <p>20%</p>
                        <span>现货返佣比例</span>
                    </div>
                    <div class="item">
                        <p>0%</p>
                        <span>现货返现比例</span>
                    </div>
                    <div class="item">
                        <p>20%</p>
                        <span>合约返佣比例</span>
                    </div>
                    <div class="item">
                        <p>0%</p>
                        <span>合约返现比例</span>
                    </div>
                </div>
                <div class="row-box row-item">
                    <span>邀请码</span>
                    <span class="code-text">
                        1154678
                        <icon-copy/>
                    </span>
                </div>
                <div class="row-box row-item">
                    <span>邀请链接</span>
                    <span class="code-text">
                        https://www.bibvip.com/zh_CN/register?inviteCode=11RFlQ
                        <icon-copy/>
                    </span>
                </div>
                <div class="row-item">
                    <a-button class="invite-button">立即邀请</a-button>
                    <div class="row-item-right">
                        <div class="img-box" @click="visible = true">
                            <svg width="32" height="32" viewBox="0 0 32 32"
                                 fill="none" xmlns="http://www.w3.org/2000/svg" iconColor="currentColor"
                                 class="icon-qrCode">
                                <path
                                    d="M11.9999 2H4.1999C2.5999 2 1.3999 3.3 1.3999 4.8V11.9C1.3999 13.5 2.6999 14.7 4.1999 14.7H11.9999C13.5999 14.7 14.7999 13.4 14.7999 11.9V4.8C14.7999 3.3 13.5999 2 11.9999 2ZM13.3999 11.9C13.3999 12.7 12.7999 13.3 11.9999 13.3H4.1999C3.3999 13.3 2.7999 12.7 2.7999 11.9V4.8C2.7999 4 3.3999 3.4 4.1999 3.4H11.9999C12.7999 3.4 13.3999 4 13.3999 4.8V11.9Z">
                                </path>
                                <path d="M6 6H10.2V10.2H6V6Z"></path>
                                <path
                                    d="M11.8 17H4.2C2.7 17 1.5 18.2 1.5 19.8V26.7C1.5 28.2 2.7 29.5 4.2 29.5H11.8C13.3 29.5 14.5 28.3 14.5 26.7V19.8C14.6 18.2 13.3 17 11.8 17ZM13.2 26.6C13.2 27.4 12.6 28 11.8 28H4.2C3.4 28 2.8 27.4 2.8 26.6V19.7C2.8 18.9 3.4 18.3 4.2 18.3H11.8C12.6 18.3 13.2 18.9 13.2 19.7V26.6Z">
                                </path>
                                <path d="M6 20.9H10.1V25H6V20.9Z"></path>
                                <path
                                    d="M19.8 14.7H27.6C29.2 14.7 30.4 13.4 30.4 11.9V4.8C30.4 3.2 29.1 2 27.6 2H19.8C18.2 2 17 3.3 17 4.8V11.9C17 13.5 18.3 14.7 19.8 14.7ZM18.4 4.8C18.4 4 19 3.4 19.8 3.4H27.6C28.4 3.4 29 4 29 4.8V11.9C29 12.7 28.4 13.3 27.6 13.3H19.8C19 13.3 18.4 12.7 18.4 11.9V4.8Z">
                                </path>
                                <path d="M21.8999 6H26.0999V10.2H21.8999V6Z"></path>
                                <path
                                    d="M17.9 22.4H22.2C22.6 22.4 22.9 22.1 22.9 21.7V17.4C22.9 17 22.6 16.7 22.2 16.7H17.9C17.5 16.7 17.2 17 17.2 17.4V21.7C17.2 22.1 17.5 22.4 17.9 22.4Z"></path>
                                <path
                                    d="M27.7 17.6V18.8C27.7 19.2 28.1 19.6 28.5 19.6H29.7C30.1 19.6 30.5 19.2 30.5 18.8V17.6C30.5 17.2 30.1 16.8 29.7 16.8H28.5C28.1 16.8 27.7 17.1 27.7 17.6Z"></path>
                                <path
                                    d="M18.2 29.5H19.7C20.1 29.5 20.4 29.2 20.4 28.8V27.3C20.4 26.9 20.1 26.6 19.7 26.6H18.2C17.8 26.6 17.5 26.9 17.5 27.3V28.8C17.5 29.2 17.8 29.5 18.2 29.5Z"></path>
                                <path
                                    d="M29.5 22H26.4C25.9 22 25.5 22.4 25.5 22.9V24.3H24C23.4 24.3 23 24.7 23 25.3V28.4C23 28.9 23.4 29.3 23.9 29.3L29.5 29.4C30 29.4 30.4 29 30.4 28.5V22.9C30.4 22.4 30 22 29.5 22Z">
                                </path>
                            </svg>
                        </div>
                        <div class="img-box">
                            <svg width="32" height="32" viewBox="0 0 32 32"
                                 fill="none" xmlns="http://www.w3.org/2000/svg" iconColor="currentColor"
                                 class="icon-tG">
                                <g clip-path="url(#clip0_3157_19345)">
                                    <path
                                        d="M31.9095 4.99997L27.0595 27.75C26.7095 29.35 25.7595 29.75 24.4095 29L17.0595 23.65L13.5095 27.05C13.1095 27.45 12.8095 27.75 12.0095 27.75L12.5595 20.25L26.2095 7.94997C26.8095 7.39996 26.0595 7.14997 25.3095 7.64997L8.40949 18.25L1.15949 16C-0.44051 15.5 -0.44051 14.4 1.50949 13.65L29.8595 2.69997C31.2095 2.24997 32.3595 2.99997 31.9095 4.99997Z">
                                    </path>
                                </g>
                                <defs>
                                    <clipPath>
                                        <rect width="32" height="32"></rect>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <div class="img-box">
                            <svg width="32" height="32" viewBox="0 0 32 32"
                                 fill="none" xmlns="http://www.w3.org/2000/svg" iconColor="currentColor"
                                 class="icon-tT">
                                <path
                                    d="M24.3252 3H28.7359L19.0999 14.0133L30.4359 29H21.5599L14.6079 19.9107L6.65321 29H2.23987L12.5465 17.22L1.67188 3H10.7732L17.0572 11.308L24.3252 3ZM22.7772 26.36H25.2212L9.44521 5.50133H6.82254L22.7772 26.36Z">
                                </path>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="data-overview">
            <div class="top">
                <span>数据总览</span>
                <div class="button-group">
                    <a-button class="button-prev button">
                        <icon-left/>
                    </a-button>
                    <a-button class="button-next button">
                        <icon-right/>
                    </a-button>
                </div>
            </div>
            <swiper class="swiper"
                    :navigation="{
                          nextEl: '.button-next',
                          prevEl: '.button-prev'
                        }"
                    :loop="false"
                    :modules="modules"
                    :space-between="10"
                    :slides-per-view="4">
                <swiper-slide class="slide">
                    <div class="slide-content">
                        <span>我的总返佣</span>
                        <span>0 USDT</span>
                    </div>
                </swiper-slide>
                <swiper-slide class="slide">
                    <div class="slide-content">
                        <span>我的总返现</span>
                        <span>0 USDT</span>
                    </div>
                </swiper-slide>
                <swiper-slide class="slide">
                    <div class="slide-content">
                        <span>现货净返佣</span>
                        <span>0 USDT</span>
                    </div>
                </swiper-slide>
                <swiper-slide class="slide">
                    <div class="slide-content">
                        <span>合约净返佣</span>
                        <span>0 USDT</span>
                    </div>
                </swiper-slide>
                <swiper-slide class="slide">
                    <div class="slide-content">
                        <span>已邀请好友</span>
                        <span>0 人</span>
                    </div>
                </swiper-slide>
                <swiper-slide class="slide">
                    <div class="slide-content">
                        <span>开始交易的好友</span>
                        <span>0 人</span>
                    </div>
                </swiper-slide>
            </swiper>
        </div>
        <div class="invite-container">
            <a-tabs class="tabs" default-active-key="1">
                <a-tab-pane title="现货返佣" key="1">
                    <div class="label-group">
                        <div class="label-item">
                            <p>开始日期 / 结束日期</p>
                            <a-range-picker
                                class="gray-box"
                                @select="timeSelect"/>
                        </div>
                        <div class="label-item">
                            <p>类型</p>
                            <a-select default-active-first-option default-value="好友UID" class="gray-box"
                                      placeholder="">
                                <a-option index="0">好友UID</a-option>
                                <a-option>邮箱</a-option>
                                <a-option>手机号码</a-option>
                            </a-select>
                        </div>
                        <div class="label-item">
                            <div class="label-input">
                                <a-input placeholder="">
                                    <template #suffix>
                                        <span>查询</span>
                                    </template>
                                </a-input>
                            </div>
                        </div>
                    </div>
                    <div class="list-warp">
                        <a-scrollbar class="list-con">
                            <not-date :height="500"></not-date>
                        </a-scrollbar>
                    </div>
                </a-tab-pane>
            </a-tabs>
        </div>
    </div>
    <!--    警示弹窗-->
    <warning-popup :visible="warningShow"
                   :content="warningContent"
                   @close="warningShow = false"
                   @handleCancel="warningShow = false"
                   @handleOk="warningShow = false">
    </warning-popup>
    <!--    设置邀请链接-->
    <a-modal :visible="invite"
             :hide-title="true"
             :footer="false"
             width="960px">
        <div class="invite-link">
            <div class="title">
                <span>设置邀请链接</span>
                <icon-close @click="invite = false"/>
            </div>
            <table class="link-table">
                <tr class="link-head">
                    <th v-for="item in linkTab">
                        <span>{{ item }}</span>
                    </th>
                </tr>
                <tr class="link-cell" v-for="item in linkOptions">
                    <td>
                        <span>{{ item.codeText }}</span>
                    </td>
                    <td>
                        <span>{{ item.spotRebate }}</span>
                    </td>
                    <td>
                        <span>{{ item.spotCash }}</span>
                    </td>
                    <td>
                        <span>{{ item.contractRebate }}</span>
                    </td>
                    <td>
                        <span>{{ item.contractCash }}</span>
                    </td>
                    <td>
                        <span @click="warningShow = true">设为默认</span>
                        <div class="copy">
                            <icon-copy/>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    </a-modal>
    <!--    添加邀请链接-->
    <a-modal :visible="inviteNew"
             :hide-title="true"
             :footer="false"
             width="600px">
        <div class="add-link">
            <div class="title">
                <span>添加新邀请链接</span>
                <icon-close @click="inviteNew = false"/>
            </div>
            <div class="content">
                <div class="row">
                    <span>设置返佣/返现比例</span>
                </div>
                <div class="row">
                    <div class="row-left">
                        <span>现货基础返佣：20%</span>
                        <i>\</i>
                        <span>合约基础返现：20%</span>
                    </div>
                </div>
                <div class="row">
                    <div class="row-item">
                        <a-tooltip background-color="var(--tooltip-bg)">
                            <p class="dotted cursor">
                                好友现货返现
                                <icon-question-circle-fill />
                            </p>
                            <template #content>
                                <span class="font-14 color-always-white">
                                    现货返现设置范围：0%-15%
                                </span>
                            </template>
                        </a-tooltip>
                        <a-input class="input" placeholder="请输入">
                            <template #suffix>
                                <span>%</span>
                            </template>
                        </a-input>
                    </div>
                    <div class="row-item">
                        <a-tooltip background-color="var(--tooltip-bg)">
                            <p class="dotted cursor">
                                好友合约返现
                                <icon-question-circle-fill />
                            </p>
                            <template #content>
                                <span class="font-14 color-always-white">
                                    合约返现设置范围：0%-15%
                                </span>
                            </template>
                        </a-tooltip>
                        <a-input class="input" placeholder="请输入">
                            <template #suffix>
                                <span>%</span>
                            </template>
                        </a-input>
                    </div>
                </div>
                <div class="row">
                    <div class="row-item">
                        <p>
                            我的现货返现
                            <icon-question-circle-fill />
                        </p>
                        <a-input readonly disabled class="input" placeholder="">
                            <template #suffix>
                                <span>%</span>
                            </template>
                        </a-input>
                    </div>
                    <div class="row-item">
                        <p>
                            我的合约返现
                            <icon-question-circle-fill />
                        </p>
                        <a-input readonly disabled class="input" placeholder="">
                            <template #suffix>
                                <span>%</span>
                            </template>
                        </a-input>
                    </div>
                </div>
            </div>
            <div class="switch-box">
                <span>设为默认</span>
                <a-switch checked-color="var(--color-primary)"
                          unchecked-color="var(--background-secondary)">
                </a-switch>
            </div>
            <div class="footer">
                <a-button class="button" @click="inviteNew = false">取消</a-button>
                <a-button class="button">确认</a-button>
            </div>
        </div>
    </a-modal>
    <!--    邀请二维码-->
    <a-modal :visible="visible"
             :hide-title="true"
             :footer="false"
             width="360px">
        <div class="invite-code">
            <div class="title">
                <img class="logo" src="@/assets/logo1.png" alt="">
                <icon-close @click="visible = false"/>
            </div>
            <div class="content">
                <p>扫一扫&nbsp;&nbsp;共享收益</p>
                <div class="code-image">
                    <canvas></canvas>
                    <p>邀请码</p>
                    <p>11RFIQ</p>
                </div>
            </div>
        </div>
    </a-modal>
</template>

<script>
import Model from "@/components/model/modelContent.vue";
import notdate from "@/components/notdate/notdate.vue";
// Import Swiper Vue.js components
import {Swiper, SwiperSlide} from 'swiper/vue';
// 引入swiper卡片模块
import {Navigation} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import NotDate from "@/components/notdate/notdate.vue";
import WarningPopup from "@/components/warningPop/warningPop.vue";


export default {
    components: {
        WarningPopup,
        NotDate,
        notdate,
        Model,
        Swiper,
        SwiperSlide
    },
    data() {
        return {
            modules: [Navigation],
            visible: false, //邀请二维码弹出层
            invite: false,  //邀请链接弹出层
            inviteNew: false, //添加邀请链接弹出层
            warningShow: false,
            warningContent: "将0FIVYc设置为默认邀请码后，每次进入邀请页将会显示相应的佣金比例和邀请链接。确定将其设置为默认邀请码？",
            linkTab: ['邀请码', '我的现货返佣比例', '好友现货返现比例', '我的合约返佣比例', '好友合约返现比例', '操作'],
            linkOptions: [{
                codeText: '0FIVYc',
                spotRebate: '18%',
                spotCash: '2%',
                contractRebate: '18%',
                contractCash: '2%'
            }, {
                codeText: '0FIVYc',
                spotRebate: '5%',
                spotCash: '15%',
                contractRebate: '5%',
                contractCash: '15%'
            }]
        };
    },
    methods: {
        timeSelect(e) {
            console.log(e);
        }
    },
};
</script>
<style lang="scss" scoped>
@import "./recommendInvite.scss";
</style>
