<template>
    <a-empty class="notdate" :style="{ 'min-height': height + 'px' }">
        <template #image>
            <img
                :src="require(`@/assets/images/assets_img/no-data-${userTheme}.svg`)"
                alt="">
        </template>
        <span>{{msg}}</span>
    </a-empty>
</template>
<script>
import {mapGetters} from "vuex";

export default {
    name: "notDate",
    props: {
        height: {
            default: 100,
            type: Number, String
        },
        msg:{
            default: "暂无数据",
            type:String
        }
    },
    computed: {
        ...mapGetters(['userTheme'])
    },
    data() {
        return {}
    }
}
</script>