<template>
  <div class="main-box">
    <div class="content-main-bottom">
      <div class="btm-left">
        <div class="btm-left-head">
          <a-tabs
            :default-active-key="activeKey"
            type="line"
            :animation="true"
            size="large"
            style="font-size: 20px"
          >
            <a-tab-pane
              class="order-tab"
              :key="index"
              v-for="(item, index) in tabs"
            >
              <template #title>
                <span :style="{ fontSize: '16px' }">{{ item.label }}</span>
              </template>
              <div>
                <div class="kline" v-if="item.id == 1">
                  <!-- 当前委托 -->
                  <div class="kline-box">
                    <!-- 格子A -->
                    <div class="kline-content">
                      <div class="kline-title">合约类型</div>
                      <div class="select-box">
                        <a-select
                          class="flex-box"
                          direction="vertical"
                          :style="{
                            width: '120px',
                            backgroundColor: '#1d2126',
                          }"
                          :default-value="decimal"
                          size="large"
                        >
                          <a-option
                            :style="{
                              backgroundColor: '#1d2126',
                              color: '#fff',
                            }"
                            v-for="(item, index) in options"
                            :key="index"
                            @click="numSelect(item.value)"
                            >{{ item.label }}</a-option
                          >
                        </a-select>
                      </div>
                    </div>
                    <!-- 格子B -->
                    <div class="kline-content">
                      <div class="kline-title">合约类型</div>
                      <div class="select-box">
                        <a-select
                          class="flex-box"
                          direction="vertical"
                          :style="{
                            width: '120px',
                            backgroundColor: '#1d2126',
                          }"
                          :default-value="decimal"
                          size="large"
                        >
                          <a-option
                            :style="{
                              backgroundColor: '#1d2126',
                              color: '#fff',
                            }"
                            v-for="(item, index) in options"
                            :key="index"
                            @click="numSelect(item.value)"
                            >{{ item.label }}</a-option
                          >
                        </a-select>
                      </div>
                    </div>
                  </div>
                  <notdate> </notdate>
                </div>
                <div class="kline" v-if="item.id == 2">
                  <!-- 计划委托 -->
                  <div class="kline-box">
                    <!-- 格子A -->
                    <div class="kline-content">
                      <div class="kline-title">合约类型</div>
                      <div class="select-box">
                        <a-select
                          class="flex-box"
                          direction="vertical"
                          :style="{
                            width: '120px',
                            backgroundColor: '#1d2126',
                          }"
                          :default-value="decimal"
                          size="large"
                        >
                          <a-option
                            :style="{
                              backgroundColor: '#1d2126',
                              color: '#fff',
                            }"
                            v-for="(item, index) in options"
                            :key="index"
                            @click="numSelect(item.value)"
                            >{{ item.label }}</a-option
                          >
                        </a-select>
                      </div>
                    </div>
                    <!-- 格子B -->
                    <div class="kline-content">
                      <div class="kline-title">合约类型</div>
                      <div class="select-box">
                        <a-select
                          class="flex-box"
                          direction="vertical"
                          :style="{
                            width: '120px',
                            backgroundColor: '#1d2126',
                          }"
                          :default-value="decimal"
                          size="large"
                        >
                          <a-option
                            :style="{
                              backgroundColor: '#1d2126',
                              color: '#fff',
                            }"
                            v-for="(item, index) in options"
                            :key="index"
                            @click="numSelect(item.value)"
                            >{{ item.label }}</a-option
                          >
                        </a-select>
                      </div>
                    </div>
                  </div>
                  <notdate> </notdate>
                </div>
                <div class="kline" v-if="item.id == 3">
                  <!-- 历史委托 -->
                  <div class="kline-box">
                    <!-- 格子A -->
                    <div class="kline-content">
                      <div class="kline-title">开始时间</div>
                      <div> 
                        <a-range-picker
                          @change="onChange"
                          @select="onSelect"
                          :style="{backgroundColor: '#1d2126',marginRight:'16px'}"
                          size="large"
                        ></a-range-picker>
                      </div>
                    </div>
                    <!-- 格子B -->
                    <div class="kline-content">
                      <div class="kline-title">合约类型</div>
                      <div class="select-box">
                        <a-select
                          class="flex-box"
                          direction="vertical"
                          :style="{
                            width: '120px',
                            backgroundColor: '#1d2126',
                          }"
                          :default-value="decimal"
                          size="large"
                        >
                          <a-option
                            :style="{
                              backgroundColor: '#1d2126',
                              color: '#fff',
                            }"
                            v-for="(item, index) in options"
                            :key="index"
                            @click="numSelect(item.value)"
                            >{{ item.label }}</a-option
                          >
                        </a-select>
                      </div>
                    </div>
                  </div>
                  <notdate> </notdate>
                </div>
                <div class="kline" v-show="item.id == 4">
                  <!-- 盈亏记录 -->
                  <div class="kline-box">
                    <!-- 格子A -->
                    <div class="kline-content">
                      <div class="kline-title">开始时间</div>
                      <div> 
                        <a-range-picker
                          @change="onChange"
                          @select="onSelect"
                          :style="{backgroundColor: '#1d2126',marginRight:'16px'}"
                          size="large"
                        ></a-range-picker>
                      </div>
                    </div>
                    <!-- 格子B -->
                    <div class="kline-content">
                      <div class="kline-title">合约类型</div>
                      <div class="select-box">
                        <a-select
                          class="flex-box"
                          direction="vertical"
                          :style="{
                            width: '120px',
                            backgroundColor: '#1d2126',
                          }"
                          :default-value="decimal"
                          size="large"
                        >
                          <a-option
                            :style="{
                              backgroundColor: '#1d2126',
                              color: '#fff',
                            }"
                            v-for="(item, index) in options"
                            :key="index"
                            @click="numSelect(item.value)"
                            >{{ item.label }}</a-option
                          >
                        </a-select>
                      </div>
                    </div>
                  </div>
                  <notdate> </notdate>
                </div>
                <div class="kline" v-show="item.id == 5">
                  <!-- 历史计划委托 -->
                  <div class="kline-box">
                    <!-- 格子A -->
                    <div class="kline-content">
                      <div class="kline-title">开始时间</div>
                      <div> 
                        <a-range-picker
                          @change="onChange"
                          @select="onSelect"
                          :style="{backgroundColor: '#1d2126',marginRight:'16px'}"
                          size="large"
                        ></a-range-picker>
                      </div>
                    </div>
                    <!-- 格子B -->
                    <div class="kline-content">
                      <div class="kline-title">合约类型</div>
                      <div class="select-box">
                        <a-select
                          class="flex-box"
                          direction="vertical"
                          :style="{
                            width: '120px',
                            backgroundColor: '#1d2126',
                          }"
                          :default-value="decimal"
                          size="large"
                        >
                          <a-option
                            :style="{
                              backgroundColor: '#1d2126',
                              color: '#fff',
                            }"
                            v-for="(item, index) in options"
                            :key="index"
                            @click="numSelect(item.value)"
                            >{{ item.label }}</a-option
                          >
                        </a-select>
                      </div>
                    </div>
                  </div>
                  <notdate> </notdate>
                </div>
                <div class="kline" v-show="item.id == 6">
                  <!-- 历史成交 -->
                  <div class="kline-box">
                    <!-- 格子A -->
                    <div class="kline-content">
                      <div class="kline-title">开始时间</div>
                      <div> 
                        <a-range-picker
                          @change="onChange"
                          @select="onSelect"
                          :style="{backgroundColor: '#1d2126',marginRight:'16px'}"
                          size="large"
                        ></a-range-picker>
                      </div>
                    </div>
                    <!-- 格子B -->
                    <div class="kline-content">
                      <div class="kline-title">合约类型</div>
                      <div class="select-box">
                        <a-select
                          class="flex-box"
                          direction="vertical"
                          :style="{
                            width: '120px',
                            backgroundColor: '#1d2126',
                          }"
                          :default-value="decimal"
                          size="large"
                        >
                          <a-option
                            :style="{
                              backgroundColor: '#1d2126',
                              color: '#fff',
                            }"
                            v-for="(item, index) in options"
                            :key="index"
                            @click="numSelect(item.value)"
                            >{{ item.label }}</a-option
                          >
                        </a-select>
                      </div>
                    </div>
                  </div>
                  <notdate> </notdate>
                </div>
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import notdate from "@/components/notdate/notdate.vue";
export default {
  components: {
    notdate,
  },
  data() {
    return {
      tabs: [
        {
          id: "1",
          label: "当前委托",
        },
        {
          id: "2",
          label: "计划委托",
        },
        {
          id: "3",
          label: "历史委托",
        },
        {
          id: "4",
          label: "盈亏记录",
        },
        {
          id: "5",
          label: "历史计划委托",
        },
        {
          id: "6",
          label: "历史成交",
        },
      ],
      activeKey: 0,
      decimal: "全部",
      options: [
        {
          label: "全部",
          value: "1",
        },
        {
          label: "测试",
          value: "2",
        },
        {
          label: "测试",
          value: "3",
        },
      ],
    };
  },
  methods: {
    numSelect(e) {
      this.decimal = e;
    },
    onSelect(dateString, date) {
        console.log('onSelect', dateString, date);
      },
    onChange(dateString, date) {
      console.log('onChange: ', dateString, date);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./exchangeOrder.scss";
</style>
