import config from './config'
import {Message} from '@arco-design/web-vue'
import axios from 'axios'
import util from '../util'

export default {
    request(url, data = {}, method = "GET") {
        let lang = util.getCache('lang', config.default_lang);
        if(lang === 'zh' || lang === 'zh_Hant'){
            lang = 'zh_CN';
        }
        let token = util.getCache('token');

        let device = 2;
        let timestamp = Date.parse(new Date()) / 1000;
        return new Promise((resolve, reject) => {
            axios({
                method: method,
                url: config.BASE_URL + '/' + url,
                data: data,
                params:method === "GET" ? data : {},
                timeout: 10000,
                headers: {
                    "Accept-Language": lang,
                    "Authorization": "Bearer " + token,
                    "Client-Device": device,
                    "Timestamp": timestamp,
                },
                withCredentials: false,
            }).then(function (response) {
                let data = response.data

                if (response.data.code === 1001 || response.data.code === 500) {
                    Message.error({
                        content: 'server error',
                        // icon: ''
                    })
                    throw new Error('not login break')
                }
                if (response.data.code === 401) {//未登录
                    throw new Error('not login break')
                }
                return resolve(data)
            }).catch(function (error) {
                // return reject(new Error(error.message || 'Error'))
            })
        });
    },
}