<template>
    <a-layout class="futures" :data-theme="userTheme">
        <a-layout-header>
            <PageNav></PageNav>
        </a-layout-header>
        <a-layout-content class="futures_content">
            <!--        热门币种-->
            <div class="hotCoin">
                <img src="@/assets/hot.png" alt="">
                <div class="item" v-for="(item,index) in (currency[0] ? currency[0]['currency'].slice(0,15) : [])"
                     :key="index" @click="setCurrentCurrency(item)">
                    <span class="color-black" :class="tableActive === index ? 'font-bold' : ''">
                        {{ item.symbol }}{{ currency[0]['legal']['symbol'] }}
                    </span>
                    <span :class="{'color-green':item.change_rate >= 0,'color-red':item.change_rate < 0}">
                        {{ item.change_rate }}%
                    </span>
                </div>
            </div>
            <div class="content_head">
                <div class="content_head_left flex flex-column align-center jus-center">
                    <a-trigger trigger="hover" :popup-translate="[100, 10]">
                        <div class="flex flex-row align-center coinSelectBox">
                            <img class="mr-12 coinImage" :src="current['icon']" alt="">
                            <div class="coinInfo">
                                <div class="row flex align-center">
                                    <span class="mr-10 font-bold color-black">
                                        {{current['symbol']}}{{currency[currencyTab] ? currency[currencyTab]['legal']['symbol'] : ''}}</span>
                                    <div class="caretDown">
                                        <icon-caret-down size="14"/>
                                    </div>
                                </div>
                                <span class="desc">永续</span>
                            </div>
                        </div>
                        <template #content>
                            <div class="market-wrap">
                                <a-input class="input" placeholder="搜索" v-model="searchVal" allow-clear>
                                    <template #suffix>
                                        <icon-search size="18"/>
                                    </template>
                                </a-input>
                                <!--               -->
                                <div class="market_tab flex align-center">
                                    <div v-for="(item,index) in currency" :key="'tab'+item.id"
                                         class="pad-lr-15 flex align-center jus-center pointer"
                                         :class="subtabActive === 0 ? 'active' : ''"
                                         @click="subtabActive = index">
                                        <span class="font-12 color-gray">{{ item['title'] }}</span>
                                    </div>
                                </div>
                                <!--                -->
                                <div class="market_tab_main">
                                    <div class="sub_tab flex align-center jus-bet">
                                        <div class="flex align-center pointer">
                                            <span class="font-12 color-gray">合约标的</span>
                                        </div>
                                        <div class="flex align-center jus-end pointer">
                                            <span class="font-12 color-gray">最新价</span>
                                            <img src="@/assets/images/ranking.svg">
                                        </div>
                                        <div class="flex align-center jus-end pointer">
                                            <span class="font-12 color-gray">24h涨跌幅</span>
                                            <img src="@/assets/images/ranking.svg">
                                        </div>
                                    </div>
                                    <a-scrollbar outer-class="scrollbar">
                                        <div class="sub_cell flex align-center jus-bet"
                                             v-for="(item,index) in (searchVal !== '' ? currency[currencyTab]['currency'].filter((item)=>{return item.symbol.toLowerCase().includes(searchVal.toLowerCase())}):currency[currencyTab]['currency'])"
                                             :key="'c'+item.id"
                                             @click="setCurrentCurrency(item)">
                                            <div class="flex align-center">
                                                <img class="mr-5" src="@/assets/images/spot/unlike.svg" alt=""
                                                     v-if="like">
                                                <img class="mr-5" src="@/assets/images/spot/like.svg" alt="" v-else>
                                                <span class="font-12 color-black mr-5">{{ item['symbol'] }}</span>
                                                <span class="font-12 color-gray">永续</span>
                                            </div>
                                            <div class="flex align-center jus-end">
                                                <span class="font-12"
                                                      :class="{'color-green':item.change_rate >= 0,'color-red':item.change_rate < 0}">
                                                    {{ $util.thousandSeparator(item['new_price'], item.decimals) }}
                                                </span>
                                            </div>
                                            <div class="flex align-center jus-end">
                                                <span class="font-12"
                                                      :class="{'color-green':item.change_rate >= 0,'color-red':item.change_rate < 0}">
                                                    {{ $util.thousandSeparator(item['change_rate'], 2) }}%
                                                </span>
                                            </div>
                                        </div>
                                    </a-scrollbar>
                                </div>
                            </div>
                        </template>
                    </a-trigger>
                </div>
                <div class="content_head_right flex flex-row align-center jus-center">
                    <div class="content_head_right_info">
                        <div class="flex flex-column align-start pad-lr-15">
                            <span class="font-18 font-bold"
                                  :class="{'color-green':current.change_rate >= 0,'color-red':current.change_rate < 0}">
                                {{ $util.thousandSeparator(current['new_price'], current['decimal']) }}
                            </span>
                        </div>
                        <!--          24h涨跌幅-->
                        <div class="flex flex-column align-start pad-lr-15">
                            <span class="font-12 color-gray pointer">24h涨跌幅</span>
                            <span class="font-12 mt-5"
                                  :class="{'color-green':current.change_rate >= 0,'color-red':current.change_rate < 0}">
                                {{ $util.thousandSeparator(current['change_rate'], 2) }}%
                            </span>
                        </div>
                        <!--          标记价格-->
                        <div class="flex flex-column align-start pad-lr-15">
                            <a-tooltip position="bottom" background-color="var(--tooltip-bg)">
                                <span class="font-12 color-gray dotted pointer">标记价格</span>
                                <template #content>
                                    <p class="font-12 color-always-white">
                                        标记价格用于结算盈亏和触发强制减仓。标记价格可能会偏离市场最新价格，以防因市场被恶意操纵造成损失。</p>
                                </template>
                            </a-tooltip>
                            <span class="font-12 color-black mt-5">
                                {{$util.thousandSeparator(market['p'],current['decimals'])}}
                            </span>
                        </div>
                        <!--          指数价格-->
                        <div class="flex flex-column align-start pad-lr-15">
                            <a-tooltip position="bottom" background-color="var(--tooltip-bg)">
                                <span class="font-12 color-gray dotted pointer">指数价格</span>
                                <template #content>
                                    <p class="font-12 color-always-white">标的货币的市场公允现货价格。</p>
                                </template>
                            </a-tooltip>
                            <span class="font-12 color-black mt-5">
                                {{$util.thousandSeparator(market['i'],current['decimals'])}}
                            </span>
                        </div>
                        <!--          本期资金费率/结算时间-->
                        <div class="flex flex-column align-start pad-lr-15">
                            <a-tooltip position="bottom" background-color="var(--tooltip-bg)">
                                <span class="font-12 color-gray dotted pointer">本期资金费率/结算时间</span>
                                <template #content>
                                    <p class="font-12 color-always-white flex jus-bet">当期结算时间：<strong>
<!--                                        9月24日 0时0分(04:30:20)-->
                                        {{ $util.dateFormatter(market['T'], 'm月d日 h時i分', 2) }}
                                    </strong></p>
                                    <p class="font-12 color-always-white flex jus-bet">当期资金费率({{market['r'] >0 ? '多仓支付':'空仓支付'}})：<strong>{{$util.float(market['r'] * 100)}}%</strong>
                                    </p>
                                    <p class="font-14 color-always-white font-bold">什么是资金费率？</p>
                                    <p class="font-12 color-always-white">
                                        资金费率的主要用途是缩小永续合约价格与标的资产市场公允价的价差。</p>
                                    <p class="font-12 color-always-white">
                                        资金费用每8个小时结算一次，若结算时资金费率为正，则由持多仓用户向持空仓用户支付资金费用。若结算时资金费率为负，则由持空仓用户向持多仓用户支付资金费用。</p>
                                    <p class="font-12 color-always-white">资金费用=持仓价值*资金费率</p>
                                </template>
                            </a-tooltip>
                            <span class="font-12 color-black mt-5">{{$util.thousandSeparator(market['r'] * 100)}}%/{{timeInt}}</span>
                        </div>
                        <!--          24H最高价-->
                        <div class="flex flex-column align-start pad-lr-15">
                            <span class="font-12 color-gray">24H最高价</span>
                            <span class="font-12 color-black mt-5">
                                 {{ $util.thousandSeparator(current['high_price'], current['decimals']) }}
                            </span>
                        </div>
                        <!--          24H最低价-->
                        <div class="flex flex-column align-start pad-lr-15">
                            <span class="font-12 color-gray">24H最低价</span>
                            <span class="font-12 color-black mt-5">
                                {{ $util.thousandSeparator(current['low_price'], current['decimals']) }}
                            </span>
                        </div>
                        <!--          24H成交量（BTC）-->
                        <div class="flex flex-column align-start pad-lr-15">
                            <span class="font-12 color-gray">24H成交量（{{ current['symbol'] }}）</span>
                            <span class="font-12 color-black mt-5">
                                {{ $util.thousandSeparator(current['quantity'], current['decimals']) }}
                            </span>
                        </div>
                        <!--          24H成交额（USDT）-->
                        <div class="flex flex-column align-start pad-lr-15">
                            <span class="font-12 color-gray">24H成交额（USDT）</span>
                            <span class="font-12 color-black mt-5">
                                {{ $util.thousandSeparator(current['quota'], current['decimals']) }}
                            </span>
                        </div>
                    </div>
                    <div class="rightButtons">
                        <a-tooltip position="top" background-color="var(--tooltip-bg)">
                            <div class="item" @click="aboutCoinShow">
                                <icon-bookmark size="16"/>
                            </div>
                            <template #content>
                                <p class="font-12 color-always-white">{{ coinName }}USDT介绍</p>
                            </template>
                        </a-tooltip>
                        <a-tooltip position="top" background-color="var(--tooltip-bg)">
                            <div class="item" @click="settingDrawer = true">
                                <icon-settings size="16"/>
                            </div>
                            <template #content>
                                <p class="font-12 color-always-white">偏好设置</p>
                            </template>
                        </a-tooltip>
                    </div>
                </div>
            </div>
            <div class="content_main mt-10 flex jus-bet">
                <!--  -->
                <div class="content_main_left">
                    <div class="left_top flex flex-row jus-bet">
                        <!--            chart-->
                        <div class="block_kline">
                            <!--              table-->
                            <div class="kline_table"></div>
                            <!--              kline box-->
                            <div class="kline_box flex align-center jus-center">
                                <div class="chartBox" id="chartBox" style="width: 100%;height:100%"></div>
                            </div>
                        </div>
                        <!--      委托列表/实时成交      -->
                        <div class="block_list">
                            <a-tabs default-active-key="1">
                                <a-tab-pane key="1" title="委托列表">
                                    <div class="_top pad-lr-15 flex flex-row align-center jus-bet">
                                        <div class="flex align-center listTagGroup">
                                            <a-tooltip content="买卖盘" position="top"
                                                       background-color="var(--tooltip-bg)">
                                                <div class="item flex align-center jus-center"
                                                     :class="listType == 0 ? 'item-active' : ''" @click="listType = 0">
                                                    <img src="@/assets/images/cut-top-bottom.svg">
                                                </div>
                                            </a-tooltip>
                                            <a-tooltip content="买盘" position="top"
                                                       background-color="var(--tooltip-bg)">
                                                <div class="item flex align-center jus-center"
                                                     :class="listType == 1 ? 'item-active' : ''" @click="listType = 1">
                                                    <img src="@/assets/images/cut-buy.svg">
                                                </div>
                                            </a-tooltip>
                                            <a-tooltip content="卖盘" position="top"
                                                       background-color="var(--tooltip-bg)">
                                                <div class="item flex align-center jus-center"
                                                     :class="listType == 2 ? 'item-active' : ''" @click="listType = 2">
                                                    <img src="@/assets/images/cut-ask.svg">
                                                </div>
                                            </a-tooltip>
                                        </div>
                                        <a-dropdown trigger="hover" @select="numSelect">
                                            <div class="flex align-center jus-end numSelect">
                                                <span class="mr-5">{{ decimal }}</span>
                                                <icon-caret-down/>
                                            </div>
                                            <template #content>
                                                <a-doption>0.000001</a-doption>
                                                <a-doption>0.00001</a-doption>
                                                <a-doption>0.0001</a-doption>
                                            </template>
                                        </a-dropdown>
                                    </div>
                                    <!--                -->
                                    <div class="list-main">
                                        <div class="down_group group_box" v-if="listType == 0 || listType == 2">
                                            <div class="_cell flex align-center jus-bet" type="title">
                                                <span class="font-12 color-gray">价格</span>
                                                <span class="font-12 color-gray">数量 ({{ current['symbol'] }})</span>
                                                <span class="font-12 color-gray">总计 (USDT)</span>
                                            </div>
                                            <div v-if="listType === 2">
                                                <div class="_cell flex align-center jus-bet" type="title">
                                                    <span class="font-12 color-gray">最新价</span>
                                                    <a-tooltip position="bottom" background-color="var(--tooltip-bg)">
                                                        <span class="font-12 color-gray pointer dotted">标记价</span>
                                                        <template #content>
                                                            <span class="font-12 color-always-white">
                                                                标记价格用于结算盈亏和触发强制减仓。标记价格可能会偏离市场最新价格，以防因市场被恶意操纵造成损失。
                                                            </span>
                                                        </template>
                                                    </a-tooltip>
                                                </div>
                                                <div class="_cell flex align-center jus-bet mb-5">
                                                     <span class="font-16 color-green font-bold">
                                                        {{ $util.thousandSeparator(current['new_price'],current['decimals']) }}
                                                         <!--                                                        <icon-arrow-up style="margin-top: 1px"/>-->
                                                    </span>
                                                    <span class="font-16 color-gray font-bold">
                                                        {{ $util.thousandSeparator(market['p'],current['decimals']) }}
                                                    </span>
                                                </div>
                                            </div>
                                            <!--                      -->
                                            <a-scrollbar :class="listType == 0 ? 'minList' : 'priceDownList'">
                                                <div class="_cell flex align-center jus-bet pointer hover-bg"
                                                     v-for="(item,index) in bids"
                                                     :key="'bids'+index">
                                                    <span class="font-12 color-red">{{ $util.thousandSeparator(item[0],current['decimals']) }}</span>
                                                    <span class="font-12 color-black">{{ item[1] }}</span>
                                                    <span class="font-12 color-black">{{ $util.thousandSeparator(item[0] * item[1],2) }}</span>
                                                    <!-- 背景盒子     -->
                                                    <div class="_cell_bg red_bg"
                                                         :style="{'--width':item[3] * 100 + '%' }"></div>
                                                </div>
                                            </a-scrollbar>
                                        </div>
                                        <div class="up_group group_box" v-if="listType == 1 || listType == 0">
                                            <div>
                                                <div class="_cell flex align-center jus-bet" type="title">
                                                    <span class="font-12 color-gray">最新价</span>
                                                    <a-tooltip position="bottom" background-color="var(--tooltip-bg)">
                                                        <span class="font-12 color-gray pointer dotted">标记价</span>
                                                        <template #content>
                                                            <span class="font-12 color-always-white">
                                                                标记价格用于结算盈亏和触发强制减仓。标记价格可能会偏离市场最新价格，以防因市场被恶意操纵造成损失。
                                                            </span>
                                                        </template>
                                                    </a-tooltip>
                                                </div>
                                                <div class="_cell flex align-center jus-bet mb-5">
                                                    <span class="font-16 color-green font-bold">
                                                        {{ $util.thousandSeparator(current['new_price'],current['decimals']) }}
<!--                                                        <icon-arrow-up style="margin-top: 1px"/>-->
                                                    </span>
                                                    <span class="font-16 color-gray font-bold">
                                                        {{ $util.thousandSeparator(market['p'],current['decimals']) }}
                                                    </span>
                                                </div>
                                            </div>
                                            <!--                      -->
                                            <a-scrollbar :class="listType === 0 ? 'minList' : 'maxList'">
                                                <div class="_cell flex align-center jus-bet pointer hover-bg"
                                                     v-for="(item,index) in asks"
                                                     :key="'a'+index">
                                                    <span class="font-12 color-green">{{ $util.thousandSeparator(item[0],current['decimals']) }}</span>
                                                    <span class="font-12 color-green">{{ item[1] }}</span>
                                                    <span class="font-12 color-green">{{ $util.thousandSeparator(item[0] * item[1],2) }}</span>
                                                    <!-- 背景盒子     -->
                                                    <div class="_cell_bg red_bg"
                                                         :style="{'--width':item[3] * 100 + '%' }"></div>
                                                </div>
                                            </a-scrollbar>
                                        </div>
                                    </div>
                                </a-tab-pane>
                                <a-tab-pane key="2" title="实时成交">
                                    <div class="list-main">
                                        <div class="down_group group_box">
                                            <div class="_cell flex align-center jus-bet" type="title">
                                                <span class="font-12 color-gray">价格</span>
                                                <span class="font-12 color-gray">数量 ({{ coinName }})</span>
                                                <span class="font-12 color-gray">时间</span>
                                            </div>
                                            <a-scrollbar class="scrollbar">
                                                <!--                      -->
                                                <div class="_cell flex align-center jus-bet pointer hover-bg"
                                                     v-for="(item,index) in trades"
                                                     :key="'t'+index">
                                                      <span class="font-12" :class="item.m ? 'color-red' : 'color-green'">
                                                          {{item.price }}
                                                      </span>
                                                    <span class="font-12 color-black">{{ item.vol }}</span>
                                                    <span class="font-12 color-black">{{ item.time }}</span>
                                                    <!-- 背景盒子     -->
                                                    <div class="_cell_bg"
                                                         :class="index % 2 === 0 ? 'red_bg' : 'green-bg'"
                                                         :style="{'--width':item.price * 100 + '%' }"></div>
                                                </div>
                                            </a-scrollbar>
                                        </div>
                                    </div>
                                </a-tab-pane>
                            </a-tabs>
                        </div>
                    </div>
                    <div class="left_bottom">
                        <!--   持仓/当前委托... -->
                        <div class="left_bottom_card">
                            <a-tabs animation default-active-key="1" @change="tabChange">
                                <a-tab-pane key="1" :title="`仓位(${isLogin ? position.position.length : ''})`">
                                    <a-scrollbar style="height:306px;overflow: auto;">
                                        <!--   table    -->
                                        <div class="top_table flex flex-row align-center" type="first">
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">合约</span>
                                            </div>
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">持仓方向</span>
                                            </div>
                                            <div class="tableItem">
                                                <a-tooltip type="subtip" position="bottom"
                                                           background-color="var(--tooltip-bg)">
                                                    <span class="font-12 color-gray dotted pointer">仓位/可平</span>
                                                    <template #content>
                                                        <p class="font-12 color-always-white">
                                                            仓位： 你当前持有的仓位大小。正数代表多仓，负数代表空仓。
                                                        </p>
                                                        <p class="font-12 color-always-white">
                                                            可平：剩余待平的仓位数量。提交平仓或只减仓类型的订单会减少可平数量。
                                                        </p>
                                                    </template>
                                                </a-tooltip>
                                            </div>
                                            <div class="tableItem">
                                                <a-tooltip type="subtip" position="bottom"
                                                           background-color="var(--tooltip-bg)">
                                                    <span class="font-12 color-gray dotted pointer">持仓均价</span>
                                                    <template #content>
                                                        <p class="font-12 color-always-white">
                                                            该多仓/空仓开仓时的平均买入/卖出价。
                                                        </p>
                                                    </template>
                                                </a-tooltip>
                                            </div>
<!--                                            <div class="tableItem">-->
<!--                                                <a-tooltip type="subtip" position="bottom"-->
<!--                                                           background-color="var(&#45;&#45;tooltip-bg)">-->
<!--                                                    <span class="font-12 color-gray dotted pointer">标记价格</span>-->
<!--                                                    <template #content>-->
<!--                                                        <p class="font-12 color-always-white">-->
<!--                                                            标记价格用于结算盈亏和触发强制减仓。标记价格可能会偏离市场最新价格，以防因市场被恶意操纵造成损失。-->
<!--                                                        </p>-->
<!--                                                        <p class="font-12 color-always-white">-->
<!--                                                            标记价格可能会偏离市场最新价格，以防因市场被恶意操纵造成损失。-->
<!--                                                        </p>-->
<!--                                                    </template>-->
<!--                                                </a-tooltip>-->
<!--                                            </div>-->
                                            <div class="tableItem">
                                                <a-tooltip type="subtip" position="bottom"
                                                           background-color="var(--tooltip-bg)">
                                                    <span class="font-12 color-gray dotted pointer">预估强平价格</span>
                                                    <template #content>
                                                        <p class="font-12 color-always-white">
                                                            当标记价格接近这个值时，此仓位会被强制平仓。</p>
                                                    </template>
                                                </a-tooltip>
                                            </div>
                                            <div class="tableItem">
                                                <a-tooltip type="subtip" position="bottom"
                                                           background-color="var(--tooltip-bg)">
                                                    <span class="font-12 color-gray dotted pointer">保证金</span>
                                                    <template #content>
                                                        <p class="font-12 color-always-white">
                                                            用于担保此仓位的资产。逐仓为此仓位的实际担保资产。全仓为当前杠杆下所需的担保资产。
                                                        </p>
                                                        <p class="font-12 color-always-white">
                                                            逐仓为此仓位的实际担保资产。全仓为当前杠杆下所需的担保资产。
                                                        </p>
                                                    </template>
                                                </a-tooltip>
                                            </div>
                                            <div class="tableItem">
                                                <a-tooltip type="subtip" position="bottom"
                                                           background-color="var(--tooltip-bg)">
                                                    <span class="font-12 color-gray dotted pointer">保证金率</span>
                                                    <template #content>
                                                        <p class="font-12 color-always-white">
                                                            此仓位的担保资产相对于仓位价值的比值。当保证金率 &lt;=
                                                            维持保证金率+ 平仓taker费率,此仓位将被强制平仓。
                                                        </p>
                                                        <p class="font-12 color-always-white">
                                                            当保证金率 &lt;= 维持保证金率+ 平仓taker费率,此仓位将被强制平仓。
                                                        </p>
                                                    </template>
                                                </a-tooltip>
                                            </div>
                                            <div class="tableItem" style="width: 150px">
                                                <a-tooltip type="subtip" position="bottom"
                                                           background-color="var(--tooltip-bg)">
                                                    <span
                                                            class="font-12 color-gray dotted pointer">持仓中盈亏/回报率</span>
                                                    <template #content>
                                                        <p class="font-12 color-always-white">
                                                            多仓盈亏 = (当前标记价格 - 持仓均价) * 持仓币量
                                                        </p>
                                                        <p class="font-12 color-always-white">
                                                            空仓盈亏 = (持仓均价 - 当前标记价格) * 持仓币量
                                                        </p>
                                                        <p class="font-12 color-always-white">
                                                            回报率 = 盈亏 / (持仓均价 * 持仓币量 / 杠杆倍数) * 100%
                                                        </p>
                                                    </template>
                                                </a-tooltip>
                                            </div>
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">已实现盈亏</span>
                                            </div>
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">操作</span>
                                            </div>
                                            <div class="tableItem">
                                                <a-tooltip type="subtip" position="bottom"
                                                           background-color="var(--tooltip-bg)">
                                                    <span class="font-12 color-gray dotted pointer">止盈价/止损价</span>
                                                    <template #content>
                                                        <p class="font-12 color-always-white">
                                                            止盈止损订单是指，预先设置触发价、委托价和下单数量，最新价达到触发价，会按委托价挂单，帮助您保住利润或减少损失
                                                        </p>
                                                    </template>
                                                </a-tooltip>
                                            </div>
                                        </div>
                                        <notlog style="height: calc(100% - 55px)" v-if="!isLogin"></notlog>
                                        <notdate v-if="isLogin && position.position.length <= 0" msg="您当前暂无合约持仓数据"></notdate>
                                        <div class="listWarp" type="first">
                                            <a-scrollbar outer-class="listWarp">
                                                <!--  列表项 -->
                                                <div class="cell flex flex-row align-center" v-for="(item,index) in position.position" :key="'p'+item.id">
                                                    <!--  合约 -->
                                                    <div class="cellItem">
                                                        <span class="coinBefore bg-red"></span>
                                                        <span class="coin color-black">{{item['symbol']}}USDT</span>
                                                        <a-tag color="var(--opacity-gray)">
                                                            <span class="color-black">{{item['margin_type'] === 1 ? '全仓':'逐仓'}}{{item['muilt']}}X</span>
                                                        </a-tag>
                                                    </div>
                                                    <!--  持仓方向 -->
                                                    <div class="cellItem">
                                                        <a-tag color="var(--opacity-sell)">
                                                            <span class="color-red" :class="{'color-red':item['direction']===2,'color-green':item['direction']!==2}">{{item['direction'] === 1 ? '开多':'开空'}}</span>
                                                        </a-tag>
                                                    </div>
                                                    <!--  仓位/开平 -->
                                                    <div class="cellItem">
                                                        <span class="color-red">
                                                            {{$util.float(item['amount'])}}/{{$util.thousandSeparator(item['amount'] - item['close'],8)}} {{item['symbol']}}
                                                        </span>
                                                    </div>
                                                    <!--  持仓均价 -->
                                                    <div class="cellItem">
                                                        <span class="color-black">
                                                            {{$util.thousandSeparator(item['price'])}}
                                                        </span>
                                                    </div>
                                                    <!--  标记价格 -->
<!--                                                    <div class="cellItem">-->
<!--                                                        <span class="color-black">-->
<!--                                                            {{$util.thousandSeparator(current['new_price'],current['decimals'])}}-->
<!--                                                        </span>-->
<!--                                                    </div>-->
                                                    <!--  预估强平价格 -->
                                                    <div class="cellItem">
                                                        <span class="color-black">
                                                            {{$util.thousandSeparator(item['stopPrice'],current['decimals'])}}
                                                        </span>
                                                    </div>
                                                    <!--  保证金 -->
                                                    <div class="cellItem">
                                                        <span class="color-black">
                                                            {{$util.float(item['margin'])}}
                                                        </span>
                                                    </div>
                                                    <!--  保证金率 -->
                                                    <div class="cellItem">
                                                        <span class="color-black">{{item['mr']}}%</span>
                                                    </div>
                                                    <!--  持仓中盈亏/回报率 -->
                                                    <div class="cellItem" style="width: 150px">
                                                        <span class="mr-5" :class="{'color-red':item['p'] <= 0 ,'color-green':item['p']>0}">{{item['p']}}</span>/
                                                        <a-tag color="var(--opacity-sell)">
                                                            <span :class="{'color-red':item['p'] <= 0 ,'color-green':item['p']>0}">{{item['rate']}}%</span>
                                                        </a-tag>
                                                    </div>
                                                    <!--  已实现盈亏 -->
                                                    <div class="cellItem">
                                                        <span class="color-black">{{$util.thousandSeparator(item['profit'])}} USDT</span>
                                                    </div>
                                                    <div class="cellItem">
                                                        <span class="color-primary cursor mr-5"
                                                              @click="pindex = index;closePotion = true">平仓</span>
                                                        <span class="color-primary cursor" @click="pindex = index;flashClose = true">闪电平仓</span>
                                                    </div>
                                                    <div class="cellItem">
                                                        <span class="mr-5 color-black">
                                                            {{item['winPrice'] > 0 ? $util.thousandSeparator(item['winPrice']):'--'}}/{{item['losePrice'] > 0 ? $util.thousandSeparator(item['losePrice']):'--'}}
                                                        </span>
                                                        <icon-edit class="cursor color-black"
                                                                   @click="pindex = index;profitLossPop = true"/>
                                                    </div>
                                                </div>
                                            </a-scrollbar>
                                        </div>
                                    </a-scrollbar>
                                </a-tab-pane>
                                <a-tab-pane key="2" :title="`当前委托(${isLogin && position.entrust ? position.entrust.length : ''})`">
                                    <a-scrollbar style="height:306px;overflow: auto;">
                                        <!--   table    -->
                                        <div class="top_table flex flex-row align-center" type="tab2">
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">合约</span>
                                            </div>
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">方向</span>
                                            </div>
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">类型</span>
                                            </div>
                                            <div class="tableItem" style="width: 230px !important;">
                                                <span class="font-12 color-gray">成交均价/委托价格</span>
                                            </div>
                                            <div class="tableItem" >
                                                <span class="font-12 color-gray">成交数量/委托数量</span>
                                            </div>
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">完成度</span>
                                            </div>
                                            <a-tooltip type="subtip" position="bottom"
                                                       background-color="var(--tooltip-bg)">
                                                <div class="tableItem">
                                                    <span class="font-12 color-gray dotted pointer">止盈价/止损价</span>
                                                </div>
                                                <template #content>
                                                    <p class="font-12 color-always-white">
                                                        当最新价格触达止盈或止损价格时，将自动市价委托平仓（平仓量=当前委托单委托数量）
                                                    </p>
                                                </template>
                                            </a-tooltip>
                                            <div class="tableItem" style="width: 194px !important;">
                                                <span class="font-12 color-gray">时间</span>
                                            </div>
                                            <div class="tableItem" style="justify-content: flex-end;text-align: right">
                                                <span class="font-12 color-gray">操作</span>
                                            </div>
                                        </div>
                                        <div class="listWarp" type="tab2">
                                            <notlog style="height: calc(100% - 55px)" v-if="!isLogin"></notlog>
                                            <notdate v-if="isLogin && position.entrust.length <= 0" msg="您当前暂无委托数据"></notdate>
                                            <a-scrollbar outer-class="listWarp">
                                                <!--  列表项 -->
                                                <div class="cell flex flex-row align-center" v-for="(item,index) in position.entrust" :key="'e'+item.id">
                                                    <!--  合约 -->
                                                    <div class="cellItem">
                                                        <span class="coin color-black">{{item['symbol']}}USDT</span>
                                                    </div>
                                                    <!--  方向 -->
                                                    <div class="cellItem">
                                                        <a-tag color="var(--opacity-sell)">
                                                            <span class="color-red" :class="{'color-red':item['direction']===2,'color-green':item['direction']!==2}">{{item['direction'] === 1 ? '开多':'开空'}}</span>
                                                        </a-tag>
                                                    </div>
                                                    <!--  类型 -->
                                                    <div class="cellItem">
                                                        <span class="color-black">限价单</span>
                                                    </div>
                                                    <!--  成交均价/委托价格 -->
                                                    <div class="cellItem">
                                                        <span class="color-black">{{$util.float(item.t_price)}}/{{$util.thousandSeparator(item.price,item.decimals)}}</span>
                                                    </div>
                                                    <!--  成交数量/委托数量 -->
                                                    <div class="cellItem" style="width: 120px">
                                                        <span class="color-black">0.00/{{$util.float(item.amount)}}</span>
                                                    </div>
                                                    <!--  完成度 -->
                                                    <div class="cellItem">
                                                        <span class="color-black">0%</span>
                                                    </div>
                                                    <!--  止盈价/止损价 -->
                                                    <div class="cellItem">
                                                        <span class="color-black">{{$util.float(item.winPrice)}}/{{$util.float(item.losePrice)}}</span>
                                                    </div>

<!--                                                    <div class="cellItem">-->
<!--                                                        <span class="color-black" v-if="i === 1">&#45;&#45;/&#45;&#45;</span>-->
<!--                                                        <span class="color-black" v-if="i != 1">0.6500/0.6990</span>-->
<!--                                                    </div>-->
                                                    <!--  时间 -->
                                                    <div class="cellItem">
                                                        <span class="color-black">{{item.created_at}}</span>
                                                    </div>
                                                    <!--  撤单 -->
                                                    <div class="cellItem" style="text-align: left;justify-content: flex-start">
                                                        <span class="color-primary cursor"
                                                              @click="pindex=index;wdOrder = true">撤单</span>
                                                    </div>
                                                </div>
                                            </a-scrollbar>
                                        </div>
                                    </a-scrollbar>
                                </a-tab-pane>
                                <a-tab-pane key="3" :title="`计划委托(${isLogin && position.entrustP ? position.entrustP.length : ''})`">
                                    <a-scrollbar style="height:306px;overflow: auto;">
                                        <!--   table    -->
                                        <div class="top_table flex flex-row align-center" type="tab3">
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">合约</span>
                                            </div>
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">委托类型</span>
                                            </div>
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">触发价格</span>
                                            </div>
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">委托价格</span>
                                            </div>
                                            <div class="tableItem" style="width: 186px">
                                                <span class="font-12 color-gray">委托数量</span>
                                            </div>
<!--                                            <div class="tableItem">-->
<!--                                                <span class="font-12 color-gray">类型</span>-->
<!--                                            </div>-->
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">提交时间</span>
                                            </div>
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">过期时间</span>
                                            </div>
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">操作</span>
                                            </div>
                                        </div>
                                        <div class="listWarp" type="tab3">
                                            <notlog style="height: calc(100% - 55px)" v-if="!isLogin"></notlog>
                                            <notdate v-if="isLogin && position.entrustP.length <= 0" msg="您当前暂无委托数据"></notdate>
                                            <a-scrollbar outer-class="listWarp">
                                                <!--  列表项 -->
                                                <div class="cell flex flex-row align-center" v-for="(item,index) in position.entrustP" :key="'ep'+item.id">
                                                    <!--  合约 -->
                                                    <div class="cellItem">
                                                        <span class="coin color-black">{{item['symbol']}}USDT</span>
                                                    </div>
                                                    <!--  方向 -->
                                                    <div class="cellItem" style="width: 143px !important;">
                                                        <a-tag color="var(--opacity-sell)">
                                                            <span class="color-red" v-if="item.type === 1">开多</span>
                                                            <span class="color-red" v-if="item.type === 2">开空</span>
                                                            <span class="color-red" v-if="item.type === 3">平多</span>
                                                            <span class="color-red" v-if="item.type === 4">平空</span>
                                                            <span class="color-red" v-if="item.type === 5">止盈</span>
                                                            <span class="color-red" v-if="item.type === 6">止损</span>
                                                        </a-tag>
                                                    </div>
                                                    <!--  触发价格 -->
                                                    <div class="cellItem">
                                                        <span class="color-black">{{$util.float(item.wprice)}}</span>
                                                    </div>
                                                    <!--  委托价格 -->
                                                    <div class="cellItem">
                                                        <span class="color-black">
                                                            {{item.price === '' ? $util.float(item.wprice):$util.float(item.wprice)}}
                                                        </span>
                                                    </div>
                                                    <!--  委托数量 -->
                                                    <div class="cellItem">
                                                        <span class="color-black">{{$util.float(item.amount)}} {{item.symbol}}</span>
                                                    </div>
                                                    <!--  提交时间 -->
                                                    <div class="cellItem" style="width: 143px !important;">
                                                        <span class="color-black">{{item.created_at}}</span>
                                                    </div>
                                                    <!--  过期时间 -->
                                                    <div class="cellItem">
                                                        <span class="color-black">{{$util.getDateAddDay(item.created_at,30)}} 00:00:00</span>
                                                    </div>
                                                    <!--  撤单 -->
                                                    <div class="cellItem">
                                                        <span class="color-primary cursor"
                                                              @click="pindex=index;wdOrder = true">撤单</span>
                                                    </div>
                                                </div>
                                            </a-scrollbar>
                                        </div>
                                        <notlog style="height: calc(100% - 55px)" v-if="false"></notlog>
                                    </a-scrollbar>
                                </a-tab-pane>
                                <a-tab-pane key="4" title="历史委托">
                                    <a-scrollbar style="height:306px;overflow: auto;">
                                        <!--   table    -->
                                        <div class="top_table flex flex-row align-center" type="tab4">
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">合约</span>
                                            </div>
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">方向</span>
                                            </div>
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">类型</span>
                                            </div>
                                            <div class="tableItem" style="width: 195px">
                                                <span class="font-12 color-gray">成交均价/委托价格</span>
                                            </div>
                                            <div class="tableItem" style="width: 195px">
                                                <span class="font-12 color-gray">成交数量/委托数量</span>
                                            </div>
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">盈亏(USDT)</span>
                                            </div>
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">手续费(USDT)</span>
                                            </div>
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">状态</span>
                                            </div>
                                            <div class="tableItem" style="width: 164px">
                                                <span class="font-12 color-gray">时间</span>
                                            </div>
                                            <div class="tableItem" style="justify-content: flex-end;text-align: right">
                                                <span class="font-12 color-gray">操作</span>
                                            </div>
                                        </div>
                                        <div class="listWarp" type="tab4">
                                            <notlog style="height: calc(100% - 55px)" v-if="!isLogin"></notlog>
                                            <notdate v-if="isLogin && history.history && history.history.list <= 0" msg="您当前暂无委托数据"></notdate>
                                            <a-scrollbar outer-class="listWarp">
                                                <!--  列表项 -->
                                                <div class="cell flex flex-row align-center" v-for="(item,index) in (history.history ? history.history.list :[])" :key="'history'+item.id">
                                                    <!--  合约 -->
                                                    <div class="cellItem">
                                                        <span class="coin color-black">{{item['symbol']}}USDT</span>
                                                    </div>
                                                    <!--  方向 -->
                                                    <div class="cellItem">
                                                        <a-tag color="var(--opacity-sell)">
                                                            <span class="color-red" v-if="item.type === 1">开多</span>
                                                            <span class="color-red" v-if="item.type === 2">开空</span>
                                                            <span class="color-red" v-if="item.type === 3">平多</span>
                                                            <span class="color-red" v-if="item.type === 4">平空</span>
                                                            <span class="color-red" v-if="item.type === 5">止盈</span>
                                                            <span class="color-red" v-if="item.type === 6">止损</span>
                                                        </a-tag>
                                                    </div>
                                                    <!--  类型 -->
                                                    <div class="cellItem">
                                                        <span class="color-black" v-if="item.ttype === 1">市价单</span>
                                                        <span class="color-black" v-if="item.ttype === 2">限价单</span>
                                                        <span class="color-black" v-if="item.ttype === 3">条简单</span>
                                                    </div>
                                                    <!--  成交均价/委托价格 -->
                                                    <div class="cellItem">
                                                        <span class="color-black">
                                                            {{item.status === 1 ? $util.thousandSeparator(item['t_price'],2):'0.00'}}/{{$util.thousandSeparator(item['price'],2)}}
                                                        </span>
                                                    </div>
                                                    <!--  成交数量/委托数量 -->
                                                    <div class="cellItem">
                                                        <span class="color-black">
                                                            {{item.status === 1 ? $util.float(item.amount):'0.00'}}/{{$util.float(item.amount)}}
                                                        </span>
                                                    </div>
                                                    <!--  盈亏 -->
                                                    <div class="cellItem">
                                                        <span class="color-black">{{$util.float(item.profit)}}</span>
                                                    </div>
                                                    <!--  手续费 -->
                                                    <div class="cellItem">
                                                        <span class="color-black">{{$util.float(item.charge)}}</span>
                                                    </div>
                                                    <!--  状态 -->
                                                    <div class="cellItem">
                                                        <span class="color-black" v-if="item.status === 2">完全成交</span>
                                                        <span class="color-black" v-if="item.status === 1">待成交</span>
                                                        <span class="color-black" v-if="item.status === 3">撤单</span>
                                                    </div>
                                                    <!--  时间 -->
                                                    <div class="cellItem">
                                                        <span class="color-black">{{item.created_at}}</span>
                                                    </div>
                                                    <!--  订单详情 -->
                                                    <div class="cellItem" style="justify-content: flex-start;text-align: left">
                                                        <span class="color-primary cursor"
                                                              @click="pindex=index;orderInfoPopup = true">详情</span>
                                                    </div>
                                                </div>
                                            </a-scrollbar>
                                        </div>
                                        <notlog style="height: calc(100% - 55px)" v-if="false"></notlog>
                                    </a-scrollbar>
                                </a-tab-pane>
                                <a-tab-pane key="5" title="盈亏记录">
                                    <a-scrollbar style="height:306px;overflow: auto;">
                                        <!--   table    -->
                                        <div class="top_table flex flex-row align-center" type="tab5">
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">合约</span>
                                            </div>
                                            <div class="tableItem" style="width: 120px !important;">
                                                <span class="font-12 color-gray">方向</span>
                                            </div>
                                            <div class="tableItem" style="width: 120px !important;">
                                                <span class="font-12 color-gray">开仓均价</span>
                                            </div>
                                            <div class="tableItem" style="width: 120px !important;">
                                                <span class="font-12 color-gray">平仓均价</span>
                                            </div>
                                            <div class="tableItem" style="width: 120px !important;">
                                                <span class="font-12 color-gray">仓位数量</span>
                                            </div>
                                            <a-tooltip type="subtip" position="bottom"
                                                       background-color="var(--tooltip-bg)">
                                                <div class="tableItem" style="width: 120px !important;">
                                                    <span class="font-12 color-gray pointer dotted">已实现盈亏</span>
                                                </div>
                                                <template #content>
                                                    <p class="font-12 color-always-white">
                                                        总盈亏为该仓位持仓期间累计发生的总盈亏，总盈亏 = 手续费+资金费用+仓位盈亏+分摊
                                                    </p>
                                                </template>
                                            </a-tooltip>
<!--                                            <div class="tableItem">-->
<!--                                                <span class="font-12 color-gray">分润</span>-->
<!--                                            </div>-->
                                            <a-tooltip type="subtip" position="bottom"
                                                       background-color="var(--tooltip-bg)">
                                                <div class="tableItem" style="width: 120px !important;">
                                                    <span class="font-12 color-gray pointer dotted">手续费</span>
                                                </div>
                                                <template #content>
                                                    <p class="font-12 color-always-white">
                                                        开仓、平仓累计总手续费
                                                    </p>
                                                </template>
                                            </a-tooltip>
                                            <a-tooltip type="subtip" position="bottom"
                                                       background-color="var(--tooltip-bg)">
                                                <div class="tableItem" style="width: 120px !important;">
                                                    <span class="font-12 color-gray pointer dotted">资金费用</span>
                                                </div>
                                                <template #content>
                                                    <p class="font-12 color-always-white">
                                                        持仓期间的总资金费用
                                                    </p>
                                                </template>
                                            </a-tooltip>
<!--                                            <a-tooltip type="subtip" position="bottom"-->
<!--                                                       background-color="var(&#45;&#45;tooltip-bg)">-->
<!--                                                <div class="tableItem">-->
<!--                                                    <span class="font-12 color-gray pointer dotted">仓位盈亏</span>-->
<!--                                                </div>-->
<!--                                                <template #content>-->
<!--                                                    <p class="font-12 color-always-white">-->
<!--                                                        仓位盈亏为仓位每次平仓的仓位盈亏之和，平仓盈亏根据-->
<!--                                                        平仓前的持仓均价和平仓均价计算得出-->
<!--                                                    </p>-->
<!--                                                </template>-->
<!--                                            </a-tooltip>-->
<!--                                            <div class="tableItem">-->
<!--                                                <span class="font-12 color-gray">分摊</span>-->
<!--                                            </div>-->
                                            <div class="tableItem" style="width: 120px !important;">
                                                <span class="font-12 color-gray">平仓时间</span>
                                            </div>
                                        </div>
                                        <div class="listWarp" type="tab5">
                                            <a-scrollbar outer-class="listWarp">
                                                <!--  列表项 -->
                                                <div class="cell flex flex-row align-center" v-for="(item,index) in (history.profit ? history.profit.list : [])" :key="'profit'+item.id">
                                                    <!--  合约 -->
                                                    <div class="cellItem">
                                                        <span class="coinBefore bg-red"></span>
                                                        <span class="coin color-black">{{item['symbol']}}USDT</span>
                                                        <a-tag color="var(--opacity-gray)">
                                                            <span class="color-black">{{item['margin_type'] === 1 ? '全':'逐'}}仓{{item['muilt']}}X</span>
                                                        </a-tag>
                                                    </div>
                                                    <div class="cellItem" style="width: 120px !important;">
                                                        <a-tag color="var(--opacity-sell)" v-if="item.direction === 1">
                                                            <span class="color-red">多</span>
                                                        </a-tag>
                                                        <a-tag color="var(--opacity-buy)" v-else>
                                                            <span class="color-green">空</span>
                                                        </a-tag>
                                                    </div>
                                                    <!--  开仓均价 -->
                                                    <div class="cellItem" style="width: 120px !important;">
                                                        <span class="color-black">
                                                            {{$util.thousandSeparator(item['price'],current['decimals'])}}
                                                        </span>
                                                    </div>
                                                    <!--  平仓均价 -->
                                                    <div class="cellItem" style="width: 120px !important;">
                                                        <span class="color-black">
                                                            {{$util.float(item['t_price_3'] === null ? item['t_price_4']:item['t_price_3'])}}
                                                        </span>
                                                    </div>
                                                    <!--  仓位数量 -->
                                                    <div class="cellItem" style="width: 120px !important;">
                                                        <span class="color-black">{{$util.float(item.amount)}} {{item['symbol']}}</span>
                                                    </div>
                                                    <!--  已实盈亏 -->
                                                    <div class="cellItem" style="width: 120px !important;">
                                                        <span :class="{'color-red':item['profit']<=0,'color-green':item['profit']>0}">{{$util.thousandSeparator(item['profit'])}} USDT</span>
                                                    </div>
                                                    <!--  手续费 -->
                                                    <div class="cellItem" style="width: 120px !important;">
                                                        <span class="color-red">{{$util.thousandSeparator(item['charge'])}} USDT</span>
                                                    </div>
                                                    <!--  资金费用 -->
                                                    <div class="cellItem" style="width: 120px !important;">
                                                        <span class="color-black">0.00 USDT</span>
                                                    </div>
                                                    <!--  平仓时间 -->
                                                    <div class="cellItem" style="width: 120px !important;">
                                                        <span class="color-black">{{item['created_at']}}</span>
                                                    </div>
                                                </div>
                                            </a-scrollbar>
                                        </div>
                                        <notlog style="height: calc(100% - 55px)" v-if="false"></notlog>
                                    </a-scrollbar>
                                </a-tab-pane>
                                <a-tab-pane key="6" title="成交记录" v-if="false">
                                    <a-scrollbar style="height:306px;overflow: auto;">
                                        <!--   table    -->
                                        <div class="top_table flex flex-row align-center" type="tab6">
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">合约</span>
                                            </div>
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">开仓均价</span>
                                            </div>
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">方向</span>
                                            </div>
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">成交数量</span>
                                            </div>
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">角色</span>
                                            </div>
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">手续费</span>
                                            </div>
                                            <div class="tableItem">
                                                <span class="font-12 color-gray">时间</span>
                                            </div>
                                        </div>
                                        <div class="listWarp" type="tab6">
                                            <a-scrollbar outer-class="listWarp">
                                                <!--  列表项 -->
                                                <div class="cell flex flex-row align-center" v-for="(item,index) in (history.profit ? history.profit : [])" :key="'profit'+item.id">
                                                    <!--  合约 -->
                                                    <div class="cellItem">
                                                        <span class="coin color-black">{{item['symbol']}}USDT</span>
                                                    </div>
                                                    <!--  开仓均价 -->
                                                    <div class="cellItem">
                                                        <span class="color-black">
                                                            {{$util.float(item['price'])}}
                                                        </span>
                                                    </div>
                                                    <!--  方向 -->
                                                    <div class="cellItem">
                                                        <a-tag color="var(--opacity-sell)" v-if="item.direction === 2">
                                                            <span class="color-red">多</span>
                                                        </a-tag>
                                                        <a-tag color="var(--opacity-buy)" v-else>
                                                            <span class="color-green">空</span>
                                                        </a-tag>
                                                    </div>
                                                    <!--  成交数量 -->
                                                    <div class="cellItem">
                                                        <span class="color-black">{{$util.float(item['amount'])}} {{item['symbol']}}</span>
                                                    </div>
                                                    <!--  角色 -->
                                                    <div class="cellItem">
                                                        <span class="color-black">taker</span>
                                                    </div>
                                                    <!--  手续费 -->
                                                    <div class="cellItem">
                                                        <span class="color-black">0.0725 USDT</span>
                                                    </div>
                                                    <!--  时间 -->
                                                    <div class="cellItem">
                                                        <span class="color-black">2023-11-28 10:28:26</span>
                                                    </div>
                                                </div>
                                            </a-scrollbar>
                                        </div>
                                        <notlog style="height: calc(100% - 55px)" v-if="false"></notlog>
                                    </a-scrollbar>
                                </a-tab-pane>
                            </a-tabs>
                        </div>
                    </div>
                </div>

                <!--     -->
                <div class="content_main_right">
                    <div class="content_main_right_top">
                        <div class="_hd flex align-center jus-bet">
                            <div class="flex align-center">
                                <div class="flex align-center jus-center pointer mr-20" @click="marginModel = true">
                                    <span class="font-14 color-gray mr-5">{{positionModel === 0 ? '全仓':'逐仓'}}</span>
                                    <icon-swap class="color-gray"/>
                                </div>
                                <div class="flex align-center jus-center pointer" @click="leverArm = true">
                                    <span class="font-14 color-gray mr-5">{{leverVal}}X</span>
                                    <icon-swap class="color-gray"/>
                                </div>
                            </div>
                            <img class="pointer" src="@/assets/images/calculator.svg" @click="calculator = true">
                        </div>
                        <div class="_mn">
                            <!-- 开仓/平仓-->
                            <div class="flex align-center jus-bet">
                                <div class="btn" :class="btnActive===0?'buyActive':''" @click="btnActive = 0">开仓</div>
                                <div class="btn" :class="btnActive===1?'sellActive':''" @click="btnActive = 1">平仓</div>
                            </div>
                            <!-- 模式-->
                            <div class="flex align-center jus-start mode_group">
                                <div class="pad-lr-10 pointer mode_item"
                                     :class="modeIndex === index ? 'modeAcitve' : ''" v-for="(item,index) in modeOption"
                                     :key="index"
                                     @click="modeIndex = index">
                                    <span class="font-14 color-black" :class="{'font-bold': modeIndex === index}">{{
                                            item
                                        }}</span>
                                </div>
                            </div>
                            <!--  -->
                            <div class="_row flex align-center jus-bet" v-if="btnActive===0">
                                <div class="flex align-center">
                                    <span class="font-12 color-gray mr-5">可用</span>
                                    <span class="font-14 color-primary">{{$util.thousandSeparator(balance.legal_balance)}} USDT</span>
                                </div>
<!--                                <div class="flex align-center" v-else>-->
<!--                                    <span class="font-12 color-gray mr-5">可平</span>-->
<!--                                    <span class="font-14 color-primary">{{$util.thousandSeparator(balance.legal_balance)}} USDT</span>-->
<!--                                </div>-->
                                <div class="transferIcon" @click="transfeShow = true">
                                    <icon-swap/>
                                </div>
                            </div>
                            <!--  -->
                            <div class="_row _row_box flex align-center jus-bet" v-if="modeIndex == 0">
                                <span class="font-12 color-gray">价格</span>
                                <a-input type="text" v-model="price" placeholder="成交价格"/>
                                <span class="font-12 color-gray">USDT</span>
                            </div>
                            <!--  -->
                            <div class="_row _row_box flex align-center jus-bet" v-if="modeIndex == 2">
                                <a-tooltip background-color="var(--tooltip-bg)">
                                    <span class="font-12 color-gray dotted cursor">触发价格</span>
                                    <template #content>
                                        <p class="font-12 color-always-white">
                                            触发价格是追踪委托的激活条件，当市场价格/标记价格触及触发价格，追踪委托被激活。激活后系统开始依据回调幅度和最高价/最低价计算是否要以市场最优价执行将您所设置的数量进行委托挂单。</p>
                                    </template>
                                </a-tooltip>
                                <a-input type="text" v-model="wprice" placeholder=""/>
                                <span class="font-12 color-gray">USDT</span>
                            </div>
                            <div class="_row flex align-center jus-bet" type="select" v-if="modeIndex == 2">
                                <div class="_subbox flex align-center jus-bet">
                                    <div class="_subbox_left">
                                        <span class="font-12 color-gray">价格</span>
                                        <div class="flex align-center jus-end inputBox">
                                            <a-input class="mr-10" :disabled="limitType!==0" v-model="price" type="text" placeholder=""/>
                                            <span class="font-12 color-gray">USDT</span>
                                        </div>
                                    </div>
                                    <a-dropdown :render-to-body="false" :popup-max-height="false">
                                        <div class="selectTag">
                                            <a-tooltip position="lb" background-color="var(--tooltip-bg)">
                                                <span class="color-black font-12 dotted mr-5">{{limitType === 0 ? '限价':'市价'}}</span>
                                                <icon-caret-down/>
                                                <template #content>
                                                    <p class="font-12 color-always-white">
                                                        限价单（Limit
                                                        Order）按特定限价在订单簿中下达订单。限定价格将由您决定。交易仅在市场达到限价（或更高价格）的达成。因此，通过限价单能够以较低的价格买入，或以高于当前市场价的价格出售。与以当前市场价格立即执行交易的市价单不同，限价单在订单薄上下达，且不会立刻执行。
                                                    </p>
                                                </template>
                                            </a-tooltip>
                                        </div>
                                        <template #content>
                                            <a-doption @click="limitType=0">限价</a-doption>
                                            <a-doption @click="limitType=1">市价</a-doption>
                                        </template>
                                    </a-dropdown>
                                </div>
                            </div>
                            <!--  -->
                            <div class="_row _row_box flex align-center jus-bet" type="select">
                                <span class="font-12 color-gray">数量</span>
                                <a-input type="text" v-model="amount" placeholder=""/>
                                <a-dropdown :render-to-body="false" :popup-max-height="false">
                                    <span class="font-12 color-gray flex align-center pointer"><b
                                            class="mr-5 color-black">{{amounType===0?current['symbol']:'USDT'}}</b> <icon-caret-down/></span>
                                    <template #content>
                                        <template v-show="btnActive===0">
                                            <a-doption @click="amounType=0">{{current['symbol']}}</a-doption>
                                            <a-doption @click="amounType=1">USDT</a-doption>
                                        </template>
                                    </template>
                                </a-dropdown>
                            </div>
                            <p class="tipText" v-if="isLogin && balance.legal_balance <= current['min_amount']">您的可用余额不足以支付保证金，请充值或转账</p>
                            <!--            -->
                            <div class="_row">
                                <a-slider @change="sliderChange" v-model="rate" :default-value="rate" :max="100" :marks="marks"/>
                            </div>
                            <!--  -->
                            <div class="_row flex align-center jus-bet">
                                <div class="flex align-center pointer">
                                    <a-checkbox value="1">
                                        <span class="font-14 color-gray">止盈止损 </span>
                                    </a-checkbox>
                                    <a-tooltip position="bottom" background-color="var(--tooltip-bg)">
                                        <icon-question-circle-fill class="color-gray pointer"/>
                                        <template #content>
                                            <span class="font-12 color-always-white">开仓成交后止盈止损方可生效。触发止盈或止损后将自动以市价进行平仓</span>
                                        </template>
                                    </a-tooltip>
                                </div>
                            </div>
                            <!--  -->
                            <div class="_row flex align-center jus-bet" type="select" v-if="false">
                                <div class="flex align-center pointer">
                                    <a-checkbox class="mr-5" value="1"></a-checkbox>
                                    <a-dropdown :render-to-body="false" :popup-max-height="false">
                                        <span class="font-14 color-gray">只做maker <icon-caret-down/></span>
                                        <template #content>
                                            <a-doption>只做Maker</a-doption>
                                            <a-doption>
                                                IOC
                                                <a-tooltip position="bottom" background-color="var(--tooltip-bg)">
                                                    <icon-question-circle-fill class="color-gray pointer"/>
                                                    <template #content>
                                                        <span
                                                                class="font-12 color-always-white">未成交部分订单立即取消</span>
                                                    </template>
                                                </a-tooltip>
                                            </a-doption>
                                            <a-doption>
                                                FOK
                                                <a-tooltip position="bottom" background-color="var(--tooltip-bg)">
                                                    <icon-question-circle-fill class="color-gray pointer"/>
                                                    <template #content>
                                                        <span
                                                                class="font-12 color-always-white">订单全部成交或立即取消</span>
                                                    </template>
                                                </a-tooltip>
                                            </a-doption>
                                        </template>
                                    </a-dropdown>
                                </div>
                            </div>
                            <!--            login-->
                            <div class="_row _row_box flex align-center jus-center" v-if="isLogin === false">
                                <span class="font-14 color-primary mr-5 pointer">登录</span>
                                <span class="font-14 color-gray mr-5">或</span>
                                <span class="font-14 color-primary pointer">注册</span>
                            </div>
                            <div class="buttonGroup flex align-center jus-bet" v-else>
                                <template v-if="btnActive === 0">
                                    <a-button class="button" :loading="loading" mode="buy" @click="submitOrder(1)">
                                        开多
                                        <span class="font-12">（看涨）</span>
                                    </a-button>
                                    <a-button class="button" :loading="loading" mode="sell" @click="submitOrder(2)">
                                        开空
                                        <span class="font-12">（看跌）</span>
                                    </a-button>
                                </template>
                                <template v-else>
                                    <a-button class="button" :loading="loading" mode="buy" @click="closePositionByForm(1)">
                                        平多
                                    </a-button>
                                    <a-button class="button" :loading="loading" mode="sell" @click="closePositionByForm(2)">
                                        平空
                                    </a-button>
                                </template>
                            </div>

                            <!--  -->
                            <div class="_row flex align-center jus-bet">
                                <template v-if="btnActive === 0">
                                    <div class="flex align-center">
                                        <span class="font-12 color-gray">可开多</span>
                                        <a-tooltip position="bottom" background-color="var(--tooltip-bg)">
                                            <icon-question-circle-fill class="color-gray pointer mr-5"/>
                                            <template #content>
                                                <span class="font-12 color-always-white">不考虑手续费时，您的可用余额在当前杠杆水平下的理论最大可买数量或对应的价值</span>
                                            </template>
                                        </a-tooltip>
                                        <span class="font-12 color-black">{{getTopAmount(1,current['new_price'])}} {{amounType === 0 ? current['symbol']:'U'}}</span>
                                    </div>
                                    <div class="flex align-center">
                                        <span class="font-12 color-gray">可开空</span>
                                        <a-tooltip position="bottom" background-color="var(--tooltip-bg)">
                                            <icon-question-circle-fill class="color-gray pointer mr-5"/>
                                            <template #content>
                                                <span class="font-12 color-always-white">不考虑手续费时，您的可用余额在当前杠杆水平下的理论最大可卖数量或对应的价值</span>
                                            </template>
                                        </a-tooltip>
                                        <span class="font-12 color-black">{{getTopAmount(2,current['new_price'])}} {{amounType === 0 ? current['symbol']:'U'}}</span>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="flex align-center">
                                        <span class="font-12 color-gray">可平多</span>
                                        <a-tooltip position="bottom" background-color="var(--tooltip-bg)">
                                            <icon-question-circle-fill class="color-gray pointer mr-5"/>
                                            <template #content>
                                                <span class="font-12 color-always-white">已有仓位中的累加数量</span>
                                            </template>
                                        </a-tooltip>
                                        <span class="font-12 color-black">{{$util.thousandSeparator(getPositionAmount(1),8)}} {{current['symbol']}}</span>
                                    </div>
                                    <div class="flex align-center">
                                        <span class="font-12 color-gray">可平空</span>
                                        <a-tooltip position="bottom" background-color="var(--tooltip-bg)">
                                            <icon-question-circle-fill class="color-gray pointer mr-5"/>
                                            <template #content>
                                                <span class="font-12 color-always-white">已有仓位中的累加数量</span>
                                            </template>
                                        </a-tooltip>
                                        <span class="font-12 color-black">{{$util.thousandSeparator(getPositionAmount(2),8)}} {{current['symbol']}}</span>
                                    </div>
                                </template>
                            </div>
                            <!--  -->
                            <div class="_row flex align-center jus-bet">
                                <template v-if="btnActive===0">
                                    <div class="flex align-center">
                                        <span class="font-12 color-gray mr-5">保证金</span>
                                        <span class="font-12 color-black">{{$util.thousandSeparator(getMarginAmount(current['new_price']),2)}} USDT</span>
                                    </div>
                                    <div class="flex align-center">
                                        <span class="font-12 color-gray mr-5">保证金</span>
                                        <span class="font-12 color-black">{{$util.thousandSeparator(getMarginAmount(current['new_price']),2)}} USDT</span>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="flex align-center">
                                        <span class="font-12 color-gray mr-5">预估盈亏</span>
                                        <span class="font-12 color-black">{{$util.thousandSeparator(getStopProfit(amount)['long'],2)}} USDT</span>
                                    </div>
                                    <div class="flex align-center">
                                        <span class="font-12 color-gray mr-5">预估盈亏</span>
                                        <span class="font-12 color-black">{{$util.thousandSeparator(getStopProfit(amount)['short'],2)}} USDT</span>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="content_main_right_bottom">
                        <div class="_hd _bottom_tb flex align-center jus-bet">
                            <span class="font-14 color-black">合约信息</span>
                            <span class="font-12 color-primary pointer" >合约数据</span>
<!--                            @click="toDetail"-->
                        </div>
                        <div class="btm_cl flex align-center jus-bet">
                            <span class="font-12 color-gray">合约代号</span>
                            <span class="font-14 color-black">{{current['symbol']}}USDT</span>
                        </div>
                        <div class="btm_cl flex align-center jus-bet">
                            <span class="font-12 color-gray">交割日期</span>
                            <span class="font-14 color-black">永续</span>
                        </div>
                        <div class="btm_cl flex align-center jus-bet">
                            <span class="font-12 color-gray">合约标的</span>
                            <span class="font-14 color-black">{{current['symbol']}}USDT 指数</span>
                        </div>
                        <div class="btm_cl flex align-center jus-bet">
                            <span class="font-12 color-gray">保证金币种</span>
                            <span class="font-14 color-black">USDT</span>
                        </div>
                        <div class="btm_cl flex align-center jus-bet">
                            <span class="font-12 color-gray">合约面值</span>
                            <span class="font-14 color-black">{{$util.float(current['face_value'])}} {{current['symbol']}}</span>
                        </div>
                        <div class="btm_cl flex align-center jus-bet">
                            <span class="font-12 color-gray">最小价格变动单位</span>
                            <span class="font-14 color-black">0.01 USDT</span>
                        </div>
                        <div class="btm_cl flex align-center jus-bet">
                            <span class="font-12 color-gray">最低维持保证金率</span>
                            <span class="font-14 color-black">1.00 %</span>
                        </div>
                        <div class="btm_cl flex align-center jus-bet">
                            <span class="font-12 color-gray">结算周期</span>
                            <span class="font-14 color-black">实时</span>
                        </div>
                    </div>
                </div>
            </div>
        </a-layout-content>
        <a-layout-footer class="layoutFooter">
        </a-layout-footer>

        <!--      币种介绍-->
        <a-modal :closable="false"
                 align-center
                 width="960px"
                 modalClass="modal_main dark"
                 :mask-style="{'background':'rgba(0, 0, 0, .5)'}"
                 :hide-title="true"
                 :footer="false"
                 :visible="aboutCoin">
            <div class="modal_main flex flex-row align-center jus-bet">
                <!--          close-->
                <div class="close" @click="aboutCoin=false">
                    <icon-close class="color-black pointer" size="24"/>
                </div>
                <!--          -->
                <div class="modal_main_left flex flex-column">
                    <p class="font-20 font-bold color-black">币种介绍</p>
                    <p class="flex align-center font-20 font-bold color-black">
                        <img class="mr-5" :src="current['icon']">
                        {{ current['symbol'] }}/USDT
                    </p>
                    <!--            排名-->
                    <p class="font-14 color-gray flex align-center jus-bet">排名
                        <span class="color-black">No.{{currencyInfo['marketSort']}}</span>
                    </p>
                    <!--            市值-->
                    <p class="font-14 color-gray flex align-center jus-bet">市值
                        <span class="color-black">{{currencyInfo['marketValue']}}</span>
                    </p>
                    <!--            市场占有率-->
                    <p type="line" class="font-14 color-gray flex align-center jus-bet">市场占有率
                        <span class="color-black">{{currencyInfo['marketShare']}}</span>
                    </p>
                    <!--            流通量-->
                    <p class="font-14 color-gray flex align-center jus-bet">流通量
                        <span class="color-black">{{currencyInfo['Circulation']}} {{ current['symbol'] }}</span>
                    </p>
                    <!--            最大供应量-->
                    <p class="font-14 color-gray flex align-center jus-bet">最大供应量
                        <span class="color-black">{{currencyInfo['totalCurrency']}} {{ current['symbol'] }}</span>
                    </p>
                    <!--            流通率-->
                    <p class="font-14 color-gray flex align-center jus-bet">流通率
                        <span class="color-black">{{currencyInfo['CirculationRate']}} {{ current['symbol'] }}</span>
                    </p>
                    <!--            发行日期-->
                    <p class="font-14 color-gray flex align-center jus-bet">发行日期
                        <span class="color-black">{{currencyInfo['issueDate']}}</span>
                    </p>
                    <!--            发行价格-->
                    <p type="line" class="font-14 color-gray flex align-center jus-bet">发行价格
                        <span class="color-black">{{currencyInfo['IssuePrice']}}</span>
                    </p>
                    <!--            历史最高价-->
                    <p class="font-14 color-gray flex align-center jus-bet">历史最高价
                        <span class="color-black">{{currencyInfo['hightPrice']}}</span>
                    </p>
                    <!--            历史最低价-->
                    <p class="font-14 color-gray flex align-center jus-bet">历史最低价
                        <span class="color-black">{{currencyInfo['IssuePrice']}}</span>
                    </p>
                </div>
                <div class="modal_main_right flex flex-column">
                    <p class="font-20 font-bold color-black">关于{{ current['symbol'] }}</p>
                    <p class="font-14 color-black">
                        {{ current['symbol'] }} -
                        简介详情
                    </p>
                    <a-scrollbar type="embed" class="scroll">
                        {{currencyInfo['about']}}
                    </a-scrollbar>
                    <p class="font-20 font-bold color-black">官方链接</p>
                    <div class="flex align-center">
                        <a href="" class="font-14 color-primary pad-lr-10">官网</a>
                        <a href="" class="font-14 color-primary pad-lr-10">Github</a>
                        <a href="" class="font-14 color-primary pad-lr-10">区块浏览器</a>
                        <a href="" class="font-14 color-primary pad-lr-10">白皮书</a>
                    </div>
                    <p class="font-20 font-bold color-black">社交媒体</p>
                    <div class="flex align-center">
                        <img class="img-small mr-12 pointer" src="@/assets/images/instagram.png">
                        <img class="img-small mr-12 pointer" src="@/assets/images/tw.png">
                        <img class="img-small mr-12 pointer" src="@/assets/images/facebook.png">
                    </div>
                </div>
            </div>
        </a-modal>
        <!--      保证金模式 -->
        <a-modal :visible="marginModel"
                 align-center
                 :render-to-body="false"
                 :mask-style="{'background':'rgba(0, 0, 0, .5)'}"
                 modal-class="popupWarp"
                 width="360px"
                 :hide-title="true"
                 :mask-closable="true"
                 :footer="false">
            <div class="popupBox">
                <div class="popupBox-title">
                    <span class="title">{{current['symbol']}}USDT保证金模式</span>
                    <icon-close @click="marginModel = false" size="24"/>
                </div>
                <div class="popupBox-content">
                    <a-radio-group type="radio" @change="positionChange" size="large" class="radioGroup">
                        <a-radio class="radioLabel" :model-value="all" :value="0">
                            <span class="color-black">全仓</span>
                        </a-radio>
                        <a-radio class="radioLabel" :model-value="signal" :value="1">
                            <span class="color-black">逐仓</span>
                        </a-radio>
                    </a-radio-group>
                    <p class="desc color-gray">全仓保证金模式:
                        所有仓位共用合约账户中的保证金来避免仓位被强平。在强平事件中，交易者可能会损失所有的保证金和仓位。</p>
                    <div class="buttonGroup">
                        <a-button class="button" @click="marginModel = false">
                            <span>取消</span>
                        </a-button>
                        <a-button class="button" @click="confirmPositionChange">
                            <span>确定</span>
                        </a-button>
                    </div>
                </div>
            </div>
        </a-modal>
        <!--      杠杆 -->
        <a-modal :visible="leverArm"
                 align-center
                 :render-to-body="false"
                 :mask-style="{'background':'rgba(0, 0, 0, .5)'}"
                 modal-class="popupWarp"
                 width="370px"
                 :hide-title="true"
                 :mask-closable="true"
                 :footer="false">
            <div class="popupBox">
                <div class="popupBox-title">
                    <span class="title">{{current['symbol']}}USDT 合约杠杆</span>
                    <icon-close @click="leverArm = false" size="24"/>
                </div>
                <div class="popupBox-content">
                    <div class="inputBox">
                        <span>杠杆倍数</span>
                        <a-input-number mode="embed" v-model="leverVal" :max="current['max_lever']" :min="current['min_lever']" :hide-button="false"
                                        placeholder="1"
                                        class="leverInput">
                            <template #plus>
                                <icon-plus/>
                            </template>
                            <template #minus>
                                <icon-minus/>
                            </template>
                        </a-input-number>
                    </div>
                    <div class="sliderBox">
                        <a-slider class="slider" v-model="leverVal" :default-value="1" :min="1" :max="125"
                                  :marks="leverSlider"/>
                        <p v-if="leverVal == 1">当前持仓最高支持杠杆{{current['max_lever']}}X</p>
                    </div>
                    <ul class="tips">
                        <li class="tipsItem">
                            <span>当前杠杆倍数最高可持有头寸：{{$util.thousandSeparator(current['max_amount'],current['decimals'])}}{{current['symbol']}}</span>
                        </li>
                        <li class="tipsItem">
                            <span>请注意杠杆变化适用于未结头寸和未结订单</span>
                        </li>
                        <li class="tipsItem">
                            <span>选择超过【 100X 】杠杆交易会增加强行平仓风险</span>
                        </li>
                    </ul>
                    <div class="buttonGroup">
                        <a-button class="button" @click="leverArm = false">
                            <span>取消</span>
                        </a-button>
                        <a-button class="button" @click="setPositionLever">
                            <span>确定</span>
                        </a-button>
                    </div>
                </div>
            </div>
        </a-modal>
        <!--      计算器 -->
        <a-modal :visible="calculator"
                 align-center
                 :render-to-body="false"
                 :mask-style="{'background':'rgba(0, 0, 0, .5)'}"
                 modal-class="popupWarp"
                 width="672px"
                 :hide-title="true"
                 :mask-closable="true"
                 :footer="false">
            <view class="popupBox">
                <div class="popupBox-title">
                    <span class="title">计算器</span>
                    <icon-close @click="calculator = false" size="24"/>
                </div>
                <div class="popupBox-content">
                    <div class="tabControl">
                        <div class="tabItem"
                             v-for="(tItem,index) in tabOption" :key="index"
                             :class="tabIndex === index ? 'tabItem-active' : ''"
                             @click="tabIndex = index">
                            <span class="color-always-white">{{ tItem }}</span>
                        </div>
                    </div>
                    <div class="tabContent">
                        <div class="tabContent-item">
                            <div class="shapedButton">
                                <div class="button" :class="buying === 1 ? 'buyActive' : ''" @click="buying = 1">
                                    <span class="color-always-white">买入做多</span>
                                </div>
                                <div class="button" :class="buying === 2 ? 'sellActive' : ''" @click="buying = 2">
                                    <span class="color-always-white">卖出做多</span>
                                </div>
                            </div>
                            <div class="inputBox">
                                <span>杠杆倍数</span>
                                <a-input-number mode="embed"
                                                v-model="cleverVal"
                                                :max="current['max_lever']"
                                                :min="1"
                                                :hide-button="false"
                                                placeholder="0"
                                                class="leverInput">
                                    <template #plus>
                                        <icon-plus/>
                                    </template>
                                    <template #minus>
                                        <icon-minus/>
                                    </template>
                                </a-input-number>
                            </div>
                            <div class="sliderBox">
                                <a-slider class="slider" v-model="cleverVal" :default-value="1" :min="1" :max="current['max_lever']"
                                          :marks="leverSlider"/>
                                <p> <span class="color-orange">当前杠杆最高可持有头寸数{{$util.float(current['max_amount'])}} {{current['symbol']}}</span></p>
                            </div>
                            <div class="inputBox">
                                <span>开仓价格</span>
                                <a-input class="input" v-model="cprice" placeholder="">
                                    <template #append>
                                        USDT
                                    </template>
                                </a-input>
                            </div>
                            <div v-if="tabIndex === 0">
                                <div class="inputBox">
                                    <span>平仓价格</span>
                                    <a-input class="input" v-model="cwprice" placeholder="">
                                        <template #append>
                                            USDT
                                        </template>
                                    </a-input>
                                </div>
                                <div class="inputBox">
                                    <span>数量</span>
                                    <a-input class="input" v-model="camount" placeholder="">
                                        <template #append>
                                            {{current['symbol']}}
                                        </template>
                                    </a-input>
                                </div>
                            </div>

                            <div v-if="tabIndex === 1">
                                <div class="inputBox">
                                    <span>回报率</span>
                                    <a-input class="input" placeholder="">
                                        <template #append>
                                            %
                                        </template>
                                    </a-input>
                                </div>
                            </div>
                            <div v-if="tabIndex === 2">
                                <div class="inputBox">
                                    <span>仓位数量</span>
                                    <a-input class="input" placeholder="">
                                        <template #append>
                                            BTC
                                        </template>
                                    </a-input>
                                </div>
                                <div class="inputBox">
                                    <span>保证金数量</span>
                                    <a-input class="input" placeholder="">
                                        <template #append>
                                            USDT
                                        </template>
                                    </a-input>
                                </div>
                            </div>
                            <div class="buttonGroup">
                                <a-button class="button" @click="leverArm = false">
                                    <span>计算</span>
                                </a-button>
                            </div>
                        </div>
                        <div class="tabContent-item">
                            <div class="rowCell title">
                                <span>计算结果</span>
                            </div>
                            <div v-if="tabIndex === 0">
                                <div class="rowCell">
                                    <span>开仓保证金</span>
                                    <span>{{camount !== '' && cprice  !== '' && cwprice  !== '' ? $util.thousandSeparator(current['new_price'] * camount / cleverVal):'--'}} USDT</span>
                                </div>
                                <div class="rowCell">
                                    <span>预计收益</span>
                                    <span>
                                        {{camount !== '' && cprice  !== '' && cwprice  !== '' ? $util.thousandSeparator((buying === 1 ? cwprice - cprice : cprice - cwprice) * camount) : '--'}}
                                    </span>
                                </div>
                                <div class="rowCell">
                                    <span>收益率</span>
                                    <span>
                                        {{camount !== '' && cprice  !== '' && cwprice  !== '' ? $util.thousandSeparator(((buying === 1 ? cwprice - cprice : cprice - cwprice) * camount) / (current['new_price'] * camount / cleverVal) * 100,2):'--' }}%
                                    </span>
                                </div>
                            </div>
                            <div class="rowCell" v-if="tabIndex === 1">
                                <span>平仓价格</span>
                                <span>
                                    {{camount !== '' && cprice  !== '' && cwprice  !== '' ? $util.thousandSeparator((buying === 1 ? cprice + (current['new_price'] * camount / cleverVal) / camount : cprice - (current['new_price'] * camount / cleverVal) / camount)):'--'}} USDT
                                </span>
                            </div>
                            <div class="rowCell" v-if="tabIndex === 2">
                                <span>强平价格</span>
                                <span>
                                    {{camount !== '' && cprice  !== '' && cwprice  !== '' ? $util.thousandSeparator((buying === 1 ? parseFloat(cprice) + parseFloat((current['new_price'] * camount / cleverVal) / camount) : parseFloat(cprice) - (current['new_price'] * camount / cleverVal) / camount)):'--'}} USDT
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </view>
        </a-modal>
        <!--    资金划转-->
        <funds-transfe :visible="transfeShow" @close="transfeShow=false" @success="getBalance"></funds-transfe>
        <!--    下单-->
        <a-modal :visible="placeOrder"
                 align-center
                 :render-to-body="false"
                 :mask-style="{'background':'rgba(0, 0, 0, .5)'}"
                 modal-class="popupWarp"
                 width="400px"
                 :hide-title="true"
                 :mask-closable="true"
                 :footer="false">
            <div class="popupBox-title">
                <span class="title" v-if="modeIndex===1">市价下单</span>
                <span class="title" v-if="modeIndex===0">限价下单</span>
                <span class="title" v-if="modeIndex===2">条件下单</span>
                <icon-close @click="placeOrder = false" size="20"/>
            </div>
            <div class="popupBox-content">
                <div class="itemCell">
                    <a-tag color="var(--opacity-sell)">
                        <span class="color-red">{{submitDirection === 1 ? '多':'空'}}</span>
                    </a-tag>
                    <span class="title color-black">{{current['symbol']}}USDT</span>
                    <a-tag color="var(--opacity-gray)">
                        <span class="color-black">{{positionModel === 0 ? '全仓':'逐仓'}}{{leverVal}}X</span>
                    </a-tag>
                </div>
                <div class="itemCell">
                    <span class="color-gray">{{$util.getDateTimeStr()}}</span>
                </div>
                <div class="itemCell">
                    <span class="color-gray">价格</span>
                    <span class="color-black">{{price === '' ? '市价':$util.thousandSeparator(price,current['decimals'])}}</span>
                </div>
                <div class="itemCell">
                    <span class="color-gray">数量</span>
                    <span class="color-black">{{$util.thousandSeparator(amount)}} {{amounType === 0 ? current['symbol']:'USDT'}}</span>
                </div>
                <div class="itemCell">
                    <span class="color-gray">保证金</span>
                    <span class="color-black">{{getMarginAmount(current['new_price'])}} USDT</span>
                </div>
                <div class="itemCell">
                    <a-checkbox v-model="checked">
                        <span class="color-gray">下次不要提示，您可在(交易偏好设置)中重新设置</span>
                    </a-checkbox>
                </div>
                <div class="buttonGroup">
                    <a-button class="button" @click="placeOrder = false;loading=false">
                        <span>取消</span>
                    </a-button>
                    <a-button class="button" @click="submitOrder(submitDirection,false)">
                        <span>确定</span>
                    </a-button>
                </div>
            </div>
        </a-modal>
        <!--    平仓-->
        <a-modal :visible="closePotion"
                 align-center
                 :render-to-body="false"
                 :mask-style="{'background':'rgba(0, 0, 0, .5)'}"
                 modal-class="popupWarp"
                 width="400px"
                 :hide-title="true"
                 :mask-closable="true"
                 :footer="false">
            <div class="popupBox-title">
                <span class="title">平仓</span>
                <icon-close @click="closePotion = false" size="20"/>
            </div>
            <div class="popupBox-content" v-if="pindex !== false && tabPanelIndex === 1">
                <div class="itemCell">
                    <a-tag color="var(--opacity-sell)">
                        <span class="color-red" v-if="parseInt(position.position[pindex]['direction']) === 2">空</span>
                        <span class="color-green" v-if="parseInt(position.position[pindex]['direction']) === 1">多</span>
                    </a-tag>
                    <span class="title color-black">{{position.position[pindex]['symbol']}}USDT</span>
                    <a-tag color="var(--opacity-gray)">
                        <span class="color-black">
                            {{position.position[pindex]['margin_type'] === 1 ? '全仓':'逐仓'}}{{position.position[pindex]['muilt']}}X
                        </span>
                    </a-tag>
                </div>
                <div class="tabControl closePotionTabs">
                    <div class="tabItem"
                         v-for="(tItem,index) in closePotionTab" :key="index"
                         :class="tabIndex === index ? 'tabItem-active' : ''"
                         @click="tabIndex = index">
                        <span class="color-black">{{ tItem }}</span>
                    </div>
                </div>
                <div class="inputBox">
                    <span>平仓价格</span>
                    <a-input class="input" :disabled="tabIndex === 1" v-model="pprice" placeholder="">
                        <template #append>
                            USDT
                        </template>
                    </a-input>
                </div>
                <div class="inputBox">
                    <span>平仓数量</span>
                    <a-input class="input" v-model="pamount" placeholder="">
                        <template #append>
                            {{current['symbol']}}
                        </template>
                    </a-input>
                </div>
                <div class="sliderBox">
                    <a-slider class="slider" @change="psliderChange" :default-value="0" :min="0" :max="100"
                              :marks="marks"/>
                </div>
                <div class="itemCell">
                    <span class="color-gray">可平仓位</span>
                    <span class="color-black">
                        {{$util.thousandSeparator(position.position[pindex]['amount'] - position.position[pindex]['close'],8)}} {{current['symbol']}}
                    </span>
                </div>
                <div class="itemCell">
                    <span class="color-gray">预计盈亏</span>
                    <span class="color-red">{{getPositionPamount(current['new_price'])}} <span class="color-black">USDT</span></span>
                </div>
                <div class="buttonGroup">
                    <a-button class="button" @click="closePotion = false">
                        <span>取消</span>
                    </a-button>
                    <a-button class="button" :loading="loading" @click="closePositions">
                        <span>确定</span>
                    </a-button>
                </div>
            </div>
        </a-modal>
        <!--    闪电平仓-->
        <a-modal :visible="flashClose"
                 align-center
                 :render-to-body="false"
                 :mask-style="{'background':'rgba(0, 0, 0, .5)'}"
                 modal-class="popupWarp"
                 width="400px"
                 :hide-title="true"
                 :mask-closable="true"
                 :footer="false">
            <div class="popupBox-title">
                <span class="title">闪电平仓</span>
                <icon-close @click="flashClose = false" size="20"/>
            </div>
            <div class="popupBox-content" v-if="pindex !== false && tabPanelIndex === 1">
                <div class="itemCell">
                    <a-tag color="var(--opacity-sell)">
                        <span class="color-red" v-if="parseInt(position.position[pindex]['direction']) === 2">空</span>
                        <span class="color-green" v-if="parseInt(position.position[pindex]['direction']) === 1">多</span>
                    </a-tag>
                    <span class="title color-black">{{position.position[pindex]['symbol']}}USDT</span>
                    <a-tag color="var(--opacity-gray)">
                        <span class="color-black">
                            {{position.position[pindex]['margin_type'] === 1 ? '全仓':'逐仓'}}{{position.position[pindex]['muilt']}}X
                        </span>
                    </a-tag>
                </div>
                <div class="itemCell">
                    <span class="color-gray">价格</span>
                    <span class="color-black">最优市价</span>
                </div>
                <div class="itemCell">
                    <span class="color-gray">数量</span>
                    <span class="color-black">全部仓位
                        <span class="color-primary">{{$util.thousandSeparator(position.position[pindex]['amount'] - position.position[pindex]['close'],8)}}</span> {{current['symbol']}}</span>
                </div>
                <div class="buttonGroup">
                    <a-button class="button" @click="flashClose = false">
                        <span>取消</span>
                    </a-button>
                    <a-button class="button" :loading="loading" @click="lightClose">
                        <span>确定</span>
                    </a-button>
                </div>
            </div>
        </a-modal>
        <!--    止盈止损-->
        <a-modal :visible="profitLossPop"
                 align-center
                 :render-to-body="false"
                 :mask-style="{'background':'rgba(0, 0, 0, .5)'}"
                 modal-class="popupWarp"
                 width="840px"
                 :hide-title="true"
                 :mask-closable="true"
                 :footer="false">
            <div class="popupBox-title">
                <span class="title">止盈止损</span>
                <icon-close @click="profitLossPop = false" size="24"/>
            </div>
            <div class="popupBox-content" v-if="pindex !== false && tabPanelIndex === 1">
                <div class="itemCell">
                    <a-tag color="var(--opacity-sell)">
                        <span class="color-red" v-if="parseInt(position.position[pindex]['direction']) === 2">空</span>
                        <span class="color-green" v-if="parseInt(position.position[pindex]['direction']) === 1">多</span>
                    </a-tag>
                    <span class="title color-black">{{position.position[pindex]['symbol']}}USDT</span>
                    <a-tag color="var(--opacity-gray)">
                        <span class="color-black">
                            {{position.position[pindex]['margin_type'] === 1 ? '全仓':'逐仓'}}{{position.position[pindex]['muilt']}}X
                        </span>
                    </a-tag>
                </div>
                <div class="columnPirce">
                    <div class="itemCol">
                        <span class="color-gray">持仓均价</span>
                        <span class="color-black">{{$util.thousandSeparator(position.position[pindex]['price'],2)}}</span>
                    </div>
                    <div class="itemCol">
                        <span class="color-gray">最新价格</span>
                        <span class="color-red">{{$util.thousandSeparator(current['new_price'],2)}}</span>
                    </div>
                    <div class="itemCol">
                        <span class="color-gray">预估强平价格</span>
                        <span class="color-black">{{$util.thousandSeparator(position.position[pindex]['stopPrice'],2)}}</span>
                    </div>
                </div>
                <div class="profit_loss">
                    <div class="item">
                        <div class="inputBox">
                            <div class="inputLeft">
                                <span class="color-green mr-5">止盈</span>
                                <span class="mr-5 dotted">触发价</span>
                                <a-tooltip type="subtip" position="top" background-color="var(--tooltip-bg)">
                                    <icon-question-circle-fill size="16" class="color-gray"/>
                                    <template #content>
                                        <p class="font-12 color-always-white">
                                            当最新市价≤此价格时触发
                                        </p>
                                    </template>
                                </a-tooltip>
                            </div>
                            <a-input class="input" v-model="wpwprice" placeholder="">
                                <template #append>
                                    USDT
                                </template>
                            </a-input>
                        </div>
                        <div class="inputGroup">
                            <div class="inputBox">
                                <div class="inputLeft">
                                    <span class="mr-5 dotted">委托价</span>
                                    <a-tooltip type="subtip" position="top" background-color="var(--tooltip-bg)">
                                        <icon-question-circle-fill size="16" class="color-gray"/>
                                        <template #content>
                                            <p class="font-12 color-always-white">
                                                触发止盈时以此价格平仓
                                            </p>
                                        </template>
                                    </a-tooltip>
                                </div>
                                <a-input class="input" v-model="wpprice" :disabled="wptype===0" :placeholder="wptype === 0 ?'最优市价':''">
                                    <template #append>
                                        USDT
                                    </template>
                                </a-input>
                            </div>
                            <a-dropdown type="modeList" :popup-max-height="false">
                                <div class="selectBox">
                                    <span class="color-black">{{wptype === 0 ? '市价':'限价'}}</span>
                                    <icon-caret-down size="14" class="color-black"/>
                                </div>
                                <template #content>
                                    <a-doption @click="wptype=0">市价</a-doption>
                                    <a-doption @click="wptype=1">限价</a-doption>
                                </template>
                            </a-dropdown>
                        </div>
                        <div class="inputBox">
                            <div class="inputLeft">
                                <span class="mr-5">数量</span>
                            </div>
                            <a-input class="input" v-model="wamount" placeholder="">
                                <template #append>
                                    {{current['symbol']}}
                                </template>
                            </a-input>
                        </div>
                        <span class="color-gray tipText">
                            持仓量/ 可平仓量 <span
                                class="color-orange">{{position.position[pindex]['amount']}} / {{$util.thousandSeparator(position.position[pindex]['amount'] - position.position[pindex]['close'],8)}} {{current['symbol']}}
                        </span>
                        </span>
                        <div class="sliderBox mt-5">
                            <a-slider class="slider" @change="lsliderChange" :default-value="0" :min="0" :max="100"
                                      :marks="marks"/>
                        </div>
                        <div class="buttonBox">
                            <a-button class="button" @click="profitLossPop = false">
                                <span>取消</span>
                            </a-button>
                        </div>
                    </div>
                    <div class="item">
                        <div class="inputBox">
                            <div class="inputLeft">
                                <span class="color-red mr-5">止损</span>
                                <span class="mr-5 dotted">触发价</span>
                                <a-tooltip type="subtip" position="top" background-color="var(--tooltip-bg)">
                                    <icon-question-circle-fill size="16" class="color-gray"/>
                                    <template #content>
                                        <p class="font-12 color-always-white">
                                            当最新市价≤此价格时触发
                                        </p>
                                    </template>
                                </a-tooltip>
                            </div>
                            <a-input class="input" v-model="lwprice" placeholder="">
                                <template #append>
                                    USDT
                                </template>
                            </a-input>
                        </div>
                        <div class="inputGroup">
                            <div class="inputBox">
                                <div class="inputLeft">
                                    <span class="mr-5 dotted">委托价</span>
                                    <a-tooltip type="subtip" position="top" background-color="var(--tooltip-bg)">
                                        <icon-question-circle-fill size="16" class="color-gray"/>
                                        <template #content>
                                            <p class="font-12 color-always-white">
                                                触发止盈时以此价格平仓
                                            </p>
                                        </template>
                                    </a-tooltip>
                                </div>
                                <a-input class="input" v-model="lprice" :disabled="lptype===0" :placeholder="lptype===0?'最优市价':''">
                                    <template #append>
                                        USDT
                                    </template>
                                </a-input>
                            </div>
                            <a-dropdown type="modeList" :popup-max-height="false">
                                <div class="selectBox">
                                    <span class="color-black">{{lptype===0?'市价':'限价'}}</span>
                                    <icon-caret-down size="14" class="color-black"/>
                                </div>
                                <template #content>
                                    <a-doption @click="lptype=0">市价</a-doption>
                                    <a-doption @click="lptype=1">限价</a-doption>
                                </template>
                            </a-dropdown>
                        </div>
                        <div class="inputBox">
                            <div class="inputLeft">
                                <span class="mr-5">数量</span>
                            </div>
                            <a-input class="input" v-model="lamount" placeholder="">
                                <template #append>
                                    {{current['symbol']}}
                                </template>
                            </a-input>
                        </div>
                        <span class="color-gray tipText">
                            持仓量/ 可平仓量 <span
                                class="color-orange">{{position.position[pindex]['amount']}} / {{$util.thousandSeparator(position.position[pindex]['amount'] - position.position[pindex]['close'],8)}} {{current['symbol']}}
                        </span>
                        </span>
                        <div class="sliderBox mt-5">
                            <a-slider class="slider" @change="llstopChange" :default-value="0" :min="0" :max="100"
                                      :marks="marks"/>
                        </div>
                        <div class="buttonBox">
                            <a-button class="button" :loading="loading" @click="wlProfitSet">
                                <span>确定</span>
                            </a-button>
                        </div>
                    </div>
                </div>
            </div>
        </a-modal>
        <!--    撤单-->
        <a-modal :visible="wdOrder"
                 align-center
                 :render-to-body="false"
                 :mask-style="{'background':'rgba(0, 0, 0, .5)'}"
                 modal-class="popupWarp"
                 width="400px"
                 :hide-title="true"
                 :mask-closable="true"
                 :footer="false">
            <div class="popupBox-content">
                <div class="imageBox">
                    <img src="@/assets/images/wd_order_img.png" alt="">
                </div>
                <span class="wdContext">确定撤销该订单？</span>
                <div class="buttonGroup">
                    <a-button class="button" @click="wdOrder = false">
                        <span>取消</span>
                    </a-button>
                    <a-button class="button" :loading="loading" @click="cancelOrder">
                        <span>确定</span>
                    </a-button>
                </div>
            </div>
        </a-modal>
        <!--    订单详情-->
        <a-modal :visible="orderInfoPopup"
                 align-center
                 :render-to-body="false"
                 :mask-style="{'background':'rgba(0, 0, 0, .5)'}"
                 modal-class="popupWarp"
                 width="488px"
                 :hide-title="true"
                 :mask-closable="true"
                 :footer="false">
            <div class="popupBox-title">
                <span class="title">订单详情</span>
                <icon-close @click="orderInfoPopup = false" size="20"/>
            </div>
            <div class="popupBox-content" v-if="tabPanelIndex===4 && pindex !== false">
                <div class="itemCell oderItemCell">
                    <div class="itemCell_Left">
                        <a-tag color="var(--opacity-sell)">
                            <span class="color-red" v-if="history.history['list'][pindex].type === 1">开多</span>
                            <span class="color-red" v-if="history.history['list'][pindex].type === 2">开空</span>
                            <span class="color-red" v-if="history.history['list'][pindex].type === 3">平多</span>
                            <span class="color-red" v-if="history.history['list'][pindex].type === 4">平空</span>
                            <span class="color-red" v-if="history.history['list'][pindex].type === 5">止盈</span>
                            <span class="color-red" v-if="history.history['list'][pindex].type === 6">止损</span>
                        </a-tag>
                        <span class="title color-black">{{history.history['list'][pindex].symbol}}USDT</span>
                        <a-tag color="var(--opacity-gray)">
                            <span class="color-black">永续</span>
                        </a-tag>
                    </div>
                    <span class="statusText color-black" v-if="history.history['list'][pindex].status===2">完全成交</span>
                    <span class="statusText color-black" v-if="history.history['list'][pindex].status===1">待成交</span>
                    <span class="statusText color-black" v-if="history.history['list'][pindex].status===3">已撤单</span>
                </div>
                <div class="itemCell">
                    <div class="itemCell_Left">
                        <span class="color-gray mr-10">{{history.history['list'][pindex].created_at}}</span>
                        <a-tag color="var(--opacity-gray)">
                            <span class="color-black" v-if="history.history['list'][pindex].ttype===1">市价单</span>
                            <span class="color-black" v-if="history.history['list'][pindex].ttype===2">限价单</span>
                            <span class="color-black" v-if="history.history['list'][pindex].ttype===3">止盈单</span>
                        </a-tag>
                    </div>
                </div>
                <div class="itemCell">
                    <span class="color-gray mr-10">成交均价/委托价格</span>
                    <span class="color-black">{{history.history['list'][pindex].status === 2 ? $util.thousandSeparator(history.history['list'][pindex].t_price):'0.00'}}/{{$util.float(history.history['list'][pindex].price)}}</span>
                </div>
                <div class="itemCell">
                    <span class="color-gray mr-10">成交数量/委托数量(USDT)</span>
                    <span class="color-black">{{history.history['list'][pindex].status === 2 ? $util.thousandSeparator(history.history['list'][pindex].amount):'0.00'}}/{{$util.float(history.history['list'][pindex].amount)}}</span>
                </div>
                <div class="itemCell">
                    <span class="color-gray mr-10">手续费(USDT)</span>
                    <span class="color-black">{{$util.float(history.history['list'][pindex].charge)}}</span>
                </div>
                <div class="itemCell">
                    <span class="color-gray mr-10">盈亏(USDT)</span>
                    <span class="color-black">{{history.history['list'][pindex].status === 2 ? $util.thousandSeparator(history.history['list'][pindex].profit):'0.00'}}</span>
                </div>
                <div class="itemCell">
                    <span class="color-gray mr-10">订单ID</span>
                    <div class="itemCell_Right">
                        <span class="mr-5 color-black">{{history.history['list'][pindex].id}}</span>
                        <icon-copy size="20" class="color-gray"/>
                    </div>
                </div>
                <div class="cardBox" v-if="history.history['list'][pindex].status === 2">
                    <div class="itemCell">
                        <span class="title color-black">成交记录</span>
                    </div>
                    <div class="itemCell">
                        <span class="color-gray">成交均价</span>
                        <span class="color-black">{{history.history['list'][pindex].status === 2 ? $util.thousandSeparator(history.history['list'][pindex].t_price) : '--'}}</span>
                    </div>
                    <div class="itemCell" v-if="history.history['list'][pindex].type <= 2">
                        <span class="color-gray">开仓价格</span>
                        <span class="color-black">{{$util.thousandSeparator(history.history['list'][pindex].price)}}</span>
                    </div>
                    <div class="itemCell">
                        <span class="color-gray">成交数量({{history.history['list'][pindex].symbol}})</span>
                        <span class="color-black">{{$util.float(history.history['list'][pindex].amount)}}</span>
                    </div>
                    <div class="itemCell">
                        <span class="color-gray">手续费(USDT)/类型</span>
                        <span class="color-black">{{$util.thousandSeparator(history.history['list'][pindex].charge)}}/taker</span>
                    </div>
                    <div class="itemCell">
                        <span class="color-gray">盈亏(USDT)</span>
                        <span class="color-black">{{$util.thousandSeparator(history.history['list'][pindex].profit)}}</span>
                    </div>
                    <div class="itemCell">
                        <span class="color-gray">成交时间</span>
                        <span class="color-black">{{history.history['list'][pindex].created_at}}</span>
                    </div>
                </div>
            </div>
        </a-modal>
        <!--    设置-->
        <a-drawer :width="340"
                  :footer="false"
                  :header="false"
                  :drawer-style="{background: 'var(--background-primary)'}"
                  :visible="settingDrawer"
                  @ok="settingDrawer = false"
                  @cancel="settingDrawer = false"
                  unmountOnClose>
            <div class="settingDrawer">
                <div class="titleBox">
                    <span>设置</span>
                    <icon-close class="color-black cursor" size="20" @click="settingDrawer = false"/>
                </div>
                <div class="label">
                    <p class="color-gray mb-5">持仓类型</p>
                    <p class="color-primary mb-5">
                        <icon-exclamation-polygon-fill/>
                        持有任何合约仓位/有挂单时不可更改持仓类型
                    </p>
                    <a-radio-group class="radioGroup">
                        <a-radio value="1">
                            <template #radio="{ checked }">
                                <div class="radioItem">
                                    <div class="radio" :class="{'radio-checked': checked}"></div>
                                    <div class="info">
                                        <p>单向持仓</p>
                                        <p class="desc">单向持仓模式下，一个合约只允许持有一个方向的仓位</p>
                                    </div>
                                </div>
                            </template>
                        </a-radio>
                        <a-radio value="2">
                            <template #radio="{ checked }">
                                <div class="radioItem">
                                    <div class="radio" :class="{'radio-checked': checked}"></div>
                                    <div class="info">
                                        <p>双向持仓</p>
                                        <p class="desc">
                                            双向持仓模式下，一个合约可允许同时持有多空两个方向的仓位，并且同一合约下不同方向仓位风险对冲</p>
                                    </div>
                                </div>
                            </template>
                        </a-radio>
                    </a-radio-group>
                </div>
                <div class="label">
                    <p class="color-gray title dotted mb-5">
                        条件单/止盈止损有效期
                        <a-tooltip position="top" background-color="var(--tooltip-bg)">
                            <icon-question-circle-fill class="color-gray cursor" size="14"/>
                            <template #content>
                                <p class="font-12 color-always-white">
                                    温馨提示：当持仓结束时，该合约下仅有平仓计划委托的，则系统将撤销该合约下的计划委托
                                </p>
                            </template>
                        </a-tooltip>
                    </p>
                    <a-radio-group class="radioGroup" mode="row">
                        <a-radio value="1">
                            <template #radio="{ checked }">
                                <div class="radioItem">
                                    <div class="radio" :class="{'radio-checked': checked}"></div>
                                    <div class="info">
                                        <p>24H</p>
                                    </div>
                                </div>
                            </template>
                        </a-radio>
                        <a-radio value="2">
                            <template #radio="{ checked }">
                                <div class="radioItem">
                                    <div class="radio" :class="{'radio-checked': checked}"></div>
                                    <div class="info">
                                        <p>7天</p>
                                    </div>
                                </div>
                            </template>
                        </a-radio>
                        <a-radio value="3">
                            <template #radio="{ checked }">
                                <div class="radioItem">
                                    <div class="radio" :class="{'radio-checked': checked}"></div>
                                    <div class="info">
                                        <p>14天</p>
                                    </div>
                                </div>
                            </template>
                        </a-radio>
                        <a-radio value="4">
                            <template #radio="{ checked }">
                                <div class="radioItem">
                                    <div class="radio" :class="{'radio-checked': checked}"></div>
                                    <div class="info">
                                        <p>30天</p>
                                    </div>
                                </div>
                            </template>
                        </a-radio>
                    </a-radio-group>
                </div>
                <div class="label">
                    <p class="color-gray title mb-5">
                        下单设置
                    </p>
                    <div class="flex align-center jus-bet">
                        <span>下单二次确认</span>
                        <a-switch/>
                    </div>
                </div>
            </div>
        </a-drawer>
    </a-layout>
</template>
<script>
import service from './service';

export default service;
</script>

<style lang="scss" scoped>
@import "./futures.scss";
</style>