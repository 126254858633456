<!-- 绑定邮箱 -->
<template>
    <div class="main-box">
        <div class="content-main">
            <div class="top-info">
                <a-button class="back-btn" @click="goBack">
                    <template #icon>
                        <icon-left/>
                    </template>
                </a-button>
                <span>绑定邮箱</span>
            </div>
            <div class="recommendInvite-content">
                <div class="recommendInvite-content-box">
                    <div class="title"><span>邮箱</span></div>
                    <a-input class="input" placeholder="请输入邮箱" v-model="email" allow-clear long/>
                </div>
                <div class="recommendInvite-content-box">
                    <div class="title"><span>验证码</span></div>
                    <a-input class="input" placeholder="请输入验证码" v-model="code" allow-clear long>
                        <template #suffix>
                            <span>获取验证码</span>
                        </template>
                    </a-input>
                </div>
                <div class="recommendInvite-content-box">
                    <a-button class="confirm"
                              :disabled="email == '' || code == ''"
                              @click="handleOk">
                        确定
                    </a-button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    components: {},
    name: 'bindEmail',
    data() {
        return {
            email: '',
            code: ''
        };
    },
    methods: {
        goBack() {
            this.$emit("safet-event");
        },

    },
};
</script>
<style lang="scss" scoped>
@import "./bindEmail.scss";
</style>
  