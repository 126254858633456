<template>
  <a-layout class="futuresData">
    <a-layout-header>
      <PageNav></PageNav>
    </a-layout-header>
    <a-layout-content>
      <div class="content-box">
        <!--        title-->
        <div class="flex align-center">
          <div class="flex align-center jus-center back_box mr-12">
            <img src="@/assets/pic-back.png">
          </div>
          <span class="font-20 color-asset-title">合约数据</span>
        </div>
        <!-- tab/con  -->
        <div class="tab_main">
          <a-tabs default-active-key="1">
            <a-tab-pane key="1" title="永续合约保险基金">
              <div class="flex align-center">
                <div class="flex flex-column align-start">
                  <span class="font-14 color-gray mb-5">保险基金币种</span>
                  <a-dropdown :popup-max-height="false">
                    <div class="btn flex align-center jus-center pointer">
                      <span class="font-14 color-white">USDT</span>
                      <icon-caret-down class="color-gray"/>
                    </div>
                    <template #content>
                      <a-doption>USDT</a-doption>
                    </template>
                  </a-dropdown>
                </div>
              </div>
              <!--            kline-->
              <div class="kline"></div>
              <div class="flex align-center jus-end mt-10">
                <span class="font-14 color-blue pointer">了解保险基金</span>
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" title="资金费率">
              <div class="flex align-center">
                <div class="flex flex-column align-start mr-20">
                  <span class="font-14 color-gray mb-5">合约类型</span>
                  <a-dropdown :popup-max-height="false">
                    <div class="btn flex align-center jus-center pointer">
                      <span class="font-14 color-white">USDT合约</span>
                      <icon-caret-down class="color-gray"/>
                    </div>
                    <template #content>
                      <a-doption>USDT合约</a-doption>
                      <a-doption>币本位合约</a-doption>
                    </template>
                  </a-dropdown>
                </div>
                <div class="flex flex-column align-start mr-20">
                  <span class="font-14 color-gray mb-5">合约</span>
                  <a-dropdown :popup-max-height="true">
                    <div class="btn flex align-center jus-center pointer">
                      <span class="font-14 color-white">USDT合约</span>
                      <icon-caret-down class="color-gray"/>
                    </div>
                    <template #content>
                      <a-doption>BTCUSDT</a-doption>
                      <a-doption>ETHUSDT</a-doption>
                      <a-doption>BCHUSDT</a-doption>
                      <a-doption>TRXUSDT</a-doption>
                      <a-doption>EOSUSDT</a-doption>
                      <a-doption>XRPUSDT</a-doption>
                      <a-doption>ETCUSDT</a-doption>
                      <a-doption>LTCUSDT</a-doption>
                      <a-doption>LINKUSDT</a-doption>
                      <a-doption>ADAUSDT</a-doption>
                      <a-doption>UNIUSDT</a-doption>
                      <a-doption>FILUSDT</a-doption>
                      <a-doption>DOTUUSDT</a-doption>
                      <a-doption>COMPUSDT</a-doption>
                      <a-doption>AAVEUSDT</a-doption>
                      <a-doption>YFIUSDT</a-doption>
                      <a-doption>QTUMUSDT</a-doption>
                      <a-doption>DOGEUSDT</a-doption>
                      <a-doption>SOLUSDT</a-doption>
                      <a-doption>BNBUSDT</a-doption>
                      <a-doption>LDOUSDT</a-doption>
                    </template>
                  </a-dropdown>
                </div>
              </div>
              <div class="kline"></div>
              <div class="flex align-center jus-end mt-10">
                <span class="font-14 color-blue pointer">了解资金费率</span>
              </div>
            </a-tab-pane>
            <a-tab-pane key="3" title="指数价格">
              <div class="flex align-center">
                <div class="flex flex-column align-start mr-20">
                  <span class="font-14 color-gray mb-5">合约类型</span>
                  <a-dropdown :popup-max-height="false">
                    <div class="btn flex align-center jus-center pointer">
                      <span class="font-14 color-white">USDT合约</span>
                      <icon-caret-down class="color-gray"/>
                    </div>
                    <template #content>
                      <a-doption>USDT合约</a-doption>
                      <a-doption>币本位合约</a-doption>
                    </template>
                  </a-dropdown>
                </div>
                <div class="flex flex-column align-start mr-20">
                  <span class="font-14 color-gray mb-5">合约</span>
                  <a-dropdown :popup-max-height="true">
                    <div class="btn flex align-center jus-center pointer">
                      <span class="font-14 color-white">USDT合约</span>
                      <icon-caret-down class="color-gray"/>
                    </div>
                    <template #content>
                      <a-doption>BTCUSDT</a-doption>
                      <a-doption>ETHUSDT</a-doption>
                      <a-doption>BCHUSDT</a-doption>
                      <a-doption>TRXUSDT</a-doption>
                      <a-doption>EOSUSDT</a-doption>
                      <a-doption>XRPUSDT</a-doption>
                      <a-doption>ETCUSDT</a-doption>
                      <a-doption>LTCUSDT</a-doption>
                      <a-doption>LINKUSDT</a-doption>
                      <a-doption>ADAUSDT</a-doption>
                      <a-doption>UNIUSDT</a-doption>
                      <a-doption>FILUSDT</a-doption>
                      <a-doption>DOTUUSDT</a-doption>
                      <a-doption>COMPUSDT</a-doption>
                      <a-doption>AAVEUSDT</a-doption>
                      <a-doption>YFIUSDT</a-doption>
                      <a-doption>QTUMUSDT</a-doption>
                      <a-doption>DOGEUSDT</a-doption>
                      <a-doption>SOLUSDT</a-doption>
                      <a-doption>BNBUSDT</a-doption>
                      <a-doption>LDOUSDT</a-doption>
                    </template>
                  </a-dropdown>
                </div>
              </div>
              <div class="kline"></div>
              <div class="flex align-center jus-end mt-10">
                <span class="font-14 color-blue pointer">了解指数价格</span>
              </div>
            </a-tab-pane>
            <a-tab-pane key="4" title="标记价格">
              <div class="flex align-center">
                <div class="flex flex-column align-start mr-20">
                  <span class="font-14 color-gray mb-5">合约类型</span>
                  <a-dropdown :popup-max-height="false">
                    <div class="btn flex align-center jus-center pointer">
                      <span class="font-14 color-white">USDT合约</span>
                      <icon-caret-down class="color-gray"/>
                    </div>
                    <template #content>
                      <a-doption>USDT合约</a-doption>
                      <a-doption>币本位合约</a-doption>
                    </template>
                  </a-dropdown>
                </div>
                <div class="flex flex-column align-start mr-20">
                  <span class="font-14 color-gray mb-5">合约</span>
                  <a-dropdown :popup-max-height="true">
                    <div class="btn flex align-center jus-center pointer">
                      <span class="font-14 color-white">USDT合约</span>
                      <icon-caret-down class="color-gray"/>
                    </div>
                    <template #content>
                      <a-doption>BTCUSDT</a-doption>
                      <a-doption>ETHUSDT</a-doption>
                      <a-doption>BCHUSDT</a-doption>
                      <a-doption>TRXUSDT</a-doption>
                      <a-doption>EOSUSDT</a-doption>
                      <a-doption>XRPUSDT</a-doption>
                      <a-doption>ETCUSDT</a-doption>
                      <a-doption>LTCUSDT</a-doption>
                      <a-doption>LINKUSDT</a-doption>
                      <a-doption>ADAUSDT</a-doption>
                      <a-doption>UNIUSDT</a-doption>
                      <a-doption>FILUSDT</a-doption>
                      <a-doption>DOTUUSDT</a-doption>
                      <a-doption>COMPUSDT</a-doption>
                      <a-doption>AAVEUSDT</a-doption>
                      <a-doption>YFIUSDT</a-doption>
                      <a-doption>QTUMUSDT</a-doption>
                      <a-doption>DOGEUSDT</a-doption>
                      <a-doption>SOLUSDT</a-doption>
                      <a-doption>BNBUSDT</a-doption>
                      <a-doption>LDOUSDT</a-doption>
                    </template>
                  </a-dropdown>
                </div>
              </div>
              <div class="kline"></div>
              <div class="flex align-center jus-end mt-10">
                <span class="font-14 color-blue pointer">了解标记价格</span>
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
    </a-layout-content>
    <a-layout-footer>
      <Footer></Footer>
    </a-layout-footer>
  </a-layout>
</template>
<script>
import {defineComponent} from "vue";
import Footer from "@/components/Footer/footer.vue";
import PageNav from "@/components/PageNav/PageNav.vue";

export default defineComponent({
  components: {PageNav, Footer}
})
</script>
<style lang="scss" scoped>
@import "./futuresData.scss";
</style>