<!-- 偏好设置 -->
<template>
    <div class="setting">
        <div class="content-main">
            <div class="top-info">
                <a-button class="back-btn" @click="goBack">
                    <template #icon>
                        <icon-left/>
                    </template>
                </a-button>
                <span>偏好设置</span>
            </div>
            <div class="recommendInvite-content">
                <div class="recommendInvite-content-box">
                    <div class="title"><span>语言</span></div>
                    <a-select defaultValue="简体中文"
                              class="input"
                              size="large" long>
                        <a-option>
                            简体中文
                        </a-option>
                        <a-option>
                            English
                        </a-option>
                    </a-select>
                </div>
                <div class="recommendInvite-content-box">
                    <div class="title"><span>货币</span></div>
                    <a-select defaultValue="CNY"
                              class="input"
                              size="large" long>
                        <a-option>
                            CNY
                        </a-option>
                        <a-option>
                            USD
                        </a-option>
                    </a-select>
                </div>
                <div class="recommendInvite-content-box">
                    <a-button class="confirm"
                              :disabled="email == '' || code == ''"
                              @click="handleOk">
                        确定
                    </a-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {};
    },
    methods: {},
};
</script>
<style lang="scss" scoped>
@import "./userSettings.scss";
</style>
  