<template>
    <div class="main-box">
        <p class="title">资金流水</p>
        <a-tabs default-active-key="1" @change="tabChange">
            <a-tab-pane title="资金" key="1">
                <div class="select-group">
                    <div class="select-label">
                        <p>时间</p>
                        <a-range-picker
                            :render-to-body="false"
                            popup-container="body"
                            :defaultValue="['2023-01-01 00:00:00']"
                            v-model="timeValue"
                            class="time-button"
                            :allow-clear="false"
                            @change="onChange"
                            @select="onSelect"
                        />
                    </div>
                    <div class="select-label">
                        <p>流水类型</p>
                        <a-dropdown @select="handleSelect">
                            <a-button class="label-button">
                                充值
                                <icon-caret-down/>
                            </a-button>
                            <template #content>
                                <a-doption v-for="(item,index) in genre">{{ item }}</a-doption>
                            </template>
                        </a-dropdown>
                    </div>
                    <div class="select-label">
                        <p>币种</p>
                        <a-dropdown @select="handleSelect">
                            <a-button class="label-button">
                                全部币种
                                <icon-caret-down/>
                            </a-button>
                            <template #content>
                                <a-doption v-for="(item,index) in coinList">{{ item }}</a-doption>
                            </template>
                        </a-dropdown>
                    </div>
                    <div class="select-label">
                        <p>状态</p>
                        <a-dropdown @select="handleSelect">
                            <a-button class="label-button">
                                全部状态
                                <icon-caret-down/>
                            </a-button>
                            <template #content>
                                <a-doption v-for="(item,index) in stateOption">{{ item }}</a-doption>
                            </template>
                        </a-dropdown>
                    </div>
                    <div class="select-label">
                        <p>操作</p>
                        <a-button class="button" @click="reset">
                            重置
                        </a-button>
                    </div>
                </div>

                <div class="list" type="zj">
                    <div class="top">
                        <div class="cell">
                            <div class="item">
                                <span>时间</span>
                            </div>
                            <div class="item">
                                <span>币种</span>
                            </div>
                            <div class="item">
                                <span>数量</span>
                            </div>
<!--                            <div class="item">-->
<!--                                <span>确认次数</span>-->
<!--                            </div>-->
                            <div class="item">
                                <span>类型</span>
                            </div>
                            <div class="item">
                                <span>操作</span>
                            </div>
                        </div>
                        <div class="cell" v-for="(item,index) in logList" :key="'b'+item.id">
                            <div class="item">
                                <span>{{item.created_at}}</span>
                            </div>
                            <div class="item coin">
                                <img :src="item.currency.icon" alt="">
                                <span>{{item.currency.symbol}}</span>
                            </div>
                            <div class="item">
                                <span>{{$util.float(item.amount)}}</span>
                            </div>
<!--                            <div class="item">-->
<!--                                <span>6/6</span>-->
<!--                            </div>-->
                            <div class="item">
                                <span>{{types[item.type]}}</span>
                            </div>
                            <div class="item">
                                <span @click="modalShow = false">详情</span>
                            </div>
                        </div>
                    </div>
                    <div class="btm">
                        <a-pagination @change="paginate" :total="total" :page-size="size" show-total show-jumper/>
                    </div>
                </div>
            </a-tab-pane>
            <a-tab-pane title="现货" key="2">
                <div class="select-group">
                    <div class="select-label">
                        <p>时间</p>
                        <a-range-picker
                            :render-to-body="false"
                            popup-container="body"
                            :defaultValue="timeValue"
                            class="time-button"
                            :allow-clear="false"
                            @change="onChange"
                            @select="onSelect"
                        />
                    </div>
                    <div class="select-label">
                        <p>流水类型</p>
                        <a-dropdown @select="handleSelect">
                            <a-button class="label-button">
                                充值
                                <icon-caret-down/>
                            </a-button>
                            <template #content>
                                <a-doption v-for="(item,index) in genre">{{ item }}</a-doption>
                            </template>
                        </a-dropdown>
                    </div>
                    <div class="select-label">
                        <p>币种</p>
                        <a-dropdown @select="handleSelect">
                            <a-button class="label-button">
                                全部币种
                                <icon-caret-down/>
                            </a-button>
                            <template #content>
                                <a-doption v-for="(item,index) in coinList">{{ item }}</a-doption>
                            </template>
                        </a-dropdown>
                    </div>
                </div>

                <div class="list" type="xh">
                    <div class="top">
                        <div class="cell">
                            <div class="item">
                                <span>时间</span>
                            </div>
                            <div class="item">
                                <span>币种</span>
                            </div>
                            <div class="item">
                                <span>类型</span>
                            </div>
                            <div class="item">
                                <span>数量</span>
                            </div>
                            <div class="item">
                                <span>状态</span>
                            </div>
                        </div>
                        <div class="cell" v-for="(item,index) in logList" :key="'t'+item.id">
                            <div class="item">
                                <span>{{item.created_at}}</span>
                            </div>
                            <div class="item coin">
                                <img :src="item.currency.icon" alt="">
                                <span>{{item.currency.symbol}}</span>
                            </div>
                            <div class="item">
                                <span>{{types[item.type]}}</span>
                            </div>
                            <div class="item">
                                <span>{{$util.float(item.amount)}}</span>
                            </div>
                            <div class="item">
                                <span>{{item.amount > 0 ? '收入':'支出'}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="btm">
                        <a-pagination @change="paginate" :total="total" :page-size="size" show-total show-jumper/>
                    </div>
                </div>
            </a-tab-pane>
            <a-tab-pane title="合约" key="3">
                <div class="select-group">
                    <div class="select-label">
                        <p>时间</p>
                        <a-range-picker
                            :render-to-body="false"
                            popup-container="body"
                            :defaultValue="timeValue"
                            v-model="timeValue"
                            class="time-button"
                            :allow-clear="false"
                            @change="onChange"
                            @select="onSelect"
                        />
                    </div>
                    <div class="select-label">
                        <p>流水类型</p>
                        <a-dropdown @select="handleSelect">
                            <a-button class="label-button">
                                充值
                                <icon-caret-down/>
                            </a-button>
                            <template #content>
                                <a-doption v-for="(item,index) in genre">{{ item }}</a-doption>
                            </template>
                        </a-dropdown>
                    </div>
                    <div class="select-label">
                        <p>币种</p>
                        <a-dropdown @select="handleSelect">
                            <a-button class="label-button">
                                全部币种
                                <icon-caret-down/>
                            </a-button>
                            <template #content>
                                <a-doption v-for="(item,index) in coinList">{{ item }}</a-doption>
                            </template>
                        </a-dropdown>
                    </div>
                </div>

                <div class="list" type="xh">
                    <div class="top">
                        <div class="cell">
                            <div class="item">
                                <span>时间</span>
                            </div>
                            <div class="item">
                                <span>类型</span>
                            </div>
                            <div class="item">
                                <span>金额</span>
                            </div>
                            <div class="item">
                                <span>状态</span>
                            </div>
                            <div class="item">
                                <span>币种</span>
                            </div>
                        </div>
                        <div class="cell" v-for="(item,index) in logList" :key="'c'+item.id">
                            <div class="item">
                                <span>{{item.created_at}}</span>
                            </div>
                            <div class="item">
                                <span>{{types[item.type]}}</span>
                            </div>
                            <div class="item">
                                <span>{{item.amount}}</span>
                            </div>
                            <div class="item">
                                <span>{{item.amount > 0 ? '收入':'支出'}}</span>
                            </div>
                            <div class="item coin">
                                <img :src="item.currency.icon" alt="">
                                <span>{{item.currency.symbol}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="btm">
                        <a-pagination @change="paginate" :total="total" :page-size="15" show-total show-jumper/>
                    </div>
                </div>
            </a-tab-pane>
            <a-tab-pane title="期权" key="4">
                <div class="select-group">
                    <div class="select-label">
                        <p>时间</p>
                        <a-range-picker
                            :render-to-body="false"
                            popup-container="body"
                            :defaultValue="timeValue"
                            v-model="timeValue"
                            class="time-button"
                            :allow-clear="false"
                            @change="onChange"
                            @select="onSelect"
                        />
                    </div>
                    <div class="select-label">
                        <p>流水类型</p>
                        <a-dropdown @select="handleSelect">
                            <a-button class="label-button">
                                充值
                                <icon-caret-down/>
                            </a-button>
                            <template #content>
                                <a-doption v-for="(item,index) in genre">{{ item }}</a-doption>
                            </template>
                        </a-dropdown>
                    </div>
                    <div class="select-label">
                        <p>币种</p>
                        <a-dropdown @select="handleSelect">
                            <a-button class="label-button">
                                全部币种
                                <icon-caret-down/>
                            </a-button>
                            <template #content>
                                <a-doption v-for="(item,index) in coinList">{{ item }}</a-doption>
                            </template>
                        </a-dropdown>
                    </div>
                </div>

                <div class="list" type="xh">
                    <div class="top">
                        <div class="cell">
                            <div class="item">
                                <span>时间</span>
                            </div>
                            <div class="item">
                                <span>类型</span>
                            </div>
                            <div class="item">
                                <span>金额</span>
                            </div>
                            <div class="item">
                                <span>状态</span>
                            </div>
                            <div class="item">
                                <span>币种</span>
                            </div>
                        </div>
                        <div class="cell" v-for="(item,index) in logList" :key="'f'+item.id">
                            <div class="item">
                                <span>{{item.created_at}}</span>
                            </div>
                            <div class="item">
                                <span>{{types[item.type]}}</span>
                            </div>
                            <div class="item">
                                <span>{{$util.float(item.amount)}}</span>
                            </div>
                            <div class="item">
                                <span>{{item.amount > 0 ? '收入':'支出'}}</span>
                            </div>
                            <div class="item coin">
                                <img :src="item.currency.icon" alt="">
                                <span>{{item.currency.symbol}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="btm">
                        <a-pagination @change="paginate" :total="total" :page-size="15" show-total show-jumper/>
                    </div>
                </div>
            </a-tab-pane>
            <a-tab-pane title="理财" key="5">
                <div class="select-group">
                    <div class="select-label">
                        <p>时间</p>
                        <a-range-picker
                            :render-to-body="false"
                            popup-container="body"
                            :defaultValue="timeValue"
                            v-model="timeValue"
                            class="time-button"
                            :allow-clear="false"
                            @change="onChange"
                            @select="onSelect"
                        />
                    </div>
                    <div class="select-label">
                        <p>流水类型</p>
                        <a-dropdown @select="handleSelect">
                            <a-button class="label-button">
                                充值
                                <icon-caret-down/>
                            </a-button>
                            <template #content>
                                <a-doption v-for="(item,index) in genre">{{ item }}</a-doption>
                            </template>
                        </a-dropdown>
                    </div>
                    <div class="select-label">
                        <p>币种</p>
                        <a-dropdown @select="handleSelect">
                            <a-button class="label-button">
                                全部币种
                                <icon-caret-down/>
                            </a-button>
                            <template #content>
                                <a-doption v-for="(item,index) in coinList">{{ item }}</a-doption>
                            </template>
                        </a-dropdown>
                    </div>
                </div>

                <div class="list" type="xh">
                    <div class="top">
                        <div class="cell">
                            <div class="item">
                                <span>时间</span>
                            </div>
                            <div class="item">
                                <span>币种</span>
                            </div>
                            <div class="item">
                                <span>类型</span>
                            </div>
                            <div class="item">
                                <span>数量</span>
                            </div>
                            <div class="item">
                                <span>状态</span>
                            </div>
                        </div>
                        <div class="cell" v-for="(item,index) in logList" :key="'i'+item.id">
                            <div class="item">
                                <span>{{item.created_at}}</span>
                            </div>
                            <div class="item coin">
                                <img :src="item.currency.icon" alt="">
                                <span>{{item.currency.symbol}}</span>
                            </div>
                            <div class="item">
                                <span>{{types[item.type]}}</span>
                            </div>
                            <div class="item">
                                <span>{{item.amount}}</span>
                            </div>
                            <div class="item">
                                <span>已完成</span>
                            </div>
                        </div>
                    </div>
                    <div class="btm">
                        <a-pagination :total="total" :page-size="15" @change="paginate" show-total show-jumper/>
                    </div>
                </div>
            </a-tab-pane>
        </a-tabs>
        <a-modal :visible="modalShow"
                 :closable="false"
                 :hide-title="false"
                 :footer="false"
                 width="450px">
            <template #title>
                <div class="model-title">
                    <span>资金详情</span>
                    <icon-close @click="modalShow = false" />
                </div>
            </template>
            <div class="model-content">
                <div class="coin">
                    <img src="@/assets/images/coin/usdt.png" alt="">
                    <span>USDT</span>
                </div>
                <div class="cell">
                    <span>充值时间</span>
                    <span>2023-09-14 14:15:01</span>
                </div>
                <div class="cell">
                    <span>充值数量</span>
                    <span>21.059222</span>
                </div>
                <div class="cell">
                    <span>确认次数</span>
                    <span>6/6</span>
                </div>
                <div class="cell">
                    <span>状态</span>
                    <span>充值成功</span>
                </div>
                <div class="cell">
                    <span>钱包处理时间</span>
                    <span>2023-09-14 14:50:01</span>
                </div>
                <div class="column">
                    <span>充值地址</span>
                    <span>TJTE6HJZtYVGYPaAGQhqAFDBRSpH54r5Pj</span>
                </div>
                <div class="column">
                    <span>区块链交易ID</span>
                    <span>d6ca082cca9dfcccffd864908504c5f0b305037bfb89daf7efc853c81e82c04a</span>
                </div>
            </div>
        </a-modal>
    </div>
</template>

<style lang="scss" scoped>
@import "capitalFlow";
</style>
<script>
import service from './service';
export default service;
</script>