<template>
  <a-layout class="download">
    <a-layout-header>
      <PageNav></PageNav>
    </a-layout-header>
    <a-layout-content>
      <div class="content-main flex jus-center">
        <div class="block_box">
          <div class="top_left flex flex-column align-start">
            <p class="font-32 font-bold color-white">随时随地 多平台终端交易</p>
            <p class="font-22 font-bold color-white">下载ComeBit客户端，所有交易尽在手中。</p>
            <div class="flex align-center download-mode">
              <div class="flex flex-column">
                <div class="btn flex align-center jus-center">
                  <img src="@/assets/ios.png">
                  <span class="font-14 font-bold color-white">Iphone IPA</span>
                </div>
                <div class="btn flex align-center jus-center">
                  <img src="@/assets/android.png">
                  <span class="font-14 font-bold color-white">Android APK</span>
                </div>
                <div class="btn flex align-center jus-center">
                  <img src="@/assets/google.png">
                  <span class="font-14 font-bold color-white">Google Play</span>
                </div>
              </div>
              <div class="flex flex-column align-center jus-center qrcode_wrap">
                <div class="qrcode flex align-center jus-center">
                  <img src="@/assets/qrcode.png">
                </div>
                <span>扫描二维码下载</span>
              </div>
            </div>
          </div>
          <div class="pro-group">
            <div class="pro-item" v-for="(proItem, index) in proGroup" :key="index" @mouseenter="mouseEnter(index)"
                 :class="{ active: index == isActive }">
              <span>{{ proItem.title }}</span>
              <p>{{ proItem.context }}</p>
              <img :src="proItem.imgSrc" alt="">
            </div>
          </div>
          <div class="card-box">
            <span class="title">iOS 用户安装教程</span>
            <div class="cell flex align-center jus-bet">
              <div class="flex flex-column align-start">
                <span class="font-14 color-white font-bold">1.下载APP</span>
                <span class="font-14 color-gray mt-10">App下载成功后，在手机中打开设置功能，选择“通用”；</span>
              </div>
              <img src="@/assets/images/download/ios-0.png">
            </div>
            <div class="cell flex align-center jus-bet">
              <div class="flex flex-column align-start">
                <span class="font-14 color-white font-bold">2.设备管理</span>
                <span class="font-14 color-gray mt-10">在通用中，选择“VPN与设备管理”；</span>
              </div>
              <img src="@/assets/images/download/ios-1.png">
            </div>
            <div class="cell flex align-center jus-bet">
              <div class="flex flex-column align-start">
                <span class="font-14 color-white font-bold">3.选择安装</span>
                <span
                    class="font-14 color-gray mt-10">在“VPN与设备管理”中的企业级应用分类中，选择要安装的企业应用的文件名称（与打开时的提示一致），点击进入；</span>
              </div>
              <img src="@/assets/images/download/ios-2.png">
            </div>
            <div class="cell flex align-center jus-bet">
              <div class="flex flex-column align-start">
                <span class="font-14 color-white font-bold">4.设置信任</span>
                <span
                    class="font-14 color-gray mt-10">进入企业签名页面后，确认企业签名中的公司名称与应用名称后，点击“信任”；</span>
              </div>
              <img src="@/assets/images/download/ios-3.png">
            </div>
            <div class="cell flex align-center jus-bet">
              <div class="flex flex-column align-start">
                <span class="font-14 color-white font-bold">5.启动APP</span>
                <span class="font-14 color-gray mt-10">返回桌面，重新打开应用即可使用。</span>
              </div>
              <img src="@/assets/images/download/ios-4.png">
            </div>
          </div>
          <div class="card-box">
            <span class="title">Android用户安装教程</span>
            <div class="cell flex align-center jus-bet">
              <div class="flex flex-column align-start">
                <span class="font-14 color-white font-bold">问题一：手机自带浏览器做出风险提示，不能正常下载。</span>
                <span class="font-14 color-gray mt-10">解决方法：使用Google Chrome浏览器下载安装。</span>
              </div>
              <img src="@/assets/images/download/android-0.png">
            </div>
            <div class="cell flex align-center jus-bet">
              <div class="flex flex-column align-start">
                <span class="font-14 color-white font-bold">1.安装Google Chrome浏览器。</span>
                <span
                    class="font-14 color-gray mt-10">用手机自带浏览器搜索Google Chrome浏览器，点击官方资源下载，下载完成后安装。</span>
              </div>
              <img src="@/assets/images/download/android-1.png">
            </div>
            <div class="cell flex align-center jus-bet">
              <div class="flex flex-column align-start">
                <span class="font-14 color-white font-bold">2.打开Google Chrome浏览器访问ComeBit官网：</span>
                <span
                    class="font-14 color-blue mt-10 pointer">https://www.bbtrade.net/</span>
              </div>
              <img src="@/assets/images/download/android-2.png">
            </div>
            <div class="cell flex align-center jus-bet">
              <div class="flex flex-column align-start">
                <span class="font-14 color-white font-bold">3.进入ComeBit主页，点击【下载】按钮进行下载。</span>
              </div>
              <img src="@/assets/images/download/android-3.png">
            </div>
            <div class="cell flex align-center jus-bet">
              <div class="flex flex-column align-start">
                <span class="font-14 color-white font-bold">4.点击谷歌右上角的设置按钮查看历史下载内容。</span>
              </div>
              <img src="@/assets/images/download/android-4.png">
            </div>
            <div class="cell flex align-center jus-bet">
              <div class="flex flex-column align-start">
                <span class="font-14 color-white font-bold">5.在“下载内容”里，找到安装包并点击安装。</span>
              </div>
              <img src="@/assets/images/download/android-5.png">
            </div>
            <div class="cell flex align-center jus-bet">
              <div class="flex flex-column align-start">
                <span class="font-14 color-white font-bold">问题二：在安装过程中显示“发现恶意应用”或“安全提醒”。</span>

                <span
                    class="font-14 color-gray mt-10">解决方法：下载安装包后请关闭网络进行安装，安装完成后开启网络即可正常使用。</span>
              </div>
              <img src="@/assets/images/download/android-6.png">
            </div>
          </div>
        </div>
      </div>
    </a-layout-content>
    <a-layout-footer>
      <Footer></Footer>
    </a-layout-footer>
  </a-layout>
</template>
<script>
import PageNav from "@/components/PageNav/PageNav.vue";
import Footer from "@/components/Footer/footer.vue";

export default {
  components: {Footer, PageNav},
  data() {
    return {
      proGroup: [{
        title: "闪电交易性能",
        context: "提供多样交易类型选择，订单极速撮合，一键闪兑，买卖更便捷",
        imgSrc: require('@/assets/images/product.png')
      }, {
        title: "资产轻松划转",
        context: "轻松划转账户内资产，实时的数字资产概览，增加币种占比饼图",
        imgSrc: require('@/assets/images/product5.png')
      }, {
        title: "自定义交易面板",
        context: "根据不同交易习惯自由定义交易布局，增加K线与深度图入口",
        imgSrc: require('@/assets/images/product6.png')
      }, {
        title: "账户集成管理",
        context: "多重安全验证设置，一个账户管理法币账户与币币账户",
        imgSrc: require('@/assets/images/product7.png')
      }],
      isActive: 0
    }
  },
  methods: {
    mouseEnter(index) {
      console.log(index);
      this.isActive = index;
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./appDownload.scss";
</style>