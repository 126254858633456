<template>
    <a-layout class="newHome">
        <a-layout-header>
            <PageNav></PageNav>
        </a-layout-header>
        <a-layout-content>
            <!--    banner_swiper  -->
            <div class="banner">
                <swiper class="bannerSwiper"
                        :space-between="20"
                        :modules="modules"
                        :autoplay="{delay:5000}"
                        :navigation="{
                          nextEl: '.banner-next',
                          prevEl: '.banner-prev'
                        }"
                        :loop="true"
                        slides-per-view="1">
                    <swiper-slide class="swiperSlide" v-for="(item,index) in bannerSwiperOption" :key="index">
                        <div class="contentBox" :style="{'background-image': `url(${item.cover})`}">
                            <div class="contentBox-info">
                                <h1 class="title">{{ item.title }}</h1>
                                <p class="context">{{ item.desc }}</p>
                                <a-button class="button">去看看</a-button>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
                <a-button class="banner-button banner-prev" @click="switchSwiper">
                    <icon-left />
                </a-button>
                <a-button class="banner-button banner-next" @click="switchSwiper">
                    <icon-right />
                </a-button>
            </div>
            <!--      notice-->
            <div class="notice">
                <div class="notice-swiper">
                    <swiper class="noticeTrade"
                            :space-between="20"
                            :navigation="{
                              nextEl: '.swiper-next',
                              prevEl: '.swiper-prev'
                            }"
                            :modules="modules"
                            :loop="false"
                            slides-per-view="4">
                        <swiper-slide class="tradeSlide" v-for="(item,index) in swiperGroup" :key="index">
                            <div class="card">
                                <img :src="item.cover" alt="">
                            </div>
                        </swiper-slide>
                    </swiper>
                    <a-button class="swiper-button swiper-prev">
                        <icon-left />
                    </a-button>
                    <a-button class="swiper-button swiper-next">
                        <icon-right />
                    </a-button>
                </div>
                <div class="noticeContent">
                    <div class="contentInfo">
                        <img :src="require(`@/assets/images/home/horn-${userTheme}.svg`)" alt="">
                        <span>{{ noticeGroup[0] ? noticeGroup[0]['name'] : '' }}</span>
                    </div>
                    <icon-right class="color-black cursor" size="16"/>
                </div>
            </div>
            <!--      containerWarp-->
            <div class="containerWarp">
                <div class="hotCoin">
                    <h1 class="title">热门加密货币</h1>
                    <div class="tab">
                        <div class="item"
                             v-for="(item, index) in tabOption"
                             :key="index" @click="switchCurrency(index)">
                            <span>{{ item }}</span>
                        </div>
                    </div>
                    <table class="table">
                        <tr class="tableHead">
                            <th v-for="(item,index) in tableColumns">
                                <span>{{ item }}</span>
                            </th>
                        </tr>
                        <tr class="tableCell" v-for="(item,index) in currencyList" :key="'currency'+item.id">
                            <td>{{ index + 1 }}</td>
                            <td>
                                <img :src="item['icon']" alt="">
                                <span class="color-gray">
                  <strong class="color-black font-16">{{ item.symbol }}</strong>
                  /usdt
                </span>
                            </td>
                            <td>
                <span :class="{'color-red':item.change_rate <= 0,'color-green':item.change_rate > 0}">
                    {{ $util.thousandSeparator(item.new_price, item.decimals) }}
                </span>
                            </td>
                            <td>
                <span :class="{'color-red':item.change_rate <= 0,'color-green':item.change_rate > 0}">
                    +{{ $util.thousandSeparator(item.change_rate, 2) }}%
                </span>
                            </td>
                            <td>
                                <span class="color-black">{{ $util.bigNumberToString(item.quota) }}</span>
                            </td>
                            <td>
                                <span class="color-primary font-bold">交易</span>
                            </td>
                        </tr>
                    </table>
                    <a-button class="seeAll" @click="$router.push('/rank')">查看全部
                        <icon-right/>
                    </a-button>
                    <div class="anmationCoin">
                        <div class="row">
                            <div class="item" v-for="(item,index) in allCurrency" :key="'s1'+item.id">
                                <img :src="item['icon']" alt="">
                                <span class="coin">{{ item['symbol'] }}</span>
                                <div class="info">
                                    <span>{{ $util.thousandSeparator(item.new_price, item.decimals) }}</span>
                                    <span
                                        :class="{'color-red':item.change_rate <= 0,'color-green':item.change_rate > 0}">
                    <icon-caret-down v-if="item.change_rate <= 0"/>
                      <icon-caret-up v-else/>
                      {{ $util.thousandSeparator(item.change_rate, 2) }}%
                  </span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="item" v-for="(item,index) in currencyList" :key="'s2'+item.id">
                                <img :src="item['icon']" alt="">
                                <span class="coin">{{ item['symbol'] }}</span>
                                <div class="info">
                                    <span>{{ $util.thousandSeparator(item.new_price, item.decimals) }}</span>
                                    <span
                                        :class="{'color-red':item.change_rate <= 0,'color-green':item.change_rate > 0}">
                  <icon-caret-down v-if="item.change_rate <= 0"/>
                      <icon-caret-up v-else/>
                      {{ $util.thousandSeparator(item.change_rate, 2) }}%
                  </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="copy">
                    <div class="copyOrder">
                        <div class="info">
                            <h1 class="title">跟单</h1>
                            <span class="contxt">全球最大的加密货币跟单交易平台</span>
                            <div class="column">
                                <div class="item">
                                    <span>{{ IndexConfig['trade_user'] }}</span>
                                    <span>带单专家人数</span>
                                </div>
                                <div class="item">
                                    <span>{{ IndexConfig['copy_user'] }}</span>
                                    <span>跟单人数</span>
                                </div>
                                <div class="item">
                                    <span>{{ IndexConfig['total_p'] }}</span>
                                    <span>已实现盈亏</span>
                                </div>
                            </div>
                            <a-button class="button" @click="$router.push('/copyTraders')">
                                开始跟单
                                <icon-right/>
                            </a-button>
                        </div>
                        <div class="bgImage">
                            <img :src="require(`@/assets/images/home/Copyhomebg_${userTheme}.png`)" alt="">
                        </div>
                    </div>
                    <div class="tradeList">
                        <div class="list">
                            <div class="item" v-for="i in 20">
                                <div class="top">
                                    <div class="imgBox">
                                        <img src="@/assets/images/user-image.png" alt="">
                                    </div>
                                    <div class="usInfo">
                                        <div class="usName">
                                            <span>{{ randName[i] ? randName[i] : 'CBUSER-TB130' + i }}</span>
                                            <!--                      <img src="" alt="">-->
                                        </div>
                                        <div class="tooltip">
                                            <span>@{{ randName[i] ? randName[i] : 'CBUSER-TB130' + i }}</span>
                                            <!--                      <span>-->
                                            <!--                        <icon-user-group/>999/1000-->
                                            <!--                      </span>-->
                                        </div>
                                    </div>
                                </div>
                                <p class="price color-green">
                                    +{{ parseFloat(Math.random(1000, 100000) * 1000).toFixed(2) }}%</p>
                                <div class="cell">
                                    <span class="color-gray">收益率</span>
                                    <span class="color-gray">30天</span>
                                </div>
                                <div class="canvasBox">
                                    <canvas class="canvas"></canvas>
                                </div>
                                <div class="cell">
                                    <span class="color-gray">总收益</span>
                                    <span
                                        class="color-black">${{
                                            parseFloat(Math.random(10000, 100000000) * 100000).toFixed(2)
                                        }}</span>
                                </div>
                                <div class="cell">
                                    <span class="color-gray">跟单者总收益</span>
                                    <span
                                        class="color-black">${{
                                            parseFloat(Math.random(10000, 100000000) * 1000).toFixed(2)
                                        }}</span>
                                </div>
                                <div class="cell">
                                    <span class="color-gray">AUM</span>
                                    <span
                                        class="color-black">${{
                                            parseFloat(Math.random(10000, 100000000) * 10000000).toFixed(2)
                                        }}</span>
                                </div>
                                <div class="cell">
                                    <span class="color-gray">最大回撤</span>
                                    <span
                                        class="color-black">{{
                                            parseFloat(Math.random(10000, 100000000) * 1000).toFixed(2)
                                        }}%</span>
                                </div>
                                <div class="bottom">
                                    <div class="imgGroup">
                                        <image src="https://static-dev.bbbtrade.net/coin/btc.png"></image>
                                        <image src="https://static-dev.bbbtrade.net/coin/eth.png"></image>
                                        <image src="https://static-dev.bbbtrade.net/coin/trx.png"></image>
                                    </div>
                                    <div class="right">
                                        <span><icon-swap/>{{
                                                parseFloat(Math.random(1000, 100000) * 100).toFixed(0)
                                            }}</span>
                                        <span><icon-archive/>{{
                                                parseFloat(Math.random(1000, 100000) * 100).toFixed(2)
                                            }}%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="exchange">
                    <div class="card">
                        <h1 class="title">值得你信赖的平台</h1>
                        <p class="subTitle">安全无小事，为了保护你的资产与信息安全，我们进无止境</p>
                        <a href="" class="boxList">
                            <div class="boxItem">
                                <img src="@/assets/images/home/one_proof_white.png" alt="">
                                <p>储备金证明</p>
                                <span>我们承诺，{{ config['site_name'] }} 以至少1:1的比例持有所有用户资产</span>
                            </div>
                            <div class="boxItem">
                                <img src="@/assets/images/home/two_storage_white.png" alt="">
                                <p>安全冷储存</p>
                                <span>我们将大部分数字资产安全存放于离线的多签钱包中</span>
                            </div>
                            <div class="boxItem">
                                <img src="@/assets/images/home/three_protection_white.png" alt="">
                                <p>储备金证明</p>
                                <span>我们价值3亿USDT的用户保护基金，为潜在的安全风险提供多一层保障</span>
                            </div>
                        </a>
                    </div>
                    <div class="sponsor" @mouseenter="messiHover = false" @mouseleave="messiHover = true">
                        <div class="info">
                            <p>{{ config['site_name'] }}</p>
                            <p>品牌大使</p>
                        </div>
                        <div class="bgImage">
                            <img src="@/assets/images/home/messi-default-new-compress.png" alt="" v-if="messiHover">
                            <img src="@/assets/images/home/messi-hover-new-compress.png" alt="" v-else>
                        </div>
                    </div>
                    <div class="card">
                        <h1 class="title">交易就要随时随地</h1>
                        <p class="subTitle">下载 App，我们7x24小时伴你交易</p>
                        <div class="download">
                            <img class="bgImage" src="@/assets/web-download-zh_CN.png" alt="">
                            <div class="download-right">
                                <div class="codeBox">
                                    <div class="codeImage">
                                        <img :src="config['dpage']" alt="">
                                    </div>
                                    <div class="downInfo">
                                        <p>扫描下载</p>
                                        <p>iOS & Android apps</p>
                                    </div>
                                </div>
                                <div class="middel">
                                    <div class="item" @click="openurl(config['ios_url'])">
                                        <img src="@/assets/appStore.png" alt="">
                                        <p>App Store</p>
                                    </div>
                                    <div class="item" @click="openurl(config['download'])">
                                        <img src="@/assets/googleplay-light.png" alt="">
                                        <p>Google Play</p>
                                    </div>
                                    <div class="item" @click="openurl(config['download'])">
                                        <img src="@/assets/android-light.png" alt="">
                                        <p>APK</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="join">
                    <h1>准备好开启您的加密之旅了吗？</h1>
                    <p>{{ config['site_name'] }} 交易｜交易“智”变</p>
                    <div class="column">
                        <div class="item">
                            <p>100亿 USDT</p>
                            <span>24小时成交量</span>
                        </div>
                        <div class="item">
                            <p>{{ allCurrency.length }}+ </p>
                            <span>加密货币种类</span>
                        </div>
                        <div class="item">
                            <p>2000万</p>
                            <span>注册用户数</span>
                        </div>
                        <div class="item">
                            <p>0.10%</p>
                            <span>最低手续费率</span>
                        </div>
                    </div>
                    <a-button class="button">
                        无忧起航
                    </a-button>
                </div>
            </div>
        </a-layout-content>
        <a-layout-footer>
            <Footer></Footer>
        </a-layout-footer>
    </a-layout>
</template>
<script>
// import { methods } from "@arco-design/web-vue/es/_utils/date";
import service from './service';

export default service;
</script>
<style lang="scss" scoped>
@import "newHome.scss";

.logo_box {
    display: flex;
    align-content: center;
    line-height: 94px;
    padding: 0 60px;
}
</style>