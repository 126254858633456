import userApi from '@/common/api/user';

let page = 1;

export default {
    data() {
        return {
            infoShow: false,
            genre: ['全部', '充值', '提现', '现货划转', '合约划转', '理财划转', '通用划转', '期权划转'],
            stateOption: ['全部状态', '未到账', '已到账', '失效'],
            coinList: ['全部币种', 'USDT', 'BTC', 'ETH', 'LTC', 'BCH', 'BNB', 'LINK'],
            modalShow: false,
            account : 1,
            size:15,
            start_at :"",
            end_at : "",
            currency_id : 0,
            type:0,
            types:[],
            logList:[],
            total : 0,
            timeValue:[]
        }
    },
    mounted() {
        page = 1;
        this.getLogList();
    },
    methods:{
        paginate(_page){
            page = _page;
            this.getLogList();
        },
        reset(){
            this.start_at = "";
            this.end_at = "";
            page = 1;
            this.timeValue = [];
            this.getLogList();
        },
        getLogList(){
            userApi.getCpitalFlow({
                account:this.account,
                start_at:this.start_at,
                end_at:this.end_at,
                currency_id:this.currency_id,
                type:this.type,
                page:page
            }).then(res=>{
                let types = [];
                res.data.types.map((item)=>{
                    types[item.value] = item.label;
                });
                this.types = types;
                this.logList = res.data.list;
                this.total = res.data.total;
            })
        },
        tabChange(e){
            console.log(e);
            page = 1;
            this.start_at = "";
            this.end_at = "";
            this.timeValue = [];
            this.account = parseInt(e);
            this.getLogList();
        },
        onChange(e){
            //日期更改
            this.start_at = e[0];
            this.end_at = e[1];
            page = 1;
            this.timeValue = e;
            this.getLogList();
        },
        onSelect(e){
            //日期选择
            console.log(e);
        },
        handleSelect(e){
            //流水类型
            console.log(e);
        }
    }
}