<template>
    <div class="volatilityOption" id="volatilityOption">
      <a-layout>
        <a-layout-header>
          <PageNav></PageNav>
        </a-layout-header>
        <a-layout-content>
          <div class="flex align-center jus-center" style="height: 500px">
            <text class="font-20 color-asset-title">快富</text>
          </div>
        </a-layout-content>
        <a-layout-footer>
          <Footer></Footer>
        </a-layout-footer>
      </a-layout>
    </div>
  </template>
  <script>
  import PageNav from "@/components/PageNav/PageNav.vue";
  import Footer from "@/components/Footer/footer.vue";
  
  export default {
    components: { PageNav, Footer },
    data() {
      return {
      };
    },
    methods: {
    },
  };
  </script>
  