<template>
    <a-layout id="register">
        <a-layout-header>
            <PageNav></PageNav>
        </a-layout-header>
        <a-layout-content class="registerBody">
            <div class="left">
                <div class="bgImage">
                    <img src="@/assets/images/login/rg-bg.png" alt="">
                </div>
                <a-button class="join" @click="openUrl">
                    <img src="@/assets/images/login/tw.png" alt="">
                    <div class="joinInfo">
                        <p class="color-black font-bold font-16">马上加入{{config.site_name}}电报群</p>
                        <span class="font-12 color-gray">获得帮助，分享心得，并接受最新的币记新闻。</span>
                    </div>
                </a-button>
            </div>
            <div class="right">
                <h1>欢迎来到 {{config['site_name']}} </h1>
                <a-tabs default-active-key="1" @change="changeType">
                    <a-tab-pane title="手机号码" key="1">
                        <div class="label">
                            <p class="color-gray">手机号码</p>
                            <a-input class="input" v-model="phone" placeholder="请输入手机号码" @change="checkPhone">
                                <template #prefix v-if="phone.length !== 0">
                                    <a-trigger v-model:popup-visible="showCountry" position="bottom" :popup-translate="[170, 10]" trigger="click">
                                        <div class="area">
                                            <span class="area-num">{{ code }}</span>
                                            <icon-caret-down/>
                                        </div>
                                        <template #content>
                                            <div class="areaWarp">
                                                <a-input-search class="searchInput" @input="filterCountry" placeholder="搜索"></a-input-search>
                                                <a-scrollbar class="areaList">
                                                    <div class="areaItem"
                                                         :class="code == item.dialingCode ? 'areaSelected' : ''"
                                                         v-for="(item,index) in country" :key="index"
                                                         @click="countryOk(item)">
                                                        <span class="color-black mr-10">{{ item.cnName }}</span>
                                                        <span class="color-black">{{ item.dialingCode }}</span>
                                                    </div>
                                                </a-scrollbar>
                                            </div>
                                        </template>
                                    </a-trigger>
                                </template>
                                <template #append>
                                    <span @click="getVcode">{{ verifyText }}</span>
                                </template>
                            </a-input>
                        </div>
                        <div class="label">
                            <p class="color-gray">密码</p>
                            <a-trigger position="br" :popup-translate="[0, 5]" trigger="focus"
                                       :unmount-on-close="false">
                                <a-input-password class="input" placeholder="设置密码" v-model="password"
                                                  @change="checkPassword"/>
                                <template #content>
                                    <div class="passwordTips">
                                        <div class="cell">
                                            <div class="checkbox"></div>
                                            <span>8~32个字符</span>
                                        </div>
                                        <div class="cell">
                                            <div class="checkbox"></div>
                                            <span>至少一个数字</span>
                                        </div>
                                        <div class="cell">
                                            <div class="checkbox"></div>
                                            <span>至少一个大写字母</span>
                                        </div>
                                    </div>
                                </template>
                            </a-trigger>
                        </div>

                    </a-tab-pane>
                    <a-tab-pane title="邮箱" key="2">
                        <div class="label">
                            <p class="color-gray">邮箱</p>
                            <a-input class="input" v-model="email" placeholder="请输入邮箱" @change="checkEmail">
                                <template #append>
                                    <span @click="getVcode">{{ verifyText }}</span>
                                </template>
                            </a-input>
                        </div>
                        <div class="label">
                            <p class="color-gray">密码</p>
                            <a-trigger position="br" :popup-translate="[0, 5]" trigger="focus"
                                       :unmount-on-close="false">
                                <a-input-password class="input" placeholder="设置密码" v-model="password"
                                                  @change="checkPassword"/>
                                <template #content>
                                    <div class="passwordTips">
                                        <div class="cell">
                                            <div class="checkbox"></div>
                                            <span>8~32个字符</span>
                                        </div>
                                        <div class="cell">
                                            <div class="checkbox"></div>
                                            <span>至少一个数字</span>
                                        </div>
                                        <div class="cell">
                                            <div class="checkbox"></div>
                                            <span>至少一个大写字母</span>
                                        </div>
                                        <div class="cell">
                                            <div class="checkbox"></div>
                                            <span>至少一个特殊字符</span>
                                        </div>
                                    </div>
                                </template>
                            </a-trigger>
                        </div>
                    </a-tab-pane>
                </a-tabs>
                <div class="label" v-show="showVcode">
                    <p class="color-gray">验证码</p>
                    <a-trigger position="br" :popup-translate="[0, 5]" trigger="focus"
                               :unmount-on-close="false">
                        <a-input class="input" placeholder="请输入验证码" v-model="verifyCode"/>
                    </a-trigger>
                </div>
                <div class="label">
                    <p class="color-gray font-12 invaite_t" @click="invaiteShow = !invaiteShow">邀请码（可选）
                        <icon-down/>
                    </p>
                    <a-input class="input" v-model="invaiteCode" placeholder="请输入邀请码，区分大小写"
                             v-if="invaiteShow">
                    </a-input>
                </div>
                <a-checkbox class="ruleCheck" value="1" @change="(v)=>{agree=v;}">
                    {{ $t('login.t26') }} {{config['site_name']}}
                    <a href='#'>《{{ $t('login.t27') }}》</a>
                    {{ $t('login.t28') }}
                    <a href='#'>《{{ $t('login.t29') }}》</a>
                </a-checkbox>
                <a-button class="registerBtn" @click="login">
                    创建账号
                </a-button>
                <div class="other">
                    <a-divider orientation="center">或通过以下方式登录</a-divider>
                    <a-button class="googleButton">
                        <img src="@/assets/googleIcon.svg" alt="">
                        通过 Google 继续
                    </a-button>
                </div>
                <div class="row">
                    <div class="item">
                        <span class="color-black">已有账号？<strong class="color-primary" @click="$router.push('/login')">去登录</strong> </span>
                    </div>
                </div>
            </div>
        </a-layout-content>
    </a-layout>
</template>
<script>
import service from './service';
export default service;
</script>