<template>
    <a-layout class="spot">
        <a-layout-header>
            <PageNav></PageNav>
        </a-layout-header>
        <a-layout-content class="spotBody">
            <div class="hotCoin">
                <img src="@/assets/hot.png" alt="">
                <div class="item" v-for="(item,index) in (currency[0] ? currency[0]['currency'].slice(0,10) :[])"
                     :key="index" @click="setCurrentCurrency(item)">
                    <span class="color-black" :class="tableActive === index ? 'font-bold' : ''">{{
                            item.symbol
                        }}USDT</span>
                    <span :class="{'color-red':item.change_rate<=0,'color-green':item.change_rate>0}">
                        {{ $util.thousandSeparator(item.new_price, item.decimals) }}
                    </span>
                    <span :class="{'color-red':item.change_rate<=0,'color-green':item.change_rate>0}"
                          style="margin-left: 5px">
                        {{ $util.thousandSeparator(item.change_rate, 2) }}%
                    </span>
                </div>
            </div>
            <div class="headCard">
                <a-trigger trigger="hover" :popup-translate="[100, 10]">
                    <div class="selectBox">
                        <img class="coinImage" :src="current['icon']" alt="">
                        <div class="coinInfo">
                            <div class="row flex align-center">
                                <span class="mr-10 font-bold color-black">{{ current['symbol'] }}USDT</span>
                                <div class="caretDown">
                                    <icon-caret-down size="14"/>
                                </div>
                            </div>
<!--                            <span class="desc">永续</span>-->
                        </div>
                    </div>
                    <template #content>
                        <div class="market-wrap">
                            <a-input class="input" placeholder="搜索" allow-clear>
                                <template #suffix>
                                    <icon-search size="18"/>
                                </template>
                            </a-input>
                            <!--               -->
                            <div class="market_tab flex align-center" v-if="false">
                                <div class="pad-lr-15 flex align-center jus-center pointer"
                                     :class="subtabActive == 0 ? 'active' : ''"
                                     @click="subtabActive = 0">
                                    <span class="font-12 color-gray">自选</span>
                                </div>
                                <div class="pad-lr-15 flex align-center jus-center pointer"
                                     :class="subtabActive == 1 ? 'active' : ''"
                                     @click="subtabActive = 1">
                                    <span class="font-12 color-gray">USDT合约</span>
                                </div>
                            </div>
                            <!--                -->
                            <div class="market_tab_main">
                                <div class="sub_tab flex align-center jus-bet">
                                    <div class="flex align-center pointer">
                                        <span class="font-12 color-gray">合约</span>
                                    </div>
                                    <div class="flex align-center jus-end pointer">
                                        <span class="font-12 color-gray">最新价</span>
                                        <img src="@/assets/images/ranking.svg">
                                    </div>
                                    <div class="flex align-center jus-end pointer">
                                        <span class="font-12 color-gray">24h涨跌幅</span>
                                        <img src="@/assets/images/ranking.svg">
                                    </div>
                                </div>
                                <a-scrollbar outer-class="scrollbar">
                                    <div class="sub_cell flex align-center jus-bet"
                                         v-for="(item,index) in (currency[0] ? currency[0]['currency']:[])"
                                         @click="setCurrentCurrency(item)">
                                        <div class="flex align-center">
                                            <img class="mr-5" src="@/assets/images/spot/unlike.svg" alt="" v-if="like">
                                            <img class="mr-5" src="@/assets/images/spot/like.svg" alt="" v-else>
                                            <span class="font-12 color-black mr-5">{{ item.symbol }}USDT</span>
<!--                                            <span class="font-12 color-gray">永续</span>-->
                                        </div>
                                        <div class="flex align-center jus-end">
                                            <span class="font-12"
                                                  :class="{'color-red':item.change_rate<=0,'color-green':item.change_rate>0}">{{
                                                    $util.thousandSeparator(item['new_price'], item.decimals)
                                                }}</span>
                                        </div>
                                        <div class="flex align-center jus-end">
                                            <span class="font-12"
                                                  :class="{'color-red':item.change_rate<=0,'color-green':item.change_rate>0}">{{
                                                    $util.thousandSeparator(item['change_rate'], 2)
                                                }}%</span>
                                        </div>
                                    </div>
                                </a-scrollbar>
                            </div>
                        </div>
                    </template>
                </a-trigger>
                <div class="cardRight">
                    <div class="infoList">
                        <div class="item">
                            <span
                                class="font-16 color-black font-bold">{{
                                    $util.thousandSeparator(current.new_price, current.decimals)
                                }}</span>
                            <span
                                class="font-12 color-gray">≈{{
                                    symbol
                                }} {{ $util.thousandSeparator(current.new_price * symbol_rate, 2) }}</span>
                        </div>
                        <div class="item">
                            <span class="font-12 color-gray">24h涨跌幅</span>
                            <span class="font-12"
                                  :class="{'color-red':current.change_rate<=0,'color-green':current.change_rate>0}">
                                {{ $util.thousandSeparator(current.change_rate, 2) }}%
                            </span>
                        </div>
                        <div class="item">
                            <span class="font-12 color-gray">24h最高价</span>
                            <span class="font-12 color-black">
                                {{ $util.thousandSeparator(current.high_price, current.decimals) }}
                            </span>
                        </div>
                        <div class="item">
                            <span class="font-12 color-gray">24h最低价</span>
                            <span class="font-12 color-black">
                                {{ $util.thousandSeparator(current.low_price, current.decimals) }}
                            </span>
                        </div>
                        <div class="item">
                            <span class="font-12 color-gray">24h成交量({{ current['symbol'] }})</span>
                            <span class="font-12 color-black">
                                {{ $util.thousandSeparator(current.quantity, 2) }}
                            </span>
                        </div>
                        <div class="item">
                            <span class="font-12 color-gray">24h成交额(USDT)</span>
                            <span class="font-12 color-black">
                                {{ $util.thousandUnit(current.quota, 2) }}
                            </span>
                        </div>
                    </div>
                    <div class="rightButtons">
                        <a-tooltip position="top" background-color="var(--tooltip-bg)">
                            <div class="item" @click="aboutCoinShow">
                                <icon-bookmark size="16"/>
                            </div>
                            <template #content>
                                <p class="font-12 color-always-white">{{ coinName }}USDT介绍</p>
                            </template>
                        </a-tooltip>
                        <a-tooltip position="top" background-color="var(--tooltip-bg)">
                            <div class="item" @click="settingDrawer = true">
                                <icon-settings size="16"/>
                            </div>
                            <template #content>
                                <p class="font-12 color-always-white">偏好设置</p>
                            </template>
                        </a-tooltip>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="left">
                    <div class="containerTop">
                        <div class="klineBox">
                            <a-tabs>
                                <template #extra>
                                    <div class="viewType">
                                        <div class="buttons">
                                            <span :class="{'chartTypeActive': chartType === 0}" @click="chartType = 0">基础版</span>
<!--                                            <span :class="{'chartTypeActive': chartType === 1}" @click="chartType = 1">TradingView</span>-->
                                        </div>
                                        <div class="iconMenu">
                                            <icon-apps/>
                                        </div>
                                    </div>
                                </template>
                                <a-tab-pane title="图表" key="1">
                                    <div class="chartBox" id="chartBox">

                                    </div>
                                </a-tab-pane>
                                <a-tab-pane title="币种概览" key="2">
                                    <div class="coinInfo">
                                        <div class="left">
                                            <p class="font-14 color-black font-bold">基本信息</p>
                                            <div class="cell">
                                                <a-tooltip position="tr" background-color="var(--tooltip-bg)">
                                                    <span class="color-gray dotted cursor">市值</span>
                                                    <template #content>
                                                        <span
                                                            class="color-always-white">加密货币流通供应的总市值。市值=当前价格*流通供应量</span>
                                                    </template>
                                                </a-tooltip>
                                                <span class="color-black">
                                                    {{
                                                        currencyInfo.currency_info ? currencyInfo.currency_info['marketValue'] : ''
                                                    }}
                                                </span>
                                            </div>
                                            <div class="cell">
                                                <a-tooltip position="tr" background-color="var(--tooltip-bg)">
                                                    <span class="color-gray dotted cursor">完全稀释市值</span>
                                                    <template #content>
                                                        <span
                                                            class="color-always-white">当最大供应量=流通量时的市值</span>
                                                    </template>
                                                </a-tooltip>
                                                <span
                                                    class="color-black">{{
                                                        currencyInfo.currency_info ? currencyInfo.currency_info['totalCurrency'] : ''
                                                    }}</span>
                                            </div>
                                            <div class="cell">
                                                <a-tooltip position="tr" background-color="var(--tooltip-bg)">
                                                    <span class="color-gray dotted cursor">24小时成交量</span>
                                                    <template #content>
                                                        <span
                                                            class="color-always-white">一种衡量过去24小时内加密货币交易量的指标，每小时更新一次</span>
                                                    </template>
                                                </a-tooltip>
                                                <span class="color-black">{{ current['quantity'] }}</span>
                                            </div>
                                            <div class="cell">
                                                <a-tooltip position="tr" background-color="var(--tooltip-bg)">
                                                    <span class="color-gray dotted cursor">市场占有率</span>
                                                    <template #content>
                                                        <span
                                                            class="color-always-white">相对于所有加密资产总市值的占比</span>
                                                    </template>
                                                </a-tooltip>
                                                <span
                                                    class="color-black">{{
                                                        currencyInfo.currency_info ? currencyInfo.currency_info['CirculationRate'] : ''
                                                    }}</span>
                                            </div>
                                            <a-divider/>
                                            <div class="cell">
                                                <span class="color-gray">历史最高价</span>
                                                <span
                                                    class="color-black">{{
                                                        currencyInfo.currency_info ? currencyInfo.currency_info['hightPrice'] : ''
                                                    }}</span>
                                            </div>
                                            <div class="cell">
                                                <span class="color-gray">历史最低价</span>
                                                <span class="color-black">$0.01</span>
                                            </div>
                                            <a-divider/>
                                            <div class="cell">
                                                <a-tooltip position="tr" background-color="var(--tooltip-bg)">
                                                    <span class="color-gray dotted cursor">流通供应量</span>
                                                    <template #content>
                                                        <span
                                                            class="color-always-white">在市场上流通并由公众持有的加密货币数量</span>
                                                    </template>
                                                </a-tooltip>
                                                <span
                                                    class="color-black">{{
                                                        currencyInfo.currency_info ? currencyInfo.currency_info['Circulation'] : ''
                                                    }}</span>
                                            </div>
                                            <div class="cell">
                                                <a-tooltip position="tr" background-color="var(--tooltip-bg)">
                                                    <span class="color-gray dotted cursor">总供应量</span>
                                                    <template #content>
                                                        <span
                                                            class="color-always-white">已产生的加密货币数量减去已销毁的加密货币数量</span>
                                                    </template>
                                                </a-tooltip>
                                                <span
                                                    class="color-black">{{
                                                        currencyInfo.currency_info ? currencyInfo.currency_info['totalCurrency'] : ''
                                                    }}</span>
                                            </div>
                                        </div>
                                        <div class="right">
                                            <a-scrollbar class="scroll">
                                                <p class="title font-14 color-black font-bold">BTC简介</p>
                                                <p class="font-12 color-gray desc">
                                                    {{
                                                        currencyInfo.currency_info ? currencyInfo.currency_info['about'] : ''
                                                    }}
                                                </p>
                                                <!--                                                <p class="title font-14 color-black font-bold">投资机构</p>-->
                                                <!--                                                <div class="collapse" >-->
                                                <!--                                                    <div class="tagList" :class="showAll == true ? 'showAll' : ''">-->
                                                <!--                                                        <div class="tag" v-for="i in 20">-->
                                                <!--                                                            <img src="@/assets/images/coin/btc.png" alt="">-->
                                                <!--                                                            <span class="font-12 color-black font-bold">ConsenSys</span>-->
                                                <!--                                                        </div>-->
                                                <!--                                                    </div>-->
                                                <!--                                                    <div class="openIcon" @click="showAll = !showAll">-->
                                                <!--                                                        <icon-down :class="{'rotate':showAll}"-->
                                                <!--                                                                   class="color-black cursor" size="16"/>-->
                                                <!--                                                    </div>-->
                                                <!--                                                </div>-->
                                                <!--                                                <p class="title font-14 color-black font-bold">社区</p>-->
                                                <!--                                                <div class="collapse">-->
                                                <!--                                                    <div class="tagList">-->
                                                <!--                                                        <a-button class="tagBtn">-->
                                                <!--                                                            <img src="@/assets/images/coin/btc.png" alt="">-->
                                                <!--                                                            <span class="font-12 color-black font-bold">BTC</span>-->
                                                <!--                                                        </a-button>-->
                                                <!--                                                    </div>-->
                                                <!--                                                </div>-->
                                                <p class="title font-14 color-black font-bold">资源</p>
                                                <div class="collapse">
                                                    <div class="tagList">
                                                        <a-dropdown position="top" trigger="hover">
                                                            <a-button class="tagBtn">
                                                                <icon-computer class="color-black mr-5"/>
                                                                <span class="font-12 color-black font-bold">官网</span>
                                                            </a-button>
                                                            <template #content>
                                                                <a-doption>
                                                                    {{
                                                                        currencyInfo.currency_info ? currencyInfo.currency_info['websiteLink'] : ''
                                                                    }}
                                                                </a-doption>
                                                                <!--                                                                <a-doption>https://www.ethereum.org/</a-doption>-->
                                                            </template>
                                                        </a-dropdown>
                                                        <a-button class="tagBtn">
                                                            <icon-book class="color-black mr-5"/>
                                                            <span class="font-12 color-black font-bold">Twitter</span>
                                                        </a-button>
                                                        <a-button class="tagBtn">
                                                            <icon-github class="color-black mr-5"/>
                                                            <span class="font-12 color-black font-bold">FaceBook</span>
                                                        </a-button>
                                                        <a-button class="tagBtn">
                                                            <icon-github class="color-black mr-5"/>
                                                            <span class="font-12 color-black font-bold">Telegram</span>
                                                        </a-button>
                                                        <a-button class="tagBtn">
                                                            <icon-github class="color-black mr-5"/>
                                                            <span
                                                                class="font-12 color-black font-bold">区块浏览器</span>
                                                        </a-button>
                                                    </div>
                                                </div>
                                                <p class="font-12 color-gray">免责声明：数据来源于第三方平台，仅供参考，不作为任何形式的建议或担保。详细声明可参考
                                                    <a class="color-primary" href="#">风险提示</a>。</p>
                                            </a-scrollbar>
                                        </div>
                                    </div>
                                </a-tab-pane>
                            </a-tabs>
                        </div>
                        <!--     订单表/成交记录-->
                        <div class="orderList">
                            <a-tabs>
                                <a-tab-pane title="订单表" key="1">
                                    <div class="subTab">
                                        <div class="tagGroup">
                                            <div class="item" :class="{'item-active':listType == 0}"
                                                 @click="listType = 0">
                                                <img src="@/assets/images/cut-top-bottom.svg">
                                            </div>
                                            <div class="item" :class="{'item-active':listType == 1}"
                                                 @click="listType = 1">
                                                <img src="@/assets/images/cut-buy.svg">
                                            </div>
                                            <div class="item" :class="{'item-active':listType == 2}"
                                                 @click="listType = 2">
                                                <img src="@/assets/images/cut-ask.svg">
                                            </div>
                                        </div>
                                        <a-dropdown trigger="hover" @select="numSelect">
                                            <div class="dropdown-trigger">
                                                <span class="mr-5">{{ decimal }}</span>
                                                <icon-caret-down/>
                                            </div>
                                            <template #content>
                                                <a-doption>0.000001</a-doption>
                                                <a-doption>0.00001</a-doption>
                                                <a-doption>0.0001</a-doption>
                                            </template>
                                        </a-dropdown>
                                    </div>
                                    <div class="listWarp">
                                        <div class="descGroup">
                                            <span class="color-gray">价格(USDT)</span>
                                            <span class="color-gray">数量({{ current['symbol'] }})</span>
                                            <span class="color-gray">成交额</span>
                                        </div>
                                        <template v-if="listType == 0">
                                            <div class="row" v-for="(item,index) in bids" :key="'bids'+index">
                                                <span class="color-red">{{
                                                        $util.thousandSeparator(item[0], current['decimals'])
                                                    }}</span>
                                                <span class="color-black">{{ $util.float(item[1]) }}</span>
                                                <span class="color-black">{{
                                                        $util.thousandSeparator(item[0] * item[1], 2)
                                                    }}</span>
                                                <div class="bg-row bg-red"
                                                     :style="{'--width':item[3] * 100 + '%' }"></div>
                                            </div>
                                            <div class="priceCenter">
                                                  <span class="font-16 font-bold"
                                                        :class="{'color-red':current['new_price'] <= lastPrice,'color-green':current['new_price'] > lastPrice}">
                                                      {{
                                                          $util.thousandSeparator(current['new_price'], current['decimals'])
                                                      }}
                                                    <icon-arrow-down v-if="current['new_price'] < lastPrice"
                                                                     style="margin-top: 1px"/>
                                                      <icon-arrow-up v-else style="margin-top: 1px"/>
                                                  </span>
                                                <span
                                                    class="font-14 font-bold color-black">≈ {{
                                                        symbol
                                                    }} {{
                                                        $util.thousandSeparator(current['new_price'] * symbol_rate, 2)
                                                    }}</span>
                                            </div>
                                            <div class="row" v-for="(item,index) in asks" :key="'asks'+index">
                                                <span class="color-green">{{
                                                        $util.thousandSeparator(item[0], current['decimals'])
                                                    }}</span>
                                                <span class="color-black">{{ $util.float(item[1]) }}</span>
                                                <span class="color-black">{{
                                                        $util.thousandSeparator(item[0] * item[1], 2)
                                                    }}</span>
                                                <div class="bg-row bg-green"
                                                     :style="{'--width':item[3] * 100 + '%' }"></div>
                                            </div>
                                        </template>
                                        <div class="priceCenter" v-if="listType>0">
                                                <span class="font-16 font-bold"
                                                      :class="{'color-red':current['new_price'] <= lastPrice,'color-green':current['new_price'] > lastPrice}">
                                                    {{
                                                        $util.thousandSeparator(current['new_price'], current['decimals'])
                                                    }}
                                                        <icon-arrow-down v-if="current['new_price'] < lastPrice"
                                                                         style="margin-top: 1px"/>
                                                        <icon-arrow-up v-else style="margin-top: 1px"/>
                                                </span>
                                            <span class="font-14 font-bold color-black">
                                                    ≈ {{
                                                    symbol
                                                }} {{ $util.thousandSeparator(current['new_price'] * symbol_rate, 2) }}
                                                </span>
                                        </div>
                                        <template v-if="listType == 1">
                                            <a-scrollbar class="scrollView">
                                                <div class="row" v-for="(item,index) in asks" :key="'askss'+index">
                                                    <span class="color-green">{{
                                                            $util.thousandSeparator(item[0], current['decimals'])
                                                        }}</span>
                                                    <span class="color-black">{{ $util.float(item[1]) }}</span>
                                                    <span class="color-black">{{
                                                            $util.thousandSeparator(item[0] * item[1], 2)
                                                        }}</span>
                                                    <div class="bg-row bg-red"
                                                         :style="{'--width':item[3] * 100 + '%' }"></div>
                                                </div>
                                            </a-scrollbar>
                                        </template>
                                        <template v-if="listType == 2">
                                            <a-scrollbar class="scrollView">
                                                <div class="row" v-for="(item,index) in bids" :key="'askss'+index">
                                                    <span class="color-red">{{
                                                            $util.thousandSeparator(item[0], current['decimals'])
                                                        }}</span>
                                                    <span class="color-black">{{ $util.float(item[1]) }}</span>
                                                    <span class="color-black">{{
                                                            $util.thousandSeparator(item[0] * item[1], 2)
                                                        }}</span>
                                                    <div class="bg-row bg-red"
                                                         :style="{'--width':item[3] * 100 + '%' }"></div>
                                                </div>
                                            </a-scrollbar>
                                            <!--                                            <div class="priceCenter">-->
                                            <!--                                                  <span class="font-16 font-bold color-red">0.061335-->
                                            <!--                                                    <icon-arrow-down style="margin-top: 1px"/>-->
                                            <!--                                                  </span>-->
                                            <!--                                                <span class="font-14 font-bold color-black">≈ $ 0.61</span>-->
                                            <!--                                            </div>-->
                                        </template>
                                    </div>
                                </a-tab-pane>
                                <a-tab-pane title="成交记录" key="2">
                                    <div class="listWarp">
                                        <div class="descGroup">
                                            <span class="color-gray">价格(USDT)</span>
                                            <span class="color-gray">数量({{ current['symbol'] }})</span>
                                            <span class="color-gray">时间</span>
                                        </div>
                                        <a-scrollbar class="maxScroll">
                                            <div class="row" v-for="(item,index) in tradeList" :key="'trade'+index">
                                                <span class="color-green">{{ $util.float(item['price']) }}</span>
                                                <span class="color-black">{{ item['vol'] }}</span>
                                                <span class="color-black">{{ item['time'] }}</span>
                                            </div>
                                        </a-scrollbar>
                                    </div>
                                </a-tab-pane>
                            </a-tabs>
                        </div>
                    </div>
                    <div class="containerBottom">
                        <a-tabs @change="orderChange">
                            <a-tab-pane key="1">
                                <template #title>
                                    <span>当前委托({{ orderList.length }})</span>
                                </template>
                                <div class="orderList">
                                    <div class="descList">
                                        <div class="item">
                                            <span>交易对</span>
                                        </div>
                                        <div class="item">
                                            <span>时间</span>
                                        </div>
                                        <div class="item">
                                            <span>委托订单号</span>
                                        </div>
                                        <div class="item">
                                            <span>方向</span>
                                        </div>
                                        <div class="item">
                                            <span>委托价格</span>
                                        </div>
                                        <div class="item">
                                            <span>委托数量</span>
                                        </div>
                                        <div class="item">
                                            <span>交易额</span>
                                        </div>
                                        <div class="item">
                                            <span>成交均价</span>
                                        </div>
                                        <div class="item">
                                            <span>已成交/未成交</span>
                                        </div>
                                        <div class="item">
                                            <span>操作</span>
                                        </div>
                                    </div>
                                    <a-scrollbar class="orderScroll">
                                        <notlog v-if="!isLogin"></notlog>
                                        <nodate v-if="orderList.length === 0 && isLogin"></nodate>
                                        <div class="cell" v-for="(item,index) in orderList" :key="'limit'+item.id">
                                            <div class="item">
                                                  <span class="color-black">
                                                    {{ item.symbol }}USDT
                                                  </span>
                                            </div>
                                            <div class="item">
                                                  <span class="color-black">
                                                      {{ item.created_at }}
                                                  </span>
                                            </div>
                                            <div class="item">
                                                  <span class="color-black">
                                                        {{ item.id }}
                                                  </span>
                                            </div>
                                            <div class="item">
                                                <a-tag :color="item.direction === 2 ? 'var(--opacity-sell)' : 'var(--opacity-buy)'">
                                                    <span :class="item.direction === 2?'color-red':'color-green'">{{
                                                            item.direction === 2 ? '卖出' : '买入'
                                                        }}</span>
                                                </a-tag>
                                            </div>
                                            <div class="item">
                                                  <span class="color-black">
                                                      {{ $util.float(item.price) }}
                                                  </span>
                                            </div>
                                            <div class="item">
                                                  <span class="color-black">
                                                      {{ $util.thousandSeparator(item.amount / item.price,8) }}
                                                  </span>
                                            </div>
                                            <div class="item">
                                                  <span class="color-black">
                                                      {{ $util.float(item.amount) }}
                                                  </span>
                                            </div>
                                            <div class="item">
                                                  <span class="color-black">
                                                        0.000000
                                                  </span>
                                            </div>
                                            <div class="item">
                                                  <span class="color-black">
                                                        未成交
                                                  </span>
                                            </div>
                                            <div class="item">
                                                <a-popconfirm content="是否取消当前委托订单？" @ok="cancelOrder(item.id)">
                                                    <span class="color-primary cancel cursor">取消</span>
                                                </a-popconfirm>
                                            </div>
                                        </div>
                                    </a-scrollbar>
                                </div>
                            </a-tab-pane>
                            <a-tab-pane title="历史订单" key="2">
                                <div class="orderList">
                                    <div class="descList">
                                        <div class="item">
                                            <span>交易对</span>
                                        </div>
                                        <div class="item">
                                            <span>时间</span>
                                        </div>
                                        <div class="item">
                                            <span>委托订单号</span>
                                        </div>
                                        <div class="item">
                                            <span>方向</span>
                                        </div>
                                        <div class="item">
                                            <span>委托价格</span>
                                        </div>
                                        <div class="item">
                                            <span>成交数量</span>
                                        </div>
                                        <div class="item">
                                            <span>交易额</span>
                                        </div>
                                        <div class="item">
                                            <span>成交均价</span>
                                        </div>
                                        <div class="item">
                                            <span>状态</span>
                                        </div>
                                        <div class="item">
                                            <span>操作</span>
                                        </div>
                                    </div>
                                    <a-scrollbar class="orderScroll" @scroll="historyOrderScroll">
                                        <notlog v-if="!isLogin"></notlog>
                                        <nodate v-if="history.length === 0 && isLogin"></nodate>
                                        <div class="cell" v-for="(item,index) in history">
                                            <div class="item">
                                                  <span class="color-black">
                                                    {{item['symbol']}}USDT
                                                  </span>
                                            </div>
                                            <div class="item">
                                                  <span class="color-black">
                                                      {{item['created_at']}}
                                                  </span>
                                            </div>
                                            <div class="item">
                                                  <span class="color-black">
                                                      {{item['id']}}
                                                  </span>
                                            </div>
                                            <div class="item">
                                                <a-tag :color="item.direction === 2 ? 'var(--opacity-sell)' : 'var(--opacity-buy)'">
                                                    <span :class="item.direction === 2?'color-red':'color-green'">{{
                                                            item.direction === 2 ? '卖出' : '买入'
                                                        }}</span>
                                                </a-tag>
                                            </div>
                                            <div class="item">
                                                  <span class="color-black">
                                                      {{$util.float(item['price'])}} USDT
                                                  </span>
                                            </div>
                                            <div class="item">
                                                  <span class="color-black">
                                                        {{$util.float(item['t_amount'] === null ? 0 :item['t_amount'])}}
                                                  </span>
                                            </div>
                                            <div class="item">
                                                  <span class="color-black">
                                                        {{$util.float(item.amount)}}
                                                  </span>
                                            </div>
                                            <div class="item">
                                                  <span class="color-black">
                                                      {{$util.float(item.t_price === null ? 0 : item.t_price)}}
                                                  </span>
                                            </div>
                                            <div class="item">
                                                  <span class="color-black">
                                                        {{item.status === 3 ? '已取消':'已成交'}}
                                                  </span>
                                            </div>
                                            <div class="item">
                                                  <span class="color-primary cursor">
                                                        详情
                                                  </span>
                                            </div>
                                        </div>
                                    </a-scrollbar>
                                </div>
                            </a-tab-pane>
                            <a-tab-pane title="资产" key="4">
                                <div class="orderList">
                                    <div class="descList">
                                        <div class="item">
                                            <span>币种</span>
                                        </div>
                                        <div class="item">
                                            <span>总额</span>
                                        </div>
                                        <div class="item">
                                            <span>可用</span>
                                        </div>
                                        <div class="item">
                                            <span>冻结</span>
                                        </div>
                                        <div class="item">
                                            <span>资产折合(USDT)</span>
                                        </div>
                                        <div class="item">
                                            <span>操作</span>
                                        </div>
                                    </div>
                                    <a-scrollbar class="orderScroll" name="asset">
                                        <notlog v-if="!isLogin"></notlog>
                                        <div class="cell" v-else>
                                            <div class="item">
                                                  <span class="color-black">
                                                        {{current['symbol']}}
                                                  </span>
                                            </div>
                                            <div class="item">
                                                <span class="color-black">
                                                    {{parseFloat(balance['trade_balance']) + parseFloat(balance['lock_trade'])}}
                                                </span>
                                            </div>
                                            <div class="item">
                                                <span class="color-black">
                                                    {{balance['trade_balance']}}
                                                </span>
                                            </div>
                                            <div class="item">
                                                <span class="color-black">
                                                    {{balance['lock_trade']}}
                                                </span>
                                            </div>
                                            <div class="item">
                                                <span class="color-black">
                                                    {{(parseFloat(balance['trade_balance']) + parseFloat(balance['lock_trade'])) * current['new_price']}}
                                                </span>
                                            </div>
                                            <div class="item">
                                                <span class="color-primary cursor" @click="transfeVisable=true">划转</span>
                                            </div>
                                        </div>

                                        <div class="cell">
                                            <div class="item">
                                                  <span class="color-black">
                                                      USDT
                                                  </span>
                                            </div>
                                            <div class="item">
                                                <span class="color-black">
                                                    {{parseFloat(balance['legal_balance']) + parseFloat(balance['legal_lock'])}}
                                                </span>
                                            </div>
                                            <div class="item">
                                                <span class="color-black">
                                                    {{balance['legal_balance']}}
                                                </span>
                                            </div>
                                            <div class="item">
                                                <span class="color-black">
                                                    {{balance['legal_lock']}}
                                                </span>
                                            </div>
                                            <div class="item">
                                                <span class="color-black">
                                                    {{(parseFloat(balance['trade_balance']) + parseFloat(balance['lock_trade']))}}
                                                </span>
                                            </div>
                                            <div class="item">
                                                <span class="color-primary cursor" @click="transfeVisable=true">划转</span>
                                            </div>
                                        </div>
                                    </a-scrollbar>
                                </div>
                            </a-tab-pane>
                        </a-tabs>
                    </div>
                </div>
                <div class="right">
                    <a-tabs @change="changeTab">
                        <a-tab-pane title="市价单" key="1">
                            <div class="modeButtons">
                                <div class="btn" :class="{'buyActive':btnActive === 0}" @click="btnActive = 0">
                                    买入
                                </div>
                                <div class="btn" :class="{'sellActive':btnActive === 1}" @click="btnActive = 1">
                                    卖出
                                </div>
                            </div>
                            <div class="form">
                                <div class="label">
                                    <a-input class="font-14" disabled default-value="当前最优价">
                                        <template #prefix>
                                            <span>委托价格</span>
                                        </template>
                                        <template #suffix>
                                            <span>USDT</span>
                                        </template>
                                    </a-input>
                                </div>
                                <div class="label">
                                    <a-input class="font-14" v-model="amount" @input="inputAmount">
                                        <template #prefix>
                                            <span>委托数量</span>
                                        </template>
                                        <template #suffix>
                                            <span>BTC</span>
                                        </template>
                                    </a-input>
                                </div>
                                <div class="sliderBox">
                                    <a-slider :disabled="!isLogin" :default-value="0" :max="100"
                                              :marks="marks" :model-value="rate" @change="sliderChange"/>
                                </div>
                                <div class="label">
                                    <a-input class="font-14" v-model="pamount" @input="inputPamoun">
                                        <template #prefix>
                                            <span>交易额</span>
                                        </template>
                                        <template #suffix>
                                            <span>USDT</span>
                                        </template>
                                    </a-input>
                                </div>
                                <div class="row">
                                    <span class="color-gray font-12">可用</span>
                                    <div class="flex align-center">
                                        <span
                                            class="color-black font-bold font-12 mr-10">{{
                                                balance ? $util.thousandSeparator(btnActive === 0 ? balance['legal_balance']:balance['trade_balance'], btnActive ===0 ?2:8) : '--'
                                            }} {{btnActive === 0 ? 'USDT':current['symbol']}}</span>
                                        <icon-swap class="color-primary cursor" @click="transfeVisable=true" size="16"/>
                                    </div>
                                </div>
                                <a-button class="button" @click="submitOrder" :loading="loading"
                                          :class="btnActive === 0 ? 'bg-green' : 'bg-red'" v-if="isLogin">
                                    {{ btnActive === 0 ? '买入' : '卖出' }}
                                </a-button>
                                <a-button class="button bg-green" @click="$router.push('/login')" v-else>去登录
                                </a-button>
                                <div class="asset">
                                    <p class="font-16 color-black font-bold title">资产</p>
                                    <div class="asset-item">
                                        <div class="top">
                                            <img :src="current['icon']" alt="">
                                            <div class="info">
                                                <div class="row">
                                                    <span class="font-bold color-black">{{ current['symbol'] }}</span>
                                                    <span class="font-bold color-black">
                                                        {{ $util.thousandSeparator(balance['trade_balance'], current['trade_decimals']) }}
                                                    </span>
                                                </div>
                                                <div class="row">
                                                    <span class="font-12 color-gray">BTC</span>
                                                    <span
                                                        class="font-12 color-gray">$ {{
                                                            $util.thousandSeparator(current['new_price'], current['decimals'])
                                                        }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="asset-item">
                                        <div class="top">
                                            <img src="@/assets/images/coin/usdt.png" alt="">
                                            <div class="info">
                                                <div class="row">
                                                    <span class="font-bold color-black">USDT</span>
                                                    <span class="font-bold color-black">
                                                        {{ $util.thousandSeparator(balance['legal_balance'], 2) }}
                                                    </span>
                                                </div>
                                                <div class="row">
                                                    <span class="font-12 color-gray">Tether</span>
                                                    <span class="font-12 color-gray">$ 1.00</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="btm" v-if="isLogin">
                                            <a-button class="btn" @click="topVisable=true">充值</a-button>
                                            <a-button class="btn" @click="transfeVisable=true">划转</a-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a-tab-pane>
                        <a-tab-pane title="限价单" key="2">
                            <div class="modeButtons">
                                <div class="btn" :class="{'buyActive':btnActive === 0}" @click="btnActive = 0">
                                    买入
                                </div>
                                <div class="btn" :class="{'sellActive':btnActive === 1}" @click="btnActive = 1">
                                    卖出
                                </div>
                            </div>
                            <div class="form">
                                <div class="label">
                                    <a-input class="font-14" v-model="price" @input="inputPrice">
                                        <template #prefix>
                                            <span>委托价格</span>
                                        </template>
                                        <template #suffix>
                                            <span>USDT</span>
                                        </template>
                                    </a-input>
                                </div>
                                <div class="label">
                                    <a-input class="font-14" v-model="amount" @input="inputAmount">
                                        <template #prefix>
                                            <span>委托数量</span>
                                        </template>
                                        <template #suffix>
                                            <span>{{ current['symbol'] }}</span>
                                        </template>
                                    </a-input>
                                </div>
                                <div class="sliderBox">
                                    <a-slider :disabled="!isLogin" :default-value="0" :max="100"
                                              :marks="marks" @change="sliderChange" :model-value="rate"/>
                                </div>
                                <div class="label">
                                    <a-input class="font-14" v-model="pamount" @input="inputPamoun">
                                        <template #prefix>
                                            <span>交易额</span>
                                        </template>
                                        <template #suffix>
                                            <span>USDT</span>
                                        </template>
                                    </a-input>
                                </div>
                                <div class="row">
                                    <span class="color-gray font-12">可用</span>
                                    <div class="flex align-center">
                                        <span
                                            class="color-black font-bold font-12 mr-10">{{
                                                balance ? $util.thousandSeparator(btnActive === 0 ? balance['legal_balance']:balance['trade_balance'], 8) : '--'
                                            }} {{btnActive === 0 ? 'USDT':current['symbol']}}</span>
                                        <icon-swap class="color-primary cursor" size="16" @click="transfeVisable=true"/>
                                    </div>
                                </div>
                                <a-button class="button" @click="submitOrder" :loading="loading"
                                          :class="btnActive === 0 ? 'bg-green' : 'bg-red'" v-if="isLogin">
                                    {{ btnActive == 0 ? '买入' : '卖出' }}
                                </a-button>
                                <a-button class="button bg-green" v-else>去登录</a-button>
                                <div class="asset">
                                    <p class="font-16 color-black font-bold title">资产</p>
                                    <div class="asset-item">
                                        <div class="top">
                                            <img :src="current['icon']" alt="">
                                            <div class="info">
                                                <div class="row">
                                                    <span class="font-bold color-black">{{ current['symbol'] }}</span>
                                                    <span class="font-bold color-black">
                                                        {{ $util.thousandSeparator(balance['trade_balance'], current['trade_decimals']) }}
                                                    </span>
                                                </div>
                                                <div class="row">
                                                    <span class="font-12 color-gray">BTC</span>
                                                    <span
                                                        class="font-12 color-gray">$ {{
                                                            $util.thousandSeparator(current['new_price'], current['decimals'])
                                                        }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="asset-item">
                                        <div class="top">
                                            <img src="@/assets/images/coin/usdt.png" alt="">
                                            <div class="info">
                                                <div class="row">
                                                    <span class="font-bold color-black">USDT</span>
                                                    <span class="font-bold color-black">
                                                        {{ $util.thousandSeparator(balance['legal_balance'], 2) }}
                                                    </span>
                                                </div>
                                                <div class="row">
                                                    <span class="font-12 color-gray">Tether</span>
                                                    <span class="font-12 color-gray">$ 1.00</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="btm" v-if="isLogin">
                                            <a-button class="btn" @click="topVisable=true">充值</a-button>
                                            <a-button class="btn" @click="transfeVisable=true">划转</a-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a-tab-pane>
                    </a-tabs>
                </div>
            </div>
        </a-layout-content>
        <a-layout-footer class="layoutFooter">

        </a-layout-footer>
        <audio ref="audioPlayer" style="display: none" :src="require('@/assets/tradetip.wav')" controls></audio>

        <funds-transfe :visible="transfeVisable" @success="reloadCredit" @close="transfeVisable=false"></funds-transfe>
        <top-up :visible="topVisable" @close="topVisable=false"></top-up>
    </a-layout>
</template>
<script>
import service from './service';

export default service;
</script>

<style lang="scss" scoped>
@import "./spot.scss";
</style>