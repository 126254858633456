<template>
  <a-layout class="futures" :data-theme="userTheme">
    <a-layout-header>
      <PageNav></PageNav>
    </a-layout-header>
    <a-layout-content class="futures_content">
      <!--        热门币种-->
      <div class="hotCoin">
        <img src="@/assets/hot.png" alt="">
        <div class="item" v-for="(item,index) in tableOption" :key="index" @click="tableToggle(item,index)">
          <span class="color-black" :class="tableActive == index ? 'font-bold' : ''">{{ item.name }}{{
              item.type
            }}</span>
          <span v-if="i % 2  == 0" class="color-green">+0.25%</span>
          <span v-else class="color-red">{{ item.rate }}</span>
        </div>
      </div>
      <div class="content_head">
        <div class="content_head_left flex flex-column align-center jus-center">
          <a-trigger trigger="hover" :popup-translate="[100, 10]">
            <div class="flex flex-row align-center coinSelectBox">
              <img class="mr-12 coinImage" src="@/assets/images/coin/btc.png">
              <div class="coinInfo">
                <div class="row flex align-center">
                  <span class="mr-10 font-bold color-black">{{ coinName }}{{ coinType }}</span>
                  <div class="caretDown">
                    <icon-caret-down size="14"/>
                  </div>
                </div>
                <span class="desc">永续</span>
              </div>
            </div>
            <template #content>
              <div class="market-wrap">
                <a-input class="input" placeholder="搜索" allow-clear>
                  <template #suffix>
                    <icon-search size="18"/>
                  </template>
                </a-input>
                <!--               -->
                <div class="market_tab flex align-center">
                  <div class="pad-lr-15 flex align-center jus-center pointer" :class="subtabActive == 0 ? 'active' : ''"
                       @click="subtabActive = 0">
                    <span class="font-12 color-gray">自选</span>
                  </div>
                  <div class="pad-lr-15 flex align-center jus-center pointer" :class="subtabActive == 1 ? 'active' : ''"
                       @click="subtabActive = 1">
                    <span class="font-12 color-gray">USDT合约</span>
                  </div>
                </div>
                <!--                -->
                <div class="market_tab_main">
                  <div class="sub_tab flex align-center jus-bet">
                    <div class="flex align-center pointer">
                      <span class="font-12 color-gray">合约</span>
                    </div>
                    <div class="flex align-center jus-end pointer">
                      <span class="font-12 color-gray">最新价</span>
                      <img src="@/assets/images/ranking.svg">
                    </div>
                    <div class="flex align-center jus-end pointer">
                      <span class="font-12 color-gray">24h涨跌幅</span>
                      <img src="@/assets/images/ranking.svg">
                    </div>
                  </div>
                  <a-scrollbar outer-class="scrollbar">
                    <div class="sub_cell flex align-center jus-bet" v-for="i in 20" @click="like = !like">
                      <div class="flex align-center">
                        <img class="mr-5" src="@/assets/images/spot/unlike.svg" alt="" v-if="like">
                        <img class="mr-5" src="@/assets/images/spot/like.svg" alt="" v-else>
                        <span class="font-12 color-black mr-5">BTCUSDT</span>
                        <span class="font-12 color-gray">永续</span>
                      </div>
                      <div class="flex align-center jus-end">
                        <span class="font-12 color-black">26614.3</span>
                      </div>
                      <div class="flex align-center jus-end">
                        <span class="font-12 color-green">+0.013%</span>
                      </div>
                    </div>
                  </a-scrollbar>
                </div>
              </div>
            </template>
          </a-trigger>
        </div>
        <div class="content_head_right flex flex-row align-center jus-center">
          <div class="content_head_right_info">
            <div class="flex flex-column align-start pad-lr-15">
              <span class="font-18 color-red font-bold">26,569.06</span>
            </div>
            <!--          24h涨跌幅-->
            <div class="flex flex-column align-start pad-lr-15">
              <span class="font-12 color-gray pointer">24h涨跌幅</span>
              <span class="font-12 color-red mt-5">26,570.7</span>
            </div>
            <!--          标记价格-->
            <div class="flex flex-column align-start pad-lr-15">
              <a-tooltip position="bottom" background-color="var(--tooltip-bg)">
                <span class="font-12 color-gray dotted pointer">标记价格</span>
                <template #content>
                  <p class="font-12 color-always-white">
                    标记价格用于结算盈亏和触发强制减仓。标记价格可能会偏离市场最新价格，以防因市场被恶意操纵造成损失。</p>
                </template>
              </a-tooltip>
              <span class="font-12 color-red mt-5">26,570.7</span>
            </div>
            <!--          指数价格-->
            <div class="flex flex-column align-start pad-lr-15">
              <a-tooltip position="bottom" background-color="var(--tooltip-bg)">
                <span class="font-12 color-gray dotted pointer">指数价格</span>
                <template #content>
                  <p class="font-12 color-always-white">标的货币的市场公允现货价格。</p>
                </template>
              </a-tooltip>
              <span class="font-12 color-black mt-5">26,570.7</span>
            </div>
            <!--          本期资金费率/结算时间-->
            <div class="flex flex-column align-start pad-lr-15">
              <a-tooltip position="bottom" background-color="var(--tooltip-bg)">
                <span class="font-12 color-gray dotted pointer">本期资金费率/结算时间</span>
                <template #content>
                  <p class="font-12 color-always-white flex jus-bet">当期结算时间：<strong>9月24日 0时0分(04:30:20)</strong></p>
                  <p class="font-12 color-always-white flex jus-bet">当期资金费率(多仓支付)：<strong>+0.01000%</strong></p>
                  <p class="font-12 color-always-white flex jus-bet">预测下期资金费率：<strong>+0.01000%</strong></p>
                  <p class="font-14 color-always-white font-bold">什么是资金费率？</p>
                  <p class="font-12 color-always-white">资金费率的主要用途是缩小永续合约价格与标的资产市场公允价的价差。</p>
                  <p class="font-12 color-always-white">
                    资金费用每8个小时结算一次，若结算时资金费率为正，则由持多仓用户向持空仓用户支付资金费用。若结算时资金费率为负，则由持空仓用户向持多仓用户支付资金费用。</p>
                  <p class="font-12 color-always-white">资金费用=持仓价值*资金费率</p>
                </template>
              </a-tooltip>
              <span class="font-12 color-black mt-5">+0.01000%/04:48:34</span>
            </div>
            <!--          预测下期资金费率-->
            <div class="flex flex-column align-start pad-lr-15">
              <a-tooltip position="bottom" background-color="var(--tooltip-bg)">
                <span class="font-12 color-gray dotted pointer">预测下期资金费率</span>
                <template #content>
                  <p class="font-12 color-always-white">08:00下期结算资金费时预计以此值进行结算。</p>
                  <p class="font-12 color-always-white">资金费率为正则多头支付空头资金费，资金费率为负则空头支付多头资金费。</p>
                </template>
              </a-tooltip>
              <span class="font-12 color-black mt-5">+0.01000%</span>
            </div>
            <!--          24H最高价-->
            <div class="flex flex-column align-start pad-lr-15">
              <span class="font-12 color-gray">24H最高价</span>
              <span class="font-12 color-black mt-5">26,620.1</span>
            </div>
            <!--          24H最低价-->
            <div class="flex flex-column align-start pad-lr-15">
              <span class="font-12 color-gray">24H最低价</span>
              <span class="font-12 color-black mt-5">26,515.1</span>
            </div>
            <!--          24H成交量（BTC）-->
            <div class="flex flex-column align-start pad-lr-15">
              <span class="font-12 color-gray">24H成交量（BTC）</span>
              <span class="font-12 color-black mt-5">7,648 BTC</span>
            </div>
            <!--          24H成交额（USDT）-->
            <div class="flex flex-column align-start pad-lr-15">
              <span class="font-12 color-gray">24H成交额（USDT）</span>
              <span class="font-12 color-black mt-5">203,185,228.3</span>
            </div>
          </div>
          <div class="rightButtons">
            <a-tooltip position="top" background-color="var(--tooltip-bg)">
              <div class="item" @click="aboutCoinShow">
                <icon-bookmark size="16"/>
              </div>
              <template #content>
                <p class="font-12 color-always-white">{{ coinName }}USDT介绍</p>
              </template>
            </a-tooltip>
            <a-tooltip position="top" background-color="var(--tooltip-bg)">
              <div class="item" @click="settingDrawer = true">
                <icon-settings size="16"/>
              </div>
              <template #content>
                <p class="font-12 color-always-white">偏好设置</p>
              </template>
            </a-tooltip>
          </div>
        </div>
      </div>
      <div class="content_main mt-10 flex jus-bet">
        <!--  -->
        <div class="content_main_left">
          <div class="left_top flex flex-row jus-bet">
            <!--            chart-->
            <div class="block_kline">
              <!--              table-->
              <div class="kline_table">
                <div class="tab_top">
                  <div class="left">
                    <div class="tabItem" :class="{'tabActive' : chartTabIndex == 0}" @click="chartTabIndex = 0">
                      <span>
                        图表
                      </span>
                    </div>
                    <div class="tabItem" :class="{'tabActive' : chartTabIndex == 1}" @click="chartTabIndex = 1">
                      <span>
                        币种概括
                      </span>
                    </div>
                  </div>
                  <div class="right">
                    <div class="buttons">
                      <span :class="{'chartTypeActive': chartType == 0}" @click="chartType = 0">基础版</span>
                      <span :class="{'chartTypeActive': chartType == 1}" @click="chartType = 1">TradingView</span>
                    </div>
                    <div class="iconMenu">
                      <icon-apps/>
                    </div>
                  </div>
                </div>
                <!--           -->
                <div class="timeTab">
                  <div class="left">
                    <div class="timeGroup">
                      <div class="item"
                           :class="{'item-active' : timeActive == index}"
                           v-for="(item,index) in selectTime"
                           :key="index"
                           @click="timeActive = index">
                        <span>{{ item }}</span>
                      </div>
                      <a-trigger :render-to-body="false"
                                 :popup-translate="[180, 5]"
                                 trigger="hover"
                                 type="timeChange">
                        <div class="iconDown">
                          <icon-caret-down size="14"/>
                        </div>
                        <template #content>
                          <div class="timeChange">
                            <div class="changeTab">
                              <span>选择周期</span>
                              <span @click="timeEdit = !timeEdit">编辑</span>
                            </div>
                            <div>
                            </div>
                            <a-checkbox-group class="timeList" :default-value="['1']">
                              <a-checkbox value="1"
                                          class="tagItem"
                                          :class="{'tagItem-active': item.checked == true}"
                                          v-for="(item,index) in timeOptions" :key="index"
                                          @click="item.checked = !item.checked">
                                <template #checkbox="{ checked }">
                                  <div class="checkbox" v-if="timeEdit">
                                    <span></span>
                                  </div>
                                </template>
                                <span class="color-black">{{ item }}</span>
                              </a-checkbox>
                            </a-checkbox-group>
                          </div>
                        </template>
                      </a-trigger>
                    </div>
                  </div>
                  <div class="right">
                    <a-dropdown trigger="hover" @select="priveChange">
                      <div class="newPirce">
                        <span class="mr-5">{{ priceTypeVal }}</span>
                        <icon-caret-down/>
                      </div>
                      <template #content>
                        <a-doption>最新价格</a-doption>
                        <a-doption>标记价格</a-doption>
                        <a-doption>指数价格</a-doption>
                      </template>
                    </a-dropdown>
                  </div>
                </div>
              </div>
              <!--              kline box-->
              <div class="kline_box flex align-center jus-center">
                <h1 class="color-black">图表区域</h1>
              </div>
            </div>
            <!--      委托列表/实时成交      -->
            <div class="block_list">
              <a-tabs default-active-key="1">
                <a-tab-pane key="1" title="委托列表">
                  <div class="_top pad-lr-15 flex flex-row align-center jus-bet">
                    <div class="flex align-center listTagGroup">
                      <a-tooltip content="买卖盘" position="top" background-color="var(--tooltip-bg)">
                        <div class="item flex align-center jus-center"
                             :class="listType == 0 ? 'item-active' : ''" @click="listType = 0">
                          <img src="@/assets/images/cut-top-bottom.svg">
                        </div>
                      </a-tooltip>
                      <a-tooltip content="买盘" position="top" background-color="var(--tooltip-bg)">
                        <div class="item flex align-center jus-center"
                             :class="listType == 1 ? 'item-active' : ''" @click="listType = 1">
                          <img src="@/assets/images/cut-buy.svg">
                        </div>
                      </a-tooltip>
                      <a-tooltip content="卖盘" position="top" background-color="var(--tooltip-bg)">
                        <div class="item flex align-center jus-center"
                             :class="listType == 2 ? 'item-active' : ''" @click="listType = 2">
                          <img src="@/assets/images/cut-ask.svg">
                        </div>
                      </a-tooltip>
                    </div>
                    <a-dropdown trigger="hover" @select="numSelect">
                      <div class="flex align-center jus-end numSelect">
                        <span class="mr-5">{{ decimal }}</span>
                        <icon-caret-down/>
                      </div>
                      <template #content>
                        <a-doption>0.000001</a-doption>
                        <a-doption>0.00001</a-doption>
                        <a-doption>0.0001</a-doption>
                      </template>
                    </a-dropdown>
                  </div>
                  <!--                -->
                  <div class="list-main">
                    <div class="down_group group_box" v-if="listType == 0 || listType == 2">
                      <div class="_cell flex align-center jus-bet" type="title">
                        <span class="font-12 color-gray">价格</span>
                        <span class="font-12 color-gray">数量 ({{ coinName }})</span>
                        <span class="font-12 color-gray">总计 ({{ coinName }})</span>
                      </div>
                      <div v-if="listType == 2">
                        <div class="_cell flex align-center jus-bet" type="title">
                          <span class="font-12 color-gray">最新价</span>
                          <a-tooltip position="bottom" background-color="var(--tooltip-bg)">
                            <span class="font-12 color-gray pointer dotted">标记价</span>
                            <template #content>
                              <span
                                  class="font-12 color-always-white">标记价格用于结算盈亏和触发强制减仓。标记价格可能会偏离市场最新价格，以防因市场被恶意操纵造成损失。</span>
                            </template>
                          </a-tooltip>
                        </div>
                        <div class="_cell flex align-center jus-bet mb-5">
                          <span class="font-16 color-red font-bold">0.061335<icon-arrow-down
                              style="margin-top: 1px"/></span>
                          <span class="font-16 color-gray font-bold">0.061335</span>
                        </div>
                      </div>
                      <!--                      -->
                      <a-scrollbar :class="listType == 0 ? 'minList' : 'priceDownList'">
                        <div class="_cell flex align-center jus-bet pointer hover-bg"
                             v-for="(item,index) in (listType == 0 ? List.slice(0, 9) : List)"
                             :key="index">
                          <span class="font-12 color-red">{{ item.price }}</span>
                          <span class="font-12 color-black">{{ item.num }}</span>
                          <span class="font-12 color-black">{{ item.total }}</span>
                          <!-- 背景盒子     -->
                          <div class="_cell_bg red_bg" :style="{'--width':item.price * 100 + '%' }"></div>
                        </div>
                      </a-scrollbar>
                    </div>
                    <div class="up_group group_box" v-if="listType == 1 || listType == 0">
                      <div>
                        <div class="_cell flex align-center jus-bet" type="title">
                          <span class="font-12 color-gray">最新价</span>
                          <a-tooltip position="bottom" background-color="var(--tooltip-bg)">
                            <span class="font-12 color-gray pointer dotted">标记价</span>
                            <template #content>
                              <span
                                  class="font-12 color-always-white">标记价格用于结算盈亏和触发强制减仓。标记价格可能会偏离市场最新价格，以防因市场被恶意操纵造成损失。</span>
                            </template>
                          </a-tooltip>
                        </div>
                        <div class="_cell flex align-center jus-bet mb-5">
                          <span class="font-16 color-green font-bold">0.061335<icon-arrow-up
                              style="margin-top: 1px"/></span>
                          <span class="font-16 color-gray font-bold">0.061335</span>
                        </div>
                      </div>
                      <!--                      -->
                      <a-scrollbar :class="listType == 0 ? 'minList' : 'maxList'">
                        <div class="_cell flex align-center jus-bet pointer hover-bg"
                             v-for="(item,index) in (listType == 0 ? List.slice(0, 9) : List)"
                             :key="index">
                          <span class="font-12 color-green">{{ item.price }}</span>
                          <span class="font-12 color-black">{{ item.num }}</span>
                          <span class="font-12 color-black">{{ item.total }}</span>
                          <!-- 背景盒子     -->
                          <div class="_cell_bg green-bg" :style="{'--width':item.price * 100 + '%' }"></div>
                        </div>
                      </a-scrollbar>
                    </div>
                  </div>
                </a-tab-pane>
                <a-tab-pane key="2" title="实时成交">
                  <div class="list-main">
                    <div class="down_group group_box">
                      <div class="_cell flex align-center jus-bet" type="title">
                        <span class="font-12 color-gray">价格</span>
                        <span class="font-12 color-gray">数量 ({{ coinName }})</span>
                        <span class="font-12 color-gray">时间</span>
                      </div>
                      <a-scrollbar class="scrollbar">
                        <!--                      -->
                        <div class="_cell flex align-center jus-bet pointer hover-bg" v-for="(item,index) in List"
                             :key="index">
                          <span class="font-12" :class="index % 2 === 0 ? 'color-red' : 'color-green'">{{
                              item.price
                            }}</span>
                          <span class="font-12 color-black">{{ item.num }}</span>
                          <span class="font-12 color-black">23:53:40</span>
                          <!-- 背景盒子     -->
                          <div class="_cell_bg" :class="index % 2 === 0 ? 'red_bg' : 'green-bg'"
                               :style="{'--width':item.price * 100 + '%' }"></div>
                        </div>
                      </a-scrollbar>
                    </div>
                  </div>
                </a-tab-pane>
              </a-tabs>
            </div>
          </div>
          <div class="left_bottom">
            <!--   持仓/当前委托... -->
            <div class="left_bottom_card">
              <a-tabs animation default-active-key="1">
                <a-tab-pane key="1" title="持仓">
                  <a-scrollbar style="height:306px;overflow: auto;">
                    <!--   table    -->
                    <div class="top_table flex flex-row align-center" type="first">
                      <div class="tableItem">
                        <span class="font-12 color-gray">合约</span>
                      </div>
                      <div class="tableItem">
                        <span class="font-12 color-gray">持仓方向</span>
                      </div>
                      <div class="tableItem">
                        <a-tooltip type="subtip" position="bottom" background-color="var(--tooltip-bg)">
                          <span class="font-12 color-gray dotted pointer">仓位/可平</span>
                          <template #content>
                            <p class="font-12 color-always-white">
                              仓位： 你当前持有的仓位大小。正数代表多仓，负数代表空仓。
                            </p>
                            <p class="font-12 color-always-white">
                              可平：剩余待平的仓位数量。提交平仓或只减仓类型的订单会减少可平数量。
                            </p>
                          </template>
                        </a-tooltip>
                      </div>
                      <div class="tableItem">
                        <a-tooltip type="subtip" position="bottom" background-color="var(--tooltip-bg)">
                          <span class="font-12 color-gray dotted pointer">持仓均价</span>
                          <template #content>
                            <p class="font-12 color-always-white">
                              该多仓/空仓开仓时的平均买入/卖出价。
                            </p>
                          </template>
                        </a-tooltip>
                      </div>
                      <div class="tableItem">
                        <a-tooltip type="subtip" position="bottom" background-color="var(--tooltip-bg)">
                          <span class="font-12 color-gray dotted pointer">标记价格</span>
                          <template #content>
                            <p class="font-12 color-always-white">
                              标记价格用于结算盈亏和触发强制减仓。标记价格可能会偏离市场最新价格，以防因市场被恶意操纵造成损失。
                            </p>
                            <p class="font-12 color-always-white">
                              标记价格可能会偏离市场最新价格，以防因市场被恶意操纵造成损失。
                            </p>
                          </template>
                        </a-tooltip>
                      </div>
                      <div class="tableItem">
                        <a-tooltip type="subtip" position="bottom" background-color="var(--tooltip-bg)">
                          <span class="font-12 color-gray dotted pointer">预估强平价格</span>
                          <template #content>
                            <p class="font-12 color-always-white">当标记价格接近这个值时，此仓位会被强制平仓。</p>
                          </template>
                        </a-tooltip>
                      </div>
                      <div class="tableItem">
                        <a-tooltip type="subtip" position="bottom" background-color="var(--tooltip-bg)">
                          <span class="font-12 color-gray dotted pointer">保证金</span>
                          <template #content>
                            <p class="font-12 color-always-white">
                              用于担保此仓位的资产。逐仓为此仓位的实际担保资产。全仓为当前杠杆下所需的担保资产。
                            </p>
                            <p class="font-12 color-always-white">
                              逐仓为此仓位的实际担保资产。全仓为当前杠杆下所需的担保资产。
                            </p>
                          </template>
                        </a-tooltip>
                      </div>
                      <div class="tableItem">
                        <a-tooltip type="subtip" position="bottom" background-color="var(--tooltip-bg)">
                          <span class="font-12 color-gray dotted pointer">保证金率</span>
                          <template #content>
                            <p class="font-12 color-always-white">
                              此仓位的担保资产相对于仓位价值的比值。当保证金率 &lt;= 维持保证金率+ 平仓taker费率,此仓位将被强制平仓。
                            </p>
                            <p class="font-12 color-always-white">
                              当保证金率 &lt;= 维持保证金率+ 平仓taker费率,此仓位将被强制平仓。
                            </p>
                          </template>
                        </a-tooltip>
                      </div>
                      <div class="tableItem">
                        <a-tooltip type="subtip" position="bottom" background-color="var(--tooltip-bg)">
                          <span class="font-12 color-gray dotted pointer">持仓中盈亏/回报率</span>
                          <template #content>
                            <p class="font-12 color-always-white">
                              多仓盈亏 = (当前标记价格 - 持仓均价) * 持仓币量
                            </p>
                            <p class="font-12 color-always-white">
                              空仓盈亏 = (持仓均价 - 当前标记价格) * 持仓币量
                            </p>
                            <p class="font-12 color-always-white">
                              回报率 = 盈亏 / (持仓均价 * 持仓币量 / 杠杆倍数) * 100%
                            </p>
                          </template>
                        </a-tooltip>
                      </div>
                      <div class="tableItem">
                        <span class="font-12 color-gray">已实现盈亏</span>
                      </div>
                      <div class="tableItem">
                        <span class="font-12 color-gray">操作</span>
                      </div>
                      <div class="tableItem">
                        <a-tooltip type="subtip" position="bottom" background-color="var(--tooltip-bg)">
                          <span class="font-12 color-gray dotted pointer">止盈价/止损价</span>
                          <template #content>
                            <p class="font-12 color-always-white">
                              止盈止损订单是指，预先设置触发价、委托价和下单数量，最新价达到触发价，会按委托价挂单，帮助您保住利润或减少损失
                            </p>
                          </template>
                        </a-tooltip>
                      </div>
                    </div>
                    <div class="listWarp" type="first">
                      <a-scrollbar outer-class="listWarp">
                        <!--  列表项 -->
                        <div class="cell flex flex-row align-center" v-for="i in 10">
                          <!--  合约 -->
                          <div class="cellItem">
                            <span class="coinBefore bg-red"></span>
                            <span class="coin color-black">XRPUSDT</span>
                            <a-tag color="var(--opacity-gray)">
                              <span class="color-black">全仓50X</span>
                            </a-tag>
                          </div>
                          <!--  持仓方向 -->
                          <div class="cellItem">
                            <a-tag color="var(--opacity-sell)">
                              <span class="color-red">开空</span>
                            </a-tag>
                          </div>
                          <!--  仓位/开平 -->
                          <div class="cellItem">
                            <span class="color-red">-60.52/60.52 USDT</span>
                          </div>
                          <!--  持仓均价 -->
                          <div class="cellItem">
                            <span class="color-black">0.6041</span>
                          </div>
                          <!--  标记价格 -->
                          <div class="cellItem">
                            <span class="color-black">0.6041</span>
                          </div>
                          <!--  预估强平价格 -->
                          <div class="cellItem">
                            <span class="color-black">0.6041</span>
                          </div>
                          <!--  保证金 -->
                          <div class="cellItem">
                            <span class="color-black">0.6041</span>
                          </div>
                          <!--  保证金率 -->
                          <div class="cellItem">
                            <span class="color-black">30.10%</span>
                          </div>
                          <!--  持仓中盈亏/回报率 -->
                          <div class="cellItem">
                            <span class="color-red mr-5">-0.15</span>
                            <span class="color-black">USDT</span>
                            <a-tag color="var(--opacity-sell)">
                              <span class="color-red">-12.41%</span>
                            </a-tag>
                          </div>
                          <!--  已实现盈亏 -->
                          <div class="cellItem">
                            <span class="color-black">+0.0347USDT</span>
                          </div>
                          <div class="cellItem">
                            <span class="color-primary cursor mr-5" @click="closePotion = true">平仓</span>
                            <span class="color-primary cursor" @click="flashClose = true">闪电平仓</span>
                          </div>
                          <div class="cellItem">
                            <span class="mr-5 color-black">--/--</span>
                            <icon-edit class="cursor color-black" @click="profitLossPop = true"/>
                          </div>
                        </div>
                      </a-scrollbar>
                    </div>
                    <notlog style="height: calc(100% - 55px)" v-if="false"></notlog>
                  </a-scrollbar>
                </a-tab-pane>
                <a-tab-pane key="2" title="当前委托">
                  <a-scrollbar style="height:306px;overflow: auto;">
                    <!--   table    -->
                    <div class="top_table flex flex-row align-center" type="tab2">
                      <div class="tableItem">
                        <span class="font-12 color-gray">合约</span>
                      </div>
                      <div class="tableItem">
                        <span class="font-12 color-gray">方向</span>
                      </div>
                      <div class="tableItem">
                        <span class="font-12 color-gray">类型</span>
                      </div>
                      <div class="tableItem">
                        <span class="font-12 color-gray">成交均价/委托价格</span>
                      </div>
                      <div class="tableItem">
                        <span class="font-12 color-gray">成交数量/委托数量</span>
                      </div>
                      <div class="tableItem">
                        <span class="font-12 color-gray">完成度</span>
                      </div>
                      <a-tooltip type="subtip" position="bottom" background-color="var(--tooltip-bg)">
                        <div class="tableItem">
                          <span class="font-12 color-gray dotted pointer">止盈价/止损价</span>
                        </div>
                        <template #content>
                          <p class="font-12 color-always-white">
                            当最新价格触达止盈或止损价格时，将自动市价委托平仓（平仓量=当前委托单委托数量）
                          </p>
                        </template>
                      </a-tooltip>
                      <div class="tableItem">
                        <span class="font-12 color-gray">时间</span>
                      </div>
                      <div class="tableItem">
                        <span class="font-12 color-gray">操作</span>
                      </div>
                    </div>
                    <div class="listWarp" type="tab2">
                      <a-scrollbar outer-class="listWarp">
                        <!--  列表项 -->
                        <div class="cell flex flex-row align-center" v-for="i in 10">
                          <!--  合约 -->
                          <div class="cellItem">
                            <span class="coin color-black">XRPUSDT</span>
                          </div>
                          <!--  方向 -->
                          <div class="cellItem">
                            <a-tag color="var(--opacity-sell)">
                              <span class="color-red">开空</span>
                            </a-tag>
                          </div>
                          <!--  类型 -->
                          <div class="cellItem">
                            <span class="color-black">限价单</span>
                          </div>
                          <!--  成交均价/委托价格 -->
                          <div class="cellItem">
                            <span class="color-black">0.6041/0.6900</span>
                          </div>
                          <!--  成交数量/委托数量 -->
                          <div class="cellItem">
                            <span class="color-black">0.000/69.00 USDT</span>
                          </div>
                          <!--  完成度 -->
                          <div class="cellItem">
                            <span class="color-black">0%</span>
                          </div>
                          <!--  止盈价/止损价 -->
                          <div class="cellItem">
                            <span class="color-black" v-if="i === 1">--/--</span>
                            <span class="color-black" v-if="i != 1">0.6500/0.6990</span>
                          </div>
                          <!--  时间 -->
                          <div class="cellItem">
                            <span class="color-black">2023-11-28 20:11:17</span>
                          </div>
                          <!--  撤单 -->
                          <div class="cellItem">
                            <span class="color-primary cursor" @click="wdOrder = true">撤单</span>
                          </div>
                        </div>
                      </a-scrollbar>
                    </div>
                    <notlog style="height: calc(100% - 55px)" v-if="false"></notlog>
                  </a-scrollbar>
                </a-tab-pane>
                <a-tab-pane key="3" title="计划委托">
                  <a-scrollbar style="height:306px;overflow: auto;">
                    <!--   table    -->
                    <div class="top_table flex flex-row align-center" type="tab3">
                      <div class="tableItem">
                        <span class="font-12 color-gray">合约</span>
                      </div>
                      <div class="tableItem">
                        <span class="font-12 color-gray">方向</span>
                      </div>
                      <div class="tableItem">
                        <span class="font-12 color-gray">触发价格</span>
                      </div>
                      <div class="tableItem">
                        <span class="font-12 color-gray">委托价格</span>
                      </div>
                      <div class="tableItem">
                        <span class="font-12 color-gray">委托数量</span>
                      </div>
                      <div class="tableItem">
                        <span class="font-12 color-gray">类型</span>
                      </div>
                      <div class="tableItem">
                        <span class="font-12 color-gray">提交时间</span>
                      </div>
                      <div class="tableItem">
                        <span class="font-12 color-gray">过期时间</span>
                      </div>
                      <div class="tableItem">
                        <span class="font-12 color-gray">操作</span>
                      </div>
                    </div>
                    <div class="listWarp" type="tab3">
                      <a-scrollbar outer-class="listWarp">
                        <!--  列表项 -->
                        <div class="cell flex flex-row align-center" v-for="i in 10">
                          <!--  合约 -->
                          <div class="cellItem">
                            <span class="coin color-black">XRPUSDT</span>
                          </div>
                          <!--  方向 -->
                          <div class="cellItem">
                            <a-tag color="var(--opacity-sell)">
                              <span class="color-red">开空</span>
                            </a-tag>
                          </div>
                          <!--  触发价格 -->
                          <div class="cellItem">
                            <span class="color-black">0.2655</span>
                          </div>
                          <!--  委托价格 -->
                          <div class="cellItem">
                            <span class="color-black">0.6041</span>
                          </div>
                          <!--  委托数量 -->
                          <div class="cellItem">
                            <span class="color-black">69.00 USDT</span>
                          </div>
                          <!--  类型 -->
                          <div class="cellItem">
                            <span class="color-black">现价单</span>
                          </div>
                          <!--  提交时间 -->
                          <div class="cellItem">
                            <span class="color-black">2023-11-28 20:11:17</span>
                          </div>
                          <!--  过期时间 -->
                          <div class="cellItem">
                            <span class="color-black">2023-11-28 20:11:17</span>
                          </div>
                          <!--  撤单 -->
                          <div class="cellItem">
                            <span class="color-primary cursor" @click="wdOrder = true">撤单</span>
                          </div>
                        </div>
                      </a-scrollbar>
                    </div>
                    <notlog style="height: calc(100% - 55px)" v-if="false"></notlog>
                  </a-scrollbar>
                </a-tab-pane>
                <a-tab-pane key="4" title="历史委托">
                  <a-scrollbar style="height:306px;overflow: auto;">
                    <!--   table    -->
                    <div class="top_table flex flex-row align-center" type="tab4">
                      <div class="tableItem">
                        <span class="font-12 color-gray">合约</span>
                      </div>
                      <div class="tableItem">
                        <span class="font-12 color-gray">方向</span>
                      </div>
                      <div class="tableItem">
                        <span class="font-12 color-gray">类型</span>
                      </div>
                      <div class="tableItem">
                        <span class="font-12 color-gray">成交均价/委托价格</span>
                      </div>
                      <div class="tableItem">
                        <span class="font-12 color-gray">成交数量/委托数量</span>
                      </div>
                      <div class="tableItem">
                        <span class="font-12 color-gray">盈亏</span>
                      </div>
                      <div class="tableItem">
                        <span class="font-12 color-gray">手续费</span>
                      </div>
                      <div class="tableItem">
                        <span class="font-12 color-gray">状态</span>
                      </div>
                      <div class="tableItem">
                        <span class="font-12 color-gray">时间</span>
                      </div>
                      <div class="tableItem">
                        <span class="font-12 color-gray">操作</span>
                      </div>
                    </div>
                    <div class="listWarp" type="tab4">
                      <a-scrollbar outer-class="listWarp">
                        <!--  列表项 -->
                        <div class="cell flex flex-row align-center" v-for="i in 10">
                          <!--  合约 -->
                          <div class="cellItem">
                            <span class="coin color-black">XRPUSDT</span>
                          </div>
                          <!--  方向 -->
                          <div class="cellItem">
                            <a-tag color="var(--opacity-sell)" v-if="i != 1">
                              <span class="color-red">卖出开空</span>
                            </a-tag>
                            <a-tag color="var(--opacity-buy)" v-if="i == 1">
                              <span class="color-green">买入平空</span>
                            </a-tag>
                          </div>
                          <!--  类型 -->
                          <div class="cellItem">
                            <span class="color-black">市价单</span>
                          </div>
                          <!--  成交均价/委托价格 -->
                          <div class="cellItem">
                            <span class="color-black">0.6041/市价</span>
                          </div>
                          <!--  成交数量/委托数量 -->
                          <div class="cellItem">
                            <span class="color-black">60.41/69.00 USDT</span>
                          </div>
                          <!--  盈亏 -->
                          <div class="cellItem">
                            <span class="color-black" v-if="i == 1">--</span>
                            <span class="color-red" v-if="i != 1">-0.0300 USDT</span>
                          </div>
                          <!--  手续费 -->
                          <div class="cellItem">
                            <span class="color-black">0.0300 USDT</span>
                          </div>
                          <!--  状态 -->
                          <div class="cellItem">
                            <span class="color-black">完全成交</span>
                          </div>
                          <!--  时间 -->
                          <div class="cellItem">
                            <span class="color-black">2023-11-28 18:20:15</span>
                          </div>
                          <!--  订单详情 -->
                          <div class="cellItem">
                            <span class="color-primary cursor" @click="orderInfoPopup = true">详情</span>
                          </div>
                        </div>
                      </a-scrollbar>
                    </div>
                    <notlog style="height: calc(100% - 55px)" v-if="false"></notlog>
                  </a-scrollbar>
                </a-tab-pane>
                <a-tab-pane key="5" title="盈亏记录">
                  <a-scrollbar style="height:306px;overflow: auto;">
                    <!--   table    -->
                    <div class="top_table flex flex-row align-center" type="tab5">
                      <div class="tableItem">
                        <span class="font-12 color-gray">合约</span>
                      </div>
                      <div class="tableItem">
                        <span class="font-12 color-gray">开仓均价</span>
                      </div>
                      <div class="tableItem">
                        <span class="font-12 color-gray">平仓均价</span>
                      </div>
                      <div class="tableItem">
                        <span class="font-12 color-gray">仓位数量</span>
                      </div>
                      <a-tooltip type="subtip" position="bottom" background-color="var(--tooltip-bg)">
                        <div class="tableItem">
                          <span class="font-12 color-gray pointer dotted">已实现盈亏</span>
                        </div>
                        <template #content>
                          <p class="font-12 color-always-white">
                            总盈亏为该仓位持仓期间累计发生的总盈亏，总盈亏 = 手续费+资金费用+仓位盈亏+分摊
                          </p>
                        </template>
                      </a-tooltip>
                      <div class="tableItem">
                        <span class="font-12 color-gray">分润</span>
                      </div>
                      <a-tooltip type="subtip" position="bottom" background-color="var(--tooltip-bg)">
                        <div class="tableItem">
                          <span class="font-12 color-gray pointer dotted">手续费</span>
                        </div>
                        <template #content>
                          <p class="font-12 color-always-white">
                            开仓、平仓累计总手续费
                          </p>
                        </template>
                      </a-tooltip>
                      <a-tooltip type="subtip" position="bottom" background-color="var(--tooltip-bg)">
                        <div class="tableItem">
                          <span class="font-12 color-gray pointer dotted">资金费用</span>
                        </div>
                        <template #content>
                          <p class="font-12 color-always-white">
                            持仓期间的总资金费用
                          </p>
                        </template>
                      </a-tooltip>
                      <a-tooltip type="subtip" position="bottom" background-color="var(--tooltip-bg)">
                        <div class="tableItem">
                          <span class="font-12 color-gray pointer dotted">仓位盈亏</span>
                        </div>
                        <template #content>
                          <p class="font-12 color-always-white">
                            仓位盈亏为仓位每次平仓的仓位盈亏之和，平仓盈亏根据 平仓前的持仓均价和平仓均价计算得出
                          </p>
                        </template>
                      </a-tooltip>
                      <div class="tableItem">
                        <span class="font-12 color-gray">分摊</span>
                      </div>
                      <div class="tableItem">
                        <span class="font-12 color-gray">平仓时间</span>
                      </div>
                    </div>
                    <div class="listWarp" type="tab5">
                      <a-scrollbar outer-class="listWarp">
                        <!--  列表项 -->
                        <div class="cell flex flex-row align-center" v-for="i in 10">
                          <!--  合约 -->
                          <div class="cellItem">
                            <span class="coinBefore bg-red"></span>
                            <span class="coin color-black">XRPUSDT</span>
                            <a-tag color="var(--opacity-gray)">
                              <span class="color-black">全仓50X</span>
                            </a-tag>
                          </div>
                          <!--  开仓均价 -->
                          <div class="cellItem">
                            <span class="color-black">0.2562</span>
                          </div>
                          <!--  平仓均价 -->
                          <div class="cellItem">
                            <span class="color-black">0.2562</span>
                          </div>
                          <!--  仓位数量 -->
                          <div class="cellItem">
                            <span class="color-black">100 XRP</span>
                          </div>
                          <!--  已实盈亏 -->
                          <div class="cellItem">
                            <span class="color-red">-1.2650 USDT</span>
                          </div>
                          <!--  分润 -->
                          <div class="cellItem">
                            <span class="color-black">0.00 USDT</span>
                          </div>
                          <!--  手续费 -->
                          <div class="cellItem">
                            <span class="color-red">-0.0725 USDT</span>
                          </div>
                          <!--  资金费用 -->
                          <div class="cellItem">
                            <span class="color-black">0.00 USDT</span>
                          </div>
                          <!--  仓位盈亏 -->
                          <div class="cellItem">
                            <span class="color-red">-0.0300 USDT</span>
                          </div>
                          <!--  分摊 -->
                          <div class="cellItem">
                            <span class="color-black">0.00 USDT</span>
                          </div>
                          <!--  平仓时间 -->
                          <div class="cellItem">
                            <span class="color-black">2023-11-28 10:28:26</span>
                          </div>
                        </div>
                      </a-scrollbar>
                    </div>
                    <notlog style="height: calc(100% - 55px)" v-if="false"></notlog>
                  </a-scrollbar>
                </a-tab-pane>
                <a-tab-pane key="6" title="成交记录">
                  <a-scrollbar style="height:306px;overflow: auto;">
                    <!--   table    -->
                    <div class="top_table flex flex-row align-center" type="tab6">
                      <div class="tableItem">
                        <span class="font-12 color-gray">合约</span>
                      </div>
                      <div class="tableItem">
                        <span class="font-12 color-gray">开仓均价</span>
                      </div>
                      <div class="tableItem">
                        <span class="font-12 color-gray">方向</span>
                      </div>
                      <div class="tableItem">
                        <span class="font-12 color-gray">成交数量</span>
                      </div>
                      <div class="tableItem">
                        <span class="font-12 color-gray">角色</span>
                      </div>
                      <div class="tableItem">
                        <span class="font-12 color-gray">手续费</span>
                      </div>
                      <div class="tableItem">
                        <span class="font-12 color-gray">时间</span>
                      </div>
                    </div>
                    <div class="listWarp" type="tab6">
                      <a-scrollbar outer-class="listWarp">
                        <!--  列表项 -->
                        <div class="cell flex flex-row align-center" v-for="i in 10">
                          <!--  合约 -->
                          <div class="cellItem">
                            <span class="coin color-black">XRPUSDT</span>
                          </div>
                          <!--  开仓均价 -->
                          <div class="cellItem">
                            <span class="color-black">0.2562</span>
                          </div>
                          <!--  方向 -->
                          <div class="cellItem">
                            <a-tag color="var(--opacity-sell)" v-if="i % 2 == 0">
                              <span class="color-red">卖出开空</span>
                            </a-tag>
                            <a-tag color="var(--opacity-buy)" v-if="i % 2 != 0">
                              <span class="color-green">买入平空</span>
                            </a-tag>
                          </div>
                          <!--  成交数量 -->
                          <div class="cellItem">
                            <span class="color-black">100 XRP</span>
                          </div>
                          <!--  角色 -->
                          <div class="cellItem">
                            <span class="color-black">taker</span>
                          </div>
                          <!--  手续费 -->
                          <div class="cellItem">
                            <span class="color-black">0.0725 USDT</span>
                          </div>
                          <!--  时间 -->
                          <div class="cellItem">
                            <span class="color-black">2023-11-28 10:28:26</span>
                          </div>
                        </div>
                      </a-scrollbar>
                    </div>
                    <notlog style="height: calc(100% - 55px)" v-if="false"></notlog>
                  </a-scrollbar>
                </a-tab-pane>
              </a-tabs>
            </div>
          </div>
        </div>

        <!--     -->
        <div class="content_main_right">
          <div class="content_main_right_top">
            <div class="_hd flex align-center jus-bet">
              <div class="flex align-center">
                <div class="flex align-center jus-center pointer mr-20" @click="marginModel = true">
                  <span class="font-14 color-gray mr-5">全仓</span>
                  <icon-swap class="color-gray"/>
                </div>
                <div class="flex align-center jus-center pointer" @click="leverArm = true">
                  <span class="font-14 color-gray mr-5">20X</span>
                  <icon-swap class="color-gray"/>
                </div>
              </div>
              <img class="pointer" src="@/assets/images/calculator.svg" @click="calculator = true">
            </div>
            <div class="_mn">
              <!-- 开仓/平仓-->
              <div class="flex align-center jus-bet">
                <div class="btn" :class="btnActive==0?'buyActive':''" @click="btnActive = 0">开仓</div>
                <div class="btn" :class="btnActive==1?'sellActive':''" @click="btnActive = 1">平仓</div>
              </div>
              <!-- 模式-->
              <div class="flex align-center jus-start mode_group">
                <div class="pad-lr-10 pointer mode_item"
                     :class="modeIndex == index ? 'modeAcitve' : ''" v-for="(item,index) in modeOption" :key="index"
                     @click="modeIndex = index">
                  <span class="font-14 color-black" :class="{'font-bold': modeIndex == index}">{{ item }}</span>
                </div>
              </div>
              <!--  -->
              <div class="_row flex align-center jus-bet">
                <div class="flex align-center">
                  <span class="font-12 color-gray mr-5">可用</span>
                  <span class="font-14 color-primary">0.00 USDT</span>
                </div>
                <div class="transferIcon" @click="transfeShow = true">
                  <icon-swap/>
                </div>
              </div>
              <!--  -->
              <div class="_row _row_box flex align-center jus-bet" v-if="modeIndex == 0">
                <span class="font-12 color-gray">价格</span>
                <a-input type="text" placeholder=""/>
                <span class="font-12 color-gray">USDT</span>
              </div>
              <!--  -->
              <div class="_row _row_box flex align-center jus-bet" v-if="modeIndex == 2">
                <a-tooltip background-color="var(--tooltip-bg)">
                  <span class="font-12 color-gray dotted cursor">触发价格</span>
                  <template #content>
                    <p class="font-12 color-always-white">
                      触发价格是追踪委托的激活条件，当市场价格/标记价格触及触发价格，追踪委托被激活。激活后系统开始依据回调幅度和最高价/最低价计算是否要以市场最优价执行将您所设置的数量进行委托挂单。</p>
                  </template>
                </a-tooltip>
                <a-input type="text" placeholder=""/>
                <span class="font-12 color-gray">USDT</span>
              </div>
              <div class="_row flex align-center jus-bet" type="select" v-if="modeIndex == 2">
                <div class="_subbox flex align-center jus-bet">
                  <div class="_subbox_left">
                    <span class="font-12 color-gray">价格</span>
                    <div class="flex align-center jus-end inputBox">
                      <a-input class="mr-10" type="text" placeholder=""/>
                      <span class="font-12 color-gray">USDT</span>
                    </div>
                  </div>
                  <a-dropdown :render-to-body="false" :popup-max-height="false">
                    <div class="selectTag">
                      <a-tooltip position="lb" background-color="var(--tooltip-bg)">
                        <span class="color-black font-12 dotted mr-5">限价</span>
                        <icon-caret-down/>
                        <template #content>
                          <p class="font-12 color-always-white">
                            限价单（Limit
                            Order）按特定限价在订单簿中下达订单。限定价格将由您决定。交易仅在市场达到限价（或更高价格）的达成。因此，通过限价单能够以较低的价格买入，或以高于当前市场价的价格出售。与以当前市场价格立即执行交易的市价单不同，限价单在订单薄上下达，且不会立刻执行。
                          </p>
                        </template>
                      </a-tooltip>
                    </div>
                    <template #content>
                      <a-doption>限价</a-doption>
                      <a-doption>市价</a-doption>
                    </template>
                  </a-dropdown>
                </div>
              </div>
              <!--  -->
              <div class="_row _row_box flex align-center jus-bet" type="select">
                <span class="font-12 color-gray">数量</span>
                <a-input type="text" placeholder=""/>
                <a-dropdown :render-to-body="false" :popup-max-height="false">
                  <span class="font-12 color-gray flex align-center pointer"><b class="mr-5 color-black">DOGE</b> <icon-caret-down/></span>
                  <template #content>
                    <a-doption>DOGE</a-doption>
                    <a-doption>USDT</a-doption>
                  </template>
                </a-dropdown>
              </div>
              <p class="tipText" v-if="balanceTip">您的可用余额不足以支付保证金，请充值或转账</p>
              <!--            -->
              <div class="_row">
                <a-slider :default-value="5" :max="100" :marks="marks"/>
              </div>
              <!--  -->
              <div class="_row flex align-center jus-bet" v-if="btnActive == 0 && modeIndex == 0">
                <div class="flex align-center pointer">
                  <a-checkbox value="1">
                    <span class="font-14 color-gray">止盈止损 </span>
                  </a-checkbox>
                  <a-tooltip position="bottom" background-color="var(--tooltip-bg)">
                    <icon-question-circle-fill class="color-gray pointer"/>
                    <template #content>
                    <span
                        class="font-12 color-always-white">开仓成交后止盈止损方可生效。触发止盈或止损后将自动以市价进行平仓</span>
                    </template>
                  </a-tooltip>
                </div>
              </div>
              <!--  -->
              <div class="_row flex align-center jus-bet" type="select" v-if="modeIndex != 1">
                <div class="flex align-center pointer">
                  <a-checkbox class="mr-5" value="1"></a-checkbox>
                  <a-dropdown :render-to-body="false" :popup-max-height="false">
                    <span class="font-14 color-gray">只做maker <icon-caret-down/></span>
                    <template #content>
                      <a-doption>只做Maker</a-doption>
                      <a-doption>
                        IOC
                        <a-tooltip position="bottom" background-color="var(--tooltip-bg)">
                          <icon-question-circle-fill class="color-gray pointer"/>
                          <template #content>
                            <span class="font-12 color-always-white">未成交部分订单立即取消</span>
                          </template>
                        </a-tooltip>
                      </a-doption>
                      <a-doption>
                        FOK
                        <a-tooltip position="bottom" background-color="var(--tooltip-bg)">
                          <icon-question-circle-fill class="color-gray pointer"/>
                          <template #content>
                            <span class="font-12 color-always-white">订单全部成交或立即取消</span>
                          </template>
                        </a-tooltip>
                      </a-doption>
                    </template>
                  </a-dropdown>
                </div>
              </div>
              <!--            login-->
              <div class="_row _row_box flex align-center jus-center" v-if="isLogin == false">
                <span class="font-14 color-primary mr-5 pointer">登录</span>
                <span class="font-14 color-gray mr-5">或</span>
                <span class="font-14 color-primary pointer">注册</span>
              </div>
              <div class="buttonGroup flex align-center jus-bet" v-else>
                <a-button class="button" mode="buy" @click="balanceTip = true, placeOrder = true">
                  开多
                  <span class="font-12">（看涨）</span>
                </a-button>
                <a-button class="button" mode="sell" @click="balanceTip = true, placeOrder = true">
                  开空
                  <span class="font-12">
                  （看跌）
                  </span>
                </a-button>
              </div>

              <!--  -->
              <div class="_row flex align-center jus-bet">
                <div class="flex align-center">
                  <span class="font-12 color-gray">可开多</span>
                  <a-tooltip position="bottom" background-color="var(--tooltip-bg)">
                    <icon-question-circle-fill class="color-gray pointer mr-5"/>
                    <template #content>
                    <span
                        class="font-12 color-always-white">不考虑手续费时，您的可用余额在当前杠杆水平下的理论最大可买数量或对应的价值</span>
                    </template>
                  </a-tooltip>
                  <span class="font-12 color-black">0 DOGE</span>
                </div>
                <div class="flex align-center">
                  <span class="font-12 color-gray">可开空</span>
                  <a-tooltip position="bottom" background-color="var(--tooltip-bg)">
                    <icon-question-circle-fill class="color-gray pointer mr-5"/>
                    <template #content>
                    <span
                        class="font-12 color-always-white">不考虑手续费时，您的可用余额在当前杠杆水平下的理论最大可卖数量或对应的价值</span>
                    </template>
                  </a-tooltip>
                  <span class="font-12 color-black">0 DOGE</span>
                </div>
              </div>
              <!--  -->
              <div class="_row flex align-center jus-bet">
                <div class="flex align-center">
                  <span class="font-12 color-gray mr-5">保证金</span>
                  <span class="font-12 color-black">0 USDT</span>
                </div>
                <div class="flex align-center">
                  <span class="font-12 color-gray mr-5">保证金</span>
                  <span class="font-12 color-black">0 USDT</span>
                </div>
              </div>
            </div>
          </div>
          <div class="content_main_right_bottom">
            <div class="_hd _bottom_tb flex align-center jus-bet">
              <span class="font-14 color-black">合约信息</span>
              <span class="font-12 color-primary pointer" @click="toDetail">合约数据</span>
            </div>
            <div class="btm_cl flex align-center jus-bet">
              <span class="font-12 color-gray">合约代号</span>
              <span class="font-14 color-black">DOGEUSDT</span>
            </div>
            <div class="btm_cl flex align-center jus-bet">
              <span class="font-12 color-gray">交割日期</span>
              <span class="font-14 color-black">永续</span>
            </div>
            <div class="btm_cl flex align-center jus-bet">
              <span class="font-12 color-gray">合约标的</span>
              <span class="font-14 color-black">DOGEUSDT 指数</span>
            </div>
            <div class="btm_cl flex align-center jus-bet">
              <span class="font-12 color-gray">保证金币种</span>
              <span class="font-14 color-black">USDT</span>
            </div>
            <div class="btm_cl flex align-center jus-bet">
              <span class="font-12 color-gray">合约面值</span>
              <span class="font-14 color-black">100 DOGE</span>
            </div>
            <div class="btm_cl flex align-center jus-bet">
              <span class="font-12 color-gray">最小价格变动单位</span>
              <span class="font-14 color-black">0.000001 USDT</span>
            </div>
            <div class="btm_cl flex align-center jus-bet">
              <span class="font-12 color-gray">最低维持保证金率</span>
              <span class="font-14 color-black">1.00 %</span>
            </div>
            <div class="btm_cl flex align-center jus-bet">
              <span class="font-12 color-gray">结算周期</span>
              <span class="font-14 color-black">1 min</span>
            </div>
          </div>
        </div>
      </div>
    </a-layout-content>
    <a-layout-footer class="layoutFooter">
    </a-layout-footer>

    <!--      币种介绍-->
    <a-modal :closable="false"
             align-center
             width="960px"
             modalClass="modal_main dark"
             mask-style="background:rgba(0, 0, 0, .5)"
             :hide-title="true"
             :footer="false"
             :visible="aboutCoin">
      <div class="modal_main flex flex-row align-center jus-bet">
        <!--          close-->
        <div class="close" @click="aboutCoin=false">
          <icon-close class="color-black pointer" size="24"/>
        </div>
        <!--          -->
        <div class="modal_main_left flex flex-column">
          <p class="font-20 font-bold color-black">币种介绍</p>
          <p class="flex align-center font-20 font-bold color-black">
            <img v-if="coinName == 'BTC'" class="mr-5" src="@/assets/images/coin/btc.png">
            <img v-if="coinName == 'ETH'" class="mr-5" src="@/assets/images/coin/eth.png">
            <img v-if="coinName == 'LINK'" class="mr-5" src="@/assets/images/coin/link.png">
            <img v-if="coinName == 'ADA'" class="mr-5" src="@/assets/images/coin/ada.png">
            <img v-if="coinName == 'DOGE'" class="mr-5" src="@/assets/images/coin/doge.png">
            {{ coinName }}/
            <span v-if="coinName == 'BTC'">Bitcoin</span>
            <span v-if="coinName == 'ETH'">Ethereum</span>
            <span v-if="coinName == 'LINK'">ChainLink</span>
            <span v-if="coinName == 'ADA'">Cardano</span>
            <span v-if="coinName == 'DOGE'">Dogecoin</span>
          </p>
          <!--            排名-->
          <p class="font-14 color-gray flex align-center jus-bet">排名
            <span class="color-black">No.20</span>
          </p>
          <!--            市值-->
          <p class="font-14 color-gray flex align-center jus-bet">市值
            <span class="color-black">￥245.56亿</span>
          </p>
          <!--            市场占有率-->
          <p type="line" class="font-14 color-gray flex align-center jus-bet">市场占有率
            <span class="color-black">0.28%</span>
          </p>
          <!--            流通量-->
          <p class="font-14 color-gray flex align-center jus-bet">流通量
            <span class="color-black">538,099,970 {{ coinName }}</span>
          </p>
          <!--            最大供应量-->
          <p class="font-14 color-gray flex align-center jus-bet">最大供应量
            <span class="color-black">1,000,000,000 {{ coinName }}</span>
          </p>
          <!--            流通率-->
          <p class="font-14 color-gray flex align-center jus-bet">流通率
            <span class="color-black">538,099,970 {{ coinName }}</span>
          </p>
          <!--            发行日期-->
          <p class="font-14 color-gray flex align-center jus-bet">发行日期
            <span class="color-black">2017-09-21</span>
          </p>
          <!--            发行价格-->
          <p type="line" class="font-14 color-gray flex align-center jus-bet">发行价格
            <span class="color-black">￥0.66</span>
          </p>
          <!--            历史最高价-->
          <p class="font-14 color-gray flex align-center jus-bet">历史最高价
            <span class="color-black">￥386.6/<span class="color-gray">2021-09-23</span></span>
          </p>
          <!--            历史最低价-->
          <p class="font-14 color-gray flex align-center jus-bet">历史最低价
            <span class="color-black">￥0.92/<span class="color-gray">2017-09-23</span></span>
          </p>
        </div>
        <div class="modal_main_right flex flex-column">
          <p class="font-20 font-bold color-black">关于{{ coinName }}</p>
          <p class="font-14 color-black">
            {{ coinName }} -
            <span v-if="coinName == 'BTC'">比特币</span>
            <span v-if="coinName == 'ETH'">以太坊</span>
            <span v-if="coinName == 'LINK'"></span>
            <span v-if="coinName == 'ADA'">艾达币</span>
            <span v-if="coinName == 'DOGE'">狗狗币</span>
            简介详情
          </p>
          <a-scrollbar type="embed" class="scroll" v-if="coinName == 'BTC'">
            <p class="font-14 color-black">
              比特币（BitCoin）的概念最初由中本聪在2008年提出，根据中本聪的思路设计发布的开源软件以及建构其上的P2P网络。比特币是一种P2P形式的数字货币。点对点的传输意味着一个去中心化的支付系统。
            </p>
            <p class="font-14 color-black">
              与大多数货币不同，比特币不依靠特定货币机构发行，它依据特定算法，通过大量的计算产生，比特币经济使用整个p2p网络中众多节点构成的分布式数据库来确认并记录所有的交易行为，并使用密码学的设计来确保货币流通各个环节安全性。
            </p>
            <p class="font-14 color-black">
              p2p的去中心化特性与算法本身可以确保无法通过大量制造比特币来人为操控币值。基于密码学的设计可以使比特币只能被真实的拥有者转移或支付。这同样确保了货币所有权与流通交易的匿名性。比特币与其他虚拟货币最大的不同，是其总数量非常有限，具有极强的稀缺性。该货币系统曾在4年内只有不超过1050万个，之后的总数量将被永久限制在2100万个。
            </p>
            <p class="font-14 color-black">
              比特，是一种计算机专业术语，是信息量单位，是由英文BIT音译而来。二进制数的一位所包含的信息就是一比特，如二进制数0100就是4比特。那么，比特这个概念和货币联系到一起，不难看出，比特币非现实货币，而是一种计算机电子虚拟货币，存储在你的电脑上。
            </p>
            <p class="font-14 color-black">
              目前，这种崭新的虚拟货币不受任何政府、任何银行控制。因此，它还未被合法化。
            </p>
          </a-scrollbar>
          <a-scrollbar type="embed" class="scroll" v-if="coinName == 'ETH'">
            <p class="font-14 color-black">
              以太坊（英语：Ethereum）是一个开源的有智能合约功能的公共区块链平台。通过其专用加密货币以太币（Ether，又称“以太币”）提供去中心化的虚拟机（称为“以太虚拟机”Ethereum
              Virtual Machine）来处理点对点合约。
              以太坊的概念首次在2013至2014年间由程序员维塔利克·布特林受比特币启发后提出，大意为“下一代加密货币与去中心化应用平台”，在2014年透过ICO众筹得以开始发展。
              截至2018年6月，以太币是市值第二高的加密货币，以太坊亦被称为“第二代的区块链平台”，仅次于比特币。
            </p>
            <p class="font-14 color-black">
              相较于较大多数其他加密货币或区块链技术，以太坊的特点包括下列：
              智能合约（smart contract）：存储在区块链上的程序，由各节点运行，需要运行程序的人支付手续费给节点的矿工或权益人。
              代币（tokens）：智能合约可以创造代币供分布式应用程序使用。分布式应用程序的代币化让用户、投资者以及管理者的利益一致。代币也可以用来进行首次代币发行。
              叔块（uncle block）：将因为速度较慢而未及时被收入母链的较短区块链并入，以提升交易量。使用的是有向无环图的相关技术。
              权益证明（proof-of-stake）：相较于工作量证明更有效率，可节省大量在挖矿时浪费的电脑资源，并避免特殊应用集成电路造成网络中心化。（测试中）
              支链（Plasma）：用较小的分支区块链运算，只将最后结果写入主链，可提升供单位时间的工作量。（尚未实现）
              状态通道（state channels）：原理类似比特币的闪雷网络，可提升交易速度、降低区块链的负担，并提高可扩展性。尚未实现，开发团队包括雷电网络（Raiden
              Network）和移动性网络（Liquidity Network）。
              分片（sharding）：减少每个节点所需纪录的数据量，并透过平行运算提升效率（尚未实现）。
              分布式应用程序：以太坊上的分布式应用程序不会停机，也不能被关掉。
            </p>
          </a-scrollbar>
          <p class="font-14 color-black" v-if="coinName == 'LINK'">
            LINK是基于以太坊区块链的ERC20标准化代币，用于支付Chainlink节点运营商，以便从脱链数据中检索数据，将数据格式化为区块链可读格式，脱链计算以及保证正常运行时间。Chainlink代币作为运行节点的一部分，可防止不良参与者。
          </p>
          <a-scrollbar type="embed" class="scroll" v-if="coinName == 'ADA'">
            <p class="font-14 color-black">
              加密货币ADA是卡尔达诺（Cardano）协议的所属货币，卡尔达诺可用于发送和接收数字资金。这种数字现金代表着货币的未来，并使通过加密技术确保安全的快速直接转账成为可能。
            </p>
            <p class="font-14 color-black">
              卡尔达诺不仅是一种加密货币，它还是一个技术平台，将能够运行世界各地个人，组织和政府目前每天使用的金融应用程序。该平台是分层构建的，这使系统具有更易于维护的灵活性，并允许通过软叉进行升级。在将要运行Ada的结算层完成之后，将建立一个单独的计算层来处理智能合约，这些数字法律协议将为未来的商业和业务提供支撑。卡尔达诺还将运行分散式应用程序或dapps服务，这些服务不受任何一方控制，而是在区块链上运行。
            </p>
            <p class="font-14 color-black">
              这是第一个根据科学原理开发的区块链项目，也是唯一由全球领先的学者和工程师团队设计和建造的项目。该技术必须安全，灵活且可扩展，以供数百万用户使用，这一点至关重要。因此，来自该领域的一些领先专家的大量思想和关怀已投入到该项目中，并做出了明智的设计决策。应用于航空航天和银行业等关键任务系统的科学严谨性已被带入加密货币领域，并具有高度保证的实施性。我们认为这是第一次完成。
            </p>
            <p class="font-14 color-black">
              卡尔达诺的一项重大创新在于，它将平衡用户需求与监管机构的需求，并将隐私与监管结合起来。卡尔达诺的愿景是，其新型的标准化计算将通过为所有人提供公平金融服务的开放渠道来带来更大的金融包容性。
            </p>
          </a-scrollbar>
          <a-scrollbar type="embed" class="scroll" v-if="coinName == 'DOGE'">
            <p class="font-14 color-black">
              Dogecoin，有人称作"狗狗币/狗币"，诞生于2013年12月8日，基于Scrypt算法，是国际上用户数仅次于比特币的第二大虚拟货币
              。狗币系统上线后，由于reddit的助力(这网站上面的Doge内容泛滥得可怕)，流量呈现爆发式发展，不过两周的时间，狗狗币已经铺开了专门的博客、论坛，截止2015年6月9日，市值达到1亿。由于没有预挖，狗币分发公平，慈善、打赏文化深得人心，用户发展惊人迅速。2015年6月9日狗币客户端地址数160万，是莱特币5倍多；客户端活跃地址数，比特币为19.6万，狗币8.3万，莱特币1.1万；国外twitter关注数15万多用户，是比特币1.7倍，莱特币6倍，facebook点赞数和比特币持平，远超莱特币
              。日真实交易量长期雄踞虚拟币前三，这还是没有上大平台前取得的成绩
              。企业互联网时代最大的价值就是庞大用户，狗狗币用户基数用了一年时间达到比特币用户数的三分之一，莱特币的四倍。
            </p>
          </a-scrollbar>
          <p class="font-20 font-bold color-black">官方链接</p>
          <div class="flex align-center">
            <a href="" class="font-14 color-primary pad-lr-10">官网</a>
            <a href="" class="font-14 color-primary pad-lr-10">Github</a>
            <a href="" class="font-14 color-primary pad-lr-10">区块浏览器</a>
            <a href="" class="font-14 color-primary pad-lr-10">白皮书</a>
          </div>
          <p class="font-20 font-bold color-black">社交媒体</p>
          <div class="flex align-center">
            <img class="img-small mr-12 pointer" src="@/assets/images/instagram.png">
            <img class="img-small mr-12 pointer" src="@/assets/images/tw.png">
            <img class="img-small mr-12 pointer" src="@/assets/images/facebook.png">
          </div>
        </div>
      </div>
    </a-modal>
    <!--      保证金模式 -->
    <a-modal :visible="marginModel"
             align-center
             :render-to-body="false"
             mask-style="background:rgba(0, 0, 0, .5)"
             modal-class="popupWarp"
             width="360px"
             :hide-title="true"
             :mask-closable="true"
             :footer="false">
      <div class="popupBox">
        <div class="popupBox-title">
          <span class="title">BTCUSDT保证金模式</span>
          <icon-close @click="marginModel = false" size="24"/>
        </div>
        <div class="popupBox-content">
          <a-radio-group type="radio" default-value="A" size="large" class="radioGroup">
            <a-radio class="radioLabel" value="A">
              <span class="color-black">全仓</span>
            </a-radio>
            <a-radio class="radioLabel" value="B">
              <span class="color-black">逐仓</span>
            </a-radio>
          </a-radio-group>
          <p class="desc color-gray">全仓保证金模式:
            所有仓位共用合约账户中的保证金来避免仓位被强平。在强平事件中，交易者可能会损失所有的保证金和仓位。</p>
          <div class="buttonGroup">
            <a-button class="button" @click="marginModel = false">
              <span>取消</span>
            </a-button>
            <a-button class="button">
              <span>确定</span>
            </a-button>
          </div>
        </div>
      </div>
    </a-modal>
    <!--      杠杆 -->
    <a-modal :visible="leverArm"
             align-center
             :render-to-body="false"
             mask-style="background:rgba(0, 0, 0, .5)"
             modal-class="popupWarp"
             width="370px"
             :hide-title="true"
             :mask-closable="true"
             :footer="false">
      <div class="popupBox">
        <div class="popupBox-title">
          <span class="title">BTCUSDT 合约杠杆</span>
          <icon-close @click="leverArm = false" size="24"/>
        </div>
        <div class="popupBox-content">
          <div class="inputBox">
            <span>杠杆倍数</span>
            <a-input-number mode="embed" v-model="leverVal" :max="125" :min="1" :hide-button="false" placeholder="1"
                            class="leverInput">
              <template #plus>
                <icon-plus/>
              </template>
              <template #minus>
                <icon-minus/>
              </template>
            </a-input-number>
          </div>
          <div class="sliderBox">
            <a-slider class="slider" v-model="leverVal" :default-value="1" :min="1" :max="125" :marks="leverSlider"/>
            <p v-if="leverVal == 1">当前持仓最高支持杠杆125X</p>
          </div>
          <ul class="tips">
            <li class="tipsItem">
              <span>当前杠杆倍数最高可持有头寸：1BTC</span>
            </li>
            <li class="tipsItem">
              <span>请注意杠杆变化适用于未结头寸和未结订单</span>
            </li>
            <li class="tipsItem">
              <span>选择超过【 125X 】杠杆交易会增加强行平仓风险</span>
            </li>
          </ul>
          <div class="buttonGroup">
            <a-button class="button" @click="leverArm = false">
              <span>取消</span>
            </a-button>
            <a-button class="button">
              <span>确定</span>
            </a-button>
          </div>
        </div>
      </div>
    </a-modal>
    <!--      计算器 -->
    <a-modal :visible="calculator"
             align-center
             :render-to-body="false"
             mask-style="background:rgba(0, 0, 0, .5)"
             modal-class="popupWarp"
             width="672px"
             :hide-title="true"
             :mask-closable="true"
             :footer="false">
      <view class="popupBox">
        <div class="popupBox-title">
          <span class="title">计算器</span>
          <icon-close @click="calculator = false" size="24"/>
        </div>
        <div class="popupBox-content">
          <div class="tabControl">
            <div class="tabItem"
                 v-for="(tItem,index) in tabOption" :key="index"
                 :class="tabIndex === index ? 'tabItem-active' : ''"
                 @click="tabIndex = index">
              <span class="color-always-white">{{ tItem }}</span>
            </div>
          </div>
          <div class="tabContent">
            <div class="tabContent-item">
              <div class="shapedButton">
                <div class="button" :class="buying == 1 ? 'buyActive' : ''" @click="buying = 1">
                  <span class="color-always-white">买入做多</span>
                </div>
                <div class="button" :class="buying == 2 ? 'sellActive' : ''" @click="buying = 2">
                  <span class="color-always-white">卖出做多</span>
                </div>
              </div>
              <div class="inputBox">
                <span>杠杆倍数</span>
                <a-input-number mode="embed"
                                v-model="leverVal"
                                :max="125"
                                :min="0"
                                :hide-button="false"
                                placeholder="0"
                                class="leverInput">
                  <template #plus>
                    <icon-plus/>
                  </template>
                  <template #minus>
                    <icon-minus/>
                  </template>
                </a-input-number>
              </div>
              <div class="sliderBox">
                <a-slider class="slider" v-model="leverVal" :default-value="0" :min="0" :max="125"
                          :marks="leverSlider"/>
                <p>当前杠杆最高可持有头寸数 <span class="color-orange">0.000 BTC</span></p>
              </div>
              <div class="inputBox">
                <span>开仓价格</span>
                <a-input class="input" placeholder="">
                  <template #append>
                    USDT
                  </template>
                </a-input>
              </div>
              <div v-if="tabIndex === 0">
                <div class="inputBox">
                  <span>平仓价格</span>
                  <a-input class="input" placeholder="">
                    <template #append>
                      USDT
                    </template>
                  </a-input>
                </div>
                <div class="inputBox">
                  <span>数量</span>
                  <a-input class="input" placeholder="">
                    <template #append>
                      BTC
                    </template>
                  </a-input>
                </div>
              </div>
              <div v-if="tabIndex === 1">
                <div class="inputBox">
                  <span>回报率</span>
                  <a-input class="input" placeholder="">
                    <template #append>
                      %
                    </template>
                  </a-input>
                </div>
              </div>
              <div v-if="tabIndex === 2">
                <div class="inputBox">
                  <span>仓位数量</span>
                  <a-input class="input" placeholder="">
                    <template #append>
                      BTC
                    </template>
                  </a-input>
                </div>
                <div class="inputBox">
                  <span>保证金数量</span>
                  <a-input class="input" placeholder="">
                    <template #append>
                      USDT
                    </template>
                  </a-input>
                </div>
              </div>
              <div class="buttonGroup">
                <a-button class="button" @click="leverArm = false">
                  <span>计算</span>
                </a-button>
              </div>
            </div>
            <div class="tabContent-item">
              <div class="rowCell title">
                <span>计算结果</span>
              </div>
              <div v-if="tabIndex === 0">
                <div class="rowCell">
                  <span>开仓保证金</span>
                  <span>0 USDT</span>
                </div>
                <div class="rowCell">
                  <span>收益率</span>
                  <span>0%</span>
                </div>
              </div>
              <div class="rowCell" v-if="tabIndex === 1">
                <span>平仓价格</span>
                <span>0 USDT</span>
              </div>
              <div class="rowCell" v-if="tabIndex === 2">
                <span>强平价格</span>
                <span>0 USDT</span>
              </div>
            </div>
          </div>
        </div>
      </view>
    </a-modal>
    <!--    资金划转-->
    <funds-transfe :visible="transfeShow"></funds-transfe>
    <!--    下单-->
    <a-modal :visible="placeOrder"
             align-center
             :render-to-body="false"
             mask-style="background:rgba(0, 0, 0, .5)"
             modal-class="popupWarp"
             width="400px"
             :hide-title="true"
             :mask-closable="true"
             :footer="false">
      <div class="popupBox-title">
        <span class="title">市价单</span>
        <icon-close @click="placeOrder = false" size="20"/>
      </div>
      <div class="popupBox-content">
        <div class="itemCell">
          <a-tag color="var(--opacity-sell)">
            <span class="color-red">空</span>
          </a-tag>
          <span class="title color-black">XRPUSDT</span>
          <a-tag color="var(--opacity-gray)">
            <span class="color-black">全仓50X</span>
          </a-tag>
        </div>
        <div class="itemCell">
          <span class="color-gray">2023-11-28 20:06:46</span>
        </div>
        <div class="itemCell">
          <span class="color-gray">价格</span>
          <span class="color-black">市价</span>
        </div>
        <div class="itemCell">
          <span class="color-gray">数量</span>
          <span class="color-black">100 XRP</span>
        </div>
        <div class="itemCell">
          <span class="color-gray">保证金</span>
          <span class="color-black">1.2102 USDT</span>
        </div>
        <div class="itemCell">
          <a-checkbox v-model="checked">
            <span class="color-gray">下次不要提示，您可在(交易偏好设置)中重新设置</span>
          </a-checkbox>
        </div>
        <div class="buttonGroup">
          <a-button class="button" @click="placeOrder = false">
            <span>取消</span>
          </a-button>
          <a-button class="button">
            <span>确定</span>
          </a-button>
        </div>
      </div>
    </a-modal>
    <!--    平仓-->
    <a-modal :visible="closePotion"
             align-center
             :render-to-body="false"
             mask-style="background:rgba(0, 0, 0, .5)"
             modal-class="popupWarp"
             width="400px"
             :hide-title="true"
             :mask-closable="true"
             :footer="false">
      <div class="popupBox-title">
        <span class="title">平仓</span>
        <icon-close @click="closePotion = false" size="20"/>
      </div>
      <div class="popupBox-content">
        <div class="itemCell">
          <a-tag color="var(--opacity-sell)">
            <span class="color-red">空</span>
          </a-tag>
          <span class="title color-black">XRPUSDT</span>
          <a-tag color="var(--opacity-gray)">
            <span class="color-black">全仓50X</span>
          </a-tag>
        </div>
        <div class="tabControl closePotionTabs">
          <div class="tabItem"
               v-for="(tItem,index) in closePotionTab" :key="index"
               :class="tabIndex === index ? 'tabItem-active' : ''"
               @click="tabIndex = index">
            <span class="color-black">{{ tItem }}</span>
          </div>
        </div>
        <div class="inputBox">
          <span>价格</span>
          <a-input class="input" placeholder="">
            <template #append>
              USDT
            </template>
          </a-input>
        </div>
        <div class="inputBox">
          <span>平仓量价值</span>
          <a-input class="input" placeholder="">
            <template #append>
              USDT
            </template>
          </a-input>
        </div>
        <div class="sliderBox">
          <a-slider class="slider" :default-value="0" :min="0" :max="100"
                    :marks="marks"/>
        </div>
        <div class="itemCell">
          <span class="color-gray">可平仓位</span>
          <span class="color-black">61.50 USDT</span>
        </div>
        <div class="itemCell">
          <span class="color-gray">预计盈亏</span>
          <span class="color-red">-1.50 <span class="color-black">USDT</span></span>
        </div>
        <div class="buttonGroup">
          <a-button class="button" @click="closePotion = false">
            <span>取消</span>
          </a-button>
          <a-button class="button">
            <span>确定</span>
          </a-button>
        </div>
      </div>
    </a-modal>
    <!--    闪电平仓-->
    <a-modal :visible="flashClose"
             align-center
             :render-to-body="false"
             mask-style="background:rgba(0, 0, 0, .5)"
             modal-class="popupWarp"
             width="400px"
             :hide-title="true"
             :mask-closable="true"
             :footer="false">
      <div class="popupBox-title">
        <span class="title">闪电平仓</span>
        <icon-close @click="flashClose = false" size="20"/>
      </div>
      <div class="popupBox-content">
        <div class="itemCell">
          <a-tag color="var(--opacity-sell)">
            <span class="color-red">空</span>
          </a-tag>
          <span class="title color-black">XRPUSDT</span>
          <a-tag color="var(--opacity-gray)">
            <span class="color-black">全仓50X</span>
          </a-tag>
        </div>
        <div class="itemCell">
          <span class="color-gray">价格</span>
          <span class="color-black">市价</span>
        </div>
        <div class="itemCell">
          <span class="color-gray">数量</span>
          <span class="color-black">全部仓位 <span class="color-primary">61.42</span> USDT</span>
        </div>
        <div class="buttonGroup">
          <a-button class="button" @click="flashClose = false">
            <span>取消</span>
          </a-button>
          <a-button class="button">
            <span>确定</span>
          </a-button>
        </div>
      </div>
    </a-modal>
    <!--    止盈止损-->
    <a-modal :visible="profitLossPop"
             align-center
             :render-to-body="false"
             mask-style="background:rgba(0, 0, 0, .5)"
             modal-class="popupWarp"
             width="840px"
             :hide-title="true"
             :mask-closable="true"
             :footer="false">
      <div class="popupBox-title">
        <span class="title">止盈止损</span>
        <icon-close @click="profitLossPop = false" size="24"/>
      </div>
      <div class="popupBox-content">
        <div class="itemCell">
          <a-tag color="var(--opacity-sell)">
            <span class="color-red">空</span>
          </a-tag>
          <span class="title color-black">XRPUSDT</span>
          <a-tag color="var(--opacity-gray)">
            <span class="color-black">全仓50X</span>
          </a-tag>
        </div>
        <div class="columnPirce">
          <div class="itemCol">
            <span class="color-gray">持仓均价</span>
            <span class="color-black">0.6041</span>
          </div>
          <div class="itemCol">
            <span class="color-gray">最新价格</span>
            <span class="color-red">0.6142</span>
          </div>
          <div class="itemCol">
            <span class="color-gray">预估强平价格</span>
            <span class="color-black">0.7091</span>
          </div>
        </div>
        <div class="profit_loss">
          <div class="item">
            <div class="inputBox">
              <div class="inputLeft">
                <span class="color-green mr-5">止盈</span>
                <span class="mr-5 dotted">触发价</span>
                <a-tooltip type="subtip" position="top" background-color="var(--tooltip-bg)">
                  <icon-question-circle-fill size="16" class="color-gray"/>
                  <template #content>
                    <p class="font-12 color-always-white">
                      当最新市价≤此价格时触发
                    </p>
                  </template>
                </a-tooltip>
              </div>
              <a-input class="input" placeholder="">
                <template #append>
                  USDT
                </template>
              </a-input>
            </div>
            <div class="inputGroup">
              <div class="inputBox">
                <div class="inputLeft">
                  <span class="mr-5 dotted">委托价</span>
                  <a-tooltip type="subtip" position="top" background-color="var(--tooltip-bg)">
                    <icon-question-circle-fill size="16" class="color-gray"/>
                    <template #content>
                      <p class="font-12 color-always-white">
                        触发止盈时以此价格平仓
                      </p>
                    </template>
                  </a-tooltip>
                </div>
                <a-input class="input" placeholder="">
                  <template #append>
                    USDT
                  </template>
                </a-input>
              </div>
              <a-dropdown type="modeList" :popup-max-height="false">
                <div class="selectBox">
                  <span class="color-black">市价</span>
                  <icon-caret-down size="14" class="color-black"/>
                </div>
                <template #content>
                  <a-doption>DOGE</a-doption>
                  <a-doption>USDT</a-doption>
                </template>
              </a-dropdown>
            </div>
            <div class="inputBox">
              <div class="inputLeft">
                <span class="mr-5">数量</span>
              </div>
              <a-input class="input" placeholder="">
                <template #append>
                  XRP
                </template>
              </a-input>
            </div>
            <span class="color-gray tipText">持仓量/ 可平仓量 <span class="color-orange">100 / 100 XRP</span></span>
            <div class="sliderBox mt-5">
              <a-slider class="slider" :default-value="0" :min="0" :max="100"
                        :marks="marks"/>
            </div>
            <div class="buttonBox">
              <a-button class="button" @click="profitLossPop = false">
                <span>取消</span>
              </a-button>
            </div>
          </div>
          <div class="item">
            <div class="inputBox">
              <div class="inputLeft">
                <span class="color-red mr-5">止损</span>
                <span class="mr-5 dotted">触发价</span>
                <a-tooltip type="subtip" position="top" background-color="var(--tooltip-bg)">
                  <icon-question-circle-fill size="16" class="color-gray"/>
                  <template #content>
                    <p class="font-12 color-always-white">
                      当最新市价≤此价格时触发
                    </p>
                  </template>
                </a-tooltip>
              </div>
              <a-input class="input" placeholder="">
                <template #append>
                  USDT
                </template>
              </a-input>
            </div>
            <div class="inputGroup">
              <div class="inputBox">
                <div class="inputLeft">
                  <span class="mr-5 dotted">委托价</span>
                  <a-tooltip type="subtip" position="top" background-color="var(--tooltip-bg)">
                    <icon-question-circle-fill size="16" class="color-gray"/>
                    <template #content>
                      <p class="font-12 color-always-white">
                        触发止盈时以此价格平仓
                      </p>
                    </template>
                  </a-tooltip>
                </div>
                <a-input class="input" placeholder="">
                  <template #append>
                    USDT
                  </template>
                </a-input>
              </div>
              <a-dropdown type="modeList" :popup-max-height="false">
                <div class="selectBox">
                  <span class="color-black">市价</span>
                  <icon-caret-down size="14" class="color-black"/>
                </div>
                <template #content>
                  <a-doption>DOGE</a-doption>
                  <a-doption>USDT</a-doption>
                </template>
              </a-dropdown>
            </div>
            <div class="inputBox">
              <div class="inputLeft">
                <span class="mr-5">数量</span>
              </div>
              <a-input class="input" placeholder="">
                <template #append>
                  XRP
                </template>
              </a-input>
            </div>
            <span class="color-gray tipText">持仓量/ 可平仓量 <span class="color-orange">100 / 100 XRP</span></span>
            <div class="sliderBox mt-5">
              <a-slider class="slider" :default-value="0" :min="0" :max="100"
                        :marks="marks"/>
            </div>
            <div class="buttonBox">
              <a-button class="button" @click="profitLossPop = false">
                <span>确定</span>
              </a-button>
            </div>
          </div>
        </div>
      </div>
    </a-modal>
    <!--    撤单-->
    <a-modal :visible="wdOrder"
             align-center
             :render-to-body="false"
             mask-style="background:rgba(0, 0, 0, .5)"
             modal-class="popupWarp"
             width="400px"
             :hide-title="true"
             :mask-closable="true"
             :footer="false">
      <div class="popupBox-content">
        <div class="imageBox">
          <img src="@/assets/images/wd_order_img.png" alt="">
        </div>
        <span class="wdContext">确定撤销该订单？</span>
        <div class="buttonGroup">
          <a-button class="button" @click="wdOrder = false">
            <span>取消</span>
          </a-button>
          <a-button class="button">
            <span>确定</span>
          </a-button>
        </div>
      </div>
    </a-modal>
    <!--    订单详情-->
    <a-modal :visible="orderInfoPopup"
             align-center
             :render-to-body="false"
             mask-style="background:rgba(0, 0, 0, .5)"
             modal-class="popupWarp"
             width="488px"
             :hide-title="true"
             :mask-closable="true"
             :footer="false">
      <div class="popupBox-title">
        <span class="title">订单详情</span>
        <icon-close @click="orderInfoPopup = false" size="20"/>
      </div>
      <div class="popupBox-content">
        <div class="itemCell oderItemCell">
          <div class="itemCell_Left">
            <a-tag color="var(--opacity-sell)">
              <span class="color-red">卖出开空</span>
            </a-tag>
            <span class="title color-black">XRPUSDT</span>
            <a-tag color="var(--opacity-gray)">
              <span class="color-black">永续</span>
            </a-tag>
          </div>
          <span class="statusText color-black">完全成交</span>
        </div>
        <div class="itemCell">
          <div class="itemCell_Left">
            <span class="color-gray mr-10">2023-11-28 20:05:39</span>
            <a-tag color="var(--opacity-gray)">
              <span class="color-black">市价单</span>
            </a-tag>
          </div>
        </div>
        <div class="itemCell">
          <span class="color-gray mr-10">成交均价/委托价格</span>
          <span class="color-black">0.6041/市价</span>
        </div>
        <div class="itemCell">
          <span class="color-gray mr-10">成交数量/委托数量(USDT)</span>
          <span class="color-black">0.6041/60.74</span>
        </div>
        <div class="itemCell">
          <span class="color-gray mr-10">手续费(USDT)</span>
          <span class="color-black">0.0362</span>
        </div>
        <div class="itemCell">
          <span class="color-gray mr-10">盈亏(USDT)</span>
          <span class="color-black">--</span>
        </div>
        <div class="itemCell">
          <span class="color-gray mr-10">订单ID</span>
          <div class="itemCell_Right">
            <span class="mr-5 color-black">1922187885618413628</span>
            <icon-copy size="20" class="color-gray"/>
          </div>
        </div>
        <div class="cardBox">
          <div class="itemCell">
            <span class="title color-black">成交记录</span>
          </div>
          <div class="itemCell">
            <span class="color-gray">成交均价</span>
            <span class="color-black">0.6041</span>
          </div>
          <div class="itemCell">
            <span class="color-gray">开仓价格</span>
            <span class="color-black">--</span>
          </div>
          <div class="itemCell">
            <span class="color-gray">成交数量(USDT)</span>
            <span class="color-black">60.41</span>
          </div>
          <div class="itemCell">
            <span class="color-gray">手续费(USDT)/类型</span>
            <span class="color-black">0.0362/taker</span>
          </div>
          <div class="itemCell">
            <span class="color-gray">盈亏(USDT)</span>
            <span class="color-black">--</span>
          </div>
          <div class="itemCell">
            <span class="color-gray">成交时间(USDT)</span>
            <span class="color-black">2023-11-28 10:26:32</span>
          </div>
        </div>
      </div>
    </a-modal>
    <!--    设置-->
    <a-drawer :width="340"
              :footer="false"
              :header="false"
              :drawer-style="{background: 'var(--background-primary)'}"
              :visible="settingDrawer"
              @ok="settingDrawer = false"
              @cancel="settingDrawer = false"
              unmountOnClose>
      <div class="settingDrawer">
        <div class="titleBox">
          <span>设置</span>
          <icon-close class="color-black cursor" size="20" @click="settingDrawer = false"/>
        </div>
        <div class="label">
          <p class="color-gray mb-5">持仓类型</p>
          <p class="color-primary mb-5">
            <icon-exclamation-polygon-fill/>
            持有任何合约仓位/有挂单时不可更改持仓类型
          </p>
          <a-radio-group class="radioGroup">
            <a-radio value="1">
              <template #radio="{ checked }">
                <div class="radioItem">
                  <div class="radio" :class="{'radio-checked': checked}"></div>
                  <div class="info">
                    <p>单向持仓</p>
                    <p class="desc">单向持仓模式下，一个合约只允许持有一个方向的仓位</p>
                  </div>
                </div>
              </template>
            </a-radio>
            <a-radio value="2">
              <template #radio="{ checked }">
                <div class="radioItem">
                  <div class="radio" :class="{'radio-checked': checked}"></div>
                  <div class="info">
                    <p>双向持仓</p>
                    <p class="desc">
                      双向持仓模式下，一个合约可允许同时持有多空两个方向的仓位，并且同一合约下不同方向仓位风险对冲</p>
                  </div>
                </div>
              </template>
            </a-radio>
          </a-radio-group>
        </div>
        <div class="label">
          <p class="color-gray title dotted mb-5">
            条件单/止盈止损有效期
            <a-tooltip position="top" background-color="var(--tooltip-bg)">
              <icon-question-circle-fill class="color-gray cursor" size="14"/>
              <template #content>
                <p class="font-12 color-always-white">
                  温馨提示：当持仓结束时，该合约下仅有平仓计划委托的，则系统将撤销该合约下的计划委托
                </p>
              </template>
            </a-tooltip>
          </p>
          <a-radio-group class="radioGroup" mode="row">
            <a-radio value="1">
              <template #radio="{ checked }">
                <div class="radioItem">
                  <div class="radio" :class="{'radio-checked': checked}"></div>
                  <div class="info">
                    <p>24H</p>
                  </div>
                </div>
              </template>
            </a-radio>
            <a-radio value="2">
              <template #radio="{ checked }">
                <div class="radioItem">
                  <div class="radio" :class="{'radio-checked': checked}"></div>
                  <div class="info">
                    <p>7天</p>
                  </div>
                </div>
              </template>
            </a-radio>
            <a-radio value="3">
              <template #radio="{ checked }">
                <div class="radioItem">
                  <div class="radio" :class="{'radio-checked': checked}"></div>
                  <div class="info">
                    <p>14天</p>
                  </div>
                </div>
              </template>
            </a-radio>
            <a-radio value="4">
              <template #radio="{ checked }">
                <div class="radioItem">
                  <div class="radio" :class="{'radio-checked': checked}"></div>
                  <div class="info">
                    <p>30天</p>
                  </div>
                </div>
              </template>
            </a-radio>
          </a-radio-group>
        </div>
        <div class="label">
          <p class="color-gray title mb-5">
            下单设置
          </p>
          <div class="flex align-center jus-bet">
            <span>下单二次确认</span>
            <a-switch/>
          </div>
        </div>
      </div>
    </a-drawer>
  </a-layout>
</template>
<script>
import PageNav from "@/components/PageNav/PageNav.vue";
import Footer from "@/components/Footer/footer.vue";
import notlog from "@/components/notlog/notlog.vue"
import fundsTransfe from "@/components/fundsTransfe/fundsTransfe.vue";
import {mapState} from "vuex";

export default {
  components: {Footer, PageNav, notlog, fundsTransfe},
  data() {
    return {
      coinName: "BTC",
      coinType: "USDT",
      chartType: 0, //图表类型
      chartTabIndex: 0, //图表or概述
      timeOptions: ['分时', '1分钟', '3分钟', '5分钟', '15分钟', '30分钟', '1小时', '2小时', '4小时', '6小时', '8小时', '12小时', '1天', '3天', '周线', '月线',],
      timeEdit: false,
      selectTime: ['分时', '1分钟', '3分钟', '5分钟'],
      timeActive: 0,
      priceTypeVal: '最新价格',
      tableOption: [
        {
          name: "BTC",
          type: "USDT",
          rate: "-0.25%"
        }, {
          name: "ETH",
          type: "USDT",
          rate: "-0.25%"
        }, {
          name: "LINK",
          type: "USDT",
          rate: "-0.25%"
        }, {
          name: "ADA",
          type: "USDT",
          rate: "-0.25%"
        }, {
          name: "DOGE",
          type: "USDT",
          rate: "-0.25%"
        }],
      tableActive: 0,
      aboutCoin: false,
      decimal: "0.000001",
      listType: 0,
      List: [
        {
          price: "0.061888",
          num: "216000",
          total: "1985264",
        }, {
          price: "0.061888",
          num: "216000",
          total: "1985264",
        }, {
          price: "0.061888",
          num: "216000",
          total: "1985264",
        }, {
          price: "0.061888",
          num: "216000",
          total: "1985264",
        }, {
          price: "0.061888",
          num: "216000",
          total: "1985264",
        }, {
          price: "0.061888",
          num: "216000",
          total: "1985264",
        }, {
          price: "0.061888",
          num: "216000",
          total: "1985264",
        }, {
          price: "0.061888",
          num: "216000",
          total: "1985264",
        }, {
          price: "0.061888",
          num: "216000",
          total: "1985264",
        }, {
          price: "0.061888",
          num: "216000",
          total: "1985264",
        }, {
          price: "0.061888",
          num: "216000",
          total: "1985264",
        }, {
          price: "0.061888",
          num: "216000",
          total: "1985264",
        }, {
          price: "0.061888",
          num: "216000",
          total: "1985264",
        }, {
          price: "0.061888",
          num: "216000",
          total: "1985264",
        }, {
          price: "0.061888",
          num: "216000",
          total: "1985264",
        }, {
          price: "0.061888",
          num: "216000",
          total: "1985264",
        }, {
          price: "0.061888",
          num: "216000",
          total: "1985264",
        }, {
          price: "0.061888",
          num: "216000",
          total: "1985264",
        }, {
          price: "0.061888",
          num: "216000",
          total: "1985264",
        },
        {
          price: "0.061888",
          num: "216000",
          total: "1985264",
        }, {
          price: "0.061888",
          num: "216000",
          total: "1985264",
        }, {
          price: "0.061888",
          num: "216000",
          total: "1985264",
        }, {
          price: "0.061888",
          num: "216000",
          total: "1985264",
        }, {
          price: "0.061888",
          num: "216000",
          total: "1985264",
        }, {
          price: "0.061888",
          num: "216000",
          total: "1985264",
        }, {
          price: "0.061888",
          num: "216000",
          total: "1985264",
        }, {
          price: "0.061888",
          num: "216000",
          total: "1985264",
        }, {
          price: "0.061888",
          num: "216000",
          total: "1985264",
        }, {
          price: "0.061888",
          num: "216000",
          total: "1985264",
        }, {
          price: "0.061888",
          num: "216000",
          total: "1985264",
        }, {
          price: "0.061888",
          num: "216000",
          total: "1985264",
        }, {
          price: "0.061888",
          num: "216000",
          total: "1985264",
        }, {
          price: "0.061888",
          num: "216000",
          total: "1985264",
        }, {
          price: "0.061888",
          num: "216000",
          total: "1985264",
        }, {
          price: "0.061888",
          num: "216000",
          total: "1985264",
        }, {
          price: "0.061888",
          num: "216000",
          total: "1985264",
        }, {
          price: "0.061888",
          num: "216000",
          total: "1985264",
        }, {
          price: "0.061888",
          num: "216000",
          total: "1985264",
        }],
      timer: "",
      btnActive: 0,
      modeOption: ["限价单", "市价单", "条件单",],
      modeIndex: 0,
      subtabActive: 0,
      marks: {
        0: '0%',
        25: '25%',
        50: '50%',
        75: '75%',
        100: '100%'
      },
      like: false,
      marginModel: false,
      leverSlider: {
        0: '1X',
        25: '25X',
        50: '50X',
        75: '75X',
        100: '100X',
        125: '125X'
      },
      isLogin: false,
      leverArm: false,
      leverVal: 1,
      balanceTip: false,
      tabIndex: 0,
      tabOption: ['收益', '平仓价格', '强平价格'],
      buying: 1,
      calculator: false, //计算器
      transfeShow: false, //划转
      checked: false,
      placeOrder: false, //下单
      closePotion: false, //平仓
      flashClose: false, //闪电平仓
      profitLossPop: false, //止盈止损
      closePotionTab: ['限价', '市价'],
      wdOrder: false, //撤单
      orderInfoPopup: false, //订单详情
      settingDrawer: false //设置
    }
  },
  mounted() {
    this.setNum();
  },
  computed: {
    ...mapState(['userTheme']),
  },
  methods: {
    tableToggle(item, index) {
      this.tableActive = index;
      this.coinName = item.name;
      this.coinType = item.type;
    },
    aboutCoinShow() {
      this.aboutCoin = true;
    },
    setNum() {
      this.timer = setInterval(() => {
        this.List.forEach(item => {
          item.price = Math.random().toFixed(5)
        })
      }, 2000)
    },
    numSelect(e) {
      this.decimal = e;
    },
    toDetail() {
      this.$router.push("/HomeView/futures/futuresData")
    },
    priveChange(e) {
      console.log(e)
      this.priceTypeVal = e;
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
}
</script>

<style lang="scss" scoped>
@import "./mock.scss";
</style>