<!-- 安全记录 -->
<template>
  <div class="main-box">
    <div class="content-main">
      <div class="top-info">
        <a-button @click="goBack">
          <template #icon>
            <icon-left />
          </template>
        </a-button>
        <span>安全记录</span>
      </div>
      <div class="recommendInvite-nav">
        <a-tabs
          :default-active-key="activeKey"
          type="line"
          :animation="true"
          size="large"
          style="font-size: 20px"
        >
          <a-tab-pane
            class="order-tab"
            :key="index"
            v-for="(item, index) in tabs"
          >
            <template #title>
              <span :style="{ fontSize: '16px' }">{{ item.label }}</span>
            </template>
          </a-tab-pane>
        </a-tabs>

      </div>

      
    </div>
    
  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      tabs: [
        {
          id: 1,
          label: "登陆记录",
        },
        {
          id: 2,
          label: "安全设置记录",
        },
      ],
      
    };
  },
  methods: {
    goBack() {
      this.$emit("safet-event");
    },
    
  },
};
</script>
<style lang="scss" scoped>
@import "./safetyRecord.scss";
</style>