<template>
    <a-layout class="quotes">
        <a-layout-header>
            <page-nav></page-nav>
        </a-layout-header>
        <a-layout-content>
            <div class="swiper-warp">
                <img src="@/assets/images/copyTrader/full.svg" alt="">
                <div class="tran-warp">
                    <div class="slide-scroll">
                        <div class="tag" v-for="(item,index) in tradeCurrency[0]['currency']" :key="'h'+item.id">
                            <span class="color-black">{{item['symbol']}}/USDT</span>
                            <span class="color-green">{{$util.thousandSeparator(item.new_price,item.decimals)}}（{{$util.float(item.change_rate,2)}}%）</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="quotes-body">
                <a-breadcrumb>
                    <a-breadcrumb-item>ComeBit</a-breadcrumb-item>
                    <a-breadcrumb-item>市场行情</a-breadcrumb-item>
                </a-breadcrumb>
                <h1>市场</h1>

                <a-tabs default-active-key="1" @change="switchTab">
                    <template #extra>
                        <a-input class="search" @input="search" placeholder="搜索">
                            <template #prefix>
                                <icon-search class="color-gray" size="16"/>
                            </template>
                        </a-input>
                    </template>
                    <a-tab-pane key="1">
                        <template #title>
                            <span>现货</span>
                        </template>
                        <div class="sub-tabs">
<!--                            <a-dropdown popup-container=".quotes" @select="handleSelect">-->
<!--                                <a-input readonly v-model="selectVal" :placeholder="selectVal" class="select-button">-->
<!--                                    <template #suffix>-->
<!--                                        <icon-down/>-->
<!--                                    </template>-->
<!--                                </a-input>-->
<!--                                <template #content>-->
<!--                                    <a-doption v-for="(item,index) in coinList" :key="index"-->
<!--                                               @click="coinChange(item,index)">-->
<!--                                        {{ item.title }}-->
<!--                                    </a-doption>-->
<!--                                </template>-->
<!--                            </a-dropdown>-->
                            <div class="_right">
                                <div class="button tagPrev">
                                    <icon-left/>
                                </div>
                                <swiper class="tagSwiper"
                                        :modules="subModules"
                                        :navigation="{
                                            nextEl: '.tagNext',
                                            prevEl: '.tagPrev'
                                        }"
                                        :loop="false"
                                        :slides-per-view="8"
                                        @slide-change="slideChange">
<!--                                    <swiper-slide class="tagSlide"-->
<!--                                                  :class="{'active': tagSelected === 0}"-->
<!--                                                  @click="tagSelected = 0">-->
<!--                                        <span>全部</span>-->
<!--                                    </swiper-slide>-->
                                    <swiper-slide class="tagSlide"
                                                  :class="{'active': tagSelected === index}"
                                                  v-for="(item,index) in tradeCurrency" :key="index"
                                                  @click="tagSelected = index">
                                        <span>{{ item.title }}</span>
                                    </swiper-slide>
                                </swiper>
                                <div class="button tagNext">
                                    <icon-right/>
                                </div>
                            </div>
                        </div>
                        <div class="list-container">
                            <div class="_head">
                                <div class="item">
                                    <span>交易对</span>
                                </div>
                                <div class="item">
                                    <span class="cursor">最新价</span>
                                    <svg class="cursor" viewBox="0 0 1024 1024" version="1.1"
                                         xmlns="http://www.w3.org/2000/svg" p-id="5195" width="18" height="14">
                                        <path
                                                d="M512 938.688a42.624 42.624 0 0 1-35.072-18.432l-192-277.312A42.56 42.56 0 0 1 320 576h384a42.752 42.752 0 0 1 35.072 66.944l-192 277.312A42.688 42.688 0 0 1 512 938.688z"
                                                :fill="pricesort == 1 || pricesort == 2 ? 'var(--border-strength-200)' : 'var(--content-primary)'"
                                                p-id="5196" data-spm-anchor-id="a313x.search_index.0.i2.72f13a81y4MtV6"
                                                class="selected">
                                        </path>
                                        <path
                                                d="M704 448H320a42.752 42.752 0 0 1-35.072-66.944l192-277.312c16-23.04 54.208-23.04 70.144 0l192 277.312A42.624 42.624 0 0 1 704 448z"
                                                :fill="pricesort == 1 || pricesort == 3 ? 'var(--border-strength-200)' : 'var(--content-primary)'"
                                                p-id="5197" data-spm-anchor-id="a313x.search_index.0.i1.72f13a81y4MtV6"
                                                class="selected">
                                        </path>
                                    </svg>
                                </div>
                                <div class="item">
                                    <span class="cursor">涨跌幅</span>
                                    <svg class="cursor" viewBox="0 0 1024 1024" version="1.1"
                                         xmlns="http://www.w3.org/2000/svg" p-id="5195" width="18" height="14">
                                        <path
                                                d="M512 938.688a42.624 42.624 0 0 1-35.072-18.432l-192-277.312A42.56 42.56 0 0 1 320 576h384a42.752 42.752 0 0 1 35.072 66.944l-192 277.312A42.688 42.688 0 0 1 512 938.688z"
                                                :fill="pricesort == 1 || pricesort == 2 ? 'var(--border-strength-200)' : 'var(--content-primary)'"
                                                p-id="5196" data-spm-anchor-id="a313x.search_index.0.i2.72f13a81y4MtV6"
                                                class="selected">
                                        </path>
                                        <path
                                                d="M704 448H320a42.752 42.752 0 0 1-35.072-66.944l192-277.312c16-23.04 54.208-23.04 70.144 0l192 277.312A42.624 42.624 0 0 1 704 448z"
                                                :fill="pricesort == 1 || pricesort == 3 ? 'var(--border-strength-200)' : 'var(--content-primary)'"
                                                p-id="5197" data-spm-anchor-id="a313x.search_index.0.i1.72f13a81y4MtV6"
                                                class="selected">
                                        </path>
                                    </svg>
                                </div>
                                <div class="item">
                                    <span class="cursor">市值</span>
                                    <svg class="cursor" viewBox="0 0 1024 1024" version="1.1"
                                         xmlns="http://www.w3.org/2000/svg" p-id="5195" width="18" height="14">
                                        <path
                                                d="M512 938.688a42.624 42.624 0 0 1-35.072-18.432l-192-277.312A42.56 42.56 0 0 1 320 576h384a42.752 42.752 0 0 1 35.072 66.944l-192 277.312A42.688 42.688 0 0 1 512 938.688z"
                                                :fill="pricesort == 1 || pricesort == 2 ? 'var(--border-strength-200)' : 'var(--content-primary)'"
                                                p-id="5196" data-spm-anchor-id="a313x.search_index.0.i2.72f13a81y4MtV6"
                                                class="selected">
                                        </path>
                                        <path
                                                d="M704 448H320a42.752 42.752 0 0 1-35.072-66.944l192-277.312c16-23.04 54.208-23.04 70.144 0l192 277.312A42.624 42.624 0 0 1 704 448z"
                                                :fill="pricesort == 1 || pricesort == 3 ? 'var(--border-strength-200)' : 'var(--content-primary)'"
                                                p-id="5197" data-spm-anchor-id="a313x.search_index.0.i1.72f13a81y4MtV6"
                                                class="selected">
                                        </path>
                                    </svg>
                                </div>
                                <div class="item">
                                    <span class="cursor">成交量</span>
                                    <svg class="cursor" viewBox="0 0 1024 1024" version="1.1"
                                         xmlns="http://www.w3.org/2000/svg" p-id="5195" width="18" height="14">
                                        <path
                                                d="M512 938.688a42.624 42.624 0 0 1-35.072-18.432l-192-277.312A42.56 42.56 0 0 1 320 576h384a42.752 42.752 0 0 1 35.072 66.944l-192 277.312A42.688 42.688 0 0 1 512 938.688z"
                                                :fill="pricesort == 1 || pricesort == 2 ? 'var(--border-strength-200)' : 'var(--content-primary)'"
                                                p-id="5196" data-spm-anchor-id="a313x.search_index.0.i2.72f13a81y4MtV6"
                                                class="selected">
                                        </path>
                                        <path
                                                d="M704 448H320a42.752 42.752 0 0 1-35.072-66.944l192-277.312c16-23.04 54.208-23.04 70.144 0l192 277.312A42.624 42.624 0 0 1 704 448z"
                                                :fill="pricesort == 1 || pricesort == 3 ? 'var(--border-strength-200)' : 'var(--content-primary)'"
                                                p-id="5197" data-spm-anchor-id="a313x.search_index.0.i1.72f13a81y4MtV6"
                                                class="selected">
                                        </path>
                                    </svg>
                                </div>
                                <div class="item">
                                    <span>24小时涨跌</span>
                                </div>
                                <div class="item">
                                    <span>操作</span>
                                </div>
                            </div>
                            <!--   币种-->
                            <div class="_cell" v-for="(item,index) in list" :key="'trade'+item.id">
                                <div class="item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15"
                                         viewBox="0 0 14 15" fill="none">
                                        <path
                                                d="M7.72569 12.5967C7.2741 12.3471 6.7259 12.3471 6.27432 12.5967L3.61611 14.0661C3.25083 14.2681 2.81354 13.96 2.88074 13.548L3.41223 10.2898C3.48887 9.81987 3.33791 9.3415 3.00543 9.00074L0.716954 6.65523C0.432749 6.36394 0.597017 5.87326 0.999316 5.81179L4.0759 5.34173C4.57038 5.26618 4.99467 4.94911 5.20721 4.49628L6.54738 1.64106C6.72734 1.25766 7.27266 1.25766 7.45262 1.64106L8.79279 4.49628C9.00533 4.94911 9.42962 5.26618 9.9241 5.34173L13.0007 5.81179C13.403 5.87326 13.5673 6.36394 13.283 6.65523L10.9946 9.00074C10.6621 9.3415 10.5111 9.81987 10.5878 10.2897L11.1193 13.548C11.1865 13.96 10.7492 14.2681 10.3839 14.0661L7.72569 12.5967Z"
                                                stroke="var(--content-tertiary)">
                                        </path>
                                    </svg>
                                    <div class="coin">
                                        <img class="coin-img" :src="item['icon']" :alt="item['symbol']">
                                        <span class="color-black">
                                            {{item['symbol']}}
                                            <b class="font-12">/ {{tradeCurrency[tagSelected]['legal']['symbol']}}</b>
                                        </span>
                                        <img v-if="index < 3" class="hot" src="@/assets/images/hot.svg" alt="">
                                    </div>
                                </div>
                                <div class="item">
                                    <span :class="{'color-red':item.change_rate <= 0,'color-green':item.change_rate > 0}">
                                        {{$util.thousandSeparator(item.new_price,item.decimals)}}
                                    </span>
                                </div>
                                <div class="item">
                                    <span :class="{'color-red':item.change_rate <= 0,'color-green':item.change_rate > 0}">
                                        {{$util.float(item.change_rate,item.decimals)}}%
                                    </span>
                                </div>
                                <div class="item">
                                    <span class="color-black">
                                        {{$util.thousandUnit(item.quota)}}
                                    </span>
                                </div>
                                <div class="item">
                                    <span class="color-black">
                                        {{$util.thousandUnit(item.quantity)}}
                                    </span>
                                </div>
                                <div class="item">
                                    <kline :currencyId="item.id" :change="item.change_rate"></kline>
                                </div>
                                <div class="item">
                                    <span class="color-primary" @click="toTrade(item)">交易</span>
                                </div>
                            </div>
                            <!--    分页-->
                            <div class="pagination">
                                <a-pagination @change="nextPage" :page-size="psize" :total="total" show-total show-jumper/>
                            </div>
                            <a-empty v-if="false">
                                <template #image>
                                    <img :src="require(`@/assets/images/assets_img/no-data-${userTheme}.svg`)" alt="">
                                </template>
                                No data, please reload!
                            </a-empty>
                        </div>
                    </a-tab-pane>
                    <a-tab-pane key="2">
                        <template #title>
                            <span>合约</span>
                        </template>
                        <div class="sub-tabs">
                            <div class="tabItem"
                                 :class="{'tabItem-active': subActive === index}"
                                 v-for="(item,index) in tradeCurrency" :key="index"
                                 @click="subActive = index">
                                <span>{{ item['title'] }}</span>
                            </div>
                        </div>
                        <div class="list-container" type="hy">
                            <div class="_head">
                                <div class="item">
                                    <span>交易对</span>
                                </div>
                                <div class="item">
                                    <span class="cursor">最新价</span>
                                    <svg class="cursor" viewBox="0 0 1024 1024" version="1.1"
                                         xmlns="http://www.w3.org/2000/svg" p-id="5195" width="18" height="14">
                                        <path
                                                d="M512 938.688a42.624 42.624 0 0 1-35.072-18.432l-192-277.312A42.56 42.56 0 0 1 320 576h384a42.752 42.752 0 0 1 35.072 66.944l-192 277.312A42.688 42.688 0 0 1 512 938.688z"
                                                :fill="pricesort == 1 || pricesort == 2 ? 'var(--border-strength-200)' : 'var(--content-primary)'"
                                                p-id="5196" data-spm-anchor-id="a313x.search_index.0.i2.72f13a81y4MtV6"
                                                class="selected">
                                        </path>
                                        <path
                                                d="M704 448H320a42.752 42.752 0 0 1-35.072-66.944l192-277.312c16-23.04 54.208-23.04 70.144 0l192 277.312A42.624 42.624 0 0 1 704 448z"
                                                :fill="pricesort == 1 || pricesort == 3 ? 'var(--border-strength-200)' : 'var(--content-primary)'"
                                                p-id="5197" data-spm-anchor-id="a313x.search_index.0.i1.72f13a81y4MtV6"
                                                class="selected">
                                        </path>
                                    </svg>
                                </div>
                                <div class="item">
                                    <span class="cursor">涨跌幅</span>
                                    <svg class="cursor" viewBox="0 0 1024 1024" version="1.1"
                                         xmlns="http://www.w3.org/2000/svg" p-id="5195" width="18" height="14">
                                        <path
                                                d="M512 938.688a42.624 42.624 0 0 1-35.072-18.432l-192-277.312A42.56 42.56 0 0 1 320 576h384a42.752 42.752 0 0 1 35.072 66.944l-192 277.312A42.688 42.688 0 0 1 512 938.688z"
                                                :fill="pricesort == 1 || pricesort == 2 ? 'var(--border-strength-200)' : 'var(--content-primary)'"
                                                p-id="5196" data-spm-anchor-id="a313x.search_index.0.i2.72f13a81y4MtV6"
                                                class="selected">
                                        </path>
                                        <path
                                                d="M704 448H320a42.752 42.752 0 0 1-35.072-66.944l192-277.312c16-23.04 54.208-23.04 70.144 0l192 277.312A42.624 42.624 0 0 1 704 448z"
                                                :fill="pricesort == 1 || pricesort == 3 ? 'var(--border-strength-200)' : 'var(--content-primary)'"
                                                p-id="5197" data-spm-anchor-id="a313x.search_index.0.i1.72f13a81y4MtV6"
                                                class="selected">
                                        </path>
                                    </svg>
                                </div>
                                <div class="item">
                                    <span class="cursor">成交量</span>
                                    <svg class="cursor" viewBox="0 0 1024 1024" version="1.1"
                                         xmlns="http://www.w3.org/2000/svg" p-id="5195" width="18" height="14">
                                        <path
                                                d="M512 938.688a42.624 42.624 0 0 1-35.072-18.432l-192-277.312A42.56 42.56 0 0 1 320 576h384a42.752 42.752 0 0 1 35.072 66.944l-192 277.312A42.688 42.688 0 0 1 512 938.688z"
                                                :fill="pricesort == 1 || pricesort == 2 ? 'var(--border-strength-200)' : 'var(--content-primary)'"
                                                p-id="5196" data-spm-anchor-id="a313x.search_index.0.i2.72f13a81y4MtV6"
                                                class="selected">
                                        </path>
                                        <path
                                                d="M704 448H320a42.752 42.752 0 0 1-35.072-66.944l192-277.312c16-23.04 54.208-23.04 70.144 0l192 277.312A42.624 42.624 0 0 1 704 448z"
                                                :fill="pricesort == 1 || pricesort == 3 ? 'var(--border-strength-200)' : 'var(--content-primary)'"
                                                p-id="5197" data-spm-anchor-id="a313x.search_index.0.i1.72f13a81y4MtV6"
                                                class="selected">
                                        </path>
                                    </svg>
                                </div>
                                <div class="item">
                                    <span>24小时涨跌</span>
                                </div>
                                <div class="item">
                                    <span>操作</span>
                                </div>
                            </div>
                            <!--   币种-->
                            <div class="_cell" v-for="(item,index) in list" :key="'f'+item.id">
                                <div class="item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15"
                                         viewBox="0 0 14 15" fill="none">
                                        <path
                                                d="M7.72569 12.5967C7.2741 12.3471 6.7259 12.3471 6.27432 12.5967L3.61611 14.0661C3.25083 14.2681 2.81354 13.96 2.88074 13.548L3.41223 10.2898C3.48887 9.81987 3.33791 9.3415 3.00543 9.00074L0.716954 6.65523C0.432749 6.36394 0.597017 5.87326 0.999316 5.81179L4.0759 5.34173C4.57038 5.26618 4.99467 4.94911 5.20721 4.49628L6.54738 1.64106C6.72734 1.25766 7.27266 1.25766 7.45262 1.64106L8.79279 4.49628C9.00533 4.94911 9.42962 5.26618 9.9241 5.34173L13.0007 5.81179C13.403 5.87326 13.5673 6.36394 13.283 6.65523L10.9946 9.00074C10.6621 9.3415 10.5111 9.81987 10.5878 10.2897L11.1193 13.548C11.1865 13.96 10.7492 14.2681 10.3839 14.0661L7.72569 12.5967Z"
                                                stroke="var(--content-tertiary)">
                                        </path>
                                    </svg>
                                    <div class="coin">
                                        <img class="coin-img" :src="item['icon']" alt="">
                                        <div class="coin-info">
                                            <p class="color-black">
                                                {{item['symbol']}}{{tradeCurrency[tagSelected]['legal']['symbol']}}
                                            </p>
                                            <div class="flex align-center">
                                                <a-tag class="tag">
                                                    永续
                                                </a-tag>
                                                <img v-if="index < 3" class="hot" src="@/assets/images/hot.svg" alt="">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item">
                                    <span :class="{'color-red':item.change_rate <= 0,'color-green':item.change_rate > 0}">
                                        {{$util.thousandSeparator(item.new_price,item.decimals)}}
                                    </span>
                                </div>
                                <div class="item">
                                    <span :class="{'color-red':item.change_rate <= 0,'color-green':item.change_rate > 0}">
                                        {{$util.float(item.change_rate,item.decimals)}}%
                                    </span>
                                </div>
                                <div class="item">
                                    <span class="color-black">
                                        {{$util.thousandUnit(item.quantity)}}
                                    </span>
                                </div>
                                <div class="item">
                                    <kline :currencyId="item.id" :change="item.change_rate"></kline>
                                </div>
                                <div class="item">
                                    <span @click="$router.push('/futures')" class="color-primary">交易</span>
                                </div>
                            </div>
                            <!--    分页-->
                            <div class="pagination">
                                <a-pagination @change="nextPage" :page-size="psize" :total="total" show-total show-jumper/>
                            </div>
                            <a-empty v-if="false">
                                <template #image>
                                    <img :src="require(`@/assets/images/assets_img/no-data-${userTheme}.svg`)" alt="">
                                </template>
                                No data, please reload!
                            </a-empty>
                        </div>
                    </a-tab-pane>
                    <a-tab-pane key="3">
                        <template #title>
                            <span>期权</span>
                        </template>
                        <div class="sub-tabs" v-if="false">
<!--                            <a-dropdown popup-container=".quotes" @select="handleSelect">-->
<!--                                <a-input readonly v-model="selectVal" :placeholder="selectVal" class="select-button">-->
<!--                                    <template #suffix>-->
<!--                                        <icon-down/>-->
<!--                                    </template>-->
<!--                                </a-input>-->
<!--                                <template #content>-->
<!--                                    <a-doption v-for="(item,index) in coinList" :key="index"-->
<!--                                               @click="coinChange(item,index)">-->
<!--                                        {{ item.coin }}-->
<!--                                    </a-doption>-->
<!--                                </template>-->
<!--                            </a-dropdown>-->
                            <div class="_right">
                                <div class="button tagPrev">
                                    <icon-left/>
                                </div>
                                <swiper class="tagSwiper"
                                        :modules="subModules"
                                        :navigation="{
                                            nextEl: '.tagNext',
                                            prevEl: '.tagPrev'
                                        }"
                                        :loop="false"
                                        :slides-per-view="8"
                                        @slide-change="slideChange">
                                    <swiper-slide class="tagSlide"
                                                  :class="{'active': tagSelected == index}"
                                                  v-for="(item,index) in coinList[coinIndex].subTabs" :key="index"
                                                  @click="tagSelected = index">
                                        <span>{{ item }}</span>
                                    </swiper-slide>
                                </swiper>
                                <div class="button tagNext">
                                    <icon-right/>
                                </div>
                            </div>
                        </div>
                        <div class="list-container">
                            <div class="_head">
                                <div class="item">
                                    <span>交易对</span>
                                </div>
                                <div class="item">
                                    <span class="cursor">最新价</span>
                                    <svg class="cursor" viewBox="0 0 1024 1024" version="1.1"
                                         xmlns="http://www.w3.org/2000/svg" p-id="5195" width="18" height="14">
                                        <path
                                                d="M512 938.688a42.624 42.624 0 0 1-35.072-18.432l-192-277.312A42.56 42.56 0 0 1 320 576h384a42.752 42.752 0 0 1 35.072 66.944l-192 277.312A42.688 42.688 0 0 1 512 938.688z"
                                                :fill="pricesort == 1 || pricesort == 2 ? 'var(--border-strength-200)' : 'var(--content-primary)'"
                                                p-id="5196" data-spm-anchor-id="a313x.search_index.0.i2.72f13a81y4MtV6"
                                                class="selected">
                                        </path>
                                        <path
                                                d="M704 448H320a42.752 42.752 0 0 1-35.072-66.944l192-277.312c16-23.04 54.208-23.04 70.144 0l192 277.312A42.624 42.624 0 0 1 704 448z"
                                                :fill="pricesort == 1 || pricesort == 3 ? 'var(--border-strength-200)' : 'var(--content-primary)'"
                                                p-id="5197" data-spm-anchor-id="a313x.search_index.0.i1.72f13a81y4MtV6"
                                                class="selected">
                                        </path>
                                    </svg>
                                </div>
                                <div class="item">
                                    <span class="cursor">涨跌幅</span>
                                    <svg class="cursor" viewBox="0 0 1024 1024" version="1.1"
                                         xmlns="http://www.w3.org/2000/svg" p-id="5195" width="18" height="14">
                                        <path
                                                d="M512 938.688a42.624 42.624 0 0 1-35.072-18.432l-192-277.312A42.56 42.56 0 0 1 320 576h384a42.752 42.752 0 0 1 35.072 66.944l-192 277.312A42.688 42.688 0 0 1 512 938.688z"
                                                :fill="pricesort == 1 || pricesort == 2 ? 'var(--border-strength-200)' : 'var(--content-primary)'"
                                                p-id="5196" data-spm-anchor-id="a313x.search_index.0.i2.72f13a81y4MtV6"
                                                class="selected">
                                        </path>
                                        <path
                                                d="M704 448H320a42.752 42.752 0 0 1-35.072-66.944l192-277.312c16-23.04 54.208-23.04 70.144 0l192 277.312A42.624 42.624 0 0 1 704 448z"
                                                :fill="pricesort == 1 || pricesort == 3 ? 'var(--border-strength-200)' : 'var(--content-primary)'"
                                                p-id="5197" data-spm-anchor-id="a313x.search_index.0.i1.72f13a81y4MtV6"
                                                class="selected">
                                        </path>
                                    </svg>
                                </div>
                                <div class="item">
                                    <span class="cursor">市值</span>
                                    <svg class="cursor" viewBox="0 0 1024 1024" version="1.1"
                                         xmlns="http://www.w3.org/2000/svg" p-id="5195" width="18" height="14">
                                        <path
                                                d="M512 938.688a42.624 42.624 0 0 1-35.072-18.432l-192-277.312A42.56 42.56 0 0 1 320 576h384a42.752 42.752 0 0 1 35.072 66.944l-192 277.312A42.688 42.688 0 0 1 512 938.688z"
                                                :fill="pricesort == 1 || pricesort == 2 ? 'var(--border-strength-200)' : 'var(--content-primary)'"
                                                p-id="5196" data-spm-anchor-id="a313x.search_index.0.i2.72f13a81y4MtV6"
                                                class="selected">
                                        </path>
                                        <path
                                                d="M704 448H320a42.752 42.752 0 0 1-35.072-66.944l192-277.312c16-23.04 54.208-23.04 70.144 0l192 277.312A42.624 42.624 0 0 1 704 448z"
                                                :fill="pricesort == 1 || pricesort == 3 ? 'var(--border-strength-200)' : 'var(--content-primary)'"
                                                p-id="5197" data-spm-anchor-id="a313x.search_index.0.i1.72f13a81y4MtV6"
                                                class="selected">
                                        </path>
                                    </svg>
                                </div>
                                <div class="item">
                                    <span class="cursor">成交量</span>
                                    <svg class="cursor" viewBox="0 0 1024 1024" version="1.1"
                                         xmlns="http://www.w3.org/2000/svg" p-id="5195" width="18" height="14">
                                        <path
                                                d="M512 938.688a42.624 42.624 0 0 1-35.072-18.432l-192-277.312A42.56 42.56 0 0 1 320 576h384a42.752 42.752 0 0 1 35.072 66.944l-192 277.312A42.688 42.688 0 0 1 512 938.688z"
                                                :fill="pricesort == 1 || pricesort == 2 ? 'var(--border-strength-200)' : 'var(--content-primary)'"
                                                p-id="5196" data-spm-anchor-id="a313x.search_index.0.i2.72f13a81y4MtV6"
                                                class="selected">
                                        </path>
                                        <path
                                                d="M704 448H320a42.752 42.752 0 0 1-35.072-66.944l192-277.312c16-23.04 54.208-23.04 70.144 0l192 277.312A42.624 42.624 0 0 1 704 448z"
                                                :fill="pricesort == 1 || pricesort == 3 ? 'var(--border-strength-200)' : 'var(--content-primary)'"
                                                p-id="5197" data-spm-anchor-id="a313x.search_index.0.i1.72f13a81y4MtV6"
                                                class="selected">
                                        </path>
                                    </svg>
                                </div>
                                <div class="item">
                                    <span>24小时涨跌</span>
                                </div>
                                <div class="item">
                                    <span>操作</span>
                                </div>
                            </div>
                            <!--   币种-->
                            <div class="_cell" v-for="(item,index) in futures" :key="'f'+item.id">
                                <div class="item">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15"
                                         viewBox="0 0 14 15" fill="none">
                                        <path
                                                d="M7.72569 12.5967C7.2741 12.3471 6.7259 12.3471 6.27432 12.5967L3.61611 14.0661C3.25083 14.2681 2.81354 13.96 2.88074 13.548L3.41223 10.2898C3.48887 9.81987 3.33791 9.3415 3.00543 9.00074L0.716954 6.65523C0.432749 6.36394 0.597017 5.87326 0.999316 5.81179L4.0759 5.34173C4.57038 5.26618 4.99467 4.94911 5.20721 4.49628L6.54738 1.64106C6.72734 1.25766 7.27266 1.25766 7.45262 1.64106L8.79279 4.49628C9.00533 4.94911 9.42962 5.26618 9.9241 5.34173L13.0007 5.81179C13.403 5.87326 13.5673 6.36394 13.283 6.65523L10.9946 9.00074C10.6621 9.3415 10.5111 9.81987 10.5878 10.2897L11.1193 13.548C11.1865 13.96 10.7492 14.2681 10.3839 14.0661L7.72569 12.5967Z"
                                                stroke="var(--content-tertiary)">
                                        </path>
                                    </svg>
                                    <div class="coin">
                                        <img class="coin-img" :src="item['icon']" alt="">
                                        <span class="color-black">
                                            {{item['symbol']}}
                                            <b class="font-12">/ USDT</b>
                                        </span>
                                        <img class="hot" src="@/assets/images/hot.svg" alt="">
                                    </div>
                                </div>
                                <div class="item">
                                    <span :class="{'color-red':item.change_rate <= 0,'color-green':item.change_rate > 0}">
                                        {{$util.thousandSeparator(item.new_price,item.decimals)}}
                                    </span>
                                </div>
                                <div class="item">
                                    <span :class="{'color-red':item.change_rate <= 0,'color-green':item.change_rate > 0}">
                                        {{$util.float(item.change_rate,item.decimals)}}%
                                    </span>
                                </div>
                                <div class="item">
                                    <span class="color-black">
                                        {{$util.thousandUnit(item.quantity)}}
                                    </span>
                                </div>
                                <div class="item">
                                    <kline :currencyId="item.currency_id" :change="item.change_rate"></kline>
                                </div>
                                <div class="item">
                                    <span class="color-primary">交易</span>
                                </div>
                            </div>
                            <!--    分页-->
                            <div class="pagination">
                                <a-pagination @change="nextPage" :page-size="psize" :total="total" show-total show-jumper/>
                            </div>
                            <a-empty v-if="false">
                                <template #image>
                                    <img :src="require(`@/assets/images/assets_img/no-data-${userTheme}.svg`)" alt="">
                                </template>
                                No data, please reload!
                            </a-empty>
                        </div>
                    </a-tab-pane>
                </a-tabs>
            </div>

        </a-layout-content>
        <a-layout-footer>
            <Footer></Footer>
        </a-layout-footer>
    </a-layout>
</template>
<script>
import service from './service';

export default service;
</script>

<style lang="scss" scoped>
@import "quotes";
</style>