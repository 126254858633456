<template>
  <a-layout class="easyEarn">
    <a-layout-header>
      <PageNav></PageNav>
    </a-layout-header>
    <a-layout-content>
      <div class="earn-body">
        <div class="banner">
          <div class="banner-content">
            <div class="_left">
              <h1>活币宝</h1>
              <p>每日计息，一键赎回</p>
            </div>
            <div class="_right">
              <div class="not-log">
                <svg width="50" height="50" viewBox="0 0 66 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M52.962 3.83c3.23 4.245 2.435 10.285-1.776 13.49-4.212 3.204-10.245 2.36-13.475-1.886-3.23-4.245-2.435-10.285 1.776-13.49C43.7-1.26 49.732-.415 52.962 3.83Z"
                      fill="#9C69FF"></path>
                  <path
                      d="M23.228 29.47c-4.163-5.471-3.138-13.254 2.29-17.383 5.426-4.13 13.2-3.042 17.363 2.429 4.163 5.47 3.138 13.253-2.29 17.382-5.426 4.13-13.2 3.042-17.363-2.428Z"
                      fill="#05C3DD"></path>
                  <g filter="url(#assetLogin_svg__a)">
                    <rect x="9" y="22" width="47" height="33" rx="1.82" fill="url(#assetLogin_svg__b)"
                          fill-opacity="0.5"></rect>
                    <rect x="8.75" y="21.75" width="47.5" height="33.5" rx="2.07" stroke="#393939"
                          stroke-width="0.5"></rect>
                  </g>
                  <path fill="#05C3DD" d="M47.233 35.597H58v6.63H47.233z"></path>
                  <defs>
                    <linearGradient id="assetLogin_svg__b" x1="10.152" y1="32.033" x2="15.541" y2="7.514"
                                    gradientUnits="userSpaceOnUse">
                      <stop stop-color="#B8B8B8"></stop>
                      <stop offset="1" stop-color="#393939" stop-opacity="0"></stop>
                    </linearGradient>
                    <filter id="assetLogin_svg__a" x="0.191" y="13.191" width="64.618" height="50.618"
                            filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                      <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
                      <feGaussianBlur in="BackgroundImageFix" stdDeviation="4.154"></feGaussianBlur>
                      <feComposite in2="SourceAlpha" operator="in"
                                   result="effect1_backgroundBlur_2497_16220"></feComposite>
                      <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_2497_16220" result="shape"></feBlend>
                    </filter>
                  </defs>
                </svg>
                <p>登录查看收益</p>
                <a-button class="button">登录</a-button>
              </div>
            </div>
          </div>
        </div>
        <div class="product">
          <div class="product-content">
            <div class="head">
              <div class="title">
                <span>精选产品</span>
                <span>存入资产获得稳定收益</span>
              </div>
            </div>
            <div class="product-list">
              <div class="subTabs">
                <div class="item">
                  <span>币种</span>
                </div>
                <div class="item">
                  <span>参考年化</span>
                </div>
                <div class="item">
                  <span>期限（天）</span>
                </div>
                <div class="item">
                  <span>自动申购</span>
                </div>
                <div class="item">
                  <span>操作</span>
                </div>
              </div>
              <div class="content">
                <div class="cell" v-for="i in 8">
                  <div class="item">
                    <img src="@/assets/images/coin/btc.png" alt="">
                    <span>BTC</span>
                  </div>
                  <div class="item">
                    <span>2.00% <icon-info-circle/></span>
                  </div>
                  <div class="item">
                    <a-radio-group class="radioGroup" :default-value="1">
                      <a-radio :value="(index+1)" v-for="(item,index) in ['活期','7','14']">
                        <template #radio="{ checked }">
                          <div class="radioBox" :class="{'checked': checked}" :checked="checked" checkable>
                            <span>{{ item }}</span>
                            <svg v-if="checked"
                                 width="17"
                                 height="17"
                                 viewBox="0 0 17 17"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M8 0C3.58172 0 0 3.58172 0 8V17H9C13.4183 17 17 13.4183 17 9V0H8ZM11.6583 5.21726C11.9728 4.91977 12.4629 4.92892 12.7664 5.23776C13.0703 5.54647 13.0787 6.04311 12.7856 6.36227L7.46826 11.7631C7.15686 12.079 6.65235 12.079 6.34095 11.7631L4.21412 9.60273C3.92123 9.28357 3.92984 8.78615 4.23366 8.47757C4.53734 8.16911 5.02707 8.16036 5.3413 8.45772L6.90461 10.0456L11.6583 5.21726Z"
                                    fill="#03AAC7">
                              </path>
                            </svg>
                          </div>
                        </template>
                      </a-radio>
                    </a-radio-group>
                  </div>
                  <div class="item">
                    <a-switch checked-color="var(--color-primary)" unchecked-color="var(--background-secondary)"/>
                  </div>
                  <div class="item">
                    <a-button class="button" @click="visible = true">立即申购</a-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--      立即申购弹窗-->
      <a-modal :visible="visible"
               align-center
               :render-to-body="false"
               mask-style="background:rgba(0, 0, 0, .5)"
               modal-class="mode-box"
               :hide-title="true"
               :footer="false">
        <div class="modal-con">
          <div class="title-box flex align-center jus-bet">
            <span class="font-24 font-bold color-black">申购确认</span>
            <icon-close @click="visible = false" class="color-black font-bold pointer" size="24"/>
          </div>
          <div class="flex align-start jus-bet con-box">
            <div class="flex flex-column align-start con-left">
              <div class="cell flex align-center jus-bet">
                <span class="font-14 color-gray">申购产品</span>
                <span class="font-14 color-black">活币宝</span>
              </div>
              <div class="cell flex align-center jus-bet">
                <div class="flex align-center">
                  <span class="font-14 color-gray mr-5">赎回方式</span>
                  <a-tooltip background-color="var(--tooltip-bg)">
                    <icon-question-circle-fill class="color-gray pointer"/>
                    <template #content>
                      <p class="color-always-white font-13">
                        赎回规则：<br />
                        活币宝支持T+0和T+1两种到账方式；<br />
                        若选择T+0到账，赎回确认后，份额将10分钟内到账，但需收取1%赎回费，且设有单日赎回上限。<br />
                        若选择T+1到账，赎回确认后，份额将于次日12:00(UTC+8)到账，无需支付任何赎回费，且没有任何赎回上限。</p>
                    </template>
                  </a-tooltip>
                </div>
                <span class="font-14 color-black">T+0&T+1</span>
              </div>
              <div class="cell flex align-center jus-bet">
                <span class="font-14 color-gray">日息</span>
                <span class="font-14 color-black">0.6‱</span>
              </div>
              <div class="cell flex align-center jus-bet">
                <span class="font-14 color-gray">年化</span>
                <span class="font-14 color-black">2.19%</span>
              </div>
              <div class="cell cell-input flex flex-column align-start">
                <span class="font-14 color-gray mb-5">申购数量</span>
                <div class="input_box mb-5">
                  <a-input placeholder="最低申购 1">
                    <template #suffix>
                      <div class="flex align-center jus-end">
                        <span class="font-14 color-gray">USDT</span>
                        <span>|</span>
                        <span class="color-primary pointer">最大</span>
                      </div>
                    </template>
                  </a-input>
                </div>
                <span class="font-13 color-red" v-if="false">账户可用余额不足</span>
              </div>
              <div class="cell flex align-center jus-bet">
                <div class="flex align-center">
                  <span class="font-14 color-gray">可用余额：</span>
                  <span class="font-14 color-black">0.0000 USDT</span>
                </div>
                <span class="color-primary pointer">划转</span>
              </div>
              <div class="cell flex align-center jus-bet mt-10">
                <span class="font-14 color-black">自动申购</span>
                <a-switch checked-color="var(--color-primary)" unchecked-color="var(--background-secondary)">
                  <template #checked-icon>
                    <icon-check/>
                  </template>
                  <template #unchecked-icon>
                    <icon-close/>
                  </template>
                </a-switch>
              </div>
              <div class="cell flex align-center jus-bet mt-10">
                <span class="font-12 color-gray">开启后，系统将在每日18:00 (UTC+8)自动将您资金账户和理财账户的全部可用余额转入活币宝。</span>
              </div>
            </div>
            <div class="flex flex-column align-start con-right">
              <p class="font-16 color-black">交易详情</p>
              <div class="cell flex align-center jus-bet mt-10">
                <span class="font-14 color-black">预计日息</span>
                <span class="font-14 color-black">0 USDT</span>
              </div>
              <div class="timeline">
                <a-timeline>
                  <a-timeline-item label="2017-03-10">买入提交</a-timeline-item>
                  <a-timeline-item label="2018-05-12">起息日</a-timeline-item>
                </a-timeline>
              </div>
              <div class="cell flex align-center jus-bet">
                <div class="flex align-center">
                  <span class="font-14 color-gray mr-5">固定日息</span>
                  <a-tooltip background-color="#636870">
                    <icon-question-circle-fill class="color-gray pointer"/>
                    <template #content>
                      <p>计息规则：</p>
                      <p>活币宝支持固定日息和阶梯日息两种计息方式，每天12:00(UTC+8)进行日息结算，收益为币本位。</p>
                      <p>固定日息 = 活币宝账户12:00时余额 × 固定日息率</p>
                      <p>阶梯日息 = 梯度1金额 × 梯度1日息率 + 梯度2金额 × 梯度2日息率 + …</p>
                      <p>注：具体日息率数值可能会根据市场行情进行调整。</p>
                    </template>
                  </a-tooltip>
                </div>
                <span class="font-14 color-green">0.6‱</span>
              </div>
            </div>
          </div>
          <div class="flex align-center mt-10">
            <div class="pointer flex align-center">
              <a-checkbox class="mr-5">
                <span class="font-14 color-black mr-5">我已阅读并同意</span>
                <span class="font-14 color-primary">《ComeBit活币宝产品用户协议》</span>
              </a-checkbox>
            </div>
          </div>
          <div class="flex align-center jus-bet btn-group">
            <a-button @click="visible = false">取消</a-button>
            <a-button disabled>确认</a-button>
          </div>
        </div>
      </a-modal>
    </a-layout-content>
    <a-layout-footer>
      <Footer></Footer>
    </a-layout-footer>
  </a-layout>
</template>
<script>
import Footer from "@/components/Footer/footer.vue";
import PageNav from "@/components/PageNav/PageNav.vue";
import notlog from "@/components/notlog/notlog.vue";

export default {
  components: {notlog, PageNav, Footer},
  data() {
    return {
      coinList: [
        {
          type: "usdt",
          name: "0.6‱",
          rate: "2.19%"
        }, {
          type: "btc",
          name: "0.4‱",
          rate: "1.46%"
        }, {
          type: "eth",
          name: "0.5‱",
          rate: "1.825%"
        },],
      visible: false
    }
  },
  methods: {
    toTerm() {
      this.$router.push("/term")
    },
    toDetail() {
      this.$router.push("/HomeView/investment/Term/detail")
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./easyEarn.scss";
</style>