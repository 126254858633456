import PageNav from "@/components/PageNav/PageNav.vue";
import Footer from "@/components/Footer/footer.vue";
import kline from "@/components/kline/kline.vue";
// Import Swiper Vue.js components
import {Swiper, SwiperSlide} from 'swiper/vue';
// 引入swiper卡片模块
import {Navigation, Pagination} from 'swiper/modules';

import currencyApi from '@/common/api/currency';
import futuresApi from '@/common/api/futures';

import {mapGetters} from "vuex";

let trades = false;
let tab = 1;
export default {
    components: {
        Footer,
        PageNav,
        Swiper,
        SwiperSlide,
        kline
    },
    computed: {
        ...mapGetters(['userTheme'])
    },
    data() {
        return {
            loop: true,
            subModules: [Navigation],
            selectVal: 'USDT',
            coinIndex: 0,
            coinList: [
                {
                    title: 'USDT',
                    currency: []
                }
            ],
            tagSelected: 0,
            activeIndex: 0,
            pricesort: 1,
            subtabOptions: ['U本位合约', '币本位合约', 'USDC合约'],
            subActive: 0,

            tradeCurrency: [{currency: []}],
            total: 0,
            psize: 30,
            list: [],
            page: 1,
            futures:[]
        }
    },
    mounted() {
        this.getTradesCurrency();
    },
    methods: {
        search(e){
            if(e !== ''){
                this.list = this.tradeCurrency[this.tagSelected]['currency'].filter((item)=>{
                    return item.symbol.toLowerCase().includes(e.toLowerCase());
                });
            }else{
                if(tab === 1){
                    this.page = 1;
                    this.list = trades[this.tagSelected]['currency'].slice(this.psize * (this.page - 1), this.page * this.psize);
                }else{
                    this.page = 1;
                    this.list = this.tradeCurrency[this.tagSelected]['currency'].slice(this.psize * (this.page - 1), this.page * this.psize);
                }
            }
        },
        nextPage(e) {
            this.page = e;
            this.list = this.tradeCurrency[this.tagSelected]['currency'].slice(this.psize * (this.page - 1), this.page * this.psize);
        },
        toTrade(item) {
            this.$util.setCache('trade-current', JSON.stringify(item));
            return this.$router.push('/spot');
        },
        switchTab(e) {
            tab = parseInt(e);
            this.list = [];
            if(parseInt(e) !== 1 && !trades){
                trades = this.tradeCurrency;
            }
            if(parseInt(e) === 1){
                this.page = 1;
                this.list = trades[this.tagSelected]['currency'].slice(this.psize * (this.page - 1), this.page * this.psize);
            }
            if(parseInt(e) === 2)
            {
                this.tagSelected = 0;
                currencyApi.contractCurrencyList().then(res => {
                    this.tradeCurrency = res.data;
                    this.list = this.tradeCurrency[this.tagSelected]['currency'].slice(this.psize * (this.page - 1), this.page * this.psize);
                    this.page = 1;
                    this.total = res.data[this.tagSelected]['currency'].length;
                })
            }
            if(parseInt(e) === 3){
                this.tagSelected = 0;
                futuresApi.getCurrency().then(res=>{
                    this.futures = res.data;
                    this.total = this.futures.length;
                    this.page = 1;
                });
            }
        },
        getTradesCurrency() {
            currencyApi.tradeCurrencyList().then(res => {
                this.tradeCurrency = res.data;
                this.total = this.tradeCurrency[this.tagSelected]['currency'].length;
                this.list = this.tradeCurrency[this.tagSelected]['currency'].slice(this.psize * (this.page - 1), this.page * this.psize);
                trades = res.data;
            });
        },
        handleSelect(e) {
            console.log(e)
            this.selectVal = e;
        },
        coinChange(item, index) {
            console.log(item.subTabs)
            this.coinIndex = index;
        },
        slideChange(e) {
            this.activeIndex = e.activeIndex;
        }
    }
}