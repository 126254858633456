<template>
    <!-- model框 -->
    <div class="model">
        <div :style="{ top : top + '%'}" class="model-box">
            <div :style="{ width: width + 'px' ,height:height + 'px'}"  class="model-content">
                <div class="model-content-header">
                    <div v-if="title">{{ title }}</div>
                    <slot v-else name="title"></slot>
                    <div @click="$emit('close')"><icon-close /></div>
                </div>
                <div class="model-content-box">
                    <slot name="content"></slot>
                </div>
                <div class="model-content-footer">
                    <div v-if="showCancelButton">
                        <a-botton :style="{ width: cancelButtonWidth + 'px' }" class="model-content-footer-btn" @click="handleCancel">取消</a-botton>
                    </div>
                    <div v-if="showConfirmButton">
                        <a-botton :style="{ width: confirmButtonWidth + 'px'}" class="model-content-footer-btn2" @click="handleOk">确定</a-botton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        width: {
            type: String,
            default: '100%',
        },
        height: {
            type: String,
            default: '100%',
        },
        title: {
            type: String,
            default: '',
        },
        showCancelButton: {
            type: Boolean,
            default: true,
        },
        showConfirmButton: {
            type: Boolean,
            default: true,
        },
        // 关闭按钮宽度
        cancelButtonWidth: {
            type: String,
            default: '140',
        },
        // 确定按钮宽度
        confirmButtonWidth: {
            type: String,
            default: '140',
        },
        // 距离顶部
        top: {
            type: String,
            default: '50',
        },
        logo: {
            type: String,
            default: '',
        }
    },
    data() {
        return {
            name: 'modelContent',
        }
    },
    methods: {
        // 取消
        handleCancel(){
            this.$emit('close')
        },
        // 确认
        handleOk(){
            this.$emit('handleOk')
        }
    }
}
</script>
<style lang="scss" scoped>
.model {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.5);

    .model-box {
        position: fixed;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        padding: 32px;
        background: #161a1e;
        border-radius: 4px;

        .model-content {
            display: flex;
            flex-direction: column;

            .model-content-header {
                justify-content: space-between;
                display: flex;
                font-size: 20px;
                font-weight: 500;
                color: #fff;
                margin-bottom: 20px;
            }

            .model-content-box {
                display: flex;
                margin-top: 20px;
                flex-direction: column;
                align-items: flex-start;
            }

            .model-content-footer {
                display: flex;
                justify-content: flex-end;
                margin-top: 40px;
                
                .model-content-footer-btn {
                    //设置宽度自适应
                    display: inline-block;
                    height: 44px;
                    color: #f0f6ff;
                    text-align: center;
                    transition: .1s;
                    font-weight: 500;
                    padding: 12px 20px;
                    font-size: 14px;
                    border-radius: 4px;
                    box-sizing: border-box;
                    outline: 0;
                    margin: 0;
                    white-space: nowrap;
                    cursor: pointer;
                    background: #2c3138;
                    margin-right: 10px;

                }

                .model-content-footer-btn:hover {
                    background: #393d44;
                }

                .model-content-footer-btn2 {
                    display: inline-block;
                    height: 44px;
                    color: #f0f6ff;
                    text-align: center;
                    transition: .1s;
                    font-weight: 500;
                    padding: 12px 20px;
                    font-size: 14px;
                    border-radius: 4px;
                    box-sizing: border-box;
                    outline: 0;
                    margin: 0;
                    white-space: nowrap;
                    cursor: pointer;
                    background: #2c3138;
                }

                .model-content-footer-btn2:hover {
                    background: #0072f5;
                }
            }

        }
    }
}</style>